I18n.translations || (I18n.translations = {});
I18n.translations["cs"] = I18n.extend((I18n.translations["cs"] || {}), {
  "activities": {
    "action": {
      "close": "Uzavřel úkol",
      "comment": "Okomentoval",
      "create": "Vytvořil úkol",
      "edit": "Upravil úkol"
    },
    "assigned_to": "Řešitel",
    "author": "Autorem",
    "change": {
      "all": "vše",
      "assigned_to": "řešitele",
      "author": "autora",
      "close": "uzavření úkolu",
      "create": "vytvoření úkolu",
      "description": "popisu",
      "done_ratio": "procent hotovo",
      "due_date": "termínu úkolu",
      "estimated_hours": "odhadu času",
      "me": "já",
      "note": "přidání komentáře",
      "others": "ostatní",
      "priority": "priority",
      "project": "přesun do jiného projektu",
      "start_date": "začátku úkolu",
      "status": "stavu",
      "tracker": "fronty"
    },
    "changes": "Změny",
    "complete_list": "Kompletní výpis",
    "no_activity": "Zatím bez aktivity.",
    "projects": "Projekty"
  },
  "admin": {
    "client": {
      "billing": {
        "cancel": "zrušit změny",
        "change_info": "Změnit údaje",
        "change_save": "Uložit změny",
        "change_tariff": "Uložit a vybrat balíček",
        "city": "Město",
        "company": "Společnost",
        "contact_info": "Kontaktní údaje",
        "country": "Stát",
        "country_cs": "Česká republika",
        "country_sk": "Slovenská republika",
        "dic_cs": "DIČ",
        "dic_sk": "IČ DPH",
        "email": "E-mail",
        "firstname": "Jméno",
        "ic": "IČO",
        "invoice_email": "Fakturační email",
        "invoice_info": "Fakturační údaje",
        "lastname": "Příjmení",
        "phone": "Telefon",
        "street": "Ulice",
        "zip": "PSČ"
      },
      "messages": {
        "deleted": "Předplatné zrušeno. Aplikace Projektově bude ke konci fakturačního období zastavena a následně smazána.",
        "deleted_trial": "Zkušební verze byla ukončena. Brzy bude účet se všemi daty kompletně smazán.",
        "new": "Balíček úspěšně objednán. Aplikace Projektově je nyní plně funkční.",
        "updated": "Balíček úspěšně změněn."
      },
      "tariff": {
        "cancel": "zrušit předplatné",
        "cancel_title": "Opravdu si přejete zrušit předplatné Projektově?",
        "cancel_trial": "ukončit zkušební verzi",
        "cancel_trial_title": "Opravdu si přejete ukončit zkušební verzi?",
        "change": "Změnit balíček",
        "current": "Aktuální balíček",
        "custom": "Individuální",
        "custom_tariff_notice": "Potřebujete změnit balíček nebo upravit fakturační období? Kontaktujte naši zákaznickou podporu.",
        "details": "Podrobnosti balíčku",
        "individual_20": "Individual 20",
        "individual_30": "Individual 30",
        "individual_40": "Individual 40",
        "individual_50": "Individual 50",
        "onpremise": "On-premise",
        "order": "Objednat balíček",
        "payment_info": "Systém po objednání nebo změně balíčku pravidelně vystavuje výzvy k platbě a po úhradě zasílá daňový doklad.",
        "payment_invoice": "Fakturace",
        "payment_method": "Platební metody",
        "payment_type": "Způsob Platby",
        "prodva": "Pro dva",
        "promo": "Promo",
        "standard": "Standard",
        "start": "Start",
        "trial": "Zkušební verze",
        "users_count": "Počet uživatelů v balíčku",
        "users_current": "Aktuální počet uživatelů",
        "users_manage": "spravovat uživatele"
      },
      "tariff_dialog": {
        "affirmation_accept": "Souhlasím s",
        "affirmation_terms": "obchodními podmínkami",
        "annual": "roční (sleva 10 %)",
        "cancel": "zrušit změny",
        "choose": "Balíček k objednání",
        "discount": "Sleva",
        "monthly": "měsíční",
        "price": "Cena",
        "price_final": "Konečná cena",
        "price_monthly": " / měsíc",
        "quarterly": "čtvrtletní",
        "semi_annual": "půlroční",
        "tax_notice": "Uvedené ceny nezahrnují DPH, jsme plátci DPH.",
        "users": "Uživatelů",
        "users_total": "Celkový počet uživatelů"
      },
      "title": "Účet služby Projektově"
    },
    "custom_fields": {
      "cancel_button": "Zrušit",
      "check_warning": "Jakmile aktivujete tuto volbu, nebude již možné ji vypnout.",
      "confirm_delete": "Opravdu si přejete odebrat tuto frontu?",
      "confirm_delete_alternative": "Opravdu si přejete změnit frontu?",
      "custom_field_header": "Správa vlastních polí",
      "delete_button": "Smazat",
      "delete_field_confirm": "Opravdu si přejete smazat uživatelské pole?",
      "delete_field_label": "Tato akce odstraní také všechny dosud vyplněné hodnoty k tomuto uživatelskému poli.",
      "delete_field_success": "Uživatelské pole úspěšně odstraněno.",
      "delete_tracker": "Odebraním fronty se nenávratně smažou i všechny zadané hodnoty z odebírané fronty.",
      "delete_tracker_alternative": "Zvolením fronty budou data z ostatních front nenávratně smazána. Pole bylo viditelné u všech front, nyní bude jen u vybraných. Zadaná data k vlastnímu poli vybraných front zůstanou, u ostatních budou smazána.",
      "edit_button": "Upravit",
      "edit_field_success": "Změny uživatelského pole uloženy.",
      "enable_more_values": "Povolit výběr více hodnot",
      "field_add": "Přidat další pole",
      "label_field": "Pole",
      "label_field_visibility": "Viditelnost polí",
      "label_label": "Popis",
      "label_show_in_projectslist": "Výpis projektů",
      "label_sorting": "Řazení",
      "label_tracker": "Fronta",
      "label_type": "Typ",
      "maximum_number": "Dosaženo maximálniho počtu vlastních polí.",
      "move_begin": "Přesunout na začátek",
      "move_end": "Přesunout na konec",
      "move_higher": "Přesunout nahoru",
      "move_lower": "Přesunout dolu",
      "name_and_tracker_error": "Zadejte název a vyberte nejméně jednu frontu.",
      "name_and_type_error": "Zadejte název a zvolte typ uživatelského pole.",
      "name_error": "Zadejte název uživatelského pole.",
      "new_line": "Každá hodnota na novém řádku.",
      "no_possible_values": "Je zvolen typ 'seznam', je potřeba uvést také možné hodnoty.",
      "possible_values": "Možné hodnoty",
      "possible_values_changed": "Možné hodnoty byly nastaveny.",
      "possible_values_obligatory": "Zadejte možné hodnoty.",
      "projects_label": "Projekty",
      "same_name_error": "Název pole už existuje, zvolte jiný název.",
      "save_button": "Uložit",
      "tasks_label": "Úkoly",
      "tracker_and_type_error": "Vyberte nejméně jednu front a zvolte typ uživatelského pole.",
      "tracker_error": "Vyberte nejméně jednu frontu.",
      "type_error": "Zvolte typ uživatelského pole.",
      "values_change": "Upravit hodnoty seznamu",
      "visible_for_all": "Viditelne všem"
    }
  },
  "budget_entries": {
    "expense_time_table": {
      "all": "vše",
      "filter": "Filtr",
      "show": "Zobrazit",
      "subtasks2": "skrýt pod-úkoly od 2 úrovně",
      "sum": "Celkem",
      "task": "Úkol",
      "title": "Časové rozvržení skutečných nákladů",
      "types": {
        "planned": "plánované náklady",
        "real": "skutečné náklady"
      },
      "warning": "Pozor, jedná se o prostý součet vypsaných řádků. Nerespektuje vztah úkolů a pod-úkolů. Pokud je výše vyfiltrován mix úkolů a pod-úkolů, součet nebude odpovídat.",
      "without_subtasks": "jen hlavní úkoly"
    },
    "modal": {
      "category": "Kategorie",
      "comment": "Poznámka",
      "date": "Datum",
      "not_set": "Nezařazeno",
      "spent_hours": "Strávené časy",
      "summary": "Souhrn",
      "task": "Úkol",
      "value": "Částka",
      "value_vat": "s DPH",
      "value_without_vat": "bez DPH",
      "variable_costs": "Variabilní náklady",
      "vat": "Daň"
    },
    "project_bilances": {
      "cash_flow": "Cash flow",
      "cash_flow_label": "skutečný příjem - skutečné náklady",
      "income_diff": "Zbývá vyfakturovat",
      "planned_expenses": "Plánované náklady",
      "project": "Projekt",
      "real_diff": "Budoucí cash flow",
      "real_income": "Skutečný příjem",
      "title": "Bilance projektů"
    },
    "report": {
      "add": "Přidat položku (úkol)",
      "addNew": "Stisknutím enter vytvoříte novou položku",
      "add_expense": "Přidat náklad",
      "add_income": "Přidat příjem",
      "added_manually": "Plánovaný náklad byl přidán ručně",
      "added_recalculated": "Plánovaný náklad byl vypočítán z dílčích nákladů nebo byl nastaven přepočítáním z odhadovaných časů.",
      "czech_currency": "Kč",
      "delete_budget_line": "Odebrat položky z rozpočtu",
      "diff": "Rozdíl",
      "expense": "Náklad",
      "expenseHours": "Náklad - variabilní",
      "expenses": "Náklady",
      "expenses_list": "Výpis nákladů:",
      "hidden_planned_expenses": "Skryté plánované náklady",
      "hidden_real_expenses": "Skryté skutečné náklady",
      "hide": "Nezobrazovat tuto položku v rozpočtu",
      "income": "Příjem",
      "incomes_list": "Výpis příjmů:",
      "list_all_expenses": "Výpis všech nákladů",
      "notify_higher_income": "Zadejte vyšší příjem",
      "notify_higher_income_text": "Celkový plánovaný příjem musí přesahovat plánované příjmy v podprojektech, které jsou nyní",
      "plan": "Plán",
      "planned_income": "Příjem (dle smlouvy)",
      "planned_profit": "Plánovaný zisk",
      "project_budget": "Rozpočet projektu",
      "real_diff_label": "V projektu bude fakturováno %{incomes}, zbývající náklady jsou %{expenses}. Budoucí peněžní tok je %{diff} aby byl splněn plán.",
      "real_expenses": "Skutečné náklady",
      "reality": "Skutečnost",
      "spent_time": "Čas zapsaný mimo úkoly",
      "sum": "Bilance",
      "sum_reserve": "Bilance / rezerva",
      "toggle": "Přepnout",
      "total": "Celkem",
      "total_real_expenses": "Součet skutečných nákladů včetně podúkolů",
      "withVat": "s DPH",
      "withoutVat": "bez DPH"
    },
    "submenu": {
      "prefill": "Předvyplnit plánované náklady",
      "prefillText": "Přejete si předvyplnit plánované náklady vypočítáním odhadovaného času krát nákladová hodinová sazba řešitele?",
      "prefillWarning": "Pozor, stisknutím ano přepíšete plán.",
      "showAllIssues": "Zobrazit v rozpočtu všechny úkoly",
      "showOnlyRootIssues": "Zobrazit v rozpočtu jen hlavní úkoly",
      "sorting": {
        "default": "výchozí",
        "dueDate": "dle termínů",
        "heading": "Řazení",
        "label": "Pořadí položek v rozpočtu",
        "startDate": "dle začátků",
        "subject": "dle názvu"
      }
    }
  },
  "dashboard": {
    "add_blocks": {
      "add": "Přidat",
      "add_error": "Přidání bloku se nezdařilo. Zkuste to, prosím, znovu.",
      "choose": "Přidat",
      "labels": {
        "activities": "Poslední aktivita",
        "allprojects": "Všechny projekty",
        "estimatedhoursbyprojects": "Graf vytížení dle projektů",
        "estimatedhoursbyusers": "Graf vytížení týmu",
        "estimatedhourstable": "Tabulka vytížení týmu",
        "favouriteprojects": "Oblíbené projekty",
        "iframe": "Vlastní blok",
        "iframebottom": "Vlastní blok - spodní",
        "issuesassignedto": "Řešitelem",
        "issuesreportedbyme": "Mnou delegované úkoly",
        "issueswatched": "Sledované úkoly",
        "polls": "Hlasování",
        "shouldbeinprogress": "Úkoly, které mají být v řešení",
        "solvedclosedtasks": "Vyřešené a uzavřené úkoly",
        "timelog": "Můj strávený čas"
      },
      "reset": "Odebrat vše",
      "reset_error": "Resetování bloků se nezdařilo. Zkuste to, prosím, znovu.",
      "title_here": "Přizpůsobit tuto stránku"
    },
    "blockEstimatedHoursByProjects": {
      "defaultView": "Pokud není nic vybráno, použije se výchozí zobrazení - prvních 30 projektů.",
      "estimated_hours": "Vytížení dle projektů",
      "graphTitle": "Výhled na 4 měsíce",
      "no_due_date": "Bez termínu",
      "past": "V minulosti",
      "tableTitle": "Hodiny mimo graf"
    },
    "blockEstimatedHoursByUsers": {
      "estimated_hours": "Vytížení uživatelů",
      "graphTitle": "Výhled na 4 měsíce",
      "no_due_date": "Bez termínu",
      "past": "V minulosti",
      "tableTitle": "Hodiny mimo graf"
    },
    "block_activities": {
      "title": "Poslední aktivita"
    },
    "block_all_projects": {
      "all_projects": "Všechny projekty",
      "empty": "Nemáte zde žádné projekty."
    },
    "block_assigned_to": {
      "assigned_to": "Řešitelem %{name}",
      "complete_list": "Kompletní výpis (%{size})",
      "empty": "Nemáte zde žádné úkoly.",
      "my_tasks": "Mé úkoly"
    },
    "block_author_me_tasks": {
      "author_me_tasks": "Mnou delegované úkoly",
      "empty": "Nemáte zde žádné úkoly.",
      "show": "Zobrazit"
    },
    "block_favorite_projects": {
      "empty": "Nemáte žádné oblíbené projekty. Označte je kliknutím na",
      "empty_end": "ve výpise projektů.",
      "favorite_projects": "Oblíbené projekty"
    },
    "block_my_tasks_today": {
      "5days": "Zpožděno do 5 dnů",
      "approaching": "Blíží se",
      "approaching_dueDate": "Termín",
      "approaching_startDate": "Začátek",
      "before_yesterday": "Předevčírem",
      "my_day": "Můj den",
      "no_tasks": "Nemáte zde žádné úkoly.",
      "to_resolved": "Ve zpoždění",
      "today": "Termín dnes",
      "yesterday": "Včera"
    },
    "block_my_time_entries": {
      "add": "Přidat čas",
      "allTimes": "Celkem",
      "comment": "Komentář",
      "complete": "Kompletní výpis",
      "empty": "V posledních 7 dnech nemáte žádný strávený čas.",
      "hours": "Hodiny",
      "project": "Projekt",
      "task": "Úkol",
      "title": "Můj strávený čas"
    },
    "block_polls": {
      "choice": "Volba",
      "due_date": "Termín",
      "question": "Otázka",
      "task": "Úkol"
    },
    "block_solved_closed_tasks": {
      "collapse": "Zkrátit",
      "expand": "Rozšířit",
      "title": "Vyřešené a uzavřené úkoly"
    },
    "block_watched_tasks": {
      "allAssigned": "i úkoly, kde jsem řešitelem",
      "all_watched_tasks_with": "Všechny sledované úkoly (%{size})",
      "all_watched_tasks_without": "Všechny sledované úkoly bez úkolů, kde jsem řešitelem (%{size})",
      "assignedWithoutMe": "bez úkolů, kde jsem řešitelem",
      "empty": "Nemáte zde žádné úkoly.",
      "show": "Zobrazit",
      "watched_tasks": "Sledované úkoly"
    },
    "complete_list": "Kompletní výpis",
    "planned_estimated_hours": "Naplánováno",
    "spent_hours": "Zapsaný čas"
  },
  "date": {
    "abbr_day_names": [
      "Ne",
      "Po",
      "Út",
      "St",
      "Čt",
      "Pá",
      "So"
    ],
    "abbr_month_names": [
      null,
      "Led",
      "Úno",
      "Bře",
      "Dub",
      "Kvě",
      "Čer",
      "Čec",
      "Srp",
      "Zář",
      "Říj",
      "Lis",
      "Pro"
    ],
    "day_names": [
      "Neděle",
      "Pondělí",
      "Úterý",
      "Středa",
      "Čtvrtek",
      "Pátek",
      "Sobota"
    ],
    "formats": {
      "default": "%-d. %-m. %Y",
      "long": "%B %d, %Y",
      "short": "%b %d"
    },
    "moment_formats": {
      "default": "D. M. YYYY",
      "from": "[od] D. M. YYYY",
      "short": "D. M.",
      "to": "[do] D. M. YYYY"
    },
    "month_names": [
      null,
      "Leden",
      "Únor",
      "Březen",
      "Duben",
      "Květen",
      "Červen",
      "Červenec",
      "Srpen",
      "Září",
      "Říjen",
      "Listopad",
      "Prosinec"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_time": {
      "day_before_yesterday_at": "předevčírem v %{time}",
      "today_at": "dnes v %{time}",
      "yesterday_at": "včera v %{time}"
    },
    "distance_in_words": {
      "about_x_hours": {
        "few": "asi %{count} hodiny",
        "one": "asi 1 hodinou",
        "other": "asi %{count} hodin"
      },
      "about_x_months": {
        "few": "asi %{count} měsíců",
        "one": "asi 1 měsícem",
        "other": "asi %{count} měsíců"
      },
      "about_x_years": {
        "few": "asi %{count} roky",
        "one": "asi 1 rok",
        "other": "asi %{count} let"
      },
      "almost_x_years": {
        "few": "téměř %{count} roky",
        "one": "téměř 1 rok",
        "other": "téměř %{count} let"
      },
      "half_a_minute": "půl minuty",
      "less_than_x_minutes": {
        "few": "méně než %{count} minutami",
        "one": "méně než minutou",
        "other": "méně než %{count} minut"
      },
      "less_than_x_seconds": {
        "few": "méně než %{count} sekundami",
        "one": "méně než sekundou",
        "other": "méně než %{count} sekund"
      },
      "over_x_years": {
        "few": "více než %{count} roky",
        "one": "více než 1 rok",
        "other": "více než %{count} let"
      },
      "x_days": {
        "few": "%{count} dny",
        "one": "1 den",
        "other": "%{count} dní"
      },
      "x_hours": {
        "few": "%{count} hodiny",
        "one": "1 hodina",
        "other": "%{count} hodin"
      },
      "x_minutes": {
        "few": "%{count} minuty",
        "one": "1 minuta",
        "other": "%{count} minut"
      },
      "x_months": {
        "few": "%{count} měsíce",
        "one": "1 měsíc",
        "other": "%{count} měsíců"
      },
      "x_seconds": {
        "few": "%{count} sekundami",
        "one": "1 sekunda",
        "other": "%{count} sekund"
      },
      "x_weeks": {
        "few": "%{count} týdny",
        "one": "1 týden",
        "other": "%{count} týdnů"
      }
    },
    "prompts": {
      "day": "Den",
      "hour": "Hodina",
      "minute": "Minuta",
      "month": "Měsíc",
      "second": "Sekunda",
      "year": "Rok"
    }
  },
  "default": {
    "admin_firstname": "Ukázka",
    "admin_lastname": "Projektově",
    "columns": {
      "assigned_to": "Řešitel",
      "author": "Autor",
      "due_date": "Termín",
      "estimated_hours": "Odhad",
      "priority": "Priorita",
      "project": "Projekt",
      "start_date": "Začátek",
      "status": "Stav",
      "subject": "Úkol",
      "total_spent_hours": "Stráveno"
    },
    "user": {
      "email": "e-mail",
      "firstname": "jméno",
      "language": "jazyk",
      "lastname": "příjmení"
    }
  },
  "estimated_hours_table": {
    "all_time": "Celkem",
    "block_name": "Naplánováné hodiny",
    "today": "Dnes",
    "tomorrow": "Zítra"
  },
  "files": {
    "addFile": "přidat soubor",
    "addFileToTask": "Zvolte úkol",
    "author": "Autor",
    "createdAt": "Datum",
    "download": "Stáhnout",
    "filename": "Soubor",
    "issue": "Ukol",
    "project": "Projekt",
    "projectFiles": "Soubory v projektu",
    "tasksFiles": "Soubory u úkolů"
  },
  "gantt": {
    "add_name": "Zadejte název nového úkolu",
    "add_new_task": "Přidat úkol",
    "add_next_task_header": "Přidat následující úkol",
    "add_previous_task_header": "Přidat předchozí úkol",
    "add_task": "Přidat úkol",
    "adding_subtask_to_task": "Přidání podúkolu k úkolu",
    "adding_task_to_project": "Přidání úkolu k projektu",
    "cancel": "Zrušit",
    "cancel_changes": "Zrušit změny",
    "cant_export_grouped_by_assigned_to": "Seskupený pohled dle řešitele není možno exportovat.",
    "change_parent_task_error": "Změna, kterou se chystáte vykonat, by vedla k změne datumu obálkového úkolu. Táto funkce je dostupná pouze manažerovi.",
    "color_by": {
      "default": "výchozí",
      "label": "Barva",
      "projects": "dle projektu",
      "templateTask": "dle úkolu z šablony"
    },
    "confirm_link_deleting": "bude odebrána.",
    "conflicts_warning": {
      "note": "Kliknutím na úkol zobrazíte jeho kartu, kde můžete změny zkontrolovat. Uložením diagramu nastavíte začátky a termíny úkolů z Ganttova diagramu.",
      "task": {
        "few": "Úkoly",
        "one": "Úkol",
        "other": "Úkoly"
      },
      "was_changed": {
        "few": ", které upravujete byly na serveru také změněny.",
        "one": ", který upravujete byl na serveru také změněn.",
        "other": ", které upravujete byly na serveru také změněny."
      }
    },
    "date_range": {
      "all_time": "Vše",
      "current_month": "Tento měsíc",
      "current_week": "Tento týden",
      "current_year": "Tento rok",
      "custom": "Vlastní",
      "next_month": "příští měsíc",
      "next_week": "Příští týden",
      "next_year": "Příští rok",
      "three_months": "3 měsíce",
      "today": "Dnes"
    },
    "filter_tasks": "Filtr na úkoly",
    "filter_user": "Filtr na řešitele",
    "fullscreen": "Celá obrazovka",
    "group_by_assignee": "Seskupit dle řešitele",
    "group_by_projects": "Seskupit dle projektů",
    "heading": "Ganttův diagram",
    "label_days": "dní",
    "label_delete_relation": "Opravdu si přejete smazat vazbu směrující z",
    "label_done": "Hotovo",
    "label_duration": "Trvání",
    "label_end_after_start": "Termín musí být později než začek úkolu.",
    "label_export": "Exportovat do",
    "label_from": "Z",
    "label_next": "Další",
    "label_no": "Ne",
    "label_previous": "Předchozí",
    "label_project": "Projekt",
    "label_start_before_end": "Datum začátku musí být dříve než termín úkolu.",
    "label_to": "Do",
    "label_to_small": "do",
    "label_yes": "Ano",
    "link": "Vazba",
    "new_task_added": "Nový úkol úspěšně přidán.",
    "new_task_not_added": "Nový úkol se nepodařilo přidat.",
    "next_tasks_header": "Stávající následující úkoly",
    "no_next_tasks": "Žádné stávající následující úkoly.",
    "no_previous_tasks": "Žádné stávající předchozí úkoly.",
    "other_exports": "Další varianty",
    "planned_dueDate": "Plánovaný konec",
    "planned_startDate": "Plánovaný začátek",
    "previous_tasks_header": "Stávající předchozí úkoly",
    "readonly_note": "Verze pro čtení. Pro aktivní verzi musíte být manažerem ve všech zobrazených projektech.",
    "save_changes": "Uložit změny",
    "save_first": "Nejprve uložte nebo zahoďte změny.",
    "section_time": "Datum začátku a trvání úkolu",
    "show": {
      "all": "vše",
      "label": "Zobrazit",
      "projects": "jen projekty",
      "subtasks2": "skrýt podúkoly od 2 úrovně",
      "tasks": "projekty a hlavní úkoly"
    },
    "show_closed_tasks": "Zobrazit uzavřené",
    "sort_tasks": "Seřadit úkoly",
    "task_duration": "Trvání",
    "task_end": "Termín",
    "task_name": "Název úkolu",
    "task_order": "Automaticky řadit úkoly",
    "task_start": "Začátek",
    "time_disable_button": "Odebrat časy",
    "time_enable_button": "Naplánovat",
    "type": {
      "envelope": "Obálka",
      "label": "Typ",
      "task": "Úkol"
    },
    "week": "Týden",
    "zoom_in": "Přiblížit",
    "zoom_out": "Oddálit"
  },
  "header": {
    "all_projects": "Všechny projekty",
    "clear": "Smazat seznam. Poté stačí ostatní Projektově navštívit, přidají se samy.",
    "menu": {
      "my_page": "Má stránka",
      "my_tasks": "Mé úkoly",
      "new_member": "Nový kontakt",
      "new_task": "Nový úkol",
      "open_tasks": "Otevřené úkoly",
      "projects": "Projekty",
      "tasks_plural": "Úkoly"
    },
    "projects": {
      "current_project": "Výpis úkolů v projektu"
    },
    "search": {
      "history": "Poslední hledání",
      "in_project": "jen v aktuálním projektu",
      "placeholder": "Hledat v úkolech"
    },
    "switch_to": "Přepnout na:",
    "title": "Pro přehledné řízení firmy, projektů a úkolů.",
    "user_menu": {
      "calendar": "Kalendář",
      "client_profil": "Účet služby Projektově",
      "help": "Nápověda",
      "last_activity": "Poslední aktivita",
      "logout": "Odhlásit",
      "mobile_application": "Mobilní aplikace",
      "my_settings": "Mé nastavení",
      "test_v2": "Vyzkoušet Projektově 2",
      "this_week": "Tento týden",
      "timetracker": "Stopky",
      "users_settings": "Správa uživatelů"
    }
  },
  "issues": {
    "bulk_edit": {
      "all_issues": "všechny otevřené úkoly",
      "all_issues_note": "z projektu včetně podprojektů",
      "assigned_to": "úkoly přiřazené:"
    },
    "delete_modal": {
      "button_no": "ne",
      "button_yes": "ano, smazat",
      "label": "Opravdu chcete úkol smazat?",
      "label_subtask": "Opravdu chcete podúkol smazat?",
      "note": "Úkol bude smazán i s jeho podúkoly."
    },
    "filters": {
      "assignedToId": "Řešitel",
      "authorId": "Autor",
      "buttons": {
        "apply": "Použít",
        "cancel": "Zrušit"
      },
      "calendar": "Kalendář",
      "closedAt": "Uzavřeno",
      "createdAt": "Vytvořeno",
      "customFields": "Vlastní pole",
      "customFields_icon_title": "Odebrat",
      "customFields_title": "Nastavené filtry",
      "dueDate": "Termín",
      "fromFavoriteProjects": "Z oblíbených projektů",
      "input_placeholder": "zadejte hledaný text",
      "lastActivityAt": "Změněno",
      "noActiveTag": "Tento filtr v daném pohledu nelze použít, proto není aktivní.",
      "no_assignedToId": "žádný uživatel nebyl nalezen",
      "no_authorId": "žádný uživatel nebyl nalezen",
      "no_one_assigned": "nepřiřazeno",
      "no_projectId": "žádný projekt nebyl nalezen",
      "not_entered": "Nezadán",
      "others": "Ostatní",
      "parentId": "Bez podúkolů",
      "priorityId": "Priorita",
      "projectId": "Projekt",
      "repetitive_false": "Bez opakov. úkolů",
      "repetitive_true": "Opakované",
      "startDate": "Začátek",
      "statusId": "Stav",
      "templates": "Včetně úkolů z šablon",
      "this_2_weeks_and_older": "Tyto 2 týdny a starší",
      "this_4_weeks_and_older": "Tyto 4 týdny a starší",
      "this_month": "Tento měsíc",
      "this_week": "Tento týden",
      "this_week_and_older": "Tento týden a starší",
      "today": "Dnes",
      "today_and_older": "Dnešní a starší",
      "trackerId": "Fronta"
    },
    "index": {
      "add_first_task": "Zadejte první úkol.",
      "add_remove_tasks_order": "Přidat / odebrat další úroveň řazení",
      "add_tasks_from_template": "přidat úkoly ze šablony",
      "bulk_edit": "Hromadně upravit",
      "bulk_modal": {
        "assigned_to": "Úkoly přiřazené",
        "edit": "upravit",
        "edit_all": "upravit všechny otevřené úkoly"
      },
      "cant_be_closed": {
        "comment_wip": "Kartu úkolu nelze nyní zavřít, neboť máte rozpracovaný komentář k úkolu.",
        "description_wip": "Kartu úkolu nelze nyní zavřít, neboť máte rozpracovaný popis úkolu.",
        "prompt": "Přejete si přesto odejít ze stránky?",
        "title": "Kartu úkolu nelze zavřít"
      },
      "choose_calendar_list": "Vyberte si výpis, který si přejete propojit s kalendářem.<br>Adresu vybraného výpisu následně vložte do Vašeho kalendáře.",
      "default_order": "Nastavit výchozí řazení.",
      "delete": "Smazat úkol %{subject}",
      "detail": "Detail",
      "exports_modal": {
        "title": "Export výpisu úkolů",
        "with_descriptions": "včetně popisů úkolů",
        "without_descriptions": "bez popisů úkolů"
      },
      "grouped": {
        "group_assignee": "dle řešitele",
        "group_author": "dle autora",
        "group_by": "Seskupit",
        "group_dueDate": "dle termínu",
        "group_lastActivityAt": "dle aktivity",
        "group_none": "zrušit seskupení",
        "group_priority": "dle priority",
        "group_project": "dle projektu",
        "group_startDate": "dle začátků",
        "group_status": "dle stavu",
        "no_dueDate": "Bez termínu",
        "no_one_assigned": "Nepřiřazeno",
        "no_startDate": "Bez začátku",
        "no_value": "Bez hodnoty",
        "sample": "Ukázka P."
      },
      "ics_export": "Propojit s kalendářem",
      "ics_modal": {
        "all_tasks": "Všechny úkoly",
        "all_tasks_duration": "Všechny úkoly se záčátky i termíny",
        "all_tasks_only_with_times": "Všechny úkoly, pouze s časy",
        "my_tasks": "Mé úkoly",
        "my_tasks_duration": "Mé úkoly se záčátky i termíny",
        "my_tasks_only_with_times": "Mé úkoly, pouze s časy"
      },
      "in_one_column": "vše pod sebou",
      "in_structure": "ve větvích",
      "link_with_calendar": "Propojení s kalendářem",
      "modals": {
        "new_issues": {
          "create": "Vytvořit",
          "parent_task": "Nadřazený úkol",
          "plan": {
            "from_end": "od konce",
            "from_start": "od začátku",
            "help_left": "Plánovaný začátek je znám, od něj jsou nastaveny začátky a termíny úkolů.",
            "help_right": "Začátky a termíny úkolů jsou nastaveny tak, aby poslední úkol ve plánovaný termín skončil.",
            "title": "Plánovat"
          },
          "planned_due_date": "Plán. termín",
          "planned_start_date": "Plán. začátek",
          "success": "Úkoly úspěšně přidány.",
          "template": "Šablona",
          "title": "Přidat úkoly ze šablony"
        }
      },
      "need_update": "Zobrazené úkoly již nejsou aktuální, klikněte pro jejich aktualizaci.",
      "no_closed_tasks": "Zatím v projektu žádné uzavřené úkoly nejsou.",
      "no_overdue_tasks": "Výborně, úkoly jsou bez zpoždění.",
      "no_solved_tasks": "Zatím v projektu žádné vyřešené úkoly nejsou.",
      "not_found": "Bohužel jsme hledaný úkol nenašli.",
      "only_with_times": "pouze s časy",
      "order_tasks": "Seřadit úkoly",
      "reminder": {
        "text": "Připomínky k novému výpisu zde.",
        "title": "Připojte se do nové facebookové skupiny. Žádost o přidání obratem potvrdíme."
      },
      "search_task": "Úkol",
      "settings": {
        "add": "Přidat",
        "columns": "Nastavit sloupce",
        "disable": "Vypnout",
        "enable": "Zapnout",
        "horizontalScroll": " scrollování výpisu kolečkem",
        "reorder": "Změnit pořadí"
      },
      "show_more": {
        "few": "Zobrazit další %{count}",
        "one": "Zobrazit poslední",
        "other": "Zobrazit dalších %{count}"
      },
      "show_structure_filter": "Zobrazit",
      "task_quickadd": "Zadejte název nového úkolu a stiskněte enter",
      "task_quickadd_hitenter": "stiskněte enter",
      "tasks_export": "Exportovat výpis úkolů"
    },
    "messages": {
      "created": "Úkol byl úspěšně vytvořen.",
      "errors": {
        "dueDate": {
          "greater_than_or_equal_to_start_date": "Termín úkolu nemůže být dříve, než začátek úkolu.",
          "locked_task_in_project": "V projektu je úkol s uzamknutým začátek a termínem. Úkol není možné změnit.",
          "lower_than_or_equal_to_parent_due_date": "Termín úkolu nemůže být později, než termín nadřazeného úkolu.",
          "start_and_due_date_locked": "Začátek a termín úkolu byl manažerem uzamknut. Je nutné jej první odemknout."
        },
        "estimatedHours": {
          "greater_than": "Odhadovaný čas musí být větší než součet odhadů u podúkolů (%{count}h)"
        },
        "startDate": {
          "greater_than_or_equal_to_parent_start_date": "Začátek úkolu nemůže být dříve, než začátek nadřazeného úkolu."
        }
      },
      "open_task": "otevřít vytvořený úkol"
    },
    "modals": {
      "repetitive": {
        "cancel": "zrušit opakování",
        "day_in_month": "den v měsíci",
        "days_in_week": "Dny v týdnu",
        "interval": "Interval",
        "last": "poslední",
        "months": {
          "few": "každé %{count} měsíce",
          "one": "každý měsíc",
          "other": "každých %{count} měsíců"
        },
        "periodically_every": "co",
        "periodically_label": "dní ode dne uzavření",
        "repeat": "Opakovat",
        "repeat_options": {
          "daily": "denně",
          "monthly": "měsíčně",
          "never": "nikdy",
          "periodically": "pravidelně",
          "weekly": "týdně",
          "yearly": "ročně"
        },
        "start": "Začít",
        "submit": "nastavit",
        "title": "Nastavení opakování",
        "weeks": {
          "few": "každé %{count} týdny",
          "one": "každý týden",
          "other": "každých %{count} týdnů"
        },
        "years": {
          "few": "každé %{count} roky",
          "one": "každý rok",
          "other": "každých %{count} let"
        }
      },
      "tracker": {
        "label": "Pozor, změnou fronty budou smazány hodnoty vyplněné u vlastních polí. Jedinou vyjímkou je, pokud se pole nachází i v nové frontě. Chcete pokračovat?"
      }
    },
    "new": {
      "add_contact": "přidat kontakt",
      "assignee": "Řešitel",
      "contact": "Kontakt",
      "contact_name": "Název kontaktu",
      "create_task": "vytvořit úkol",
      "customer_tittle": "Nové úkoly zadané zákazníkem jsou přiřazovány uvedené osobě.",
      "due_date": "Termín",
      "errors": {
        "cannot_create": "Nepodařilo se vytvořit úkol.",
        "date": "Termín úkolu nesmí být dříve než začátek.",
        "name": "Není zadán název úkolu.",
        "project": "Není vybrán projekt."
      },
      "new_contact": "Nový kontakt",
      "new_task": "Nový úkol",
      "placeholders": {
        "assignee": "Nepřiřazeno",
        "new_contact": "Název kontaktu",
        "new_contact_in_project": "Kontakt do projektu %{project}",
        "new_task": "Název úkolu",
        "new_task_in_project": "Úkol do projektu %{project}",
        "project": "Vyberte projekt",
        "starting_today": "Začíná dnes"
      },
      "project": "Projekt",
      "set_start_date": "zadat datum začátku",
      "start_date": "Začátek",
      "task": "Úkol",
      "task_name": "Název úkolu",
      "tracker": "Fronta"
    },
    "no_due_date": "Bez termínu",
    "no_one_assigned": "Nepřiřazeno",
    "show": {
      "activity_created_at": "Úkol byl vytvořen.",
      "add_comment": "přidat komentář",
      "add_time": "Přidat čas k úkolu",
      "attachments": {
        "attach_delete": "Opravdu chcete soubor smazat?",
        "attach_deleted": "Soubor byl smazán.",
        "attach_file": "Přiložit soubory",
        "attach_not_deleted": "Soubor se nepodařilo smazat.",
        "button_no": "ne",
        "button_yes": "ano, smazat",
        "not_saved": "Ukládání souborů se nezdařilo.",
        "saved": "Soubory byly uloženy.",
        "saving": "Ukládám soubory...",
        "title_delete": "Odstranit soubor"
      },
      "budget": {
        "add": "Přidat",
        "amount": "Částka",
        "bad_amount": "Částka nesmí být nulová nebo záporná.",
        "date": "Datum",
        "error": "Částka nebyla přidána. Prosím, zkuste to znovu později.",
        "expenses": "Náklady",
        "note": "Poznámka",
        "title": "Přidat náklad",
        "vat": "DPH"
      },
      "cannot_add_comment": "Uzavřené úkoly není možné komentovat. Prosím založte nový úkol přes tlačítko nahoře.",
      "close": "Uzavřít?",
      "close_title": "Uzavřít úkol",
      "closed": "Uzavřeno",
      "done_ratio_locked": "Procenta hotovo se počítají skrze podúkoly. Upravte prosím % hotovo v nich.",
      "due_date_locked": "Termín určil autor úkolu, jedině on jej může změnit.",
      "due_reminder": {
        "add": "Přidat připomenutí",
        "before": "před termínem splnění",
        "title": "Připomenutí",
        "tooltip": "Přidat připomenutí termínu e-mailem pro přešitele."
      },
      "editor": {
        "cancel": "Zahodit změny",
        "placeholder": "Podrobnosti pro Vás nebo pro kolegy...",
        "save": "Uložit"
      },
      "open_task": "Otevřít úkol",
      "options": {
        "add_tasks": "Přidat úkoly ze šablony",
        "copy": "Zkopírovat úkol",
        "delete": "Smazat úkol",
        "download": "Stáhnout jako PDF bez komentářů",
        "download_full": "Stáhnout jako PDF s komentáři",
        "lock_dates": "Zamknout začátek a termín",
        "title": "Nastavení úkolu",
        "unlock_dates": "Odemknout začátek a termín"
      },
      "parent": {
        "loading": "Načítání...",
        "more_items": {
          "few": "...a další %{count} úkoly. Upřesněte hledání.",
          "one": "...a 1 další úkol. Upřesněte hledání.",
          "other": "...a dalších %{count} úkolů. Upřesněte hledání."
        },
        "no_items": "Nebyl nalezen žádný úkol.",
        "no_parent": "Žádný"
      },
      "project": {
        "no_items": "Nebyl nalezen žádný projekt."
      },
      "quill_editor": {
        "h1": "Nadpis",
        "h2": "Podnadpis",
        "normal": "Normální"
      },
      "relations": {
        "add_related": "Přidat související",
        "close_title": "Uzavřít",
        "closed_title": "Uzavřeno",
        "hint_relates": "Začněte psát název úkolu",
        "hint_subtask": "Zadejte název podúkolu a stiskněte Enter",
        "label_relates": "Související: %{name}",
        "label_subtask": "Podúkol",
        "link_relates": "Související",
        "open_title": "Otevřený",
        "relates": "Související",
        "remove_relation_to_relates": "Odebrat vazbu na související úkol",
        "reopen_title": "Uzavřeno, vrátit do stavu \"Řeší se\"",
        "solve_title": "Vyrešit",
        "solved_title": "Vyřešeno",
        "unsolve_title": "Vyřešeno, vrátit do stavu \"Řeší se\""
      },
      "reopen_title": "Otevřít úkol",
      "repetitive": {
        "add": "nastavit opakování",
        "disabled": "nelze nastavit opakování",
        "disabled_reason": {
          "already_repeated": "Tento úkol již byl zopakován. Upravte nastavení v posledním aktivním úkolu, který najdete mezi otevřenými úkoly v projektu.",
          "already_repetitive": "Některý z nadúkolů nebo podúkolů již má nastaveno opakování."
        },
        "edit": "změnit opakování",
        "next_due": "Termím příštího úkolu",
        "wrong_input": "vyberte alespoň jeden den",
        "wrong_next_due": "Nesouhlasí příští termín? Zrušte opakování a nastavte nové."
      },
      "solve": "Vyrešit?",
      "solve_title": "Označit úkol jako vyřešený, aby jej autor mohl uzavřít.",
      "solved": "Vyřešeno",
      "task_has_x_files": {
        "few": "Úkol má %{count} soubory",
        "one": "Úkol má %{count} soubor",
        "other": "Úkol má %{count} souborů"
      },
      "total_spent_hours": "%{hours} celkem",
      "unsolve_title": "Označit úkol jako nevyřešený (řeší se)",
      "watchers": {
        "add": "Přidat sledující",
        "add_watchers": "Přidat do sledování",
        "assigned": "Úkol je přiřazen %{name}",
        "check_all": "zaškrtnout vše",
        "dialog_title": "Přidání sledujících",
        "how_it_looks": "Zde je <a href=\"%{url}\" target=\"_blank\">náhled</a>, jak to vidí přizvaná osoba.",
        "label": "Sledující",
        "not_search": "Žádný člen s tímto jménem nebyl nalezen.",
        "notes": {
          "all_added": "Všichni současní členové týmu již byli přidáni.",
          "externalusers_notifications": "Na e-mail jim přicházejí jen komentáře, na které mohou také rovnou z e-mailu (odpovědí na e-mail) reagovat.",
          "externalusers_visibility": "V aplikaci máte uživatele, kteří se se svým e-mailem a heslem mohou přihlásit a spolupracovat na úkolech. Uživatelé mohou sledovat libovolné úkoly. Když budu ve sledování úkolu, budou mi na e-mail chodit notifikace se změnami úkolu a komentáři. Pokud komentáře chci nasdílet ostatním, kteří nemají přístup do systému, mohu použít Externí sledující. Tito Externí sledující (bez registrace) nemají přístup do systému, přes odkaz v e-mailu vidí jen název úkolu, popis úkolu a komentáře.",
          "how_to": "Pokud chcete úkol sdílet s dalšími kolegy, nejdříve jim nasdílejte tento projekt nebo požádejte manažera týmu, aby projekt kolegům nasdílel."
        },
        "remove": "Odebrat %{name} ze sledování úkolu",
        "removeExternal": "Odebrat externího sledujícího %{name}. Odebráním ztratí přístup k omezenému náhledu úkolu.",
        "tab_external": "Sdílet bez registrace",
        "tab_users": "Registrovaní uživatelé",
        "uncheck_all": "odškrtnout vše"
      }
    },
    "show_static": {
      "no_comments_yet": "Zatím zde nejsou žádné komentáře."
    },
    "taskParentError": {
      "add": "Přidat",
      "added": "Přidáno.",
      "ask1": "Prosím požádejte manažera projektu",
      "ask2": "aby je zde přidal.",
      "heading": "V cílovém projektu chybí uživatelé",
      "missingTracker": "V cílovém projektu chybí fronta. Přesunutím úkolu bude jeho fronta automaticky změněna a tím zahozeny data z připojených vlastních polí. Opravdu chcete pokračovat?",
      "move": "Přesunout",
      "note1": "Přesunutím úkolu by uživatelé níže k úkolu ztratili přístup.",
      "role": "Role",
      "step1": "Nejprve přidejte uživatele do projektu",
      "step2": "A následně přesunte úkol",
      "user": "Uživatel"
    },
    "time_tracker": {
      "comment": "komentář",
      "error_already_runing": "Stopky již dávno běží.",
      "stopped": "Úspěšně uloženo",
      "stopped_and_started": "Uloženo a spuštěno.",
      "stopped_and_started_text": "K úkolu %{issue} byl připsán strávený čas a zároveň byly stopky pro další úkol znova spuštěny."
    }
  },
  "journals": {
    "add": "přidán",
    "allDayChange": "celodenní událost změněna z",
    "attributes": {
      "assigned_to_id": "řešitel",
      "attachment": "soubor",
      "author_id": "autor",
      "description": "byl aktualizován popis úkolu",
      "done_ratio": "%hotovo",
      "due_date": "termín",
      "estimated_hours": "odhadovaný čas",
      "parent_id": "nadúkol",
      "priority_id": "priorita",
      "project_id": "projekt",
      "start_date": "začátek",
      "status_id": "stav",
      "subject": "jméno úkolu",
      "tracker_id": "fronta"
    },
    "button_cancel": "zahodit změny",
    "button_save": "uložit",
    "changedFromMan": "změněn z",
    "changedFromThing": "změněno z",
    "changedFromWoman": "změněna z",
    "comment_deleted": "Komentář byl smazán.",
    "customField": "volitelné pole",
    "deleteMan": "smazán",
    "deleteThing": "smazáno",
    "deleteWoman": "smazána",
    "edit_note": "Úprava komentáře nezásílá emaily. Pokud chcete zaslat email, vložte nový komentář.",
    "events": {
      "repeated_from": {
        "link_label": "předchozí",
        "title": "Vytvořeno zopakováním úkolu."
      },
      "repeated_to": {
        "link_label": "následující",
        "title": "Vytvořen opakovaný úkol."
      },
      "user_archived": "Uživatel <b>%{user}</b> byl archivován."
    },
    "hours": "hod",
    "newDescriptionValue": "Nový popis",
    "no": "ne",
    "oldDescriptionValue": "Původní popis",
    "setToMan": "nastaven na",
    "setToThing": "nastaveno na",
    "setToWoman": "nastavena na",
    "showAll": "Zobrazit celou historii úkolu",
    "to": "na",
    "userDeleted": "uživatel již byl smazán",
    "yes": "ano"
  },
  "labels": {
    "actions": {
      "add": "Přidat",
      "archive": "Archivovat",
      "cancel": "Zrušit",
      "close": "Zavřít",
      "confirm_archive": "archivovat",
      "confirm_delete": "ano, smazat",
      "confirm_move": "ano, přesunout",
      "confirm_reset_password": "ano, resetovat",
      "create": "Vytvořit",
      "delete": "Smazat",
      "delete_from_all_tasks": "Odebrat ze všech úkolů",
      "edit": "Upravit",
      "print": "Tisk",
      "refresh": "Obnovit",
      "register": "Registrovat",
      "remove": "Odebrat",
      "reset": "Resetovat",
      "save": "Uložit",
      "zoomIn": "Přiblížit",
      "zoomOut": "Oddálit"
    },
    "choose": "Vyberte",
    "columns": {
      "assignedTo": "Řešitel",
      "author": "Autor",
      "budgetRealExpense": "Náklady",
      "close_toggle": "Uzavřeno",
      "createdOn": "Vytvořeno",
      "doneRatio": "Hotovo",
      "dueDate": "Termín",
      "estimatedHours": "Odhadovaný čas",
      "id": "#",
      "lastActivityAt": "Aktualizováno",
      "parent": "Nadúkol",
      "parentProject": "Nadřazený projekt",
      "parentTask": "Nadřazený úkol",
      "priority": "Priorita",
      "project": "Projekt",
      "relations": "Související",
      "remainingHours": "Zbývající čas",
      "spentHours": "Strávený čas",
      "startDate": "Začátek",
      "status": "Stav",
      "subject": "Úkol",
      "timeTracker": "Stopky",
      "totalEstimatedHours": "vč. podúkolů",
      "totalEstimatedHoursTitle": "Odhadovaný čas včetně podúkolů",
      "totalExpenses": "Náklady",
      "totalSpentHours": "Strávený čas včetně podúkolů",
      "tracker": "Fronta",
      "updatedAt": "Aktualizováno"
    },
    "custom_field_types": {
      "bool": "Ano/Ne",
      "date": "Datum",
      "file": "Soubor",
      "int": "Celé číslo",
      "list": "Seznam",
      "string": "Text",
      "text": "Dlouhý text",
      "url": "Url"
    },
    "false": "ne",
    "help": "Nápověda",
    "languages": {
      "cs": "čeština",
      "de": "němčina",
      "en": "angličtina",
      "hr": "chorvatština",
      "sk": "slovenština"
    },
    "loading": "Načítání...",
    "modified": "upraveno",
    "priority": {
      "high": "Vysoká",
      "low": "Nízká",
      "normal": "Normální",
      "urgent": "Urgentní"
    },
    "query": {
      "1": "Mé úkoly",
      "1-contacts": "Mé kontakty",
      "10": "Mé uzavřené úkoly",
      "11": "Delegované úkoly ve zpoždění",
      "12": "Mé zpožděné úkoly",
      "13": "Mé úkoly",
      "14": "Bez podúkolů",
      "15": "Mé sledované úkoly",
      "16": "Mé sledované úkoly, kde nejsem řešitelem",
      "17": "Vyřešené úkoly, kterých jsem autorem",
      "18": "Delegované úkoly ve zpoždění",
      "19": "Úkoly, které mají být v řešení",
      "2": "Zpožděné úkoly",
      "2-contacts": "Zpožděné kontakty",
      "3": "Otevřené úkoly",
      "3-contacts": "Otevřené kontakty",
      "4": "Uzavřené úkoly",
      "4-contacts": "Uzavřené kontakty",
      "5": "Vyřešené úkoly",
      "5-contacts": "Vyřešené kontakty",
      "6": "Mnou delegované úkoly",
      "7": "Mé zadané úkoly",
      "8": "Strukturovaný výpis",
      "9": "Mé úkoly dle data",
      "all": "Veškeré úkoly",
      "custom": "Vlastní řazení"
    },
    "time_entries": {
      "activity": "Aktivita",
      "comment": "Komentář",
      "date": "Datum",
      "hours": "Strávený čas",
      "user": "Uživatel"
    },
    "today": "dnes",
    "true": "ano",
    "user": {
      "deleted": "Uživatel smazán",
      "email": "E-mail",
      "firstname": "Jméno",
      "lastname": "Příjmení"
    }
  },
  "messages": {
    "announcements": {
      "deprecated_browser": {
        "info": "Projektově v blízké budoucnosti ukončí podporu pro Internet Explorer 11.",
        "note1": "Stále bude možné aplikaci v IE 11 zobrazit, nicméně aplikace nebude pro tento prohlížeč optimalizována.",
        "note2": "Společnost Microsoft ke dni 17. 8. 2021 také končí podporu IE 11 ve svých produktech. Doporučujeme proto použít modernější prohlížeč, například Google Chrome nebo Microsoft Edge."
      },
      "link_email": "Opět zapnout zasílání notifikací.",
      "nps": {
        "confirmation": "Děkujeme.",
        "note_10": "Děkujeme. Co Projektově dělá dobře, že si vysloužilo nejvyšší hodnocení?",
        "note_9": "Děkujeme. Co Projektově dělá dobře, že si vysloužilo vysoké hodnocení?",
        "note_low": "Děkujeme. Co můžeme udělat, aby si Projektově zasloužilo vyšší skóre?",
        "postpone": "odpovím později",
        "question": "Doporučili byste Projektově jiným manažerům nebo firmám?",
        "scale_high": "Určitě ano",
        "scale_low": "Určitě ne",
        "send": "Odeslat"
      },
      "release": {
        "message": "Je k dispozici nová verze aplikace.",
        "update": "provést aktualizaci"
      },
      "text_email": "Odesílání e-mailových notifikací o změnách úkolů (od Vás kolegům) je nyní pozastaveno.",
      "trial": {
        "expires": {
          "few": "Nyní používáte zkušební verzi. Ta končí za %{count} dny.",
          "one": "Zítra končí Vaše zkušební verze. Objednejte Projektově ještě dnes, ať můžete bez přerušení pokračovat.",
          "other": "Nyní používáte zkušební verzi. Ta končí za %{count} dní.",
          "zero": "Dnes končí Vaše zkušební verze. Objednejte Projektově ještě dnes, ať můžete bez přerušení pokračovat."
        },
        "note": "O dny zdarma nepřijdete, placený účet plynule navazuje na zkušební verzi.",
        "order_now": "Objednat"
      }
    },
    "by_code": {
      "1060": "E-mail je obsazený",
      "1061": "E-mailová adresa je již použita jako vaše primární E-mailová adresa.",
      "2220": "Fronta '%{tracker_name}' je používána úkoly v projektu a není možné ji odebrat.",
      "2301": "Projekt nemohl být převeden na složku, protože obsahuje úkoly.",
      "2302": "Projekt nemohl být převeden na složku, protože má nadžazený projekt. Složka nemůže být vytvořena v projektu.",
      "2303": "Složka nemůže být prevedena na projekt, dokud obsahuje podsložky."
    },
    "errors": {
      "adding_time_entry_failed": "Při přidávání stráveného času došlo k chybě.",
      "cannot_save": "Nepodařilo se uložit.",
      "create_issue_failure": "Vytvoření úkolu se nezdařilo.",
      "create_relation_failure": "Přidání souvislosti se nezdařilo.",
      "error": "Chyba",
      "exceeds_tariff": {
        "assign_role": "Uživatele nebylo možné přidat v placené roli. Před přidáním dalšího člena v této roli je nutné, aby administrátor navýšil balíček služby Projektově."
      },
      "file_too_big": "Soubor %{file} je pro nahrání příliš velký.",
      "file_upload": "Nepodařilo se nahrát soubor.",
      "forbidden": "Pro daný požadavek nemáte oprávnění.",
      "form_reading_failed": "Načítání formuláře se nezdařilo. Zkuste to, prosím, znovu.",
      "general": "Ouha, stala se chyba. Zkuste to prosím později.",
      "gql": {
        "error": "Při načítání se stala chyba.",
        "retry": "Zkusit znova"
      },
      "is_primary_mail": "Adresa %{mail} je již použita jako Váš hlavní e-mail.",
      "issue_assignee_change_failure": "Změna řešitele se nezdařila.",
      "issue_closing_or_opening_failure": "Nepodařilo se uzavřít/otevřít úkol.",
      "issue_due_date_change_failure": "Změna termínu se nezdařila.",
      "issue_status_change_failure": "Nepodařilo se změnit stav úkolu.",
      "mail_already_used": "Adresu %{mail} již používá jiný uživatel.",
      "removing_relation_failure": "Vazbu se nepodařilo odstranit."
    },
    "feedback": {
      "error": "Chyba při ukladání.",
      "loading": "Načítají se projekty a úkoly...",
      "pending": "Ukládám...",
      "processing_request": "Chvilku strpení, požadavek se zpracovává...",
      "saved": "Všechny změny uloženy."
    },
    "member_role_changed": "Role byla změněna",
    "select": {
      "no_options": "Žádné dostupné volby",
      "type_to_search": "Začněte psát pro vyhledání..."
    },
    "success": {
      "changed": "Úspěšně změněno.",
      "deleted": "Úspěšně smazáno.",
      "password_changed": "Heslo bylo úspěšně změněno.",
      "password_reset_sent": "Instrukce k obnovení hesla byly odeslány na email uživatele.",
      "saved": "Úspěšně uloženo."
    }
  },
  "mindmap": {
    "addSubtask": "Přidat podúkol",
    "addTask": "Přidat úkol",
    "collapse": "Skrýt podúkoly",
    "expand": "Zobrazit podúkoly",
    "heading": "Myšlenková mapa",
    "hide_closed": "Skrýt uzavřené",
    "order": {
      "default": "výchozí",
      "dueDate": "dle termínu",
      "label": "Řazení",
      "priority": "dle priority",
      "subject": "dle názvu",
      "updatedAt": "dle poslední změny"
    },
    "relations": {
      "howto1": "Tato myšlenková mapa zobrazí úkoly tak jak jsou naplánovány v čase za sebou dle vazeb v Ganttově diagramu.",
      "howto2": "Zatím úkoly žádné vazby nemají. Otevřete v levém menu Ganttův diagram a přidejte vazby mezi úkoly v něm."
    },
    "view": {
      "basic": "základní",
      "extended": "rozšířená",
      "relations": "dle vazeb"
    }
  },
  "mobileApplication": {
    "benefit1": "S mobilní aplikací budete mít veškeré své projekty při ruce.",
    "benefit2": "Snadno zadáte nový úkol a zkontrolujete stav projektu.",
    "fastLogin": "Rychlé přihlášení:",
    "fastLogin2": "Spusťte mobilní aplikaci, klikněte na přihlášení QR a namiřte fotoaparátem na obrázek níže.",
    "getItGoogle": "Nyní na Google Play",
    "getItiOS": "Stáhnout v App Store",
    "installation": "Instalace",
    "login": "Přihlášení",
    "step1": "Spusťte mobilní aplikaci,",
    "step2": "klikněte na přihlásit přes QR kód",
    "step3": "namiřte na obrázek níže:",
    "step4": "hotovo, přes obrázek budete automaticky přihlášeni.",
    "step5": "Pokud ne, druhou možností je ručně vyplnit adresu (nahoře z adresního řádku), Váš e-mail a heslo se kterým se přihlašujete.",
    "title": "Jak nainstalovat mobilní aplikaci?"
  },
  "my": {
    "account": {
      "api": {
        "app_reload": "Znovu načíst celou aplikaci",
        "created": "API přístupový klíč vytvořen  %{value}",
        "reset": "Resetovat",
        "show": "Zobrazit",
        "title": "API přístupový klíč"
      },
      "languages": {
        "croatian": "Hrvatski",
        "czech": "Čeština",
        "english": "English",
        "german": "Deutsch",
        "slovak": "Slovenčina"
      },
      "mail": {
        "change_all_day": "Přidání času splnění",
        "change_assigned_to": "Změně řešitele",
        "change_attachment": "Přidání přílohy",
        "change_author": "Změně autora",
        "change_description": "Změně popisu úkolu",
        "change_done_ratio": "Změně procent hotovo",
        "change_due_date": "Změně termínu splnění",
        "change_estimated_hours": "Změně odhadu času",
        "change_notes": "Přidání komentáře",
        "change_parent_id": "Přesunu úkolu",
        "change_priority_id": "Změně priority",
        "change_project_id": "Změně projektu",
        "change_start_date": "Změně začátku",
        "change_status": "Změně stavu",
        "change_subject": "Změně názvu úkolu",
        "estimated_hours_exhausted": "Varování odhadovaných časů",
        "estimated_hours_exhausted_note": "Odesílat notifikaci při naplnění odhadovaného času z 80 %.",
        "mail_mark_off": "Zaškrtnout doporučené nastavení",
        "mail_note": "Máte od nás až příliš e-mailů?",
        "mail_notification": "E-mailová oznámení",
        "mail_settings": "E-mailová nastavení",
        "new_task": "Někdo Vám vytvořil nebo předal úkol",
        "newsletter": "Odebírat newsletter",
        "newsletter_note": "Odesíláme max. 1x měsíčně tipy pro řízení projektů a novinky.",
        "oncoming_tasks_summary": "Zobrazovat Blížící se termíny",
        "receive_notififactions_when": "Přijímat upozornění při",
        "save_changes": "Uložit změny",
        "set_to_all_users": "Propsat nastavení e-mailů všem uživatelům",
        "solved_task": "Někdo vyřešil nebo uzavřel úkol, kterého jste autorem",
        "summaries": "Souhrnné ranní e-maily",
        "title": "E-mailová nastavení"
      },
      "mail_notifications": {
        "default": "Zasílat upozornění",
        "none": "Nezasílat žádná upozornění"
      },
      "mail_summaries": {
        "everyday": "každý den",
        "never": "nikdy nezasílat",
        "no_weekends": "každý den mimo víkendy"
      },
      "mobile": {
        "install": "Instalace",
        "login": "Rychlé přihlášení",
        "text": "Spusťte mobilní aplikaci, klikněte na přihlášení QR a namiřte fotoaparátem na obrázek níže.",
        "title": "Mobilní aplikace"
      },
      "prefill_due_date": {
        "none": "ne",
        "today_plus": {
          "few": "dnes +%{count} dní",
          "one": "dnes +1 den",
          "other": "dnes +%{count} dní"
        }
      },
      "profile_image": {
        "cancel_changes": "Zrušit změny",
        "choose_crop": "Vyberte část obrázku, kterou chcete oříznout.",
        "crop_tutorial": "Klikněte a tahem vpravo dolů nebo pokud ořezáváte zprava zespodu, tak potom tahem vlevo nahoru, označíte výřez obrázku, který chcete získat.",
        "dropzone_tutorial": "Přetáhněte obrázek myší nebo klikněte na vybrat soubor.",
        "use_crop": "Použít"
      },
      "project_overview": {
        "gantt": "Ganttův diagram",
        "issues": "Výpis úkolů - otevřené úkoly",
        "issues_closed": "Výpis úkolů - uzavřené",
        "issues_my_tasks": "Výpis úkolů - jen mé úkoly",
        "issues_overdue": "Výpis úkolů - zpožděné",
        "issues_solved": "Výpis úkolů - vyřešené",
        "mind_map": "Myšlenková mapa",
        "overview": "Přehled projektu",
        "tasks_board": "Tabule s úkoly"
      },
      "tasks_in_summary": {
        "1": "úkoly na nadcházející den",
        "14": "úkoly s termínem do 14 dní",
        "3": "úkoly s termínem do 3 dní",
        "7": "úkoly s termínem do 7 dní",
        "none": "ne"
      },
      "user": {
        "due_date_preset": "Přednastavovat termín splnění",
        "errors": {
          "invalid": "Neplatný e-mail",
          "taken": "E-mail je obsazený"
        },
        "firstname": "Jméno",
        "language": "Jazyk",
        "lastname": "Příjmení",
        "login_mail": "E-mail / Přihlášení",
        "other_mail": "Mé další e-mail adresy",
        "project_view": "Po kliknutí na projekt zobrazit",
        "time_zone": "Časové pásmo",
        "title": "Mé nastavení"
      }
    }
  },
  "number": {
    "currency": {
      "czk": {
        "delimiter": " ",
        "format": "%n %u",
        "precision": 2,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "Kč"
      },
      "eur": {
        "delimiter": " ",
        "format": "%n %u",
        "precision": 2,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "€"
      },
      "format": {
        "delimiter": " ",
        "format": "%n %u",
        "precision": 2,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "Kč"
      }
    },
    "format": {
      "delimiter": " ",
      "precision": 2,
      "separator": ",",
      "significant": false,
      "strip_insignificant_zeros": true
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Miliarda",
          "million": "Milion",
          "quadrillion": "Kvadrilion",
          "thousand": "Tisíc",
          "trillion": "Bilion",
          "unit": ""
        }
      },
      "format": {
        "delimiter": " ",
        "precision": 1,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "few": "Bajty",
            "one": "Bajt",
            "other": "Bajtů"
          },
          "gb": "GB",
          "kb": "kB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": " "
      }
    }
  },
  "polls": {
    "add_answers": "Zadejte odpovědi",
    "add_choice": "+ Přidat možnost...",
    "add_ok": "Hlasování bylo přidáno.",
    "add_poll": "Přidat hlasování",
    "add_question": "Zadejte otázku",
    "block_name": "Hlasování",
    "confirm_no": "ne",
    "confirm_yes": "ano, smazat",
    "delete_answer": "Smazat odpověď",
    "delete_choice_confirm": "Snažíte se smazat odpověď, u které se již hlasovalo. Skutečně ji chcete odstranit včetně zaznamenaných hlasů?",
    "delete_ok": "Hlasování bylo smazáno.",
    "delete_poll": "Smazat hlasování",
    "delete_poll_confirm": "Opravdu chcete hlasování smazat? Veškeré zaznamenané hlasy uživatelů budou rovněž nevratně smazány.",
    "edit_ok": "Hlasování bylo upraveno.",
    "edit_poll": "Upravit hlasování",
    "empty_question": "Byla zadána prázdná otázka. Prosím, vyplňte ji.",
    "error": "Chyba při ukládání. Zkuste to znovu později.",
    "error_conflict": "Hlasování není možno přidat, neboť tento úkol již hlasování obsahuje.",
    "error_insufficient_choices": "Hlasování musí mít nejméně 2 možnosti.",
    "no_polls": "Nyní nesledujete žádná hlasování.",
    "no_votes": "Nehlasováno",
    "successfully_voted": "Váš hlas byl zaznamenán."
  },
  "projectOverview": {
    "estimated_hours": "Odhadovaný čas",
    "heading": "Přehled projektu",
    "hide": "skrýt",
    "hours": "hod",
    "planned_due_date": "Plán. termín",
    "planned_start_date": "Plán. začátek",
    "remaining_hours": "Zbývající čas",
    "show": "zobrazit",
    "spent_hours": "Strávený čas"
  },
  "projects": {
    "archive": {
      "cant_unarchive": "Nelze obnovit",
      "error_cant_unarchive": "Nebylo možné obnovit projekt, protože některý z nadprojektů byl archivován.",
      "exceeds_tariff_modal": {
        "exceeded_tariff": "Máte více aktivních účtovaných uživatelů než je počet uživatelů objednaného balíčku Projektově.",
        "explanation": {
          "few": "Členy projektu jsou %{count} uživatelé, kteří by po obnovení projektu překračovali aktuální balíček Projektově.",
          "one": "Členem projektu je uživatel <b>%{user}</b>, který by po obnovení projektu překračoval aktuální balíček Projektově.",
          "other": "Členy projetu je %{count} uživatelů, kteří by po obnovení projektu překračovali aktuální balíček Projektově."
        },
        "overlimit_users": "Uživatelé, kteří budou nově zpoplatněni po obnovení projektu",
        "tip": "Navyšte balíček Projektově nebo archivujte potřebný počet uživatelů, abyste mohli projekt obnovit.",
        "title": "Projekt nebylo možné obnovit"
      },
      "from_project": "Z aktivního projektu",
      "remove": "Trvale smazat projekt",
      "title": "Archiv projektů",
      "unarchive": "Obnovit projekt",
      "unarchiveInfoModal": {
        "h2": "Nejdříve obnovte nadřazený projekt",
        "h3": "Postup",
        "note1": "Projekt",
        "note2": "je zařazen v rovněž archivovaném projektu",
        "note3": "Pro zachování struktury projektů je nutné nejdříve obnovit všechny nadřazené projekty.",
        "step1": "Obnovte z archivu nadřazený projekt %{name}",
        "step2": "Obnovte tento projekt, tedy %{name}"
      }
    },
    "breadcrumbs": {
      "all_projects": "Všechny projekty",
      "end_path": {
        "budget_entries": "Rozpočet projektu",
        "contacts": "Výpis kontaktů",
        "files": "Soubory v projektu",
        "gantt": "Ganttův diagram",
        "issues": "Výpis úkolů",
        "mindmap": "Myšlenková mapa",
        "overview": "Přehled projektu",
        "settings": "Nastavení",
        "tasks_board": "Tabule s úkoly",
        "team_settings": "Členové projektu",
        "time_entries": "Výpis stráveného času"
      },
      "subprojects": "Podprojekty"
    },
    "filters": {
      "buttons": {
        "apply": "Použít",
        "cancel": "Zrušit"
      },
      "calendar": "Kalendář",
      "customFields": "Vlastní pole",
      "customFields_icon_title": "Odebrat",
      "customFields_title": "Nastavené filtry",
      "doneRatio": "Průběh",
      "doneRatio_g": "je větší než",
      "doneRatio_i": "je",
      "doneRatio_l": "je menší než",
      "favorites": "Oblíbené",
      "input_placeholder": "zadejte hledaný text",
      "ismanager": "Jsem manažerem",
      "member": "Člen týmu",
      "not_entered": "Nezadán",
      "only_favorites": "pouze oblíbené",
      "only_favorites_tree": "oblíbené s podprojekty",
      "only_templates": "pouze šablony",
      "plannedDueDate": "Plánovaný termín",
      "plannedStartDate": "Plánovaný začátek",
      "templates": "Šablony",
      "this_month": "Tento měsíc",
      "this_week": "Tento týden",
      "today": "Dnes",
      "without_favorites": "bez oblíbených",
      "without_templates": "bez šablon"
    },
    "index": {
      "add_project": "přidat projekt",
      "add_sub_project": "Přidat podprojekt",
      "archive_project": "Archivovat projekt",
      "columns": {
        "dates": "Období",
        "members": "Členové týmu",
        "name": "Název",
        "parentProject": "Nadřazený projekt",
        "plannedDueDate": "Plán. termín",
        "plannedStartDate": "Plán. začátek",
        "progress": "Průběh"
      },
      "copy_project": "Zkopírovat projekt",
      "details": {
        "collapse": "Sbalit řádek",
        "expand": "Rozbalit řádek",
        "incomes": "Rozpočet - příjmy"
      },
      "export": "Exportovat výpis projektů",
      "favorite_default": "Hvězdičkou označené projekty budete mít po ruce",
      "favorite_projects": "Oblíbené projekty",
      "move_project": "Přesunout projekt",
      "project_settings": "Nastavení projektu",
      "remove_project": "Smazat projekt",
      "rename_project": "Přejmenovat projekt",
      "share_project": "Sdílet projekt",
      "show_all": "všechny projekty",
      "show_archive": "archiv",
      "show_filter": "Zobrazit",
      "show_my": "mé projekty"
    },
    "modals": {
      "archive_project": {
        "text1": "Archivací projektu",
        "text2": "budou archivovány také všechny jeho pod-projekty",
        "title": "Opravdu si přejete archivovat projekt?"
      },
      "destroy_project": {
        "all_files": "všechny soubory projektu",
        "all_tasks": "všechny úkoly v projektu",
        "members": "seznam členů týmu",
        "members_note": "samotní uživatelé zůstávají v Projektově, je možné je přiřadit do jiných projektů",
        "save": "smazat projekt",
        "settings": "nastavení projektu",
        "spent_times": "strávené časy na úkolech",
        "subprojects_note": "Případné podprojekty jsou součástí projektu a budou rovněž odstraněny.",
        "title": "Opravdu si přejete smazat projekt?",
        "what_you_lost": "Smazáním projektu nenávratně odstraníte:"
      },
      "move_project": {
        "no_parent": "-- bez nadřazeného projektu --",
        "save": "přesunout",
        "title": "Přesunutí projektu"
      },
      "new_project": {
        "choose_template": "Je nutné vybrat šablonu.",
        "create": "vytvořit",
        "empty": "Prázdný",
        "folder": "Složka",
        "fromTemplate": "Ze šablony",
        "name": "Název",
        "parent": "Nadřazený projekt",
        "parentErrorHint": "Jen administrátor může zadávat hlavní projekty. Jako manažer můžete zakládat jen pod-projekty svých projektů. Prosím vyberte nadřazený projekt.",
        "parentFolder": "Nadřazená složka",
        "plan": {
          "from_end": "od konce projektu",
          "from_start": "od začátku projektu",
          "help_left": "Plánovaný začátek projektu je znám, od něj jsou nastaveny začátky a termíny úkolů.",
          "help_right": "Začátky a termíny úkolů jsou nastaveny tak, aby projekt ve plánovaný termín skončil.",
          "title": "Plánovat"
        },
        "planned_due_date": "Plán. termín",
        "planned_start_date": "Plán. začátek",
        "template": "Šablona",
        "title": "Nový projekt nebo složka"
      },
      "rename_project": {
        "save": "přejmenovat",
        "title": "Přejmenování projektu"
      }
    },
    "navigation": {
      "labels": {
        "budget_entries": "Rozpočet projektu",
        "contacts": "Kontakty",
        "files": "Soubory",
        "gantt": "Ganttův diagram",
        "issues": "Úkoly",
        "last_activity": "Poslední aktivita",
        "mindmap": "Myšlenková mapa",
        "mobile_application": "Mobilní aplikace",
        "my_page": "Má stránka",
        "new_issues": "nový",
        "overview": "Přehled projektu",
        "projects": "Projekty",
        "projektove_cz": "Projektově",
        "settings": "Nastavení",
        "tasks_board": "Tabule s úkoly",
        "team_settings": "Členové týmu",
        "time_entries": "Strávený čas",
        "users_settings": "Správa uživatelů"
      }
    },
    "settings": {
      "convert_to_folder": "Převést projekt na složku",
      "convert_to_project": "Převést složku na projekt",
      "description": "Popis",
      "folder": "Složka",
      "is_template": "Projektová šablona",
      "is_template_inherited": "Tuto vlastnost nemůžete změnit, neboť některý z nad-projektů je šablonou.",
      "name": "Název",
      "only_admin_can_change": "Toto nastavení může změnit jen administrátor.",
      "parentId": "Nadřazený projekt",
      "parentIdFolder": "Nadřazená složka",
      "path": "Cesta",
      "plannedDueDate": "Plánovaný termín",
      "planned_due_date": "Plánovaný termín",
      "planned_income": "Plánovaný příjem",
      "planned_start_date": "Plánovaný začátek",
      "real_incomes": "Skutečný příjem",
      "reset_trackers": "Obnovit původní",
      "shortcut": "Zkratka",
      "shortcut_add": "Vyplňte zkratku projektu. Poté získáte e-mailovou adresu, kdy zasláním či přeposláním e-mailu na tuto adresu vytvoříte z e-maiu úkol.",
      "shortcut_alt_add": "Vyplňte zkratku projektu. Poté získáte instrukce, jak zasláním či přeposláním e-mailu vyvořit úkol v tomto projektu.",
      "shortcut_alt_note1": "Zasláním či přeposláním e-mailu na adresu",
      "shortcut_alt_note2": "vytvoříte z e-mailu úkol. Z předmětu bude název úkolu, z obsahu pak jeho popis.",
      "shortcut_alt_note3": "Na začátek předmětu zprávy připište zkratku projektu a dvojtečku, např. pro zkratku 'nacenovani' to bude vypadat následovně:",
      "shortcut_alt_subject": "předmět zprávy...",
      "shortcut_alt_subject_example": "nacenovani: Prosím o cenovou kalkulaci úprav dle zadání",
      "shortcut_format": "Povoleny jsou pouze písmena bez diakritiky a číslice. Je možné použít také tečku, podtržítko a pomlčku, avšak tyto nesmí být použity na začátku ani na konci.",
      "shortcut_note1": "Zasláním či přeposláním e-mailu na adresu",
      "shortcut_note2": "vytvoříte z e-mailu úkol.",
      "shortcut_note3": "Z předmětu bude název úkolu, z obsahu pak jeho popis.",
      "tracker": "Fronta",
      "unsaved_changes": {
        "prompt": "Přejete si přesto odejít ze stránky?",
        "title": "Máte dosud neuložené změny v projektu."
      },
      "unsortedCustomFields": "Projektová pole"
    },
    "team_settings": {
      "add_member": "přidat člena",
      "member": {
        "add_member": "Přidat člena do podprojektů",
        "add_member_error": "Uživatel je již v projektu %{project} zařazen",
        "add_member_info": "Uživatel byl úspěšně přiřazen do projektu %{project}",
        "add_task": "Zadat úkol",
        "change_contact_person": "Nové úkoly zadané zákazníkem jsou nejprve přiřazeny uvedené osobě.",
        "change_hour_cost": "Nastavit hodinovou sazbu pro sledování variabilních nákladů (strávené hodiny na úkolu * sazba) v projektu",
        "change_role": "Změnit roli člena v tomto týmu",
        "remove": "Odebrat člena z projektu",
        "remove_from_subprojects": "Přejete si člena odebrat i z podprojektů?",
        "show_info": "Zobrazit podrobnosti o členovi"
      },
      "modals": {
        "add": {
          "add": "přidat",
          "check_all": "zaškrtnout vše",
          "choose_member": "Vyberte člena do týmu",
          "choose_role": "Vyberte roli",
          "no_user_found": "Hledaného uživatele jsme nenašli, je nutné jej registrovat.",
          "search": "vyhledat podle jména nebo doplňujících informací",
          "selected": "Vybráno: %{count}",
          "uncheck_all": "odškrtnout vše"
        },
        "register": {
          "add": "přidat do projektu",
          "add_additional_info": "přidat doplňující informace",
          "additional_info_1": "doplňující informace 1",
          "additional_info_2": "doplňující informace 2",
          "already_registered": "Zadaného uživatele jsme našli v systému, není nutné jej registrovat.",
          "already_registered_external": "Pokoušíte se registrovat uživatele, který je již přidán jako externí sledující.",
          "choose_role": "Vyberte roli",
          "delete_user": "Odeberte nejprve kolegu z externích sledujících.",
          "help_1": "Na zadaný e-mail bude zaslána pozvánka, přes kterou si zvaný s prvním přihlášení nastaví své vlastní heslo.",
          "help_2": "Po přijetí pozvánky pak aplikace zašle rekapitulaci - kde a jak se přihlásit.",
          "hint_email": "E-mail",
          "office365_only": "Povolit pouze přihlášení účtem Microsoft",
          "only_admin": "dostupné jen administrátorům",
          "register_user": "Registrovat uživatele",
          "set_name": "Před registrací prosím zadejte jméno a příjmení.",
          "title": "Registrovat nového uživatele",
          "user_registered": "Uživatel byl zaregistrován a na jeho e-mail byla zaslána pozvánka k online spolupráci."
        }
      },
      "precheck": {
        "cannot_remove": "Nelze odebrat z projektu",
        "info": "%{user} má v projektu úkoly, které řeší nebo kterých je autorem.",
        "list_assignee": "Výpis úkolů, kterých je řešitelem.",
        "list_author": "Výpis úkolů, kterých je autorem.",
        "list_check": "Zkontrolujte také uzavřené úkoly.",
        "remove_anyway": "Přesto odebrat"
      },
      "register_member": "registrovat nového uživatele",
      "register_member_external": "registrovat uživatele mimo doménu (LDAP)",
      "restricted_registrations": "O registraci nových uživatelů požádejte vašeho správce.",
      "show_member_info": "Zobrazit podrobnosti o členovi",
      "title": "Členové týmu"
    }
  },
  "quickadd": {
    "block_name": "Rychlé zadání úkolu"
  },
  "search": {
    "button": "hledat",
    "header": "Hledání",
    "inComments": "hledat i v komentářích",
    "inProject": "hledat jen v jednom projektu",
    "notFound": "Nenalezeno."
  },
  "shared": {
    "button_back": "Zpět",
    "disableEmails": {
      "long": {
        "disable": "Pozastavit zasílání e-mailů",
        "enable": "Spustit zasílání e-mailů"
      },
      "short": {
        "disable": "Pozastavit",
        "enable": "Spustit"
      }
    },
    "errorView": {
      "403": "Nemáte dostatečná práva pro zobrazení této stránky.",
      "404": "Stránka, kterou se snažíte zobrazit, neexistuje nebo byla smazána.",
      "oops_title": "Ztraceni v úkolech?",
      "options": "Možnosti",
      "step1": "Přejděte na výpis projektů a podívejte se přímo do projektu,",
      "step1_note": "Pokud projekt existuje a máte k němu přístup, uvidíte jej zde,",
      "step2": "použijte hledání vpravo nahoře",
      "step2_note": "Hledání prohledává názvy a popisy úkolů v celé aplikaci. Pokud úkol existuje a máte k němu přístup, najdete jej.",
      "step3": "kontaktujte projektového manažera."
    },
    "forced_logout": {
      "explanation": "Pravděpodobně byla vynucena obnova přístupových údajů (hesel) ze strany správce Vašeho Projektově. Pokud by se Vám tedy nedařilo znovu se přihlásit, zkontrolujte svůj email, zda jste neobdrželi instrukce pro změnu hesla.",
      "reason": "Došlo k odhlášení z bezpečnostních důvodů.",
      "relog": "Znovu přihlásit",
      "title": "Byli jste odhlášeni"
    },
    "pnotify": {
      "close_title": "Zavřít"
    },
    "tasksReminders": {
      "approaching_deadline": {
        "few": "Blíží se termíny úkolů",
        "one": "Blíží se termín úkolu",
        "other": "Blíží se termíny úkolů"
      },
      "close": "Skrýt vše",
      "hide": "Skrýt"
    }
  },
  "time": {
    "am": "dop.",
    "formats": {
      "at": "%-d. %-m. %Y v %-H:%M",
      "default": "%-d. %-m. %Y, %-H:%M",
      "long": "%B %d, %Y %-H:%M",
      "short": "%d %b %-H:%M",
      "time": "%-H:%M",
      "time2": "%H:%M"
    },
    "moment_formats": {
      "default": "D. M. YYYY, H:mm",
      "only_time": "H:mm"
    },
    "pm": "odp."
  },
  "time_entries": {
    "hours_short": "hod.",
    "last_month": "Minulý měsíc",
    "modals": {
      "add": {
        "title": "Přidat strávený čas"
      },
      "edit": {
        "title": "Upravit strávený čas"
      }
    },
    "next_month": "Příští měsíc",
    "next_week": "Příští týden",
    "reports": {
      "by_projects_members_tasks": "dle projektů, členů týmu a úkolů",
      "by_projects_tasks": "dle projektů a úkolů",
      "title": "Reporty"
    },
    "this_month": "Tento měsíc",
    "this_week": "Tento týden",
    "today": "Dnes",
    "today_and_future": "Dnes a budoucí",
    "yesterday": "Včera"
  },
  "timelog": {
    "compoundReport": {
      "groupByUsers": "Seskupit dle uživatelů",
      "hideArchivedProjects": "Archivované projekty",
      "hideNullHours": "Zobrazit prázdné řádky",
      "sum": "Celkem",
      "title": "Souhrnný report",
      "users": "Uživatelé"
    },
    "daterange": {
      "all_time": "vše",
      "current_month": "tento měsíc",
      "current_week": "tento týden",
      "current_year": "tento rok",
      "custom": "vlastní",
      "last_month": "minulý měsíc",
      "last_week": "minulý týden",
      "last_year": "minulý rok",
      "title": "Časový rozsah",
      "today": "dnes",
      "yesterday": "včera"
    }
  },
  "users": {
    "add_alias": "přidat e-mail adresu",
    "change_password": {
      "button": "změnit heslo",
      "button_first_login": "nastavit heslo",
      "current_password": "Současné heslo",
      "errors": {
        "invalid": "Zadané současné heslo není správné. Změnu nebylo možné provést.",
        "missing_current": "Zadejte současné heslo",
        "often": "Heslo je možné změnit jen 1x denně. Změnu nebylo možné provést."
      },
      "password": "Nové heslo",
      "requirements": {
        "char_types": {
          "capital": "velká písmena",
          "lowercase": "malá písmena",
          "number": "číslice",
          "special": "speciální znak, např. !, $,"
        },
        "complexity": {
          "all": "obsahovat každý z těchto typů znaků",
          "other": "alespoň %{required} ze 4 typů znaků"
        },
        "length": "délka nejméně %{length} znaků",
        "recent": "nesmí být použito v minulosti",
        "title": "Z důvodu zvýšení bezpečnosti, heslo musí splňovat následující požadavky"
      },
      "set_password_and_begin": "Nyní je potřeba nastavit vaše heslo a můžete začít.",
      "title": "Změna hesla",
      "title_first_login": "Už jen malý krůček"
    },
    "me": "já",
    "profile": {
      "add_custom_image": "Nahrát vlastní obrázek",
      "change_color": "Změnit barvu",
      "crop_image": "Oříznout obrázek",
      "delete_image": "Odebrat obrázek",
      "drag_to_upload": "Přetáhněte obrázek myší nebo klikněte na vybrat soubor.",
      "profile_image": "Profilový obrázek",
      "upload_image": "Vybrat soubor"
    },
    "projects": {
      "bulkEditNote": "U zaškrtnutých projektů změnit roli na",
      "change": "Změnit",
      "checkAll": "Zaškrtnout vše",
      "no_memberships": "Uživatel není členem žádného projektu.",
      "only_if_member": "Pouze projekty, kde je členem",
      "project": "Projekt",
      "role": "Role",
      "title": "Projekty uživatele",
      "title_external": "Úkoly uživatele"
    },
    "settings": {
      "active_users": "Aktivní uživatelé",
      "add_note": "Registrovat další uživatele je možné na úrovni projektu - přidat člena do projektu.",
      "add_user": "Přidat uživatele",
      "additional_info": "Doplnění",
      "additional_info1": "Doplnění 1",
      "additional_info2": "Doplnění 2",
      "admin": "Admin",
      "archive": {
        "archived_header": "Uživatel byl archivován",
        "archived_info": "Při archivaci uživatele dochází k jeho odebrání z řešitelství úkolů. Aktuálně máte %{count} úkolů bez řešitele. Zde je můžete zkontrolovat:",
        "archived_link": "Úkoly bez řešitele",
        "label": "Archivovat uživatele %{name}?",
        "note": "U komentářů a strávených časů bude vidět jméno uživatele. Nebude se však moci přihlásit do aplikace a nebudou mu chodit žádné e-maily.",
        "note2": "Archivovaný uživatel nemůže být autorem ani řešitelem žádného úkolu. Autorství úkolů bude změněno na Vás, řešitelství na \"nepřiřazeno\"."
      },
      "archived_users": "Archivovaní uživatelé",
      "available_month_hours": "Měsíční úvazek",
      "bar": {
        "all_label": "Počet uživatelů v aktuálním balíčku.",
        "manage": "Spravovat účet",
        "paid_label": "Počet účtovaných uživatelů.",
        "title": "Využití balíčku"
      },
      "cant_access": "Nemáte dostatečná práva.",
      "delete": {
        "alternative": "Kromě smazání můžete uživatele také archivovat.",
        "label": "Smazat uživatele %{name}?",
        "note": "Zapsané strávené časy uživatele zůstanou, ale bude u nich uveden autor Neznámý. U úkolů, kterých byl řešitelem bude nastaveno řešitel Nepřiřazeno. V historii úkolů bude u jeho komentářů napsáno neznámý uživatel.",
        "note_archive_label": "Archivace",
        "note_label": "Smazání"
      },
      "edit_avatar": "Upravit avatar",
      "edit_user": "Upravit",
      "errors": {
        "firstname": "Je nutné vyplnit křestní jméno.",
        "lastname": "Je nutné vyplnit příjmení.",
        "mail": "Zadejte e-mail.",
        "noCheckedProjects": "Je nutné zaškrtnout alespoň jeden projekt.",
        "noSelectedRole": "Vyberte jednu z rolí."
      },
      "firstname": "Jméno",
      "help_add": "Vhodné vyplnit při větších týmech. Do prvního pole uveďte například pracovní pozici, do druhého pole oddělení.",
      "help_admin": "Administrátor si může zobrazit i projekty, kterých není členem, spravovat uživatele a účet služby.",
      "hour": "hod",
      "hour_cost": "Hodinová sazba",
      "issuesCheck": {
        "assignedTo": "je řešitelem",
        "author": "je autorem",
        "pass": "není autorem ani řešitelem žádných otevřených úkolů.",
        "progress": "Kontrola úkolů...",
        "tasks": "otevřených úkolů"
      },
      "language": "Jazyk",
      "lastname": "Příjmení",
      "mail": "E-mail",
      "month_hours": "hod / měsíc",
      "no_external_users": "Nemáte žádné externí sledující",
      "notifications_opt_out": "Novým uživatelům přednastavit e-mailová upozornění na",
      "projects": "Členství v projektech",
      "register": "Registrovat",
      "register_user": "Registrovat uživatele",
      "reset": {
        "password": "Opravdu si přejete resetovat heslo uživatele",
        "password_note": "Na e-mail kolegy bude zaslán odkaz, přes který si bude moci nastavit nové, své vlastní, heslo.",
        "reset_password_label": "Resetovat heslo"
      },
      "return_to_my_page": "Návrat na Mou stránku.",
      "settings": "Nastavení",
      "success": "Úspěšně registrováno, uživatelské přístupy zaslány na e-mail.",
      "tasks": "Úkoly",
      "title": "Správa uživatelů",
      "title_external": "Externí sledující",
      "unwatch_tasks": {
        "confirm_question": "Odstranit externího sledujícího <b>%{name}</b> ze všech úkolů?"
      }
    }
  },
  "wizard": {
    "add_projects": "přidat projekty",
    "add_template": "přidat šablony",
    "blank_project": "Máte nevyplněný nadřazený projekt.",
    "blank_template": "Máte nevyplněnou šablonu.",
    "create_templates": "vytvořit šablony",
    "e-mail": "e-mail",
    "examples": "Příklady",
    "first_name": "Jméno",
    "go_to_projects": "přejít na projekty",
    "invalid_mail": "Uživatel %{name} má neplatný email.",
    "invalid_role": "Uživatel %{name} nemá vyplnenou rolu.",
    "last_name": "Příjmení",
    "non_unique_emails": "Uživatelé nemohou používat stejný email.",
    "one_project": "Musíte založit alespoň jeden projekt.",
    "one_template": "Musíte založit alespoň jednou šablonu.",
    "project": "Zadejte nazev projektu",
    "register_users": "registrovat uživatele",
    "role": "Vyberte roli",
    "skip": "přeskočit",
    "start": "začít",
    "step1": "přivítání",
    "step2": "přidání projektů",
    "step3": "registrace  uživatelů",
    "subproject": "Zadejte nazev podprojektu",
    "subtemplate": "Zadejte nazev podprojektu šablony",
    "template": "Zadejte nazev šablony",
    "template_text1": "Šablony založeny.",
    "template_text2": "Ve výpise projektů šablonu uvidíte označnou ikonou šálku kávy. Nyní na ni stačí kliknout a začít přidávat úkoly.",
    "template_text3": "Tip: vyzkoušejte v šabloně v levém menu kliknout na",
    "template_text4": "a naplánovat úkoly přes myšlenkovou mapu.",
    "template_textHelp": "Šablony jsou výbornou cestou jak nanečisto naplánovat projekt. V šabloně můžete rozdat úkoly a nastavit termíny aniž by o tom chodily notifikace kolegům. Takto připravíte základ projektu a následně ze šablony snadno založíte ostrý projekt.",
    "template_title1": "Přidat šablony projektu",
    "template_title2": "Hotovo",
    "text1_step2": "Projekty tvoří složky, do kterých zadáváte úkoly. Zde si můžete vytvořit strukturu prvních projektů a pod-projektů. Pro inspiraci jsme vpravo přiložili několik příkladů, stačí zadat alespoň jeden projekt.",
    "text1_step3": "Tento krok je dobrovolný, nicméně v týmu se projekt realizuje rychleji.",
    "text1_step3b": "Kolegům budete moci brzy rozdat úkoly, které Projektově ohlídá.",
    "text1_step4": "Gratulujeme, Projektově je pro Vás připraveno.",
    "text2_step3": "Manažer - vidí v projektech vše, může vše.",
    "text2_step4": "Nyní stačí rozkliknout jakýkoliv projekt a přidávat úkoly.",
    "text3_step3": "Člen týmu - pracuje na úkolech, může zakládat úkoly sobě i ostatním.",
    "text3_step4": "Nebo si přejete ještě",
    "text4_step3": "Zákazník - role k nahlížení.",
    "text4_step4": "vytvořit šablonu projektu?",
    "text5_step3": "Externista - stejně jako člen týmu, vidí ale jen své úkoly. Například když je v projektu úkolů 8, on vidí jen 1 svůj.",
    "text6_step3": "Více zde: ",
    "text7_step3": "zobrazit více k rolím",
    "text_step1": "Jak mít pořádek a přehled nad realizovanými projekty? V následujích 2 krocích společně začneme.",
    "title_step2": "Jaké projekty realizujete?",
    "title_step3": "Přidejte své kolegy",
    "title_step4": "Hotovo",
    "welcome_text": "Vítejte v Projektově"
  }
});
I18n.translations["de"] = I18n.extend((I18n.translations["de"] || {}), {
  "activities": {
    "action": {
      "close": "Aufgabe geschlossen",
      "comment": "Kommentiert",
      "create": "Aufgabe erstellt",
      "edit": "Aufgabe geändert"
    },
    "assigned_to": "Aufgabelöser",
    "author": "Autor",
    "change": {
      "all": "alles",
      "assigned_to": "Aufgabelöser",
      "author": "autor",
      "close": "Aufgaben schliessen",
      "create": "Aufgabenerstellung",
      "description": "Beschreibung",
      "done_ratio": "% erledigt",
      "due_date": "Aufgabetermin",
      "estimated_hours": "Schätzung der Zeit",
      "me": "ich",
      "note": "Kommentar hinzufügen",
      "others": "andere",
      "priority": "priorität",
      "project": "schieben zu andere Projekt",
      "start_date": "Aufgabeanfang",
      "status": "Status",
      "tracker": "Warteschlangen"
    },
    "changes": "Änderungen",
    "complete_list": "Vollständige Auflistung",
    "no_activity": "Bis jetzt ohne Aktivität.",
    "projects": "Projekte"
  },
  "admin": {
    "client": {
      "billing": {
        "cancel": "änderungen abbrechen",
        "change_info": "Daten ändern",
        "change_save": "Änderungen speichern",
        "change_tariff": "Änderungen speichern und auswählen paket",
        "city": "Stadt",
        "company": "Unternehmen",
        "contact_info": "Kontaktdaten",
        "country": "Land",
        "country_cs": "Tschechische Republik",
        "country_sk": "Slowakische Republik",
        "dic_cs": "MWSt",
        "dic_sk": "MWSt",
        "email": "E-mail",
        "firstname": "Name",
        "ic": "Firmen-Identifikationsnummer",
        "invoice_email": "Rechnungs E-mail",
        "invoice_info": "Rechnungsdaten",
        "lastname": "Nachname",
        "phone": "Telefon",
        "street": "Strasse",
        "zip": "PLZ"
      },
      "messages": {
        "deleted": "Abonnement gekündigt. Die Anwendung Projektově wird am Ende des Abrechnungszeitraums gestoppt und anschließend gelöscht.",
        "deleted_trial": "Testkonto gekündigt. Alle Daten werden bald gelöscht.",
        "new": "Plan erfolgreich bestellt. Die Anwendung Projektově ist jetzt voll funktionsfähig.",
        "updated": "Plan erfolgreich geändert."
      },
      "tariff": {
        "cancel": "abonnement kündigen",
        "cancel_title": "Sind Sie sicher, dass Sie Ihr Abonnement für Projektově kündigen möchten ?",
        "cancel_trial": "Testkonto kündigen",
        "cancel_trial_title": "Möchten Sie das Testkonto wirklich kündigen?",
        "change": "Paket wechseln",
        "current": "Aktuelles Paket",
        "custom": "Individuell",
        "custom_tariff_notice": "Müssen Sie das Abonnement ändern oder den Abrechnungszeitraum anpassen? Wenden Sie sich an unseren Kundensupport.",
        "details": "Paket Details",
        "individual_20": "Individual 20",
        "individual_30": "Individual 30",
        "individual_40": "Individual 40",
        "individual_50": "Individual 50",
        "onpremise": "On-premise",
        "order": "Bestellen Paket",
        "payment_info": "Nach der Bestellung oder Änderung des paket erstellt das System regelmäßig Zahlungsaufforderungen und sendet nach der Zahlung einen Steuerbeleg",
        "payment_invoice": "Rechnung",
        "payment_method": "Zahlungsarten",
        "payment_type": "Zahlungsart",
        "prodva": "Für zwei",
        "promo": "Promo",
        "standard": "Standard",
        "start": "Start",
        "trial": "Testversion",
        "users_count": "Anzahl der Nutzer",
        "users_current": "Aktuelle Anzahl der Benutzer",
        "users_manage": "Benutzerverwaltung"
      },
      "tariff_dialog": {
        "affirmation_accept": "Ich stimme zu",
        "affirmation_terms": "Geschäftsbedingungen",
        "annual": "jährlich (10% Rabatt)",
        "cancel": "änderungen abbrechen",
        "choose": "Tarif auf Bestellung",
        "discount": "Rabatt",
        "monthly": "monatlich",
        "price": "Preis",
        "price_final": "Endgültiger Preis",
        "price_monthly": " / Monat",
        "quarterly": "vierteljährlich",
        "semi_annual": "halbjährlich",
        "support_contact": "Kontaktieren Sie Support",
        "support_users": "Benötigen Sie mehr Benutzer?",
        "tax_notice": "Diese Preise enthalten keine Mehrwertsteuer, wir sind Mehrwertsteuerzahler.",
        "users": "Uživatelů",
        "users_total": "Gesamtzahl der Benutzer"
      },
      "title": "Dienstkonto Projektově"
    },
    "custom_fields": {
      "cancel_button": "Abbrechen",
      "check_warning": "Wenn Sie diese Option aktivieren, kann sie nicht mehr deaktiviert werden.",
      "confirm_delete": "Opravdu si přejete odebrat tuto frontu?",
      "confirm_delete_alternative": "Möchten Sie den Tracker wirklich wechseln?",
      "confirm_delete_tracker": "Möchten Sie diese Front wirklich entfernen?",
      "custom_field_header": "Benutzerdefinierte Feldverwaltung",
      "delete_button": "Löschen",
      "delete_field_confirm": "Möchten Sie das benutzerdefinierte Feld wirklich löschen?",
      "delete_field_label": "Durch das Löschen werden auch alle Werte gelöscht.",
      "delete_field_success": "Benutzerdefiniertes Feld wurde erfolgreich gelöscht.",
      "delete_tracker": "Durch das Entfernen der Front werden alle angegebenen Werte dauerhaft gelöscht.",
      "delete_tracker_alternative": "Mit dem Trackerwechselsystem werden Daten von anderen Tracker gelöscht. Benutzerdefiniertes Feld war in allen Trackern sichtbar, jetzt werde ich nur noch in ausgewählten angezeigt. Die Daten der ausgewählten Tracker bleiben erhalten, andere Tracker werden gelöscht.",
      "edit_button": "Bearbeiten",
      "edit_field_success": "Benutzerdefiniertes Feld wurde erfolgreich geändert.",
      "enable_more_values": "Aktivieren Sie die Auswahl mehrerer Werte",
      "field_add": "Neues benutzerdefiniertes Feld hinzufügen",
      "label_field": "Feld",
      "label_field_visibility": "Sichtbarkeit des benutzerdefinierten Feldes",
      "label_label": "Beschreibung",
      "label_show_in_projectslist": "Projektliste",
      "label_sorting": "Sortieren",
      "label_tracker": "Vorne",
      "label_type": "Typ",
      "maximum_number": "Maximale Anzahl Ihrer benutzerdefinierten Felder erreicht.",
      "move_begin": "Nach oben gehen",
      "move_end": "Nach unten gehen",
      "move_higher": "Nach oben gehen",
      "move_lower": "Nach unten bewegen",
      "name_and_tracker_error": "Name und Vorderseite des benutzerdefinierten Felds sind obligatorisch.",
      "name_and_type_error": "Name und Typ des benutzerdefinierten Felds sind obligatorisch.",
      "name_error": "Der Name des benutzerdefinierten Felds ist obligatorisch.",
      "name_type_tracker_error": "Name, Vorderseite und Typ des benutzerdefinierten Felds sind obligatorisch.",
      "new_line": "Jeder Wert in der neuen Zeile.",
      "no_possible_values": "Sie haben einen Listentyp ausgewählt, Sie müssen mögliche Werte angeben.",
      "possible_values": "Mögliche Werte",
      "possible_values_changed": "Mögliche Werte wurden erfolgreich geändert.",
      "possible_values_obligatory": "Mögliche Werte sind obligatorisch.",
      "projects_label": "Projekte",
      "same_name_error": "Der Name des Feldes ist bereits vorhanden. Bitte wählen Sie einen neuen Namen.",
      "save_button": "Speichern",
      "tasks_label": "Aufgaben",
      "tracker_and_type_error": "Das vordere Feld und der Typ des benutzerdefinierten Felds sind obligatorisch.",
      "tracker_error": "Die Vorderseite des benutzerdefinierten Felds ist obligatorisch.",
      "type_error": "Der Typ des benutzerdefinierten Felds ist obligatorisch.",
      "values_change": "Listenwerte bearbeiten",
      "visible_for_all": "Für jeden sichtbar"
    }
  },
  "budget_entries": {
    "expense_time_table": {
      "all": "alles",
      "filter": "Filter",
      "show": "Zeigen",
      "subtasks2": "Unteraufgaben unter 2 Ebenen ausblenden",
      "sum": "Insgesamt",
      "task": "Aufgaben",
      "title": "Tatsächlichen Kosten einplanung",
      "types": {
        "planned": "plánované náklady",
        "real": "skutečné náklady"
      },
      "warning": "Achtung, dies ist eine einfache Summe der angezeigten Zeilen. Es respektiert nicht die Beziehung zwischen Aufgaben und Unteraufgaben. Wenn die Mischung aus Aufgaben und Unteraufgaben oben gefiltert wird, stimmt die Summe nicht überein.",
      "without_subtasks": "nur Hauptaufgaben"
    },
    "modal": {
      "add": "Hinzufügen",
      "category": "Kategorie",
      "comment": "Bemerkung",
      "date": "Datum",
      "not_set": "Nicht zugeordnet",
      "spent_hours": "Verbrachte Zeiten",
      "summary": "Summe",
      "task": "Aufgabe",
      "value": "Betrag",
      "value_vat": "mit Mwst",
      "value_without_vat": "ohne Mwst",
      "variable_costs": "Variable Kosten",
      "vat": "Daň"
    },
    "project_bilances": {
      "cash_flow": "Cashflow",
      "cash_flow_label": "Realeinkommen - Realausgaben",
      "income_diff": "Zbývá vyfakturovat",
      "planned_expenses": "Geplante Kosten",
      "project": "Project",
      "real_diff": "Zukünftiger Cashflow",
      "real_income": "Realeinkommen",
      "title": "Projektbilanzen"
    },
    "report": {
      "add": "Artikel hinzufügen (Aufgabe)",
      "addNew": "Eintrag erstellen durch Enter",
      "add_expense": "Kosten hinzufügen",
      "add_income": "Einkommen hinzufügen",
      "added_manually": "Geplanter Aufwand wurde manuell hinzugefügt",
      "added_recalculated": "Geplanter Aufwand wurde als Summe erstellt oder aus geschätzten Stunden berechnet",
      "czech_currency": "Eur",
      "delete_budget_line": "Entfernen Sie Artikels von Budget",
      "diff": "Unterschied",
      "expense": "Kosten",
      "expenseHours": "Aufwand - variabel",
      "expenses": "Kosten",
      "expenses_list": "Kostenauflistung",
      "hidden_planned_expenses": "Versteckte geplante Kosten",
      "hidden_real_expenses": "Versteckte Ist kosten",
      "hide": "Diesen Artikel nicht in Ihrem Budget anzeigen",
      "income": "Empfang",
      "incomes_list": "Einkommenauflistung",
      "list_all_expenses": "Auflistung aller Kosten",
      "notify_higher_income": "Höhere Einnahmen eingeben",
      "notify_higher_income_text": "Der gesamte geplante Umsatz muss die geplanten Einnahmen in Teilprojekten übersteigen, die jetzt sind",
      "plan": "Plan",
      "planned_income": "Einkommen (durch Vertrag)",
      "planned_profit": "Geplannte Gewinn",
      "project_budget": "Projektbudget",
      "real_diff_label": "Das Projekt wird Einnahmen %{incomes} und Ausgaben %{expenses} haben. Der zukünftige Cashflow beträgt %{diff}, um das geplante Budget zu erfüllen.",
      "real_expenses": "Tatsächliche Kosten",
      "reality": "Wirklichkeit",
      "spent_time": "Zeit aussert Aufgaben",
      "sum": "Bilanz",
      "sum_reserve": "Bilanz / Reserve",
      "toggle": "Umschalten",
      "total": "Insgesamt",
      "total_real_expenses": "Die Summe der tatsächlichen Kosten einschließlich Teilaufgaben",
      "withVat": "mit Mwst",
      "withoutVat": "ohne Mwst"
    },
    "submenu": {
      "prefill": "Geplante Kosten vorab füllen",
      "prefillText": "Möchten Sie die geplanten Kosten vorab füllen, indem Sie die geschätzte Zeit multipliziert mit dem Stundensatz des Lösers berechnen?",
      "prefillWarning": "Achtung, dies wird den Plan überschreiben.",
      "showAllIssues": "Alle Ausgaben im Budget anzeigen",
      "showOnlyRootIssues": "Nur Stammprobleme im Budget anzeigen",
      "sorting": {
        "default": "Standard",
        "dueDate": "nach Fälligkeitsdatum",
        "heading": "Sortierung",
        "label": "Reihenfolge der Artikel im Budget",
        "startDate": "nach Startdatum",
        "subject": "mit Namen"
      }
    }
  },
  "dashboard": {
    "add_blocks": {
      "add": "Hinzufügen",
      "add_error": "Hinzufügen eines Blocks fehlgeschlagen. Bitte versuche es erneut.",
      "choose": "Wählen um hinzufügen",
      "labels": {
        "activities": "Letzte Aktivität",
        "allprojects": "Alle Projekte",
        "estimatedhoursbyprojects": "Graf nach Projektauslastung",
        "estimatedhoursbyusers": "Teamauslastungsgraf",
        "estimatedhourstable": "Teamauslastungstabelle",
        "favouriteprojects": "Favoritenprojekte",
        "iframe": "Benutzerdefinierter Block",
        "iframebottom": "Benutzerdefinierter Block - unten",
        "issuesassignedto": "Aufgabenlöser",
        "issuesreportedbyme": "Von mir delegierte Aufgaben",
        "issueswatched": "Beobachtete Aufgaben",
        "polls": "Abstimmung",
        "shouldbeinprogress": "Aufgaben, die ausgeführt werden sollen",
        "solvedclosedtasks": "Vyřešené a uzavřené úkoly",
        "timelog": "Meine verbrachte Zeit"
      },
      "reset": "alles entfernen",
      "reset_error": "Block zurücksetzen fehlgeschlagen. Bitte versuche es erneut.",
      "title_here": "passen Sie diese Seite an"
    },
    "blockEstimatedHoursByProjects": {
      "defaultView": "Wenn nichts ausgewählt ist, wird die Standardansicht verwendet – die ersten 30 Projekte.",
      "estimated_hours": "Auslastung nach Projekten",
      "graphTitle": "4 Monaten Aussicht",
      "no_due_date": "OhneTermin",
      "past": "In der Vergangenheit",
      "tableTitle": "Stunden ausert Diagramm"
    },
    "blockEstimatedHoursByUsers": {
      "estimated_hours": "Nutzung von Benutzern.",
      "graphTitle": "4 Monate Aussicht",
      "no_due_date": "Ohne Termin",
      "past": "In der Vergangenheit",
      "tableTitle": "Stunden aussert Diagramm"
    },
    "block_activities": {
      "title": "Letzte Aktivität"
    },
    "block_all_projects": {
      "all_projects": "Alle Projekte",
      "empty": "Sie haben hier keine Projekte."
    },
    "block_assigned_to": {
      "assigned_to": "Aufgabelöser %{name}",
      "complete_list": "Vollständige Auflistung (%{size})",
      "empty": "Sie haben keine Aufgaben.",
      "my_tasks": "Meine Aufgaben"
    },
    "block_author_me_tasks": {
      "author_me_tasks": "Von mir delegierte Aufgaben",
      "empty": "Keine Aufgaben",
      "show": "Anzeigen"
    },
    "block_favorite_projects": {
      "empty": "Sie haben keine Favoritenprojekte. Markieren Sie durch klicken auf Eigetragene Zeit",
      "empty_end": "in Projekentliste.",
      "favorite_projects": "Favoritenprojekte"
    },
    "block_my_tasks_today": {
      "5days": "Verpasst innerhalb von 5 Tagen",
      "approaching": "Bald zu erledigen",
      "approaching_dueDate": "Termin",
      "approaching_startDate": "Anfang",
      "before_yesterday": "Vorgestern",
      "my_day": "Mein Tag",
      "no_tasks": "Sie haben keine Aufgaben.",
      "to_resolved": "In Verspätung",
      "today": "Termin Heute",
      "yesterday": "Gestern"
    },
    "block_my_time_entries": {
      "add": "Zeit hinzufügen",
      "allTimes": "Insgesamt",
      "comment": "Kommentar",
      "complete": "Vollständige Auflistung",
      "empty": "Sie haben keine Zeit in den letzten 7 Tagen verbracht.",
      "hours": "Stunden",
      "project": "Projekt",
      "task": "Aufgabe",
      "title": "Meine verbrachte Zeit"
    },
    "block_polls": {
      "choice": "Auswahl",
      "due_date": "Termin",
      "question": "Frage",
      "task": "Aufgabe"
    },
    "block_solved_closed_tasks": {
      "collapse": "Zusammenbruch",
      "expand": "Expandieren",
      "title": "Gelöste und abgeschlossene Aufgaben"
    },
    "block_watched_tasks": {
      "allAssigned": "und Aufgaben, bei denen ich der Löser bin",
      "all_watched_tasks_with": "Alle verfolgten Jobs (%{size})",
      "all_watched_tasks_without": "Alle verfolgten Aufgaben ohne Aufgaben, bei denen ich Aufgabenlöser bin ( %{size})",
      "assignedWithoutMe": "ohne Aufgaben, wo ich der Löser bin",
      "empty": "Sie haben keine Aufgaben.",
      "show": "Anzeigen",
      "watched_tasks": "Beobachtete Aufgaben"
    },
    "complete_list": "Vollständige Auflistung",
    "planned_estimated_hours": "Geplannt",
    "spent_hours": "Eigetragene Zeit"
  },
  "date": {
    "abbr_day_names": [
      "So",
      "Mo",
      "Di",
      "Mi",
      "Do",
      "Fr",
      "Sa"
    ],
    "abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mär",
      "Apr",
      "Mai",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dez"
    ],
    "day_names": [
      "Sonntag",
      "Montag",
      "Dienstag",
      "Mittwoch",
      "Donnerstag",
      "Freitag",
      "Samstag"
    ],
    "formats": {
      "default": "%d.%m.%Y",
      "long": "%e. %B %Y",
      "short": "%e. %b"
    },
    "moment_formats": {
      "default": "D.M.YYYY",
      "from": "[from] D.M.YYYY",
      "short": "D.M.",
      "to": "[to] D.M.YYYY"
    },
    "month_names": [
      null,
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember"
    ],
    "order": [
      "day",
      "month",
      "year"
    ]
  },
  "datetime": {
    "distance_in_time": {
      "day_before_yesterday_at": "předevčírem v %{time}",
      "today_at": "dnes v %{time}",
      "yesterday_at": "včera v %{time}"
    },
    "distance_in_words": {
      "about_x_hours": {
        "one": "etwa 1 Stunde",
        "other": "etwa %{count} Stunden"
      },
      "about_x_months": {
        "one": "etwa 1 Monat",
        "other": "etwa %{count} Monaten"
      },
      "about_x_years": {
        "one": "etwa 1 Jahr",
        "other": "etwa %{count} Jahren"
      },
      "almost_x_years": {
        "one": "fast 1 Jahr",
        "other": "fast %{count} Jahren"
      },
      "half_a_minute": "eine halbe Minute",
      "less_than_x_minutes": {
        "one": "weniger als 1 Minute",
        "other": "weniger als %{count} Minuten"
      },
      "less_than_x_seconds": {
        "one": "weniger als 1 Sekunde",
        "other": "weniger als %{count} Sekunden"
      },
      "over_x_years": {
        "one": "mehr als 1 Jahr",
        "other": "mehr als %{count} Jahren"
      },
      "x_days": {
        "one": "1 Tag",
        "other": "%{count} Tagen"
      },
      "x_hours": {
        "one": "1 hour",
        "other": "%{count} hours"
      },
      "x_minutes": {
        "one": "1 Minute",
        "other": "%{count} Minuten"
      },
      "x_months": {
        "one": "1 Monat",
        "other": "%{count} Monaten"
      },
      "x_seconds": {
        "one": "1 Sekunde",
        "other": "%{count} Sekunden"
      },
      "x_weeks": {
        "one": "1 Woche",
        "other": "%{count} Wochen"
      }
    },
    "prompts": {
      "day": "Tag",
      "hour": "Stunden",
      "minute": "Minute",
      "month": "Monat",
      "second": "Sekunde",
      "year": "Jahr"
    }
  },
  "default": {
    "admin_firstname": "Anzeige",
    "admin_lastname": "Projektově",
    "columns": {
      "assigned_to": "Aufgabenlöser",
      "author": "Autor",
      "due_date": "Termin",
      "estimated_hours": "Schätzung",
      "priority": "Priorität",
      "project": "Projekt",
      "start_date": "Anfang",
      "status": "Zustand",
      "subject": "Aufgabe",
      "total_spent_hours": "Verbraucht"
    },
    "user": {
      "change_color": "Farbe ändern",
      "email": "e-mail",
      "firstname": "Vorname",
      "language": "Sprache",
      "lastname": "Nachname"
    }
  },
  "estimated_hours_table": {
    "all_time": "Insgesamt",
    "block_name": "Geplante Stunden",
    "today": "Heute",
    "tomorrow": "Morgen"
  },
  "files": {
    "addFile": "Datei hinzufügen",
    "addFileToTask": "Aufgabe wählen",
    "author": "Autor",
    "createdAt": "Datum",
    "download": "Download",
    "filename": "Datei",
    "issue": "Aufgabe",
    "project": "Projekt",
    "projectFiles": "Dateien im Projekt",
    "tasksFiles": "Dateien für Aufgaben"
  },
  "gantt": {
    "add_name": "Aufgabe hinzufügen",
    "add_new_task": "Aufgabe hinzufügen",
    "add_next_task_header": "Folgeaufgabe hinzufügen",
    "add_previous_task_header": "Vorherige Aufgabe hinzufügen",
    "add_task": "Přidat úkol",
    "adding_subtask_to_task": "Subtask zur Aufgabe hinzufügen",
    "adding_task_to_project": "Aufgabe zum Projekt hinzufügen",
    "cancel": "Stornieren",
    "cancel_changes": "Cancel changes",
    "cant_export_grouped_by_assigned_to": "Die nach Aufgabenempfängern gruppierte Ansicht kann nicht exportiert werden.",
    "change_parent_task_error": "The change you are going to make will change the date of the envelope task. This feature is only available to the manager.",
    "color_by": {
      "default": "standard",
      "label": "Farbe",
      "projects": "nach Projekt",
      "templateTask": "nach Aufgabe aus Vorlage"
    },
    "confirm_link_deleting": "wird gelöscht.",
    "conflicts_warning": {
      "note": "Klicken Sie auf eine Aufgabe, um deren Registerkarte anzuzeigen, auf der Sie die Änderungen überprüfen können. Speichern Sie das Diagramm, um das Start- und Enddatum der Aufgaben im Gantt-Diagramm festzulegen.",
      "task": {
        "few": "Die Aufgaben",
        "one": "Die Aufgabe",
        "other": "Die Aufgaben"
      },
      "was_changed": {
        "few": ", die Sie gerade bearbeiten, wurden auch auf dem Server geändert.",
        "one": ", die Sie gerade bearbeiten, wurde auch auf dem Server geändert.",
        "other": ", die Sie gerade bearbeiten, wurden auch auf dem Server geändert."
      }
    },
    "date_range": {
      "all_time": "Alle",
      "current_month": "Diesen Monat",
      "current_week": "Diese Woche",
      "current_year": "Dieses Jahr",
      "custom": "Benutzerdefiniert",
      "next_month": "nächsten Monat",
      "next_week": "Nächste Woche",
      "next_year": "Nächstes Jahr",
      "three_months": "3 Monate",
      "today": "Heute"
    },
    "filter_tasks": "Filter task",
    "filter_user": "Filterempfänger",
    "fullscreen": "Vollbild",
    "group_by_assignee": "Gruppenaufgaben",
    "group_by_projects": "Nach Projekten gruppieren",
    "heading": "Gantt diagram",
    "hide_subtasks": "Unteraufgaben ausblenden",
    "label_days": "days",
    "label_delete_relation": "Sie möchten die Relation wirklich löschen",
    "label_done": "Done",
    "label_duration": "Duration",
    "label_end_after_start": "Der Begriff muss höher als das Startdatum sein.",
    "label_export": "Exportieren nach",
    "label_from": "From",
    "label_next": "Mächste",
    "label_no": "Nein",
    "label_previous": "Vorhergehend",
    "label_project": "Projekt",
    "label_start_before_end": "Das Startdatum muss kleiner als die Laufzeit sein.",
    "label_to": "To",
    "label_to_small": "to",
    "label_yes": "Ja",
    "link": "Link",
    "new_task_added": "Neue Aufgabe erfolgreich hinzugefügt.",
    "new_task_not_added": "Neue Aufgabe konnte nicht hinzugefügt werden.",
    "next_tasks_header": "Bestehende Folgeaufgaben",
    "no_next_tasks": "Keine Folgeaufgaben vorhanden.",
    "no_previous_tasks": "Keine vorhandenen vorherigen Aufgaben.",
    "other_exports": "Andere Varianten",
    "planned_dueDate": "Geplanter Begriff",
    "planned_startDate": "Geplantes Startdatum",
    "previous_tasks_header": "Vorhandene vorherige Aufgaben",
    "readonly_note": "Nur lesbar. Für aktives Gantt müssen Sie Manager in sichtbaren Projekten sein.",
    "save_changes": "Save changes",
    "save_first": "Änderungen zuerst speichern oder verwerfen",
    "section_time": "Startdatum und Dauer der Aufgabe",
    "show": {
      "all": "alles",
      "label": "Anzeigen",
      "projects": "nur Projekte",
      "subtasks2": "Unteraufgaben aus 2 Ebenen ausblenden",
      "tasks": "Projekte und Hauptaufgaben"
    },
    "show_closed_tasks": "Show geschlossen",
    "sort_tasks": "Ordne Aufgaben an",
    "task_duration": "Dauer",
    "task_end": "Task end",
    "task_name": "Task name",
    "task_order": "Automatically arrange tasks when editing",
    "task_start": "Task start",
    "time_disable_button": "Zeiten entfernen",
    "time_enable_button": "Zeitplan",
    "type": {
      "envelope": "Umschlag",
      "label": "Typ",
      "task": "Aufgabe"
    },
    "week": "Woche",
    "zoom_in": "Zoom in",
    "zoom_out": "Zoom out"
  },
  "header": {
    "all_projects": "Alle Projekte",
    "clear": "Liste löschen .",
    "menu": {
      "my_page": "Meine Seite",
      "my_tasks": "Meine Aufgaben",
      "new_member": "Kontakt Neu",
      "new_task": "Aufgabe Neu",
      "open_tasks": "Geöffnete Aufgaben",
      "projects": "Projekte",
      "tasks_plural": "Aufgaben"
    },
    "projects": {
      "current_project": "Aufgabenauflistung im Projekt"
    },
    "search": {
      "history": "Search history",
      "in_project": "nur im aktuellen Projekt",
      "placeholder": "Suche in Aufgaben"
    },
    "switch_to": "Umschalten auf",
    "title": "Pro přehledné řízení firmy, projektů a úkolů.",
    "user_menu": {
      "calendar": "Kalender",
      "client_profil": "Konto Projektově",
      "help": "Hilfe",
      "last_activity": "Letzte Aktivität",
      "logout": "Abmelden",
      "mobile_application": "Mobile Apps",
      "my_settings": "Meine Einstellungen",
      "test_v2": "Try Projektově 2",
      "this_week": "Diese Woche",
      "timetracker": "Stoppuhr",
      "users_settings": "Benutzerverwaltung"
    }
  },
  "issues": {
    "bulk_edit": {
      "all_issues": "Alle geöffnete Aufgaben",
      "all_issues_note": "von Projekt mit Unterprojekte",
      "assigned_to": "Aufagben zugeordnet :"
    },
    "delete_modal": {
      "button_no": "Nein",
      "button_yes": "Ja, löschen",
      "label": "Aufgabe wirklich löschen?",
      "label_subtask": "Unteraufgabe wirklich löschen?",
      "note": "Aufgabe wird gelöscht mit alle Unteraufgaben."
    },
    "filters": {
      "assignedToId": "Aufgabenlöser",
      "authorId": "Autor",
      "buttons": {
        "apply": "Verwenden",
        "cancel": "Stornieren"
      },
      "calendar": "Kalender",
      "closedAt": "Geschossene",
      "createdAt": "Erstellt",
      "customFields": "Eigene Feld",
      "customFields_icon_title": "Entfernen",
      "customFields_title": "Filters",
      "dueDate": "Termin",
      "fromFavoriteProjects": "Aus Favoritprojekte",
      "input_placeholder": "Einfügen gesuchte Text",
      "lastActivityAt": "Geändert",
      "noActiveTag": "Dieser Filter kann nicht in der Ansicht verwendet werden und ist daher nicht aktiv.",
      "no_assignedToId": "keine Benutzer gefunden",
      "no_authorId": "keine Benutzer gefunden",
      "no_one_assigned": "unzugeordnet",
      "no_projectId": "keine Projekt gefunden",
      "not_entered": "Nicht eingegeben",
      "others": "Andere",
      "parentId": "Ohne Teilaufgaben",
      "priorityId": "Priorität",
      "projectId": "Projekt",
      "repetitive_false": "Ohne Aufgabewiederholung",
      "repetitive_true": "Wiederholt",
      "startDate": "Anfang",
      "statusId": "Status",
      "templates": "mit Aufgabeschablonen",
      "this_2_weeks_and_older": "Diese 2 Wochen und älter",
      "this_4_weeks_and_older": "Diese 4 Wochen und älter",
      "this_month": "Diese Monat",
      "this_week": "Diese Woche",
      "this_week_and_older": "Diese Woche und älter",
      "today": "Heute",
      "today_and_older": "Heute und älter",
      "trackerId": "Warteschlange"
    },
    "index": {
      "add_first_task": "Geben Sie die erste Aufgabe ein.",
      "add_remove_tasks_order": "Hinzufügen / Entfernen der nächsten Sortierungsebene",
      "add_tasks_from_template": "Aufgaben aus Vorlage hinzufügen",
      "bulk_edit": "Massenbearbeitung",
      "bulk_modal": {
        "assigned_to": "Aufgaben zugeordnet",
        "edit": "bearbeiten",
        "edit_all": "alle geöffnete Aufgaben bearbeiten"
      },
      "cant_be_closed": {
        "comment_wip": "Die Aufgabenkarte kann jetzt nicht geschlossen werden, Kommnetar steht in bearbeitung.",
        "description_wip": "Die Aufgabenkarte kann jetzt nicht geschlossen werden, Stellenbeschreibung steht in bearbeitung.",
        "prompt": "Möchten Sie mit der nächsten Seite fortfahren?",
        "title": "Aufgabekarte kann nicht geschlossen werden"
      },
      "choose_calendar_list": "Wählen Sie den Eintrag, den Sie mit dem Kalender verknüpfen möchten. <br> Geben Sie dann die ausgewählte Anweisungsliste in Ihren Kalender ein.",
      "default_order": "Startsortierung einstellen",
      "delete": "Aufgabe %{subject} löschen",
      "detail": "Detail",
      "exports_modal": {
        "title": "Aufgabenlisten exportieren",
        "with_descriptions": "mit Aufgabenbeschreibung",
        "without_descriptions": "ohne Aufgabenbeschreibung"
      },
      "grouped": {
        "group_assignee": "nach Aufgabenlöser",
        "group_author": "nach Autor",
        "group_by": "Gruppieren",
        "group_dueDate": "nach Termin",
        "group_lastActivityAt": "nach Aktivität",
        "group_none": "Gruppierung entfernen",
        "group_priority": "nach Priorität",
        "group_project": "nach Projekt",
        "group_startDate": "nach Anfang",
        "group_status": "nach Status",
        "no_dueDate": "Ohne Termin",
        "no_one_assigned": "Nicht zugeordnete",
        "no_startDate": "Ohne Anfang",
        "no_value": "Ohne Wert",
        "sample": "Vorschau P."
      },
      "ics_export": "Verbinden mit Kalender",
      "ics_modal": {
        "all_tasks": "Alle Aufgaben",
        "all_tasks_duration": "Alle Aufgaben mit Start- und Fälligkeitsdatum",
        "all_tasks_only_with_times": "Alle Aufgaben, nur mit Zeiten",
        "my_tasks": "Meine Aufgaben",
        "my_tasks_duration": "Meine Aufgaben mit Start- und Fälligkeitsdatum",
        "my_tasks_only_with_times": "Meine Aufgaben, nur mit der Zeiten"
      },
      "in_one_column": "alle untereinander",
      "in_structure": "in struktur",
      "link_with_calendar": "Link zum Kalender",
      "modals": {
        "new_issues": {
          "create": "Erstellen",
          "parent_task": "Übergeordnete Aufgabe",
          "plan": {
            "from_end": "vom Ende",
            "from_start": "vom Anfang",
            "help_left": "Das geplante Startdatum ist bekannt, ab diesem Datum planen wir Aufgaben.",
            "help_right": "Start und Fälligkeitsdatum werden berechnet, um die letzte Aufgabe am geplanten Enddatum zu beenden.",
            "title": "Planung"
          },
          "planned_due_date": "Termin geplannt",
          "planned_start_date": "Anfang geplannt",
          "success": "Aufgaben erfolgreich hinzugefügt",
          "template": "Vorlage",
          "title": "Aufgaben aus Vorlage hinzufügen"
        }
      },
      "need_update": "Angesehene Aufgaben sind nicht mehr aktuell, klicken Sie, um sie zu aktualisieren.",
      "no_closed_tasks": "Es gibt noch keine geschlosene Aufagben im Projekt.",
      "no_overdue_tasks": "Prima, die Aufgaben sind ohne Verzögerung.",
      "no_solved_tasks": "Es gibt noch keine gelösste Aufagben im Projekt.",
      "not_found": "Wir haben den gewünschten Aufgabe nicht gefunden.",
      "only_with_times": "nur mit Zeiten",
      "order_tasks": "Aufgaben sortieren",
      "reminder": {
        "text": "Kommentare zu einem neuen Eintrag hier.",
        "title": "Tritt einer neuen Facebook-Gruppe bei. Wir werden die Anfrage bestätigen."
      },
      "search_task": "Aufgabe",
      "settings": {
        "add": "Hinzufügen",
        "columns": "Spalten einrichten",
        "disable": "Ausschalten",
        "enable": "Einschalten",
        "horizontalScroll": "Liste scrollen",
        "reorder": "Reihenfolge ändern"
      },
      "show_more": {
        "few": "Zeige mehr %{count}",
        "one": "Anzeigen letzte",
        "other": "Zeige weitere %{count}"
      },
      "show_structure_filter": "Anzeigen",
      "task_quickadd": "Geben Sie den Namen des neuen Aufgaben ein und drücken Sie die Eingabetaste",
      "task_quickadd_hitenter": "Drücken Sie Enter",
      "tasks_export": "Aufgabenliste exportieren"
    },
    "messages": {
      "created": "Die Aufgabe wurde erfolgreich erstellt.",
      "errors": {
        "dueDate": {
          "greater_than_or_equal_to_start_date": "Fertigstellung darf nicht vor dem Start des Auftrag liegen.",
          "locked_task_in_project": "Es gibt eine Aufgabe mit gesperrtem Start- und Fälligkeitsdatum im Projekt. Aufgabe kann nicht geändert werden.",
          "lower_than_or_equal_to_parent_due_date": "Das Fertigstellung muss vor dem Fertigstellung der übergeordneten Aufgabe liegen.",
          "start_and_due_date_locked": "Aufgabenstart und Fälligkeitsdatum sind vom Manager gesperrt. Aufgabe muss zuerst entsperrt werden."
        },
        "estimatedHours": {
          "greater_than": "Die geschätzte Zeit muss größer sein als die Summe der Schätzungen für Teilaufgaben (%{count} Std)"
        },
        "startDate": {
          "greater_than_or_equal_to_parent_start_date": "Das Startdatum kann nicht vor dem Startdatum der übergeordneten Aufgabe liegen."
        }
      },
      "open_task": "erstellte Aufgabe öffnen"
    },
    "modals": {
      "repetitive": {
        "cancel": "wiederholung stornieren",
        "day_in_month": "Tag in Monat",
        "days_in_week": "Tages in Woche",
        "interval": "Interval",
        "last": "letzte",
        "months": {
          "few": "alle %{count} Monate",
          "one": "jeden Monat",
          "other": "alle %{count} Monaten"
        },
        "periodically_every": "was",
        "periodically_label": "Tage ab dem Tag der Schließung",
        "repeat": "Wiederholen",
        "repeat_options": {
          "daily": "täglich",
          "monthly": "monatlich",
          "never": "niemals",
          "periodically": "regelmässig",
          "weekly": "wochentlich",
          "yearly": "Jährlich"
        },
        "start": "Starten",
        "submit": "Einstellen",
        "title": "Wiederholung - Einstellungen",
        "weeks": {
          "few": "alle %{count} Wochen",
          "one": "jede Woche",
          "other": "jede %{count} Woche"
        },
        "years": {
          "few": "alle %{count} Jahre",
          "one": "jedes Jahr",
          "other": "alle %{count} Jahre"
        }
      },
      "tracker": {
        "label": "Beachten Sie, dass Sie beim Ändern des Warteschlange benutzerdefinierte Feldwerte verlieren. Die einzige Ausnahme besteht darin, diese benutzerdefinierten Felder auch im neuen Warteschlange zu haben. Willst du fortfahren?"
      }
    },
    "new": {
      "add_contact": "Kontakt hinzufügen",
      "assignee": "Aufgabenlöser",
      "contact": "Kontakt",
      "contact_name": "Kontakt",
      "create_task": "Aufgabe erstellen",
      "customer_tittle": "Neue Aufgaben von Kunden sind zugeordnet zu diese Persone.",
      "due_date": "Termin",
      "errors": {
        "cannot_create": "Aufgabe nicht erstellt",
        "date": "Termin von Aufgabe kann nicht sein vor dem Beginn",
        "name": "Keine Name von Aufgabe",
        "project": "Keine Projekt ausgewählt"
      },
      "new_contact": "Neue Kontakt",
      "new_task": "Neue Aufgabe",
      "placeholders": {
        "assignee": "NIcht zugeordnete",
        "new_contact": "Kontaktname",
        "new_contact_in_project": "Kontakt zu Projekt %{project}",
        "new_task": "Aufgabename",
        "new_task_in_project": "Aufgabe zu Projekt %{project}",
        "project": "Projekt auswählen",
        "starting_today": "Heute fängt an"
      },
      "project": "Projekt",
      "set_start_date": "Anfangsdatum eingeben",
      "start_date": "Anfang",
      "task": "Aufgabe",
      "task_name": "Name von Aufgabe",
      "tracker": "Warteschlange"
    },
    "no_due_date": "Ohne Termin",
    "no_one_assigned": "Nicht zugeordnete",
    "show": {
      "activity_created_at": "Aufgabe erstellt",
      "add_comment": "Kommentar einfügen",
      "add_time": "Aufgabenzeit hinzufügen",
      "attachments": {
        "attach_delete": "Datei wirklich löschen?",
        "attach_deleted": "Datei wurde gelöscht.",
        "attach_file": "Dateien hinzufügen",
        "attach_not_deleted": "Datei ist nicht gelöscht.",
        "button_no": "Nein",
        "button_yes": "Ja, löschen",
        "not_saved": "Datei speichern ist fehlgeschlagen.",
        "saved": "Dateien sind gespeichert",
        "saving": "Dateien lade ein ...",
        "title_delete": "Datei entfernen"
      },
      "budget": {
        "add": "Einfügen",
        "amount": "Betrag",
        "bad_amount": "Der Betrag darf nicht null oder negativ sein.",
        "date": "Datum",
        "error": "Betrag wurde nicht hinzugefügt. Bitte versuche es später erneut.",
        "expenses": "Kosten",
        "note": "Bemerkung",
        "title": "Kosten zuordnen",
        "vat": "Mwst"
      },
      "cannot_add_comment": "Geschlossene Aufgaben können nicht kommentiert werden. Bitte erstellen Sie eine neue Aufgabe über die Schaltfläche oben.",
      "close": "Schliessen ?",
      "close_title": "Aufgabe schliessen",
      "closed": "Geschlossen",
      "done_ratio_locked": "Abgeschlossene Prozentsätze werden über Unteraufgaben gezählt. Bitte ändere % in Aufgaben.",
      "due_date_locked": "Termin kann nur Autor ändern.",
      "due_reminder": {
        "add": "Erinnerungen hinzufügen",
        "before": "vor Ablauf der Frist",
        "title": "Erinnerung",
        "tooltip": "Erinnerung an die Frist per E-Mail an den Aufgabenlöser hinzufügen."
      },
      "editor": {
        "cancel": "Änderungen wegwerfen",
        "placeholder": "Details für Sie oder Kollegen ...",
        "save": "Speichern"
      },
      "open_task": "Aufgabe öffnen",
      "options": {
        "add_tasks": "Aufgaben aus Vorlage hinzufügen",
        "copy": "Aufgabe kopieren",
        "delete": "Aufgabe löschen",
        "download": "Als PDF mit Kommentaren herunterladen",
        "download_full": "Als PDF ohne Kommentare herunterladen",
        "lock_dates": "Zamknout začátek a termín",
        "title": "Aufgabeneinrichtung",
        "unlock_dates": "Odemknout začátek a termín"
      },
      "parent": {
        "loading": "Wird geladen ...",
        "more_items": {
          "few": "... und andere  %{count}  Aufgabe. Suche verfeinern.",
          "one": "... und 1 andere Aufgabe. Suche verfeinern.",
          "other": "... und andere  %{count}  Aufgaben. Suche verfeinern."
        },
        "no_items": "Keine Aufgabe gefunden.",
        "no_parent": "Keine"
      },
      "project": {
        "no_items": "Kein Projekt gefunden."
      },
      "quill_editor": {
        "h1": "Titel",
        "h2": "Untertitel",
        "normal": "Normal"
      },
      "relations": {
        "add_related": "Zugehörig hinzufügen",
        "close_title": "Schliessen",
        "closed_title": "Geschlossene",
        "hint_relates": "der Name der zugehörigen Aufgabe",
        "hint_subtask": "Eingeben Name für untergeordnete Aufgabe und Enter",
        "label_relates": "Zugehörige : %{name}",
        "label_subtask": "Untergeordnete Aufgabe",
        "link_relates": "Verbindene",
        "open_title": "Geöffnet",
        "relates": "Zugehörig",
        "remove_relation_to_relates": "Link zu zugehörige Aufgabe entfernen",
        "reopen_title": "Geschlossene, Status ändern auf \" in Lösung\"",
        "solve_title": "Lösen",
        "solved_title": "Gelösst",
        "unsolve_title": "Gelösste , Status ändern auf \" unter Lösung\""
      },
      "reopen_title": "Aufgabe öffnen",
      "repetitive": {
        "add": "Wiederholung einstellen",
        "disabled": "keine Wiederholung möglich",
        "disabled_reason": {
          "already_repeated": "Diese Aufgabe wurde wiederholt. Ändern Sie die Einstellungen in der zuletzt aktiven Aufgabe, die in den geöffneten Aufgaben im Projekt gefunden wurden.",
          "already_repetitive": "Některý z nadúkolů nebo podúkolů již má nastaveno opakování.",
          "already_repetitve": "Einige Übergeordnete- oder Untergeordnete Aufgaben bereits hat eine Wiederholung"
        },
        "edit": "Wiederholung ändern",
        "next_due": "Fälligkeitsdatum der nächsten Aufgabe",
        "wrong_input": "Wähle mindestens einen Tag",
        "wrong_next_due": "Falscher nächster Fälligkeitstermin? Wiederholung abbrechen und neue einrichten."
      },
      "solve": "Lösen ?",
      "solve_title": "Markieren Sie den Aufgabe als aufgelöst, damit kann Autor schliessen.",
      "solved": "Gelösst",
      "task_has_x_files": {
        "few": "Die Aufgabe hat %{count} Dateien",
        "one": "Die Aufgabe hat eine %{count} Datei",
        "other": "Die Aufgabe hat %{count} Dateien"
      },
      "total_spent_hours": "%{hours} insgesamt",
      "unsolve_title": "Markieren Sie Aufgabe als ungelöst (in Lösungsprozes)",
      "watchers": {
        "add": "Verfolger hinzufügen",
        "add_watchers": "Hinzufügen",
        "assigned": "Aufgaben, der %{name} zugewiesen ist",
        "check_all": "alles markeiren",
        "dialog_title": "Verfolger hinzufügen",
        "how_it_looks": "Hier ist eine <a href=\"%{url}\" target=\"_blank\"> Vorschau </a>, wie sieht es eingeladene Person.",
        "label": "Verfolger",
        "not_search": "Kein Mitglied mit diesem Namen gefunden.",
        "notes": {
          "all_added": "Alle aktuellen Teammitglieder wurden hinzugefügt.",
          "externalusers_notifications": "Sie erhalten nur Kommentare zur E-Mail, auf die sie auch direkt aus der E-Mail antworten können (Antwort auf die E-Mail).",
          "externalusers_visibility": "In der Anwendung haben Sie Benutzer, die sich mit ihrer E-Mail-Adresse und ihrem Kennwort anmelden und bei Aufgaben zusammenarbeiten können. Benutzer können beliebige Aufgaben beobachten. Wenn ich eine Aufgabe verfolge, erhalte ich E-Mail-Benachrichtigungen mit Aufgabenänderungen und Kommentaren. Wenn ich Kommentare an andere weitergeben möchte, die keinen Zugriff auf das System haben, kann ich externe Beobachter verwenden. Diese externen Beobachter (ohne Registrierung) haben keinen Zugriff auf die App, sie sehen nur den Aufgabennamen, die Aufgabenbeschreibung und die Kommentare über einen Link in der E-Mail.",
          "how_to": "Wenn Sie die Aufgabe mit anderen Kollegen teilen möchten, müssen Sie sie zunächst für die freigeben dieses Projekts oder den Team-Manager bitten, das Projekt mit Kollegen zu teilen."
        },
        "remove": "Entfernen Sie %{name} aus der Aufgabenverfolgung",
        "removeExternal": "Löschen Sie den externen Verfolger %%{name}. Entfernung verliert den Zugriff auf eine eingeschränkte Ansicht eines Jobs.",
        "tab_external": "Teilen ohne Registrierung",
        "tab_users": "Registrierte Benutzer",
        "uncheck_all": "alles unmarkieren"
      }
    },
    "show_static": {
      "no_comments_yet": "Zatím zde nejsou žádné komentáře."
    },
    "taskParentError": {
      "add": "Hinzufügen",
      "added": "Hinzugefügt.",
      "ask1": "Bitte fragen Sie den Projektleiter",
      "ask2": "um sie hier hinzuzufügen.",
      "heading": "Im Zielprojekt fehlen Benutzer",
      "missingTracker": "Im Zielprojekt fehlt ein Tracker. Beim Verschieben einer Aufgabe wird automatisch der Tracker geändert und Daten aus benutzerdefinierten Feldern gelöscht. Bist du sicher?",
      "move": "Bewegen",
      "note1": "Durch das Verschieben der Aufgabe verlieren Benutzer den Zugriff auf die folgende Aufgabe.",
      "role": "Rolle",
      "step1": "Fügen Sie zuerst den Benutzer dem Projekt hinzu",
      "step2": "Und dann verschieben Sie die Aufgabe",
      "user": "Benutzer"
    },
    "time_tracker": {
      "comment": "Kommentar",
      "error_already_runing": "Stoppuhr läuft schon lange.",
      "stopped": "Gespeichert",
      "stopped_and_started": "Gespeichert und gestartet",
      "stopped_and_started_text": "Zeit wurde zu Aufgabe %{issue} zugewiesen und die Stoppuhr für die nächste Aufgabe wurde neu gestartet ."
    }
  },
  "journals": {
    "ShowAll": "Gesamten Aufgabenverlauf anzeigen",
    "add": "zugefügt",
    "allDayChange": "ganztägige Veranstaltung geändert von",
    "attributes": {
      "assigned_to_id": "Aufgabenlöser",
      "attachment": "Datei",
      "author_id": "Autor",
      "description": "Aufgabebeschreibung ist aktualisiert",
      "done_ratio": "% fertig",
      "due_date": "Termín",
      "estimated_hours": "geschätzte Zeit",
      "parent_id": "Hauptaufgabe",
      "priority_id": "Priorität",
      "project_id": "Projekt",
      "start_date": "Anfang",
      "status_id": "Status",
      "subject": "Augabename",
      "tracker_id": "Warteschlange"
    },
    "button_cancel": "Änderungen verwerfen",
    "button_save": "Speichern",
    "changedFromMan": "geändert von",
    "changedFromThing": "geändert von",
    "changedFromWoman": "geändert von",
    "comment_deleted": "Kommentar gesendet.",
    "customField": "optionales Feld",
    "deleteMan": "gelöscht",
    "deleteThing": "gelöscht",
    "deleteWoman": "gelöscht",
    "edit_note": "Bearbeiten von Kommentare sendet keine E-Mails. Erstellen Sie neuen Kommentar, um eine E-Mail zu senden.",
    "events": {
      "repeated_from": {
        "link_label": "vorherige",
        "title": "Erstellt durch Wiederholen einer Aufgabe."
      },
      "repeated_to": {
        "link_label": "folgende",
        "title": "Wiederkehrende Aufgabe erstellt."
      },
      "user_archived": "Benutzer <b>%{user}</b> wurde archiviert."
    },
    "hours": "Std",
    "newDescriptionValue": "Neuer Beschreibungswert",
    "no": "nein",
    "oldDescriptionValue": "Alter Beschreibungswert",
    "setToMan": "eingestellt auf",
    "setToThing": "eingestellt auf",
    "setToWoman": "eingestellt auf",
    "showAll": "Zobrazit celou historii úkolu",
    "to": "auf",
    "userDeleted": "Benutzer wurde bereits gelöscht",
    "yes": "ja"
  },
  "labels": {
    "actions": {
      "add": "Hinzufügen",
      "archive": "Archivieren",
      "cancel": "Stornieren",
      "close": "Schließen",
      "confirm_archive": "archivieren",
      "confirm_delete": "ja, löschen",
      "confirm_move": "ja, verschieben",
      "confirm_reset_password": "ja, zurücksetzen",
      "create": "Erstellen",
      "delete": "Löschen",
      "delete_from_all_tasks": "Entfernen Sie alle Aufgaben",
      "edit": "Bearbeiten",
      "print": "Drucken",
      "refresh": "Wiederherstellen",
      "register": "Registrieren",
      "remove": "Entfernen",
      "reset": "Reset",
      "save": "Sichern",
      "zoomIn": "Zoomen",
      "zoomOut": "Verzögern"
    },
    "choose": "Wählen",
    "columns": {
      "assignedTo": "Aufgabelöser",
      "author": "Autor",
      "budgetRealExpense": "Kosten",
      "close_toggle": "Abgeschlossen",
      "createdOn": "Erstellt",
      "doneRatio": "Erledigt",
      "dueDate": "Termin",
      "estimatedHours": "Geschätzte Zeit",
      "id": "#",
      "lastActivityAt": "Aktualisiert",
      "parent": "Hauptaufgabe",
      "parentProject": "Hauptprojekt",
      "parentTask": "Übergeordnete Aufgabe",
      "priority": "Priorität",
      "project": "Projekt",
      "relations": "verbundene",
      "remainingHours": "Zeit übrig",
      "spentHours": "Zeit verbracht",
      "startDate": "Anfang",
      "status": "Status",
      "subject": "Aufgabe",
      "timeTracker": "Stoppuhr",
      "totalEstimatedHours": "mit Teilaufgaben",
      "totalEstimatedHoursTitle": "Geschätzte Zeit, einschließlich Teilaufgaben",
      "totalExpenses": "Kosten",
      "totalSpentHours": "Verbrachte Zeit einschließlich Teilaufgaben",
      "tracker": "Reihe",
      "updatedAt": "Aktualisiert"
    },
    "custom_field_types": {
      "bool": "Ja/Nein",
      "date": "Datum",
      "file": "Datei",
      "int": "ganze Zahl",
      "list": "Liste",
      "string": "Text",
      "text": "langer Text",
      "url": "Url"
    },
    "false": "nein",
    "help": "Hilfe",
    "languages": {
      "cs": "tschechisch",
      "de": "deutsch",
      "en": "englisch",
      "hr": "kroatisch",
      "sk": "slowakisch"
    },
    "loading": "Verladung...",
    "modified": "bearbeitet",
    "priority": {
      "high": "Hoch",
      "low": "Niedrig",
      "normal": "Normal",
      "urgent": "Urgent"
    },
    "query": {
      "1": "Meine Aufgaben",
      "1-contacts": "Meine Kontakte",
      "10": "Meine abgeschlossenen Aufgaben",
      "11": "Delegierte Aufgaben in Verzögerungen",
      "12": "Meine verzögerte Aufgaben",
      "13": "Meine Aufgaben",
      "14": "Ohne Protokolle",
      "15": "Meine überwachtene Aufgaben",
      "16": "Meine beaufsichtigten Aufgaben, bei denen ich keine Aufgabenlöser bin",
      "17": "Gelöste Aufgaben bei denen bin ich Autor",
      "18": "Delegierte Aufgaben in Verzögerungen",
      "19": "Aufgaben, die ausgeführt werden sollen",
      "2": "Verpasste Aufgaben",
      "2-contacts": "Verpasste Kontakte",
      "3": "Aktive Aufgaben",
      "3-contacts": "Geöffnete Kontakte",
      "4": "Abgeschlossene Aufgaben",
      "4-contacts": "Abgeschlossene Kontakte",
      "5": "Gelöste Aufgaben",
      "5-contacts": "Gelösste Kontakte",
      "6": "Von mir delegierte Aufgaben",
      "7": "Meine Aufgaben",
      "8": "Strukturierte Auflistung",
      "9": "Meine Aufgaben nach Datum",
      "all": "Alle Aufgaben",
      "custom": "Customizing"
    },
    "time_entries": {
      "activity": "Aktivität",
      "comment": "Kommentar",
      "date": "Datum",
      "hours": "Zeit verbracht",
      "user": "Benutzer"
    },
    "today": "heute",
    "true": "ja",
    "user": {
      "deleted": "gelöschter Benutzer",
      "email": "E-mail",
      "firstname": "Vorname",
      "lastname": "Nachname"
    }
  },
  "messages": {
    "announcements": {
      "deprecated_browser": {
        "info": "Projektove wird die Unterstützung für Internet Explorer 11 in naher Zukunft einstellen.",
        "note1": "Während Sie Projektove weiterhin auf IE 11 besuchen können, werden wir die Benutzererfahrung nicht mehr optimieren.",
        "note2": "Microsoft beendet den Support für seine Produkte am 17. 8. 2021. Als alternative Browser empfehlen wir Google Chrome oder Microsoft Edge."
      },
      "link_email": "Sendebenachrichtigung erneut aktivieren",
      "nps": {
        "confirmation": "Danke",
        "note_10": "Vielen Dank für die höchste Punktzahl. Was macht Projektove besonders gut, um Ihre Empfehlung zu verdienen?",
        "note_9": "Vielen Dank für die hohe Punktzahl. Was macht Projektove besonders gut, um Ihre Empfehlung zu verdienen?",
        "note_low": "Danke dir. Was sollen wir tun, um eine bessere Punktzahl zu erzielen?",
        "postpone": "später antworten",
        "question": "Wie wahrscheinlich ist es, dass Sie Projektove an einen Freund oder ein anderes Unternehmen verweisen?",
        "scale_high": "Auf jeden Fall ja",
        "scale_low": "Auf jeden Fall nein",
        "send": "Senden"
      },
      "release": {
        "message": "Eine neue Version der App ist verfügbar.",
        "update": "aktualisieren"
      },
      "text_email": "Senden von E-Mail-Benachrichtigungen zu Aufgabenänderungen (von Ihnen an Kollegen) ist jetzt ausgesetzt.",
      "trial": {
        "expires": {
          "few": "Sie verwenden jetzt die Testversion. Es endet in %{count} Tagen.",
          "one": "Ihre Testversion läuft morgen ab. Bestellen Sie Project noch heute, damit Sie ohne Unterbrechung weitermachen können.",
          "other": "Sie verwenden jetzt die Testversion. Es endet in %{count} Tagen.",
          "zero": "Ihre Testversion läuft heute ab. Bestellen Sie Project noch heute, damit Sie ohne Unterbrechung weitermachen können."
        },
        "note": "Die kostenlosen Tage gehen Ihnen nicht verloren, das kostenpflichtige Konto schließt sich nahtlos an die Testversion an.",
        "order_now": "Bestellen"
      }
    },
    "by_code": {
      "1060": "E-Mail wird übernommen",
      "1061": "E-mailová adresa je již použita jako vaše primární E-mailová adresa.",
      "2220": "Die Warteschlange '%{tracker_name}' wird von Aufgaben im Projekt verwendet und kann nicht entfernt werden.",
      "2301": "Projekt nemohl být převeden na složku, protože obsahuje úkoly.",
      "2302": "Projekt nemohl být převeden na složku, protože má nadžazený projekt. Složka nemůže být vytvořena v projektu.",
      "2303": "Složka nemůže být prevedena na projekt, dokud obsahuje podsložky."
    },
    "errors": {
      "adding_time_entry_failed": "Beim Hinzufügen der Zeit ist ein Fehler aufgetreten.",
      "cannot_save": "Ist nicht gespeichert",
      "create_issue_failure": "Aufgabenerstellung ist fehlgeschlagen",
      "create_relation_failure": "Fehler beim Hinzufügen der Verbindung.",
      "error": "Fehler",
      "exceeds_tariff": {
        "assign_role": "Es ist nicht möglich, ein neues Projektmitglied mit bezahlter Rolle hinzuzufügen. Der Administrator muss zuerst den Projektove-Tarif aktualisieren."
      },
      "file_too_big": "Datei %{file} ist zu groß zum Hochladen.",
      "file_upload": "Datei konnte nicht hochgeladen werden.",
      "forbidden": "Sie haben keine Berechtigung für die Anfrage.",
      "form_reading_failed": "Laden des Formulars fehlgeschlagen. Bitte versuche es erneut.",
      "general": "Hups ! Es ist ein Fehler da. Bitte versuche es später erneut.",
      "gql": {
        "error": "Beim Laden ist ein Fehler aufgetreten.",
        "retry": "Versuchen Sie es erneut"
      },
      "is_primary_mail": "Die Adresse %{mail} wird bereits als primäre E-Mail verwendet.",
      "issue_assignee_change_failure": "Aufgabenlöseränderung ist fehlgeschlagen",
      "issue_closing_or_opening_failure": "Die Aufgabe konnte nicht geschlossen / geöffnet werden.",
      "issue_due_date_change_failure": "Terminänderung ist fehlgeschlagen",
      "issue_status_change_failure": "Der Auftragstatus konnte nicht geändert werden.",
      "mail_already_used": "%{mail} wird bereits von einem anderen Benutzer verwendet.",
      "removing_relation_failure": "Verbindungsänderung ist fehlgeschlagen"
    },
    "feedback": {
      "error": "Speichernfehler",
      "loading": "Projekte und Aufgaben werden geladen...",
      "pending": "Speicherung...",
      "processing_request": "Bitte warten, Anfrage bearbeiten...",
      "saved": "Alle Änderungen gespeichert."
    },
    "member_role_changed": "Rolle geändert",
    "select": {
      "no_options": "Keine Optionen verfügbar",
      "type_to_search": "Beginnen Sie mit der Eingabe, um zu suchen..."
    },
    "success": {
      "changed": "Geändert",
      "deleted": "Gelöscht",
      "password_changed": "Das Passwort wurde erfolgreich geändert.",
      "password_reset_sent": "Die Anweisungen zur Passwortwiederherstellung wurden an die E-Mail des Benutzers gesendet.",
      "saved": "Gespeichert"
    }
  },
  "mindmap": {
    "addSubtask": "Přidat podúkol",
    "addTask": "Unteraufgabe hinzufügen",
    "collapse": "Unteraufgaben ausblenden",
    "expand": "Unteraufgaben anzeigen",
    "heading": "Gedankenkarte",
    "hide_closed": "Geschlossene ausblenden",
    "order": {
      "default": "Default",
      "dueDate": "nach Termin",
      "label": "Sortierung",
      "priority": "nach Priorität",
      "subject": "nach Name",
      "updatedAt": "nach letzte Änderung"
    },
    "relations": {
      "howto1": "Diese Mindmap zeigt Aufgaben in der Reihenfolge, wie sie durch Beziehungen im Gantt-Diagramm geplant wurden.",
      "howto2": "Es gibt keine Beziehung zwischen Aufgaben, öffnen Sie das Gantt-Diagramm und fügen Sie dort Beziehungen hinzu."
    },
    "view": {
      "basic": "elementar",
      "extended": "verlängert",
      "relations": "nach Beziehungen"
    }
  },
  "mobileApplication": {
    "android1": "Treten Sie über folgenden Link der G + Community bei",
    "android2": "Beim Betatest anmelden",
    "android3": "Installation von Google Play:",
    "benefit1": "Mit Ihrer mobilen App haben Sie alle Ihre Projekte zur Hand.",
    "benefit2": "Geben Sie einfach eine neue Aufgabe ein und überprüfen Sie den Status des Projekts.",
    "fastLogin": "Schnellanmeldung:",
    "fastLogin2": "Starten Sie Ihre mobile App, klicken Sie auf QR und richten Sie die Kamera auf das Bild unten.",
    "getItGoogle": "Nyní na Google Play",
    "getItiOS": "Stáhnout v App Store",
    "installation": "Instalation",
    "iphone1": "Installiere TestFlight (Apples offizielles Tool)",
    "iphone2": "Bitte schreiben Sie uns unten rechts für Live Chat Support",
    "iphone3": "E-Mail, in Telefon als standard  (Apple ID)",
    "iphone4": "Wir senden ihm eine Einladung mit einem Link zur Installation",
    "login": "Login",
    "noteBeta": "Beide Apps (Android und iPhone) werden nun in der offiziellen Betaversion getestet und können im App Store installiert werden. Diese App ist praktisch für bestehende Benutzer. Das Szenario für komplett neue Benutzer ist noch nicht gelöst, daher wird die App in der Beta getestet.",
    "optionally": "optional",
    "step1": "Starten Sie Ihre mobile App,",
    "step2": "Klicken Sie auf Login durch QR-Code",
    "step3": "Richten Sie auf das Bild unten:",
    "step4": "Fertig, Sie werden automatisch über das Bild angemeldet.",
    "step5": "Ist dies nicht der Fall, besteht die zweite Möglichkeit darin, die Adresse (von der Adresszeile aus), Ihre E-Mail-Adresse und das Passwort, das Sie anmelden, manuell einzugeben.",
    "title": "Wie installiere ich eine mobile App?"
  },
  "my": {
    "account": {
      "api": {
        "app_reload": "Laden Sie die gesamte Anwendung neu",
        "created": "Der API-Zugriffsschlüssel wurde vor %{value} erstellt",
        "reset": "Zurücksetzen",
        "show": "Anzeigen",
        "title": "API-Zugriffsschlüssel"
      },
      "languages": {
        "croatian": "Hrvatski",
        "czech": "Čeština",
        "english": "English",
        "german": "Deutsch",
        "slovak": "Slovenčina"
      },
      "mail": {
        "change_all_day": "Erfüllungszeit hinzufügen",
        "change_assigned_to": "Aufgabelöser änderung",
        "change_attachment": "Anhang hinzufügen",
        "change_author": "Autor ändern",
        "change_description": "Aufgabenbeschreibung änderung",
        "change_done_ratio": "Fertige Prozentsätze ändern",
        "change_due_date": "Termin änderung",
        "change_estimated_hours": "Geschätzten Zeit änderung",
        "change_notes": "Kommentar einfügen",
        "change_parent_id": "Aufgabe schieben",
        "change_priority_id": "Priorität änderung",
        "change_project_id": "Projekt änderung",
        "change_start_date": "Anfang änderung",
        "change_status": "Status änderung",
        "change_subject": "Aufgabename änderung",
        "estimated_hours_exhausted": "Warnung bei aufgewendeter Zeit",
        "estimated_hours_exhausted_note": "Senden Sie eine Benachrichtigung, wenn die aufgewendete Zeit 80 % der geschätzten Zeit erreicht.",
        "mail_mark_off": "Markieren Sie die empfohlenen Einstellungen",
        "mail_note": "Haben Sie viele E-Mails von uns",
        "mail_notification": "E-Mail-Benachrichtigung",
        "mail_settings": "E-mail einstellungen",
        "new_task": "Jemand hat eine Aufgabe für Sie erstellt oder delegiert",
        "newsletter": "Newsletter abonnieren",
        "newsletter_note": "Wir senden Ihnen bis zu einmal im Monat Projektmanagement-Tipps und Neuigkeiten in Projektove.CZ.",
        "oncoming_tasks_summary": "In E-mail anzeigen bevorstehende Termine",
        "receive_notififactions_when": "Erhalten Sie Benachrichtigungen wann",
        "save_changes": "Änderungen speichern",
        "set_to_all_users": "E-mail-einstellungen für alle Benutzer überschreiben",
        "solved_task": "Jemand hat eine von Ihnen erstellte Aufgabe gelöst oder abgeschlossen",
        "summaries": "Zusammenegefasste Morgen E-Mails",
        "title": "E-mail einstellungen"
      },
      "mail_notifications": {
        "default": "Standard Benachrichtigungsoptionen",
        "none": "keine Ereignisse"
      },
      "mail_summaries": {
        "everyday": "jeden Tag",
        "never": "niemals senden",
        "no_weekends": "jeden Tag außerhalb der Wochenenden"
      },
      "mobile": {
        "install": "Instalation",
        "login": "Schnellanmeldung",
        "text": "Starten Sie Ihre mobile App, klicken Sie auf QR und richten Sie die Kamera auf das Bild unten.",
        "title": "Mobile Apps"
      },
      "prefill_due_date": {
        "none": "nein",
        "today_plus": {
          "few": "heute +%{count} tagen",
          "one": "heute +1 tag",
          "other": "heute +%{count} tagen"
        }
      },
      "profile_image": {
        "cancel_changes": "Änderungen verwerfen",
        "choose_crop": "Wählen Sie den Teil des Bildes aus, den Sie zuschneiden möchten.",
        "crop_tutorial": "Klicken und ziehen Sie nach rechts unten, oder wenn Sie von rechts unten zuschneiden, ziehen Sie nach links, um das Ansichtsfenster des Bildes hervorzuheben, das Sie erhalten möchten.",
        "dropzone_tutorial": "Ziehen Sie das Bild oder klicken Sie, um eine Datei auszuwählen.",
        "use_crop": "Anwenden"
      },
      "project_overview": {
        "gantt": "Gantt-Diagramm",
        "issues": "Aufgabeliste - Offene Aufgaben",
        "issues_closed": "Aufgabeliste - geschlossene Aufgaben",
        "issues_my_tasks": "Aufgabeliste - nur meine Aufgaben",
        "issues_overdue": "Aufgabeliste - verzögerte Aufgaben",
        "issues_solved": "Aufgabeliste - gelösst",
        "mind_map": "Gedankenkarte",
        "overview": "Projektübersicht",
        "tasks_board": "Aufgabentafel"
      },
      "tasks_in_summary": {
        "": "nein",
        "1": "Aufgaben am nächsten Tag",
        "14": "Aufgaben mit Termin bis 14 Tagen",
        "3": "Aufgaben mit Termin bis 3 Tagen",
        "7": "Aufgaben mit Termin bis 7 Tagen",
        "none": "ne"
      },
      "user": {
        "due_date_preset": "Frist voreinstellen",
        "errors": {
          "invalid": "Ungültige E-Mail",
          "taken": "E-Mail wird übernommen"
        },
        "firstname": "Name",
        "language": "Sprache",
        "lastname": "Nachname",
        "login_mail": "E-mail / Einloggen",
        "other_mail": "Meine anderen E-Mail-Adressen",
        "project_view": "Klicken Sie, um das Projekt anzuzeigen",
        "time_zone": "Zeitzone",
        "title": "Mein Konto"
      }
    }
  },
  "number": {
    "currency": {
      "czk": {
        "delimiter": " ",
        "format": "%n %u",
        "precision": 2,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "Kč"
      },
      "eur": {
        "delimiter": ".",
        "format": "%n %u",
        "precision": 2,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "€"
      },
      "format": {
        "delimiter": ".",
        "format": "%n %u",
        "precision": 2,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "€"
      }
    },
    "format": {
      "delimiter": ".",
      "precision": 2,
      "separator": ",",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": {
            "one": "Milliarde",
            "other": "Milliarden"
          },
          "million": "Millionen",
          "quadrillion": {
            "one": "Billiarde",
            "other": "Billiarden"
          },
          "thousand": "Tausend",
          "trillion": "Billionen",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "separator": ",",
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n %"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "polls": {
    "add_answers": "Antwort eingeben",
    "add_choice": "+ Option hinzufügen ...",
    "add_ok": "Die Abstimmung wurde hinzugefügt.",
    "add_poll": "Abstimmung hinzufügen",
    "add_question": "Frage eingeben",
    "block_name": "Abstimmung",
    "confirm_no": "nein",
    "confirm_yes": "Ja, löschen",
    "delete_answer": "Antwort löschen",
    "delete_choice_confirm": "Sie versuchen, die Antwort zu löschen, für die bereits abgestimmt wurde. Willst du es wirklich entfernen, einschließlich der aufgezeichneten Stimmen?",
    "delete_ok": "Die Abstimmung wurde gelöscht.",
    "delete_poll": "Abstimmung löschen",
    "delete_poll_confirm": "Sind Sie sicher, dass Sie die Abstimmung löschen möchten? Alle aufgezeichneten Stimmen von Benutzern werden ebenfalls dauerhaft gelöscht.",
    "edit_ok": "Die Abstimmung wurde geändert.",
    "edit_poll": "Abstimmung ändern",
    "empty_question": "Eine leere Frage wurde eingegeben. Bitte fülle es aus.",
    "error": "Fehler beim Speichern. Bitte versuche es später erneut.",
    "error_conflict": "Die Abstimmung kann nicht hinzugefügt werden, da diese Funktion bereits eine Stimme hat.",
    "error_insufficient_choices": "Die Abstimmung muss mindestens zwei Optionen umfassen.",
    "no_polls": "Sie folgen derzeit keine Abstimmung",
    "no_votes": "Nehlasované",
    "successfully_voted": "Deine Stimme wurde aufgenommen."
  },
  "projectOverview": {
    "estimated_hours": "Schätzung der Zeit",
    "heading": "Projektübersicht",
    "hide": "Verstecke es",
    "hours": "Std",
    "planned_due_date": "Geplanter Termin",
    "planned_start_date": "Geplanter Start",
    "remaining_hours": "Zeit übrig",
    "show": "Zeig mehr",
    "spent_hours": "Eigetragene Zeit"
  },
  "projects": {
    "archive": {
      "cant_unarchive": "Kann nicht unarchiviert werden",
      "error_cant_unarchive": "Sie können das Archiv nicht aufheben, da einige seiner übergeordneten Projekte noch archiviert sind.",
      "exceeds_tariff_modal": {
        "exceeded_tariff": "Sie haben mehr aktive bezahlte Benutzer als zugelassene Benutzer.",
        "explanation": {
          "few": "In archivierten Projekten gibt es %{count} Benutzer. Mit ihnen haben Sie mehr aktive Benutzer als zulässig.",
          "one": "In einem archivierten Projekt ist Benutzer <b>%{Benutzer} </ b>. Mit ihm gibt es aktivere Benutzer, die erlaubt sind.",
          "other": "In archivierten Projekten gibt es %{count} Benutzer. Mit ihnen haben Sie mehr aktive Benutzer als zulässig."
        },
        "overlimit_users": "Neue bezahlte Benutzer nach Aufhebung der Archivierung",
        "tip": "Erhöhen Sie den Projektove-Tarif oder archivieren Sie Benutzer, um die Projektarchivierung zu aktivieren.",
        "title": "Sie können das Projekt nicht entarchivieren"
      },
      "from_project": "Vom aktiven Projekt",
      "remove": "Projekt dauerhaft löschen",
      "title": "Projektarchiv",
      "unarchive": "Unarchive Projekt",
      "unarchiveInfoModal": {
        "h2": "Sie müssen zuerst das übergeordnete Projekt entarchivieren",
        "h3": "Schritte",
        "note1": "Projekt",
        "note2": "ist ein Teilprojekt des archivierten Projekts",
        "note3": "Um die Struktur von Projekten und Teilprojekten beizubehalten, müssen Sie zuerst alle übergeordneten Projekte aus dem Archiv entfernen.",
        "step1": "Nicht archiviertes übergeordnetes Projekt %{name}",
        "step2": "Und dann archivieren Sie dieses Projekt - %{name}"
      }
    },
    "breadcrumbs": {
      "all_projects": "Alle Projekte",
      "end_path": {
        "budget_entries": "Projektbudget",
        "contacts": "Kontaktenliste",
        "files": "Dateien im Projekt",
        "gantt": "Gantt-Diagramm",
        "issues": "Aufgabenliste",
        "mindmap": "Gedankenkarte",
        "overview": "Projektenübersicht",
        "settings": "Einstellung",
        "tasks_board": "Aufgabentabelle",
        "team_settings": "Projektmitglieder",
        "time_entries": "Verbrachte Zeitliste"
      },
      "subprojects": "Teilprojekte"
    },
    "filters": {
      "buttons": {
        "apply": "Anwenden",
        "cancel": "Abbrechen"
      },
      "calendar": "Kalender",
      "customFields": "Eigene Feld",
      "customFields_icon_title": "Entfernen",
      "customFields_title": "Filters",
      "doneRatio": "Fortschritt",
      "doneRatio_g": "ist größer als",
      "doneRatio_i": "ist",
      "doneRatio_l": "ist kleiner als",
      "favorites": "Favoriten",
      "input_placeholder": "Einfügen gesuchte Text",
      "ismanager": "Ich bin Manager",
      "member": "Teammitglied",
      "not_entered": "Nicht eingegeben",
      "only_favorites": "nur favoriten",
      "only_favorites_tree": "Favoriten mit Unterprojekten",
      "only_templates": "nur Vorlagen",
      "plannedDueDate": "Geplanter Termin",
      "plannedStartDate": "Geplanter Start",
      "templates": "Vorlagen",
      "this_month": "Diese Monat",
      "this_week": "Diese Woche",
      "today": "Heute",
      "without_favorites": "ohne Favoriten",
      "without_templates": "ohne Vorlagen"
    },
    "index": {
      "add_project": "Projekt hinzufügen",
      "add_sub_project": "Projekt hinzufügen",
      "archive_project": "Projekt archivieren",
      "columns": {
        "dates": "Zeitspanne",
        "members": "Teammitglieders",
        "name": "Name",
        "parentProject": "Nadřazený projekt",
        "plannedDueDate": "Termin geplannt",
        "plannedStartDate": "Anfang geplannt",
        "progress": "Fortschritt"
      },
      "copy_project": "Kopieren",
      "details": {
        "collapse": "Reihe kollabieren",
        "expand": "Zeile erweitern",
        "incomes": "Budget - Einkommen",
        "parentProject": "Hauptprojekt"
      },
      "export": "Projektliste exportieren",
      "favorite_default": "Favoritenprojekte mit Sternen markiert",
      "favorite_projects": "Favoritenprojekte",
      "move_project": "Projekt schieben",
      "project_settings": "Projekteinrichtung",
      "remove_project": "Projekt löschen",
      "rename_project": "Projekt umbenennen",
      "share_project": "Projekt mitteilen",
      "show_all": "alle Projekte",
      "show_archive": "Archiv",
      "show_filter": "Anzeigen",
      "show_my": "meine Projekte"
    },
    "modals": {
      "archive_project": {
        "text1": "Durch die Archivierung des Projekts",
        "text2": "werden auch alle seine Unterprojekte archiviert",
        "title": "Sind Sie sicher, dass Sie das Projekt archivieren möchten?"
      },
      "destroy_project": {
        "all_files": "alle Projektdateien",
        "all_tasks": "alle Aufgaben im Projekt",
        "members": "Liste der Teammitglieder",
        "members_note": "Benutzer verbleiben selbst in Projektově, sie können anderen Projekten zugewiesen werden",
        "save": "Projekt löschen",
        "settings": "Projekteinrichtung",
        "spent_times": "verbrachte Zeiten für Aufgaben",
        "subprojects_note": "Alle Teilprojekte sind Teil des Projekts und werden ebenfalls entfernt.",
        "title": "Projekt wirklich löschen?",
        "what_you_lost": "Durch Projektlöschung wird endgültig verloren:"
      },
      "move_project": {
        "no_parent": "-- ohne ein übergeordnete Projekt --",
        "save": "schieben",
        "title": "Projekt schieben"
      },
      "new_project": {
        "choose_template": "Bitte wählen Sie eine Vorlage.",
        "create": "erstellen",
        "empty": "Leer",
        "folder": "Složka",
        "fromTemplate": "Von der Vorlage",
        "name": "Name",
        "parent": "Hauptprojekt",
        "parentErrorHint": "Only the administrator can enter root projects. As a manager, you can only create sub-projects for your projects. Bitte wählen Sie das übergeordnete Projekt",
        "parentFolder": "Nadřazená složka",
        "plan": {
          "from_end": "vom Ende des Projekts",
          "from_start": "vom Anfang des Projekts",
          "help_left": "Geplantes Startdatum ist bekannt, ab diesem Datum planen wir Projektaufgaben.",
          "help_right": "Start und Fälligkeitsdaten werden berechnet, um das Projekt mit geplantem Enddatum zu beenden.",
          "title": "Planung"
        },
        "planned_due_date": "Termin geplannt",
        "planned_start_date": "Anfang geplannt",
        "template": "Vorlage",
        "title": "Projekt Neu"
      },
      "rename_project": {
        "save": "umbenennen",
        "title": "Projekt umbenennen"
      }
    },
    "navigation": {
      "labels": {
        "budget_entries": "Projektbudget",
        "contacts": "Kontakte",
        "files": "Dateien",
        "gantt": "Gantt-Diagramm",
        "issues": "Aufgaben",
        "last_activity": "Letzte Aktivität",
        "mindmap": "Gedankenkarte",
        "mobile_application": "Mobile Apps",
        "my_page": "Meine Seite",
        "new_issues": "neu",
        "overview": "Projektübersicht",
        "projects": "Projekten",
        "projektove_cz": "Projektově",
        "settings": "Einstellung",
        "tasks_board": "Aufgabentabelle",
        "team_settings": "Projektmitglieder",
        "time_entries": "Zeit verbracht",
        "users_settings": "Benutzerverwaltung"
      }
    },
    "settings": {
      "convert_to_folder": "Převést projekt na složku",
      "convert_to_project": "Převést složku na projekt",
      "description": "Beschreibung",
      "folder": "Složka",
      "is_template": "Projektvorlage",
      "is_template_inherited": "Eigenschaft nicht ändernbar, da eines der Hautp-Projekte eine Vorlage ist.",
      "name": "Name",
      "only_admin_can_change": "Nur der Administrator kann diese Einstellungen ändern",
      "parentId": "Hauptprojekt",
      "parentIdFolder": "Nadřazená složka",
      "path": "Weg",
      "plannedDueDate": "Geplanter Termin",
      "planned_due_date": "Geplanter Termin",
      "planned_income": "Geplantes Einkommen",
      "planned_start_date": "Geplanter Start",
      "real_incomes": "Realeinkommen",
      "reset_trackers": "Wiederherstellen",
      "shortcut": "Abkürzung",
      "shortcut_add": "Füllen Sie die Projektabkürzung aus. Dann erhalten Sie eine E-Mail-Adresse, wenn Sie eine E-Mail an diese E-Mail-Adresse senden oder weiterleiten werden Sie Aufgabe erstellen.",
      "shortcut_alt_add": "Füllen Sie die Projektverknüpfung aus. Dann sehen Sie Anweisungen zum Senden oder Weiterleiten einer E-Mail, um eine Aufgabe in diesem Projekt zu erstellen.",
      "shortcut_alt_note1": "Senden oder Weiterleiten einer E-Mail an",
      "shortcut_alt_note2": "um eine Aufgabe im Projekt zu erstellen.",
      "shortcut_alt_note3": "Schreiben Sie am Anfang der Nachricht die Abkürzung des Projekts und den Doppelpunkt, zum Beispiel für die Abkürzung 'pricing' wird es so aussehen:",
      "shortcut_alt_subject": "Betreff der Nachricht ...",
      "shortcut_alt_subject_example": "Preisgestaltung: Bitte senden Sie unser Angebot",
      "shortcut_format": "Nur Buchstaben ohne Diakritika und Ziffern sind erlaubt. Es ist auch möglich, einen Punkt, einen Unterstrich und einen Bindestrich zu verwenden, aber diese sollten nicht am Anfang oder am Ende verwendet werden.",
      "shortcut_note1": "Senden oder Weiterleiten von E-Mail an Adresse",
      "shortcut_note2": "Erstellen Sie einen E-Mail Aufgabe.",
      "shortcut_note3": "Das Thema wird der Titel der Aufgabe sein, dann die Beschreibung des Themas.",
      "tracker": "Front",
      "unsaved_changes": {
        "prompt": "Möchten Sie zur nächsten Seite übergehen?",
        "title": "Sie haben unveränderte Projektänderungen."
      },
      "unsortedCustomFields": "Projektfelder"
    },
    "team_settings": {
      "add_member": "Mittglied hinzufügen",
      "member": {
        "add_member": "Mitglied zu Teilprojekt hinzufügen",
        "add_member_error": "Benutzer wurde erfolgreich dem Projekt %{project} zugewiesen",
        "add_member_info": "Benutzer wurde erfolgreich dem Projekt %{project} zugewiesen",
        "add_task": "Aufgabe eingeben",
        "change_contact_person": "Kundaufgaben werden zuerst dieser Person zugewiesen.",
        "change_hour_cost": "Stundensatz festlegen, um die variablen Kosten (Stunden pro Aufagbe * Rate) im Projekt zu verfolgen",
        "change_role": "Mitgliedsrolle ändern in deise Team",
        "remove": "Mitglied von Teilprojekt entfernen",
        "remove_from_subprojects": "Möchten Sie das Teammitglied auch aus Teilprojekten entfernen?",
        "show_info": "Mitgliederdetails anzeigen"
      },
      "modals": {
        "add": {
          "add": "hinzufügen",
          "check_all": "alles markieren",
          "choose_member": "Teammitglied auswählen",
          "choose_role": "Rolle auswählen",
          "no_user_found": "Gesuchten Benutzer nicht gefunden, Sie müssen ihn registrieren.",
          "search": "Suchen nach Name oder Zusatzinformationen",
          "selected": "Ausgewählt: %{count}",
          "uncheck_all": "alles unmarkieren"
        },
        "register": {
          "add": "Zum Projekt hinzufügen",
          "add_additional_info": "zusatzinformationen hinzufügen",
          "additional_info_1": "Zusatzinformationen 1 hinzufügen",
          "additional_info_2": "Zusatzinformationen 2 hinzufügen",
          "already_registered": "Benutzer im System gefunden, muss nicht registriert sein.",
          "already_registered_external": "Sie versuchen, einen Benutzer zu registrieren, der bereits als externer Monitor hinzugefügt wurde.",
          "choose_role": "Rolle auswählen",
          "delete_user": "Entfernen Sie zuerst Kollegen von externen Monitoren.",
          "help_1": "Neuer Benutzer verwendet E-Mail-Einladung für die erste Anmeldung und Passworteinrichtung.",
          "help_2": "Nach der ersten Anmeldung sendet die App eine Bestätigungs-E-Mail mit einer erneuten Anmeldung.",
          "hint_email": "E-mail",
          "office365_only": "Anmeldungen nur mit einem Microsoft-Konto zulassen",
          "only_admin": "Nur für Administratoren verfügbar",
          "register_user": "Benutzer registrieren",
          "set_name": "Bitte geben Sie Ihren Vor- und Nachnamen vor der Registrierung ein.",
          "title": "Neue Benutzer registrieren",
          "user_registered": "Der Benutzer wurde registriert und eine Einladung zur Online-Zusammenarbeit wurde an seine E-Mail gesendet."
        }
      },
      "precheck": {
        "cannot_remove": "Kann nicht aus dem Projekt entfernt werden",
        "info": "%{user} hat Aufgaben im Projekt, die bearbeitet oder ist Autor",
        "list_assignee": "Aufgabenliste, die der Aufgabelöser ist.",
        "list_author": "Aufgabenliste, die der Autor ist.",
        "list_check": "Überprüfen Sie auch geschlossene Aufgaben.",
        "remove_anyway": "Trotzdem entfernen"
      },
      "register_member": "Mittglied registrieren",
      "register_member_external": "einen Nicht-Domänenbenutzer (LDAP) registrieren",
      "restricted_registrations": "Wenden Sie sich an Ihren Administrator, um neue Benutzer zu registrieren.",
      "show_member_info": "Mitgliederdetails anzeigen",
      "title": "Teammitglieder"
    }
  },
  "quickadd": {
    "block_name": "Schnelle Aufgabenerstellung"
  },
  "search": {
    "button": "suchen",
    "header": "Suche",
    "inComments": "Suche auch in Kommentaren",
    "inProject": "Suche nur in einem Projekt",
    "notFound": "Nicht gefunden."
  },
  "shared": {
    "button_back": "Zurück",
    "disableEmails": {
      "long": {
        "disable": "E-mails deaktivieren",
        "enable": "E-mails freigeben"
      },
      "short": {
        "disable": "Pause",
        "enable": "Starten"
      }
    },
    "errorView": {
      "403": "Sie haben nicht genügend Rechte, um diese Seite anzuzeigen.",
      "404": "Es tut uns leid, aber die gesuchte Seite existiert nicht.",
      "oops_title": "In Aufgaben verloren?",
      "options": "Optionen",
      "step1": "Öffnen Sie die Projektliste und öffnen Sie das Projekt von dort aus",
      "step1_note": "Wenn ein Projekt vorhanden ist und Sie ein Teammitglied sind, wird es in der Projektliste angezeigt.",
      "step2": "Verwenden Sie die Suchleiste oben auf dieser Seite",
      "step2_note": "Die Suche durchsucht Aufgabennamen und Beschreibungen. Wenn eine Aufgabe vorhanden ist und Sie Zugriff darauf haben, werden Sie sie finden.",
      "step3": "Wenden Sie sich an Ihren Projektmanager."
    },
    "forced_logout": {
      "explanation": "Die Wiederherstellung der Zugangsdaten (Passwörter) durch den Administrator Ihres Projekts wurde wahrscheinlich erzwungen. Wenn Sie sich nicht erneut anmelden können, überprüfen Sie daher Ihre E-Mail, um zu sehen, ob Sie Anweisungen zum Ändern Ihres Passworts erhalten haben.",
      "reason": "Aus Sicherheitsgründen abgemeldet.",
      "relog": "Melden Sie sich erneut an",
      "title": "Sie wurden ausgeloggt"
    },
    "pnotify": {
      "close_title": "Abschliessen"
    },
    "tasksReminders": {
      "approaching_deadline": {
        "few": "Aufgabetermine bald zu erledigen",
        "one": "Aufgabetermin bald zu erledigen",
        "other": "Aufgabetermine bald zu erledigen"
      },
      "close": "Ausblenden alles",
      "hide": "Ausblenden"
    }
  },
  "time": {
    "am": "vormittags",
    "formats": {
      "at": "%-d. %-m. %Y v %-H:%M",
      "default": "%d.%m.%Y %H:%M",
      "long": "%A, %e. %B %Y, %H:%M Uhr",
      "short": "%e. %b %H:%M",
      "time": "%H:%M",
      "time2": "%H:%M"
    },
    "moment_formats": {
      "default": "D.M.YYYY, H:mm",
      "only_time": "H:mm"
    },
    "pm": "nachmittags"
  },
  "time_entries": {
    "hours_short": "Std.",
    "last_month": "Letzten Monat",
    "modals": {
      "add": {
        "title": "Verbrachte Zeit hinzufügen"
      },
      "edit": {
        "title": "Bearbeite Zeiť"
      }
    },
    "next_month": "Nächsten Monat",
    "next_week": "Nächste Woche",
    "reports": {
      "by_projects_members_tasks": "nach Projekten, Mitgliedern und Aufgaben",
      "by_projects_tasks": "nach Projekten und Aufgaben",
      "title": "Berichte"
    },
    "this_month": "Diesen Monat",
    "this_week": "Diese Woche",
    "today": "Heute",
    "today_and_future": "Heute und in Zukunft",
    "yesterday": "Gestern"
  },
  "timelog": {
    "compoundReport": {
      "groupByUsers": "Nach Benutzern gruppieren",
      "hideArchivedProjects": "archivierte Projekte",
      "hideNullHours": "Leere Zeilen anzeigen",
      "sum": "Summe",
      "title": "Zusammengesetzter Bericht",
      "users": "Benutzer"
    },
    "daterange": {
      "all_time": "gesamter Zeitraum",
      "current_month": "aktueller Monat",
      "current_week": "aktuelle Woche",
      "current_year": "aktuelles Jahr",
      "custom": "benutzerdefiniert",
      "last_month": "voriger Monat",
      "last_week": "vorige Woche",
      "last_year": "letztes Jahr",
      "title": "Zeitraum",
      "today": "heute",
      "yesterday": "gestern"
    }
  },
  "users": {
    "add_alias": "Fügen Sie eine E-Mail-Adresse hinzu",
    "change_password": {
      "button": "Passwort ändern",
      "button_first_login": "nastavit heslo",
      "current_password": "Aktuelles Passwort",
      "errors": {
        "invalid": "Das aktuelle Passwort ist falsch. Passwort wurde nicht geändert.",
        "missing_current": "Aktuelles Passwort eingeben",
        "often": "Es ist nicht möglich, das Passwort mehrmals am Tag zu ändern. Passwort wurde nicht geändert."
      },
      "password": "Neues Passwort",
      "requirements": {
        "char_types": {
          "capital": "Großbuchstabe",
          "lowercase": "Kleinbuchstaben",
          "number": "Zahl",
          "special": "Sonderzeichen, zum Beispiel: !, $, #, %"
        },
        "complexity": {
          "all": "enthält mindestens einen dieser Zeichentypen",
          "other": "mindestens %{required} von 4"
        },
        "length": "minimale Länge %{length} Zeichen",
        "recent": "kann nicht in der Vergangenheit verwendet",
        "title": "Aus Sicherheitsgründen muss ein neues Passwort folgende Regeln erfüllen"
      },
      "set_password_and_begin": "Jetzt müssen Sie Ihr Passwort festlegen und Sie können beginnen.",
      "title": "Passwort ändern",
      "title_first_login": "Nur ein kleiner Schritt"
    },
    "me": "ich",
    "profile": {
      "add_custom_image": "Profilbild hochladen",
      "change_color": "Farbe ändern",
      "crop_image": "Bild zuschneiden",
      "delete_image": "Lösche Profilbild",
      "drag_to_upload": "Ziehen Sie das Bild per Drag & Drop oder klicken Sie auf Choose file",
      "profile_image": "Profilbild",
      "upload_image": "Wählen Sie eine Datei"
    },
    "projects": {
      "bulkEditNote": "Ändern Sie bei Projekten, die überprüft wurden, die Rolle in",
      "change": "Ändern",
      "checkAll": "Alles überprüfen",
      "no_memberships": "Der Benutzer ist kein Mitglied eines Projekts.",
      "only_if_member": "Nur Projekte, bei denen er Mitglied ist",
      "project": "Projekt",
      "role": "Rolle",
      "title": "Benutzerprojekte",
      "title_external": "Benutzeraufgaben"
    },
    "settings": {
      "active_users": "Aktive Benutzer",
      "add_note": "Registration andere Benutzer auf Projektebene ist möglich - fügen Sie dem Projekt ein Mitglied hinzu.",
      "add_user": "Benutzer hinzufügen",
      "additional_info": "Zusatz",
      "additional_info1": "Zusatz 1",
      "additional_info2": "Zusatz 2",
      "admin": "Admin",
      "archive": {
        "archived_header": "Benutzer wurde archiviert",
        "archived_info": "Innerhalb der Archivierung wird der Benutzer aus seinen zugewiesenen Aufgaben entfernt. Sie haben %{count} Aufgaben ohne Empfänger. Hier können Sie dies überprüfen.",
        "archived_link": "Aufgaben ohne Beauftragten",
        "label": "Benutzer %{name} archivieren?",
        "note": "Der Benutzername wird in Aufgabenkommentaren und in Zeiteinträgen angezeigt. Archivierte Benutzer können sich nicht in die App einloggen und erhalten niemals eine E-Mail von der App.",
        "note2": "Der archivierte Benutzer kann kein Autor oder Beauftragter einer Aufgabe sein. Die Urheberschaft wird auf Sie geändert, die Zuordnung zu \"nicht zugewiesen\"."
      },
      "archived_users": "Archivierte Benutzer",
      "available_month_hours": "Monatliche Arbeit",
      "bar": {
        "all_label": "Alle Benutzer",
        "manage": "Konto verwalten",
        "paid_label": "Bezahlte Benutzer",
        "title": "Tarifnutzung"
      },
      "cant_access": "Du hast keine ausreichenden Berechtigungen.",
      "delete": {
        "alternative": "Neben Löschen können Sie auch Benutzer archivieren.",
        "label": "Benutzer %{name} löschen?",
        "note": "Benutzerzeiteinträge bleiben erhalten, sie haben jedoch einen unbekannten Autor. Seine Aufgaben werden nicht zugewiesen. In der Aufgabenhistorie werden seine Kommentare als unbekannter Benutzer signiert.",
        "note_archive_label": "Archivieren",
        "note_label": "Löschen"
      },
      "edit_avatar": "Avatar bearbeiten",
      "edit_user": "Benutzer bearbeiten",
      "errors": {
        "firstname": "Bitte geben Sie Vorname ein.",
        "lastname": "Bitte geben Sie den Nachnamen ein.",
        "mail": "Bitte geben Sie eine E-Mail-Adresse ein.",
        "noCheckedProjects": "Sie müssen mindestens ein Projekt auswählen.",
        "noSelectedRole": "Wählen Sie eine Projektrolle."
      },
      "firstname": "Name",
      "help_add": "Geeignet für größere Teams. Geben Sie im ersten Feld die Arbeitsposition an, z. B. im zweiten Feld der Abteilung.",
      "help_admin": "Der Administrator kann auch Nichtmitgliedprojekte anzeigen, Benutzer und Konten für den Dienst verwalten.",
      "hour": "Uhr",
      "hour_cost": "Stunden-Rate",
      "issuesCheck": {
        "assignedTo": "ist Aufgabenlöser",
        "author": "ist Autor",
        "pass": "ist nicht Autor auch nicht Aufgabenlöser von geöffnete Aufagben",
        "progress": "Aufgaben prüfen",
        "tasks": "geöffnete Aufgaben"
      },
      "language": "Sprache",
      "lastname": "Nachname",
      "mail": "E-mail",
      "month_hours": "Uhr / monat",
      "no_external_users": "Sie haben keine externen Beobachter",
      "notifications_opt_out": "Neue Benutzer E-Mail-Benachrichtigungen freigeben",
      "projects": "Mitgliedschaft in Projekten",
      "register": "Registrieren",
      "register_user": "Benutzer registrieren",
      "reset": {
        "password": "Möchten Sie das Passwort für den Benutzer wirklich zurücksetzen",
        "password_note": "Das System sendet eine E-Mail mit einem Link, mit dem ein neues Passwort erstellt werden kann.",
        "reset_password_label": "Passwort zurücksetzen"
      },
      "return_to_my_page": "Kehre zu Meine Seite zurück.",
      "settings": "Einstellung",
      "success": "Erfolgreich registration, Benutzerzugriff gesendet auf E-Mails",
      "tasks": "Aufgaben",
      "title": "Benutzerverwaltung",
      "title_external": "externe Benutzer",
      "unwatch_tasks": {
        "confirm_question": "Entfernen Sie den externen Monitor %{name} von allen Aufgaben?"
      }
    }
  },
  "wizard": {
    "Examples": "Beispiele",
    "add_projects": "projekte hinzufügen",
    "add_template": "fügen Sie Vorlagen hinzu",
    "blank_project": "Sie müssen ein übergeordnetes Projekt eingeben.",
    "blank_template": "Sie müssen eine übergeordnete Vorlage eingeben.",
    "create_templates": "Erstellen Sie Vorlagen",
    "e-mail": "E-mail",
    "examples": "Příklady",
    "first_name": "Name",
    "go_to_projects": "gehe zu Projekten",
    "invalid_mail": "Benutzer %{name} hat eine ungültige E-Mail.",
    "invalid_role": "Benutzer %{name} hat keine Rolle.",
    "last_name": "Nachname",
    "non_unique_emails": "Benutzer können nicht dieselbe E-Mail verwenden.",
    "one_project": "Sie müssen mindestens ein Projekt erstellen.",
    "one_template": "Sie müssen mindestens eine Vorlage erstellen.",
    "project": "Projektnamen eingeben",
    "register_users": "Benutzer registrieren",
    "role": "Wählen Sie eine Rolle aus",
    "skip": "überspringen",
    "start": "start",
    "step1": "herzlich willkommen",
    "step2": "projekte hinzufügen",
    "step3": "benutzerregistrierung",
    "subproject": "Name des Unterprojekts eingeben",
    "subtemplate": "Name der Unterprojektvorlage eingeben",
    "template": "Name der Vorlage eingeben",
    "template_text1": "Vorlagen erstellt.",
    "template_text2": "In der Projektliste wird neben dem Namen der Vorlage ein Symbol für Kaffee angezeigt. Klicken Sie einfach darauf und beginnen Sie mit dem Hinzufügen von Aufgaben.",
    "template_text3": "Tipp: Versuchen Sie, Ihre Vorlage zu öffnen und klicken Sie im linken Menü auf",
    "template_text4": "und erstellen Sie Aufgaben über die Mindmap.",
    "template_textHelp": "Vorlagen sind eine hervorragende Möglichkeit, ein Projekt zu planen. In der Vorlage können Sie Aufgaben zuweisen und Termine festlegen, ohne Ihr Team zu benachrichtigen. Auf diese Weise bereiten Sie die Basis des Projekts vor und können später problemlos ein reales Projekt aus der Vorlage erstellen.",
    "template_title1": "Projektvorlagen hinzufügen",
    "template_title2": "Fertig",
    "text1_step2": "Projekte sind die Ordner, in die Sie Aufgaben eingeben. Hier können Sie die Struktur der ersten Projekte und Unterprojekte erstellen. Zur Inspiration haben wir einige Beispiele auf der rechten Seite dargestellt. Geben Sie einfach mindestens ein Projekt an.",
    "text1_step3": "Dieser Schritt ist freiwillig, aber mit dem Team können Sie das Projekt schneller abschließen.",
    "text1_step3b": "Sie können in Kürze Ihren Kollegen Aufgaben erteilen, und Projektově wird die Fristen einhalten.",
    "text1_step4": "Glückwunsch, Projektově ist für Sie bereit.",
    "text2_step3": "Manager - hat uneingeschränkten Zugriff, kann alles.",
    "text2_step4": "Klicken Sie jetzt auf ein beliebiges Projekt, um Aufgaben hinzuzufügen.",
    "text3_step3": "Teammitglied - arbeitet an Aufgaben, kann Aufgaben für sich und andere erstellen.",
    "text3_step4": "Oder willst du",
    "text4_step3": "Rolle des Kunden - Watchers.",
    "text4_step4": "Projektvorlage erstellen?",
    "text5_step3": "Externist - Genau wie Teammitglied, aber er sieht nur seine Aufgaben. Zum Beispiel: Wenn 8 Aufgaben im Projekt sind, sieht er nur seine eigenen 1.",
    "text6_step3": "Mehr hier:",
    "text7_step3": "Hilfe für Rollen anzeigen",
    "text_step1": "Wie führe ich deine Projekte leicht? In den nächsten 2 Schritten beginnen wir zusammen.",
    "title_step2": "Welche Projekte leiten Sie?",
    "title_step3": "Fügen Sie Ihre Kollegen hinzu",
    "title_step4": "Fertig",
    "welcome_text": "Willkommen bei Projektově"
  }
});
I18n.translations["en"] = I18n.extend((I18n.translations["en"] || {}), {
  "activities": {
    "action": {
      "close": "Closed task",
      "comment": "Commented",
      "create": "Created task",
      "edit": "Updated task"
    },
    "assigned_to": "Assigned to",
    "author": "Author",
    "change": {
      "all": "all",
      "assigned_to": "assigned to",
      "author": "author",
      "close": "close task",
      "create": "create task",
      "description": "description",
      "done_ratio": "done ration",
      "due_date": "due date",
      "estimated_hours": "estimated hours",
      "me": "me",
      "note": "add comment",
      "others": "others",
      "priority": "priority",
      "project": "move into different project",
      "start_date": "start date",
      "status": "status",
      "tracker": "tracker"
    },
    "changes": "Updates",
    "complete_list": "Complete list",
    "no_activity": "No activities yet.",
    "projects": "Projects"
  },
  "admin": {
    "client": {
      "billing": {
        "cancel": "cancel changes",
        "change_info": "Change data",
        "change_save": "Save changes",
        "change_tariff": "Save changes and select plan",
        "city": "City",
        "company": "Company",
        "contact_info": "Contact information",
        "country": "Country",
        "country_cs": "Czech Rebublic",
        "country_sk": "Slovak Republic",
        "dic_cs": "VAT",
        "dic_sk": "VAT",
        "email": "E-mail",
        "firstname": "First name",
        "ic": "CIN",
        "invoice_email": "Invoice E-mail",
        "invoice_info": "Billing information",
        "lastname": "Last name",
        "phone": "Phone",
        "street": "Street",
        "zip": "Zip"
      },
      "messages": {
        "deleted": "Subscription canceled. The Projektově application will be stopped at the end of the invoicing period and subsequently deleted.",
        "deleted_trial": "Trial account canceled. All data will be soon deleted.",
        "new": "Plan successfully ordered. The Projektově application is now fully functional.",
        "updated": "Plan changed successfully."
      },
      "tariff": {
        "cancel": "cancel subscription",
        "cancel_title": "Are you sure you want to cancel your subscription to Projektově?",
        "cancel_trial": "cancel trial account",
        "cancel_trial_title": "Are you sure you want to cancel trial account?",
        "change": "Change Plan",
        "current": "Current Plan",
        "custom": "Individual",
        "custom_tariff_notice": "Do you need to change the subscription or adjust the billing period? Contact our customer support.",
        "details": "Plan Details",
        "individual_20": "Individual 20",
        "individual_30": "Individual 30",
        "individual_40": "Individual 40",
        "individual_50": "Individual 50",
        "onpremise": "On-premise",
        "order": "Order Plan",
        "payment_info": "After ordering or changing the Plan, the system regularly issues requests for payment and sends a tax document after payment.",
        "payment_invoice": "Invoice",
        "payment_method": "Payment Methods",
        "payment_type": "Payment Type",
        "prodva": "For two",
        "promo": "Promo",
        "standard": "Standard",
        "start": "Start",
        "trial": "Trial",
        "users_count": "Number of users",
        "users_current": "Current number of users",
        "users_manage": "Manage users"
      },
      "tariff_dialog": {
        "affirmation_accept": "I agree with",
        "affirmation_terms": "Terms of service.",
        "annual": "annual (discount 10 %)",
        "cancel": "Cancel changes",
        "choose": "Plan to order",
        "discount": "Discount",
        "monthly": "monthly",
        "price": "Price",
        "price_final": "Final Price",
        "price_monthly": " / month",
        "quarterly": "quarterly",
        "semi_annual": "semi-annual",
        "support_contact": "Contact support",
        "support_users": "Need more users?",
        "tax_notice": "Prices are without VAT, Projektove.CZ s.r.o. is VAT registered.",
        "users": "Uživatelů",
        "users_total": "Total users count"
      },
      "title": "Service account Projektově"
    },
    "custom_fields": {
      "cancel_button": "Cancel",
      "check_warning": "Once you enable this option, it will no longer be possible to turn it off.",
      "confirm_delete": "Opravdu si přejete odebrat tuto frontu?",
      "confirm_delete_alternative": "Do you really want to change trackers?",
      "confirm_delete_tracker": "Do you really want to remove this tracker?",
      "custom_field_header": "Custom field management",
      "delete_button": "Delete",
      "delete_field_confirm": "Do you really want to delete custom field?",
      "delete_field_label": "Deleting will also remove all the values filled in.",
      "delete_field_success": "Custom field has been successfully deleted.",
      "delete_tracker": "Removing the tracker will also permanently delete all the specified values.",
      "delete_tracker_alternative": "With tracker change system will delete data from other trackers. Custom field was visible in all trackers, now i will be visible only in choosed ones. Data from choosed trackers will remain, for other trackers will be deleted.",
      "edit_button": "Edit",
      "edit_field_success": "Custom field has been successfully changed.",
      "enable_more_values": "Enable select multiple values",
      "field_add": "Add new custom field",
      "label_field": "Field",
      "label_field_visibility": "Custom field visibility",
      "label_label": "Description",
      "label_show_in_projectslist": "Projectlist",
      "label_sorting": "Sorting",
      "label_tracker": "Front",
      "label_type": "Type",
      "maximum_number": "Maximum number of your customfields reached.",
      "move_begin": "Move to the top",
      "move_end": "Move to the bottom",
      "move_higher": "Move up",
      "move_lower": "Move down",
      "name_and_tracker_error": "Name and front of custom field is obligatory.",
      "name_and_type_error": "Name and type of custom field is obligatory.",
      "name_error": "Name of custom field is obligatory.",
      "name_type_tracker_error": "Name, front and type of custom field is obligatory.",
      "new_line": "Every value on the new line.",
      "no_possible_values": "You have selected a list type, you must specify possible values.",
      "possible_values": "Possible values",
      "possible_values_changed": "Possible values has been successfully changed.",
      "possible_values_obligatory": "Possible values are obligatory.",
      "projects_label": "Projects",
      "same_name_error": "The name of the field already exists, please choose new name.",
      "save_button": "Save",
      "tasks_label": "Tasks",
      "tracker_and_type_error": "Front and type of custom field is obligatory.",
      "tracker_error": "Front of custom field is obligatory.",
      "type_error": "Type of custom field is obligatory.",
      "values_change": "Edit list values",
      "visible_for_all": "Visible to everyone"
    }
  },
  "budget_entries": {
    "expense_time_table": {
      "all": "All",
      "filter": "Filter",
      "show": "Show",
      "subtasks2": "hide subtasks below 2 level",
      "sum": "Total",
      "task": "Task",
      "title": "Real expenses history",
      "types": {
        "planned": "plánované náklady",
        "real": "skutečné náklady"
      },
      "warning": "Attention, this is a simple sum of the displayed lines. It does not respect the relationship between tasks and sub-tasks. If the mix of tasks and sub-tasks is filtered above, the sum will not match.",
      "without_subtasks": "only main tasks"
    },
    "modal": {
      "category": "Category",
      "comment": "Note",
      "date": "Date",
      "not_set": "Without category",
      "spent_hours": "Spent time",
      "summary": "Summary",
      "task": "Task",
      "value": "Value",
      "value_vat": "tax inc.",
      "value_without_vat": "tax excl.",
      "variable_costs": "Variabile costs",
      "vat": "VAT"
    },
    "project_bilances": {
      "cash_flow": "Cash flow",
      "cash_flow_label": "real income - real expenses",
      "income_diff": "Zbývá vyfakturovat",
      "planned_expenses": "Planned expenses",
      "project": "Project",
      "real_diff": "Future cash flow",
      "real_income": "Real income",
      "title": "Project balances"
    },
    "report": {
      "add": "Add entry (task)",
      "addNew": "Hit enter to create new entry",
      "add_expense": "Add expense",
      "add_income": "Add income",
      "added_manually": "Planned expense was added manually",
      "added_recalculated": "Planned expense was created by sum or calculated from estimated hours",
      "czech_currency": "Kč",
      "delete_budget_line": "Delete from project budget",
      "diff": "Difference",
      "expense": "Expense",
      "expenseHours": "Expense - variable",
      "expenses": "Expenses",
      "expenses_list": "Expenses list:",
      "hidden_planned_expenses": "Hidden planned expenses",
      "hidden_real_expenses": "Hidden real expenses",
      "hide": "Dont show this entry in project budget",
      "income": "Income",
      "incomes_list": "Incomes list:",
      "list_all_expenses": "List all expenses",
      "notify_higher_income": "Enter higher income",
      "notify_higher_income_text": "Total planned income must be higher than summary of planned incomes in subprojects, that are now",
      "plan": "Plan",
      "planned_income": "Income (from contract)",
      "planned_profit": "Planned profit",
      "project_budget": "Project budget",
      "real_diff_label": "Project will have incomes %{incomes} and expenses %{expenses}. Future cash flow is %{diff} in order to fulfill planned budget.",
      "real_expenses": "Real expenses",
      "reality": "Reality",
      "spent_time": "Time spent directly on project",
      "sum": "Balance",
      "sum_reserve": "Balance / reserve",
      "toggle": "Toggle Vat",
      "total": "Total",
      "total_real_expenses": "Součet skutečných nákladů včetně podúkolů",
      "withVat": "with VAT",
      "withoutVat": "without VAT"
    },
    "submenu": {
      "prefill": "Prefill planned costs",
      "prefillText": "Do you want to pre-fill the planned costs by calculating the estimated time multiplied by the solvers cost hourly rate?",
      "prefillWarning": "Attention, this will overwrite the plan.",
      "showAllIssues": "Show all issues in budget",
      "showOnlyRootIssues": "Show only root issues in budget",
      "sorting": {
        "default": "default",
        "dueDate": "by due date",
        "heading": "Sorting",
        "label": "Order of items in budget",
        "startDate": "by start date",
        "subject": "by name"
      }
    }
  },
  "dashboard": {
    "add_blocks": {
      "add": "Add",
      "add_error": "Block added failed. Please try again.",
      "choose": "Choose what to add",
      "labels": {
        "activities": "Last activities",
        "allprojects": "All projects",
        "estimatedhoursbyprojects": "Workload per projects",
        "estimatedhoursbyusers": "Team workload",
        "estimatedhourstable": "Estimated time spent",
        "favouriteprojects": "Favorite projects",
        "iframe": "Custom block",
        "iframebottom": "Custom block - bottom",
        "issuesassignedto": "Assigned to",
        "issuesreportedbyme": "Tasks delegated by me",
        "issueswatched": "Watched tasks",
        "polls": "Poll",
        "shouldbeinprogress": "Tasks that should be in progress",
        "solvedclosedtasks": "Vyřešené a uzavřené úkoly",
        "timelog": "My time spent"
      },
      "reset": "Remove all",
      "reset_error": "Resetting blocks failed. Please try again.",
      "title_here": "Customize this page"
    },
    "blockEstimatedHoursByProjects": {
      "deadline": "Deadline",
      "defaultView": "If nothing is selected, the default view is used - the first 30 projects.",
      "estimated_hours": "Workload by projects",
      "graphTitle": "4 months view",
      "no_due_date": "No due date",
      "past": "In past",
      "tableTitle": "Hours outside graph"
    },
    "blockEstimatedHoursByUsers": {
      "estimated_hours": "Workload per users",
      "graphTitle": "4 months view",
      "no_due_date": "No due date",
      "past": "In past",
      "tableTitle": "Hours out of graph"
    },
    "block_activities": {
      "title": "Last activity"
    },
    "block_all_projects": {
      "all_projects": "All projects",
      "empty": "You have no projects here."
    },
    "block_assigned_to": {
      "assigned_to": "Assigned to %{name}",
      "complete_list": "Complete list (%{size})",
      "empty": "You have no tasks here.",
      "my_tasks": "My tasks"
    },
    "block_author_me_tasks": {
      "author_me_tasks": "Tasks delegated by me",
      "empty": "You have no tasks here.",
      "show": "Show"
    },
    "block_favorite_projects": {
      "empty": "You do not have any favorite projects. Mark them by clicking on",
      "empty_end": "in the Project List.",
      "favorite_projects": "Favorite projects"
    },
    "block_my_tasks_today": {
      "5days": "Delayed up to 5 days",
      "approaching": "Approaching",
      "approaching_dueDate": "Due date",
      "approaching_startDate": "Start date",
      "before_yesterday": "Day before yesterday",
      "my_day": "My day",
      "no_tasks": "You have no tasks here.",
      "to_resolved": "In delay",
      "today": "Due date today",
      "yesterday": "Yesterday"
    },
    "block_my_time_entries": {
      "add": "Add time",
      "allTimes": "Total",
      "comment": "Comment",
      "complete": "Complete listing",
      "empty": "You have no time spent in the last 7 days.",
      "hours": "Hours",
      "project": "Project",
      "task": "Task",
      "title": "My time spent"
    },
    "block_polls": {
      "choice": "Choice",
      "due_date": "Due date",
      "question": "Question",
      "task": "Task"
    },
    "block_solved_closed_tasks": {
      "collapse": "Collapse",
      "expand": "Expand",
      "title": "Solved and closed tasks"
    },
    "block_watched_tasks": {
      "allAssigned": "tasks, where I am the assignee",
      "all_watched_tasks_with": "All watched tasks (%{size})",
      "all_watched_tasks_without": "All watched tasks without tasks, where I am the assignee (%{size})",
      "assignedWithoutMe": "without tasks, where I am the assignee",
      "empty": "You have no tasks here.",
      "show": "Show",
      "watched_tasks": "Watched tasks"
    },
    "complete_list": "Complete list",
    "planned_estimated_hours": "Planned",
    "spent_hours": "Spent time"
  },
  "date": {
    "abbr_day_names": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "formats": {
      "default": "%Y-%m-%d",
      "long": "%B %d, %Y",
      "short": "%b %d"
    },
    "moment_formats": {
      "default": "YYYY-MM-DD",
      "from": "[from] YYYY-MM-DD",
      "short": "MM-DD",
      "to": "[to] YYYY-MM-DD"
    },
    "month_names": [
      null,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_time": {
      "day_before_yesterday_at": "předevčírem v %{time}",
      "today_at": "Today at %{time}",
      "yesterday_at": "Yesterday at %{time}"
    },
    "distance_in_words": {
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      },
      "half_a_minute": "half a minute",
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "x_hours": {
        "one": "1 hour",
        "other": "%{count} hours"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "x_weeks": {
        "one": "1 week",
        "other": "%{count} weeks"
      }
    },
    "prompts": {
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "month": "Month",
      "second": "Seconds",
      "year": "Year"
    }
  },
  "default": {
    "admin_firstname": "Example",
    "admin_lastname": "Projektově",
    "columns": {
      "assigned_to": "Assignee",
      "author": "Author",
      "due_date": "Due date",
      "estimated_hours": "Estimated hours",
      "priority": "Priority",
      "project": "Project",
      "start_date": "Start date",
      "status": "Statys",
      "subject": "Task",
      "total_spent_hours": "Spent time"
    },
    "user": {
      "change_color": "Change color",
      "email": "e-mail",
      "firstname": "name",
      "language": "language",
      "lastname": "lastname"
    }
  },
  "estimated_hours_table": {
    "all_time": "All time",
    "block_name": "Planned hours",
    "today": "Today",
    "tomorrow": "Tomorrow"
  },
  "files": {
    "addFile": "Attach file",
    "addFileToTask": "Choose task",
    "author": "Author",
    "createdAt": "Date",
    "download": "Download",
    "filename": "File",
    "issue": "Task",
    "project": "Project",
    "projectFiles": "Project files",
    "tasksFiles": "Files attached to tasks"
  },
  "gantt": {
    "add_name": "Enter a name for the new task",
    "add_new_task": "Add task",
    "add_next_task_header": "Add follow-up task",
    "add_previous_task_header": "Add previous task",
    "add_task": "Add task",
    "adding_subtask_to_task": "Add subtask to task",
    "adding_task_to_project": "Add task to project",
    "cancel": "Cancel",
    "cancel_changes": "Cancel changes",
    "cant_export_grouped_by_assigned_to": "Seskupené zobrazení podle příjemců úkolu nelze exportovat.",
    "change_parent_task_error": "The change you are going to make will change the date of the envelope task. This feature is only available to the manager.",
    "color_by": {
      "default": "default",
      "label": "Color",
      "projects": "by project",
      "templateTask": "by task from template"
    },
    "confirm_link_deleting": "will be deleted.",
    "conflicts_warning": {
      "note": "Click on the task to view its card where you can check the changes. Save the diagram to set the start and end dates of the tasks from the Gantt chart.",
      "task": {
        "few": "The tasks",
        "one": "The task",
        "other": "The tasks"
      },
      "was_changed": {
        "few": " you edit have also been changed on the server.",
        "one": " you are editing has also been changed on the server.",
        "other": " you edit have also been changed on the server."
      }
    },
    "date_range": {
      "all_time": "All",
      "current_month": "This month",
      "current_week": "This week",
      "current_year": "This year",
      "custom": "Custom",
      "next_month": "next month",
      "next_week": "Next week",
      "next_year": "Next year",
      "three_months": "3 months",
      "today": "Today"
    },
    "filter_tasks": "Filter task",
    "filter_user": "Filter assignee",
    "fullscreen": "Fullscreen",
    "group_by_assignee": "Group tasks",
    "group_by_projects": "Group by projects",
    "heading": "Gantt chart",
    "label_days": "days",
    "label_delete_relation": "You really want to delete the relation from",
    "label_done": "Done",
    "label_duration": "Duration",
    "label_end_after_start": "The term must be higher than the start date.",
    "label_export": "Export to",
    "label_from": "From",
    "label_next": "Next",
    "label_no": false,
    "label_previous": "Previous",
    "label_project": "Project",
    "label_start_before_end": "The start date must be less than the term.",
    "label_to": "To",
    "label_to_small": "to",
    "label_yes": true,
    "link": "Link",
    "new_task_added": "New task added successfully.",
    "new_task_not_added": "Failed to add new task.",
    "next_tasks_header": "Existing follow-up tasks",
    "no_next_tasks": "No existing follow-up tasks.",
    "no_previous_tasks": "No existing previous tasks.",
    "other_exports": "Other variants",
    "planned_dueDate": "Planned term",
    "planned_startDate": "Planned start date",
    "previous_tasks_header": "Existing previous tasks",
    "readonly_note": "Read only. For active gantt you have to be manager in visible projects.",
    "save_changes": "Save changes",
    "save_first": "Save or discard changes first",
    "section_time": "Task start date and duration",
    "show": {
      "all": "all",
      "label": "Show",
      "projects": "only projects",
      "subtasks2": "hide subtasks from 2 level",
      "tasks": "projects and main tasks"
    },
    "show_closed_tasks": "Show closed",
    "sort_tasks": "Arrange tasks",
    "task_duration": "Duration",
    "task_end": "Task end",
    "task_name": "Task name",
    "task_order": "Automatically arrange tasks when editing",
    "task_start": "Task start",
    "time_disable_button": "Remove",
    "time_enable_button": "Schedule",
    "type": {
      "envelope": "Envelope",
      "label": "Type",
      "task": "Task"
    },
    "week": "Week",
    "zoom_in": "Zoom in",
    "zoom_out": "Zoom out"
  },
  "header": {
    "all_projects": "All projects",
    "clear": "Clear list. Visit another Projektově and it will be automatically added to your list.",
    "menu": {
      "my_page": "My page",
      "my_tasks": "My tasks",
      "new_member": "New contact",
      "new_task": "New task",
      "open_tasks": "Open tasks",
      "projects": "Projects",
      "tasks_plural": "Tasks"
    },
    "projects": {
      "current_project": "Tasks list in project"
    },
    "search": {
      "history": "Search history",
      "in_project": "only in actual project",
      "placeholder": "Search in tasks"
    },
    "switch_to": "Switch to:",
    "title": "Pro přehledné řízení firmy, projektů a úkolů.",
    "user_menu": {
      "calendar": "Calendar",
      "client_profil": "Billing",
      "help": "Help",
      "last_activity": "Last activity",
      "logout": "Logout",
      "mobile_application": "Mobile application",
      "my_settings": "My settings",
      "test_v2": "Try Projektově 2",
      "this_week": "This week",
      "timetracker": "Timetracker",
      "users_settings": "Manage users"
    }
  },
  "issues": {
    "bulk_edit": {
      "all_issues": "all open tasks",
      "all_issues_note": "from current project and subprojects",
      "assigned_to": "tasks assigned to"
    },
    "delete_modal": {
      "button_no": "no",
      "button_yes": "yes, delete",
      "label": "Are you sure?",
      "label_subtask": "Are you sure to delete subtask?",
      "note": "This will delete task with all his subtasks."
    },
    "filters": {
      "assignedToId": "Assignee",
      "authorId": "Author",
      "buttons": {
        "apply": "Použít",
        "cancel": "Zrušit"
      },
      "calendar": "Calendar",
      "closedAt": "Closed",
      "createdAt": "Created",
      "customFields": "Custom fields",
      "customFields_icon_title": "Remove",
      "customFields_title": "Set filters",
      "dueDate": "Due date",
      "fromFavoriteProjects": "From favorite projects",
      "input_placeholder": "enter search text",
      "lastActivityAt": "Updated",
      "noActiveTag": "The filter in this view can not be used, therefore, is not active.",
      "no_assignedToId": "user not found",
      "no_authorId": "user not found",
      "no_one_assigned": "unassigned",
      "no_projectId": "project not found",
      "not_entered": "Not entered",
      "others": "Others",
      "parentId": "Without subtasks",
      "priorityId": "Priority",
      "projectId": "Project",
      "repetitive_false": "Without repetitive",
      "repetitive_true": "Repetitive",
      "startDate": "Start date",
      "statusId": "Status",
      "templates": "Include tasks from templates",
      "this_2_weeks_and_older": "This 2 weeks and older",
      "this_4_weeks_and_older": "This 4 weeks and older",
      "this_month": "This month",
      "this_week": "This week",
      "this_week_and_older": "This week and older",
      "today": "Today",
      "today_and_older": "Today and older",
      "trackerId": "Tracker"
    },
    "index": {
      "add_first_task": "Add fist task.",
      "add_remove_tasks_order": "Add / remove tasks sort level",
      "add_tasks_from_template": "add tasks from template",
      "bulk_edit": "Bulk edit",
      "bulk_modal": {
        "assigned_to": "Tasks Assigned",
        "edit": "edit",
        "edit_all": "edit all open tasks"
      },
      "cant_be_closed": {
        "comment_wip": "Task card can not close because you have unfinished task comment.",
        "description_wip": "Task card can not close because you have unfinished task description.",
        "prompt": "Do you want to proceed to the next page?",
        "title": "Task card can not close."
      },
      "choose_calendar_list": "Choose view that you want to synchronize.<br>Than enter calendar URL as external calendar feed into your calendar (Gmail, Outlook, Mac).",
      "default_order": "Set default order.",
      "delete": "Delete task %{subject}",
      "detail": "Detail",
      "exports_modal": {
        "title": "Export tasklist",
        "with_descriptions": "with descriptions",
        "without_descriptions": "without descriptions"
      },
      "grouped": {
        "group_assignee": "by assignee",
        "group_author": "by author",
        "group_by": "Grouped",
        "group_dueDate": "by due date",
        "group_lastActivityAt": "by activity",
        "group_none": "ungroup",
        "group_priority": "by priority",
        "group_project": "by project",
        "group_startDate": "by start date",
        "group_status": "by status",
        "no_dueDate": "Without",
        "no_one_assigned": "Unassigned",
        "no_startDate": "Without",
        "no_value": "No value",
        "sample": "Sample P."
      },
      "ics_export": "Connect with calendar",
      "ics_modal": {
        "all_tasks": "All tasks",
        "all_tasks_duration": "All tasks with start and due dates",
        "all_tasks_only_with_times": "All tasks, only with times",
        "my_tasks": "My tasks",
        "my_tasks_duration": "My tasks with start and due dates",
        "my_tasks_only_with_times": "My tasks, only with times"
      },
      "in_one_column": "in one column",
      "in_structure": "in branches",
      "link_with_calendar": "Connect with calendar",
      "modals": {
        "new_issues": {
          "create": "Create",
          "parent_task": "Parent task",
          "plan": {
            "from_end": "from end",
            "from_start": "from start",
            "help_left": "Planned start date is known, from that date we will plan tasks.",
            "help_right": "Start and due dates are computed in order to end last task in planned end date.",
            "title": "Plan"
          },
          "planned_due_date": "Planned due",
          "planned_start_date": "Planned start",
          "success": "Tasks successfully added",
          "template": "Template",
          "title": "Add tasks from template"
        }
      },
      "need_update": "There are updates in displayed tasks, click here for update.",
      "no_closed_tasks": "There are no closed tasks.",
      "no_overdue_tasks": "Excelent, there are no tasks behind schedule.",
      "no_solved_tasks": "There are no solved tasks.",
      "not_found": "We haven't found anything.",
      "only_with_times": "only with times",
      "order_tasks": "Sort tasks",
      "reminder": {
        "text": "Připomínky k novému výpisu zde.",
        "title": "Join our facebook group."
      },
      "search_task": "Task",
      "settings": {
        "add": "Add",
        "columns": "Columns settings",
        "disable": "Disable",
        "enable": "Enable",
        "horizontalScroll": " scrolling with mouse wheel",
        "reorder": "Reorder columns"
      },
      "show_more": {
        "one": "Show last",
        "other": "Show more %{count}"
      },
      "show_structure_filter": "Show",
      "task_quickadd": "Create new task quickly by typing subject and then hit enter.",
      "task_quickadd_hitenter": "press enter",
      "tasks_export": "Export tasks"
    },
    "messages": {
      "created": "Task successfully created.",
      "errors": {
        "dueDate": {
          "greater_than_or_equal_to_start_date": "The due date of a task cannot be before the start of the task.",
          "locked_task_in_project": "There is task with locked start and due date in project. Cannot change task.",
          "lower_than_or_equal_to_parent_due_date": "The due date must be sooner than parent task due date.",
          "start_and_due_date_locked": "Task start and due date are locked by manager. Task must be unlocked first."
        },
        "estimatedHours": {
          "greater_than": "Estimated hours must be greater than sum of estimates in subtasks (%{count}h"
        },
        "startDate": {
          "greater_than_or_equal_to_parent_start_date": "Start date cannot be sooner than parent task start date."
        }
      },
      "open_task": "open task"
    },
    "modals": {
      "repetitive": {
        "cancel": "cancel repetition",
        "day_in_month": "day in month",
        "days_in_week": "Days in week",
        "interval": "Interval",
        "last": "last",
        "months": {
          "one": "every month",
          "other": "every %{count} months"
        },
        "periodically_every": "every",
        "periodically_label": "days from closing",
        "repeat": "Repeat",
        "repeat_options": {
          "daily": "daily",
          "monthly": "monthly",
          "never": "never",
          "periodically": "regulary",
          "weekly": "weekly",
          "yearly": "yearly"
        },
        "start": "Start",
        "submit": "set",
        "title": "Repetition settings",
        "weeks": {
          "one": "every week",
          "other": "every %{count} weeks"
        },
        "years": {
          "one": "every year",
          "other": "every %{count} years"
        }
      },
      "tracker": {
        "label": "Beware, with tracker change you will lost custom fields values. Only exception is to have these custom fields in new tracker too. Do you want to proceed?"
      }
    },
    "new": {
      "add_contact": "add contact",
      "assignee": "Assignee",
      "contact": "Contact",
      "contact_name": "Contact's name",
      "create_task": "create task",
      "customer_tittle": "New tasks set by the customer are assigned to said person.",
      "due_date": "Due date",
      "errors": {
        "cannot_create": "Failed to create task.",
        "date": "Deadline for the task can't be before start.",
        "name": "Task's name isn't specified.",
        "project": "Project isn't selected."
      },
      "new_contact": "New contact",
      "new_task": "New task",
      "placeholders": {
        "assignee": "Unassigned",
        "new_contact": "Contact name",
        "new_contact_in_project": "Contact in project %{project}",
        "new_task": "Task name",
        "new_task_in_project": "Task in project %{project}",
        "project": "Choose project",
        "starting_today": "Starting today"
      },
      "project": "Project",
      "set_start_date": "enter start date",
      "start_date": "Start date",
      "task": "Task",
      "task_name": "Task's name",
      "tracker": "Queue"
    },
    "no_due_date": "No due date",
    "no_one_assigned": "Unassigned",
    "show": {
      "activity_created_at": "The task was created.",
      "add_comment": "add comment",
      "add_time": "Add time",
      "attachments": {
        "attach_delete": "Do you really want to delete the file?",
        "attach_deleted": "File was deleted.",
        "attach_file": "Attach files",
        "attach_not_deleted": "Failed to delete the file.",
        "button_no": "No",
        "button_yes": "Yes, delete",
        "not_saved": "Saving files failed.",
        "saved": "Files were saved.",
        "saving": "Saving files...",
        "title_delete": "Delete file"
      },
      "budget": {
        "add": "Add",
        "amount": "Amount",
        "bad_amount": "The amount should not be zero or negative.",
        "date": "Date",
        "error": "The amount was not added. Please try again later.",
        "expenses": "Expenses",
        "note": "Note",
        "title": "Add budget",
        "vat": "VAT"
      },
      "cannot_add_comment": "You cannot comment closed tasks. Please create new tasks with button on top of the screen.",
      "close": "Close?",
      "close_title": "Close task",
      "closed": "Closed",
      "done_ratio_locked": "Done ratio is computed via subtasks. Please edit done ratio in these tasks.",
      "due_date_locked": "Due date was set by task author, only he can change it.",
      "due_reminder": {
        "add": "Add reminder",
        "before": "before due date",
        "title": "Reminder",
        "tooltip": "Add e-mail due date reminder for the assignee."
      },
      "editor": {
        "cancel": "Cancel changes",
        "placeholder": "Details for you or colleague...",
        "save": "Save"
      },
      "open_task": "Open task",
      "options": {
        "add_tasks": "Add tasks from template",
        "copy": "Copy task",
        "delete": "Delete task",
        "download": "Download as PDF without comments",
        "download_full": "Download as PDF with comments",
        "lock_dates": "Zamknout začátek a termín",
        "title": "Task settings",
        "unlock_dates": "Odemknout začátek a termín"
      },
      "parent": {
        "loading": "Loading...",
        "more_items": {
          "one": "...and 1 next task. Be more specific.",
          "other": "...and next %{count} tasks. Be more specific."
        },
        "no_items": "No task found.",
        "no_parent": "None"
      },
      "project": {
        "no_items": "No project found."
      },
      "quill_editor": {
        "h1": "Title",
        "h2": "Subtitle",
        "normal": "Normal"
      },
      "relations": {
        "add_related": "Add related",
        "close_title": "Close",
        "closed_title": "Closed",
        "hint_relates": "Enter first letters of task",
        "hint_subtask": "Write subtask name and hit Enter",
        "label_relates": "Relates: %{name}",
        "label_subtask": "Subtask",
        "link_relates": "Relates",
        "open_title": "Open",
        "relates": "Related",
        "remove_relation_to_relates": "Remove task relation",
        "reopen_title": "Closed, return to status \"In progress\"",
        "solve_title": "Solve",
        "solved_title": "Solved",
        "unsolve_title": "Solved, return to status \"In progress\""
      },
      "reopen_title": "Reopen task",
      "repetitive": {
        "add": "Set to repeat",
        "disabled": "repeat cannot be set",
        "disabled_reason": {
          "already_repeated": "This task has been repeated. Modify the settings in the last active task found in the open tasks in the project.",
          "already_repetitive": "Some parent or sub-task already have task repetition."
        },
        "edit": "Edit repeat",
        "next_due": "Next task due date",
        "wrong_input": "choose at least one day",
        "wrong_next_due": "Wrong next due date? Cancel repetition and set up new one."
      },
      "solve": "Solve?",
      "solve_title": "Mark task as solved in order to be closed by task author.",
      "solved": "Solved",
      "task_has_x_files": {
        "one": "Task has %{count} file",
        "other": "Task has %{count} files"
      },
      "total_spent_hours": "%{hours} total",
      "unsolve_title": "Mark as unsolved (as in progress)",
      "watchers": {
        "add": "Add watchers",
        "add_watchers": "Add to watchers",
        "assigned": "Task is assigned to %{name}",
        "check_all": "check all",
        "dialog_title": "Adding watchers",
        "how_it_looks": "There is a <a href=\"%{url}\" target=\"_blank\">previewnáhled</a> how it looks.",
        "label": "Watchers",
        "not_search": "No member of this name could not be found.",
        "notes": {
          "all_added": "All project members are already added to watchers.",
          "externalusers_notifications": "They only receive comments on the e-mail, to which they can also respond directly from the e-mail (reply to the e-mail).",
          "externalusers_visibility": "In the application, you have users who can log inwith their e-mail and password and collaborate on tasks . Users can watch any tasks. When I'm tracking a task, I will receive e-mail notifications with task changes and comments. If I want to share comments to others who don't have access to the system, I can use external watchers. These external watchers (without registration) do not have access to the app, they only see the task name, task description and comments via a link in the e-mail.",
          "how_to": "If you want to add more watchers, you need to share this project with them first."
        },
        "remove": "Remove %{name} from watchers",
        "removeExternal": "Remove external watcher %{name}. With removing user lost accest to the limited task view.",
        "tab_external": "Share without registration",
        "tab_users": "Registered users",
        "uncheck_all": "uncheck all"
      }
    },
    "show_static": {
      "no_comments_yet": "No comments here yet."
    },
    "taskParentError": {
      "add": "Addt",
      "added": "Added.",
      "ask1": "Please ask manager of project",
      "ask2": "to add these users.",
      "heading": "There are missing users in target project",
      "missingTracker": "There is missing tracker in the target project. Moving a task will automatically change its tracker and delete data from custom fields. Are you sure?",
      "move": "Move",
      "note1": "By moving task users bellow would lost this issue",
      "role": "Role",
      "step1": "First, add users into this project",
      "step2": "And then move task",
      "user": "User"
    },
    "time_tracker": {
      "comment": "comment",
      "error_already_runing": "Time tracker already running.",
      "stopped": "Successfully saved.",
      "stopped_and_started": "Saved & started.",
      "stopped_and_started_text": "%{issue} spent time was updated and time tracker was started again for next task."
    }
  },
  "journals": {
    "add": "add",
    "allDayChange": "all day event changed from",
    "attributes": {
      "assigned_to_id": "assignee",
      "attachment": "file",
      "author_id": "author",
      "description": "task description has been updated",
      "done_ratio": "%done ratio",
      "due_date": "due date",
      "estimated_hours": "estimated hours",
      "parent_id": "parent",
      "priority_id": "priority",
      "project_id": "project",
      "start_date": "start date",
      "status_id": "status",
      "subject": "task subject",
      "tracker_id": "tracker"
    },
    "button_cancel": "cancel",
    "button_save": "save",
    "changedFromMan": "changed from",
    "changedFromThing": "changed from",
    "changedFromWoman": "changed from",
    "comment_deleted": "Comment was deleted.",
    "customField": "custom field",
    "delete": "delete",
    "deleteMan": "delete",
    "deleteThing": "delete",
    "deleteWoman": "delete",
    "edit_note": "Comment edit doesn't send email nofications. If you want to send a notification, add new comment instead.",
    "events": {
      "repeated_from": {
        "link_label": "previous",
        "title": "Creating by repeating task."
      },
      "repeated_to": {
        "link_label": "following",
        "title": "Recurring task created."
      },
      "user_archived": "User <b>%{user}</b> has been archived."
    },
    "hours": "h",
    "newDescriptionValue": "New description",
    "no": "no",
    "oldDescriptionValue": "Old description",
    "setTo": "set to",
    "setToMan": "set to",
    "setToThing": "set to",
    "setToWoman": "set to",
    "showAll": "Show all task history",
    "to": "to",
    "userDeleted": "user is deleted",
    "yes": "yes"
  },
  "labels": {
    "actions": {
      "add": "Add",
      "archive": "Archive",
      "cancel": "Cancel",
      "close": "Close",
      "confirm_archive": "archive",
      "confirm_delete": "yes, delete",
      "confirm_move": "yes, move",
      "confirm_reset_password": "yes, reset",
      "create": "Create",
      "delete": "Delete",
      "delete_from_all_tasks": "Delete from all tasks",
      "edit": "Edit",
      "print": "Print",
      "refresh": "Refresh",
      "register": "Register",
      "remove": "Remove",
      "reset": "Reset",
      "save": "Save",
      "zoomIn": "Zoom in",
      "zoomOut": "Zoom out"
    },
    "choose": "Choose",
    "columns": {
      "assignedTo": "Assigned to",
      "author": "Author",
      "budgetRealExpense": "Expenses",
      "close_toggle": "Close",
      "createdOn": "Created on",
      "doneRatio": "Done",
      "dueDate": "Due date",
      "estimatedHours": "Estimated hours",
      "id": "#",
      "lastActivityAt": "Updated at",
      "parent": "Parent task",
      "parentProject": "Parent project",
      "parentTask": "Parent task",
      "priority": "Priority",
      "project": "Project",
      "relations": "Related",
      "remainingHours": "Remaining hours",
      "spentHours": "Spent hours",
      "startDate": "Start",
      "status": "Status",
      "subject": "Task",
      "timeTracker": "Time tracker",
      "totalEstimatedHours": "inc. subtasks",
      "totalEstimatedHoursTitle": "Estimated hours including subtasks",
      "totalExpenses": "Expenses",
      "totalSpentHours": "Spent hours with subtasks",
      "tracker": "Tracker",
      "updatedAt": "Updated at"
    },
    "custom_field_types": {
      "bool": "Yes/No",
      "date": "Date",
      "file": "File",
      "int": "Integer",
      "list": "List",
      "string": "Text",
      "text": "Long text",
      "url": "Url"
    },
    "false": "no",
    "help": "Help",
    "languages": {
      "cs": "czech",
      "de": "german",
      "en": "english",
      "hr": "croatian",
      "sk": "slovak"
    },
    "loading": "Loading...",
    "modified": "modified",
    "priority": {
      "high": "Vysoká",
      "low": "Nízká",
      "normal": "Normální",
      "urgent": "Urgentní"
    },
    "query": {
      "1": "My tasks",
      "1-contacts": "My contacts",
      "10": "My closed tasks",
      "11": "Delegated tasks that are behind schedule",
      "12": "My tasks behind schedule",
      "13": "My tasks",
      "14": "Without subtasks",
      "15": "My watched tasks",
      "16": "My watched tasks, where I am not a assignee",
      "17": "Solved tasks , where I am author",
      "18": "Delegated tasks that are behind schedule",
      "19": "Tasks that should be in progress",
      "2": "Tasks behind schedule",
      "2-contacts": "Contacts behind schedule",
      "3": "Open tasks",
      "3-contacts": "Open contacts",
      "4": "Closed tasks",
      "4-contacts": "Closed contacts",
      "5": "Solved tasks",
      "5-contacts": "Solved contacts",
      "6": "Tasks delegated by me",
      "7": "Created by me",
      "8": "Structured view",
      "9": "My tasks by date",
      "all": "All tasks",
      "custom": "Custom view"
    },
    "time_entries": {
      "activity": "Activity",
      "comment": "Comment",
      "date": "Date",
      "hours": "Spent hours",
      "time_tracker": "Time tracker",
      "user": "User"
    },
    "today": "today",
    "true": "yes",
    "user": {
      "deleted": "Deleted user",
      "email": "E-mail",
      "firstname": "Name",
      "lastname": "Surname"
    }
  },
  "messages": {
    "announcements": {
      "deprecated_browser": {
        "info": "Projektově will be deprecating support for Internet Explorer 11 in the near future.",
        "note1": "While you will still be able to visit Projektově on IE 11, we’ll no longer be optimizing the user experience.",
        "note2": "Microsoft is ending support at 17. 8. 2021 in their products. As alternative browser we recommend Google Chrome or Microsoft Edge."
      },
      "link_email": "Unpause e-mail notifications.",
      "nps": {
        "confirmation": "Thank you",
        "note_10": "Thank you for highest score. What is it, that Projektově does particularly well, to earn your recommendation?",
        "note_9": "Thank you for high score. What is it, that Projektově does particularly well, to earn your recommendation?",
        "note_low": "Thank you. What should we do in order to earn better score?",
        "postpone": "reply later",
        "question": "How likely are you to refer Projektově to a friend or other company?",
        "scale_high": "Definitely yes",
        "scale_low": "Definitely no",
        "send": "Send"
      },
      "release": {
        "message": "A new version of the application is available.",
        "update": "update now"
      },
      "text_email": "E-mail notifications sending (from you) is now paused.",
      "trial": {
        "expires": {
          "few": "You are now using the trial version. It ends in %{count} days.",
          "one": "Your trial expires tomorrow. Order Project today so you can continue without interruption.",
          "other": "You are now using the trial version. It ends in %{count} days.",
          "zero": "Your trial expires today. Order Project today so you can continue without interruption."
        },
        "note": "You won't lose the free days, the paid account seamlessly follows the trial version.",
        "order_now": "order"
      }
    },
    "by_code": {
      "1060": "Email is taken",
      "1061": "Die E-Mail-Adresse wird bereits als Ihre primäre E-Mail-Adresse verwendet.",
      "2220": "The tracker '%{tracker_name}' is used by tasks in the project and cannot be removed.",
      "2301": "Projekt nemohl být převeden na složku, protože obsahuje úkoly.",
      "2302": "Projekt nemohl být převeden na složku, protože má nadžazený projekt. Složka nemůže být vytvořena v projektu.",
      "2303": "Složka nemůže být prevedena na projekt, dokud obsahuje podsložky."
    },
    "errors": {
      "adding_time_entry_failed": "There was error in saving spent time.",
      "cannot_save": "Save failed.",
      "create_issue_failure": "Failed to create task.",
      "create_relation_failure": "Failed to create relation.",
      "error": "Error",
      "exceeds_tariff": {
        "assign_role": "Its not possible to add new project member with paid role. Administrator have to update Projektově tariff first."
      },
      "file_too_big": "File %{file} is too large to upload.",
      "file_upload": "Failed to upload file.",
      "forbidden": "You do not have permission for the request.",
      "form_reading_failed": "We have some difficulties with loading this form. Please try it again.",
      "general": "Oups, error. We will be working on it soon.",
      "gql": {
        "error": "An error occurred while loading.",
        "retry": "Try again"
      },
      "is_primary_mail": "Address %{mail} is already used as your primary e-mail.",
      "issue_assignee_change_failure": "Failed to change assigned to.",
      "issue_closing_or_opening failure": "Failed to close / open task.",
      "issue_closing_or_opening_failure": "Nepodařilo se uzavřít/otevřít úkol.",
      "issue_due_date_change_failure": "Failed to change due date.",
      "issue_status_change failure": "Failed to change task status.",
      "issue_status_change_failure": "Nepodařilo se změnit stav úkolu.",
      "mail_already_used": "Address %{mail} is already used by an another user.",
      "removing_relation_failure": "Failed to remove relation."
    },
    "feedback": {
      "error": "Saving error.",
      "loading": "Loading projects and tasks ...",
      "pending": "Saving...",
      "processing_request": "Please wait, processing request...",
      "saved": "All changes saved."
    },
    "member_role_changed": "Role was changed.",
    "select": {
      "no_options": "No options available",
      "type_to_search": "Start typing to search..."
    },
    "success": {
      "changed": "Successfully changed.",
      "deleted": "Successfully deleted.",
      "password_changed": "The password was successfully changed.",
      "password_reset_sent": "The password recovery instructions were sent to the user's email.",
      "saved": "Successfully saved."
    }
  },
  "mindmap": {
    "addSubtask": "Add subtask",
    "addTask": "Add task",
    "collapse": "Hide subtasks",
    "expand": "Show subtasks",
    "heading": "Mindmap",
    "hide_closed": "Hide closed",
    "order": {
      "default": "default",
      "dueDate": "by due date",
      "label": "Order",
      "priority": "by priority",
      "subject": "by name",
      "updatedAt": "by updated at"
    },
    "relations": {
      "howto1": "This mindmap show tasks in order as they where planned through relations in Gantt chart.",
      "howto2": "There is no relation between tasks, open Gantt chard and add relations there."
    },
    "view": {
      "basic": "basic",
      "extended": "extended",
      "relations": "by relations"
    }
  },
  "mobileApplication": {
    "benefit1": "With mobile app you will have all projects in your pocket",
    "benefit2": "You can easily create new task or check project status.",
    "fastLogin": "Fast login:",
    "fastLogin2": "Launch mobile app, press Login with QR and point camera on image bellow.",
    "getItGoogle": "Get it on Google Play",
    "getItiOS": "Download on the App Store",
    "installation": "Installation",
    "login": "Log in",
    "step1": "Run mobile app",
    "step2": "click on button 'Login with QR Code'",
    "step3": "point on image bellow:",
    "step4": "done. You will be automatically logged in.",
    "step5": "If not, manually fill in your login credentials.",
    "title": "How to install mobile application?"
  },
  "my": {
    "account": {
      "api": {
        "app_load": "Reload the entire application",
        "app_reload": "Znovu načíst celou aplikaci",
        "created": "API access key created %{value} ago",
        "reset": "Reset API key",
        "show": "Display key",
        "title": "API access key"
      },
      "languages": {
        "croatian": "Hrvatski",
        "czech": "Čeština",
        "english": "English",
        "german": "Deutsch",
        "slovak": "Slovenčina"
      },
      "mail": {
        "change_all_day": "Times added",
        "change_assigned_to": "Assigned to changed",
        "change_attachment": "Attachment added",
        "change_author": "Author changed",
        "change_description": "Task description updated",
        "change_done_ratio": "Done ratio updated",
        "change_due_date": "Due date updated",
        "change_estimated_hours": "Estimated hours changed",
        "change_notes": "New comment",
        "change_parent_id": "Task moved",
        "change_priority_id": "Priority changed",
        "change_project_id": "Project changed",
        "change_start_date": "Start date updated",
        "change_status": "Status changed",
        "change_subject": "Task name updated",
        "estimated_hours_exhausted": "Spent time warning",
        "estimated_hours_exhausted_note": "Send a notification when spent time reach 80 % of estimated time.",
        "mail_mark_off": "Mark the recommended settings",
        "mail_note": "Do you have too many messages?",
        "mail_notification": "Email notifications",
        "mail_settings": "E-mail settings",
        "new_task": "Someone has created or delegated a task for you",
        "newsletter": "Subscribe to newsletter",
        "newsletter_note": "Every month we send tips for project management and news from Projektove.CZ",
        "oncoming_tasks_summary": "Oncoming Tasks In Summary",
        "receive_notififactions_when": "Receive notifications when",
        "save_changes": "Save changes",
        "set_to_all_users": "Overwrite email settings for all users",
        "solved_task": "Someone solved or closed a task you authored",
        "summaries": "Morning summary email",
        "title": "E-mail settings"
      },
      "mail_notifications": {
        "default": "Send notifications",
        "none": "No events"
      },
      "mail_summaries": {
        "everyday": "every day",
        "never": "disable  (never send)",
        "no_weekends": "every day except weekends"
      },
      "mobile": {
        "install": "Installation",
        "login": "Quick login",
        "text": "Launch mobile app, press Login with QR and point camera on image bellow.",
        "title": "Mobile application"
      },
      "prefill_due_date": {
        "none": "no",
        "today_plus": {
          "few": "today +%{count} days",
          "one": "today +1 day",
          "other": "today +%{count} days"
        }
      },
      "profile_image": {
        "cancel_changes": "Discard Changes",
        "choose_crop": "Select the part of the image you want to crop.",
        "crop_tutorial": "Click and drag right down, or if you are cropping from the bottom right, then drag left to highlight the viewport of the image you want to get.",
        "dropzone_tutorial": "Drag the image or click to select a file.",
        "use_crop": "Apply"
      },
      "project_overview": {
        "gantt": "Gantt",
        "issues": "Task list - open tasks",
        "issues_closed": "Task list - closed",
        "issues_my_tasks": "Task list - only my tasks",
        "issues_overdue": "Task list - behind schedule",
        "issues_solved": "Task list - solved",
        "mind_map": "Mind map",
        "overview": "Overview",
        "tasks_board": "Task board"
      },
      "tasks_in_summary": {
        "1": "tasks due to tomorrow",
        "14": "tasks with due date up to 14 days",
        "3": "tasks with due date up to 3 days",
        "7": "tasks with due date up to 7 days",
        "none": "no"
      },
      "user": {
        "due_date_preset": "Autoset due date",
        "errors": {
          "invalid": "Invalid email",
          "taken": "Email is taken"
        },
        "firstname": "First name",
        "language": "Language",
        "lastname": "Last name",
        "login_mail": "E-mail / Sign in",
        "other_mail": "My other emails",
        "project_view": "After click on project show",
        "time_zone": "Time zone",
        "title": "My settings"
      }
    }
  },
  "number": {
    "currency": {
      "czk": {
        "delimiter": " ",
        "format": "%n %u",
        "precision": 2,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "Kč"
      },
      "eur": {
        "delimiter": ",",
        "format": "%n %u",
        "precision": 2,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "€"
      },
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "$"
      }
    },
    "format": {
      "delimiter": "",
      "precision": 2,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": true
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Billion",
          "million": "Million",
          "quadrillion": "Quadrillion",
          "thousand": "Thousand",
          "trillion": "Trillion",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 2,
        "separator": ",",
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": " "
      }
    }
  },
  "polls": {
    "add_answers": "Add answers",
    "add_choice": "+ Add choices...",
    "add_ok": "Poll was added.",
    "add_poll": "Add new poll",
    "add_question": "Add question",
    "block_name": "Polls",
    "confirm_no": "No",
    "confirm_yes": "Yes, delete",
    "delete_answer": "Delete answer",
    "delete_choice_confirm": "The answer you are about to delete already has a votes. Are you sure?",
    "delete_ok": "Poll was deleted.",
    "delete_poll": "Delete poll",
    "delete_poll_confirm": "Answers and possible votes will be irrevocably deleted. Are you sure?",
    "edit_ok": "Poll was modified.",
    "edit_poll": "Edit poll",
    "empty_question": "An empty question has been entered. Please fill it out.",
    "error": "The poll has not changed. Please try again.",
    "error_conflict": "This task already has a poll.",
    "error_insufficient_choices": "The poll must have at least 2 choices.",
    "no_polls": "There are no polls right now.",
    "no_votes": "No answer",
    "successfully_voted": "Successfully voted."
  },
  "projectOverview": {
    "estimated_hours": "Estimated hours",
    "heading": "Project overview",
    "hide": "hide",
    "hours": "h",
    "planned_due_date": "Planned term",
    "planned_start_date": "Planned start date",
    "remaining_hours": "Remaining hours",
    "show": "show",
    "spent_hours": "Spent hours"
  },
  "projects": {
    "archive": {
      "cant_unarchive": "Cant unarchive",
      "error_cant_unarchive": "You cannot unarchive project, because some of his parent projects are still archived.",
      "exceeds_tariff_modal": {
        "exceeded_tariff": "You have more active paid users than allowed users.",
        "explanation": {
          "few": "In archived project are %{count} users. With them you will have more active users than are allowed.",
          "one": "In archived project is user <b>%{user}</b>. With him there will be more active users that are allowed.",
          "other": "In archived project are %{count} users. With them you will have more active users than are allowed."
        },
        "overlimit_users": "New paid users after unarchivation",
        "tip": "Increase Projektově tariff or archive users in order to enable project unarchivation.",
        "title": "You can't unarchive project"
      },
      "from_project": "From active project",
      "remove": "Pernamently delete project",
      "title": "Projects archive",
      "unarchive": "Unarchive project",
      "unarchiveInfoModal": {
        "h2": "You have to unarchive parent project first",
        "h3": "Steps",
        "note1": "Project",
        "note2": "is subproject of archived project",
        "note3": "In order to keep projects and subprojects structure, you need unarchive all parent projects first.",
        "step1": "Unarchive parent project %{name}",
        "step2": "And then unarchive this project - %{name}"
      }
    },
    "breadcrumbs": {
      "all_projects": "All projects",
      "end_path": {
        "budget_entries": "Budget",
        "contacts": "Contacts list",
        "files": "Files in project",
        "gantt": "Gantt chart",
        "issues": "Tasks list",
        "mindmap": "Mindmap",
        "overview": "Overview",
        "settings": "Settings",
        "tasks_board": "Tasks board",
        "team_settings": "Project members",
        "time_entries": "Spent time list"
      },
      "subprojects": "Subprojects"
    },
    "filters": {
      "buttons": {
        "apply": "Apply",
        "cancel": "Cancel"
      },
      "calendar": "Calendar",
      "customFields": "Custom fields",
      "customFields_icon_title": "Remove",
      "customFields_title": "Set filters",
      "doneRatio": "Progress",
      "doneRatio_g": "is greater than",
      "doneRatio_i": "is",
      "doneRatio_l": "is lower than",
      "favorites": "Favorites",
      "input_placeholder": "enter search text",
      "ismanager": "I'm manager",
      "member": "Team member",
      "not_entered": "Not entered",
      "only_favorites": "only favorites",
      "only_favorites_tree": "favorites with subprojects",
      "only_templates": "only templates",
      "plannedDueDate": "Planned date of project",
      "plannedStartDate": "Planned start date",
      "templates": "Templates",
      "this_month": "This month",
      "this_week": "This week",
      "today": "Today",
      "without_favorites": "without favorites",
      "without_templates": "without templates"
    },
    "index": {
      "add_project": "add project",
      "add_sub_project": "Add subproject",
      "archive_project": "Archive project",
      "columns": {
        "dates": "Period",
        "members": "Team members",
        "name": "Name",
        "parentProject": "Parent project",
        "plannedDueDate": "Planned due",
        "plannedStartDate": "Planned start",
        "progress": "Progress"
      },
      "copy_project": "Copy project",
      "details": {
        "collapse": "Collapse row",
        "expand": "Expand row",
        "incomes": "Budget - incomes"
      },
      "export": "Export projects list",
      "favorite_default": "Projects marked with an asterisk will be on hand",
      "favorite_projects": "Favorite projects",
      "move_project": "Move project",
      "project_settings": "Project settings",
      "remove_project": "Remove project",
      "rename_project": "Rename project",
      "share_project": "Share project",
      "show_all": "all projects",
      "show_archive": "archive",
      "show_filter": "View",
      "show_my": "my projects"
    },
    "modals": {
      "archive_project": {
        "text1": "By archiving the project",
        "text2": "all its sub-projects will also be archived",
        "title": "Are you sure you want to archive the project?"
      },
      "destroy_project": {
        "all_files": "all project files",
        "all_tasks": "all project tasks",
        "members": "list of team members",
        "members_note": "users themselves remain in Projektově, they can be assigned to other projects",
        "save": "delete project",
        "settings": "project settings",
        "spent_time": "time spent on tasks",
        "spent_times": "strávené časy na úkolech",
        "subprojects_note": "Possible subprojects are part of the project and will also be deleted.",
        "title": "Are you sure you want to delete the project?",
        "what_you_lost": "Deleting the project will permanently remove:"
      },
      "move_project": {
        "no_parent": "-- without parent project --",
        "save": "move",
        "title": "Move project"
      },
      "new_project": {
        "choose_template": "Please choose template.",
        "create": "create",
        "empty": "Empty",
        "folder": "Složka",
        "fromTemplate": "From template",
        "name": "Name",
        "parent": "Parent project",
        "parentErrorHint": "Jen administrátor může zadávat hlavní projekty. Jako manažer můžete zakládat jen pod-projekty svých projektů. Prosím vyberte nadřazený projekt.",
        "parentFolder": "Nadřazená složka",
        "plan": {
          "from_end": "from project end",
          "from_start": "from project start",
          "help_left": "Planned start date is known, from that date we will plan project tasks.",
          "help_right": "Start and due dates are computed in order to end project with planned end date.",
          "title": "Plan"
        },
        "planned_due_date": "Planned due",
        "planned_start_date": "Planned start",
        "showParentErrorHint": "Only the administrator can enter root projects. As a manager, you can only create sub-projects for your projects. Please choose parent project.",
        "template": "Template",
        "title": "New project"
      },
      "rename_project": {
        "save": "rename",
        "title": "Rename project"
      }
    },
    "navigation": {
      "labels": {
        "budget_entries": "Budget",
        "contacts": "Contacts",
        "files": "Files",
        "gantt": "Gantt chart",
        "issues": "Tasks list",
        "last_activity": "Last activity",
        "mindmap": "Mindmap",
        "mobile_application": "Mobile application",
        "my_page": "My page",
        "new_issues": "new",
        "overview": "Overview",
        "projects": "Projects",
        "projektove_cz": "Projektově",
        "settings": "Settings",
        "tasks_board": "Tasks board",
        "team_settings": "Team members",
        "time_entries": "Spent time",
        "users_settings": "Users settings"
      }
    },
    "settings": {
      "convert_to_folder": "Převést projekt na složku",
      "convert_to_project": "Převést složku na projekt",
      "description": "Description",
      "folder": "Složka",
      "is_template": "Project template",
      "is_template_inherited": "You cannot change this because parent project is already marked as a project template.",
      "name": "Name",
      "only_admin_can_change": "Only admin can change this settings.",
      "parentId": "Parent project",
      "parentIdFolder": "Nadřazená složka",
      "path": "Path",
      "plannedDueDate": "Planned due date",
      "planned_due_date": "Planned due date",
      "planned_income": "Planned income",
      "planned_start_date": "Planned start",
      "real_incomes": "Real income",
      "reset_trackers": "Restore",
      "shortcut": "Shortcut",
      "shortcut_add": "Add project shortcut. Then you can forward e-mail to project e-mail address and create task from email.",
      "shortcut_alt_add": "Fill in the project shortcut. Then you will see instructions how to send or forward an e-mail to create a task in this project.",
      "shortcut_alt_note1": "Send or forward an email to",
      "shortcut_alt_note2": "to create task in project.",
      "shortcut_alt_note3": "At the beginning of the message, write the shortcut of the project and the colon, for example, for the shortcut 'pricing' it will look like this:",
      "shortcut_alt_subject": "message subject...",
      "shortcut_alt_subject_example": "pricing: please send our offer",
      "shortcut_format": "Only letters without diacritics and digits are allowed. It is also possible to use a dot, an underscore and a dash, but these should not be used at the beginning or at the end.",
      "shortcut_note1": "Forward e-mail to",
      "shortcut_note2": "to create project task.",
      "shortcut_note3": "",
      "tracker": "Front",
      "unsaved_changes": {
        "prompt": "Do you want to proceed to the next page?",
        "title": "You have unchanged project changes."
      },
      "unsortedCustomFields": "Project fields"
    },
    "team_settings": {
      "add_member": "add member",
      "member": {
        "add_member": "Add member into subprojects",
        "add_member_error": "User is already in the project %{project}",
        "add_member_info": "User was successfully added into project %{project}",
        "add_task": "Add Task",
        "change_contact_person": "New issues added from client will be assigned to this team member.",
        "change_hour_cost": "Set hour cost for variable expenses.",
        "change_role": "Change member role in this team",
        "remove": "Remove team member from project",
        "remove_from_subprojects": "Do you wish to remove team member also from subprojects?",
        "show_info": "Show team member details"
      },
      "modals": {
        "add": {
          "add": "add",
          "check_all": "check all",
          "choose_member": "Choose a member",
          "choose_role": "Choose role",
          "no_user_found": "User not found. You need to register him.",
          "search": "search by name of user or additional informations",
          "selected": "Selected: %{count}",
          "uncheck_all": "uncheck all"
        },
        "register": {
          "add": "add to the project",
          "add_additional_info": "add an additional informations",
          "additional_info_1": "additional information 1",
          "additional_info_2": "additional information 2",
          "already_registered": "User is already registered, registration isn't necessary.",
          "already_registered_external": "You are trying to register a user who has already been added as an external user.",
          "choose_role": "Choose role",
          "delete_user": "Remove colleagues from external users first.",
          "help_1": "New user will use e-mail invitation for first login and password setup.",
          "help_2": "After first login, app will send confirmation e-mail with login recapitulation.",
          "hint_email": "E-mail",
          "office365_only": "Only allow logins with a Microsoft account",
          "only_admin": "only available to administrators",
          "register_user": "Register user",
          "set_name": "Please enter user firstname and lastname before registration.",
          "title": "Register new user",
          "user_registered": "User was registered and to them e-mail was sent an invitation."
        }
      },
      "precheck": {
        "cannot_remove": "Cannot remove from project",
        "info": "%{user} has in project tasks, that is author of or assignee.",
        "list_assignee": "As assignee tasks list.",
        "list_author": "As author tasks list.",
        "list_check": "Check closed tasks too.",
        "remove_anyway": "Remove anyway"
      },
      "register_member": "register new user",
      "register_member_external": "register user outside the domain (LDAP)",
      "restricted_registrations": "To register new users, contact your administrator.",
      "show_member_info": "Show member details",
      "title": "Team Members"
    }
  },
  "quickadd": {
    "block_name": "Quick add"
  },
  "search": {
    "button": "Search",
    "header": "Search",
    "inComments": "search in comments too",
    "inProject": "search only in one project",
    "notFound": "Not found."
  },
  "shared": {
    "button_back": "Back",
    "disableEmails": {
      "long": {
        "disable": "Pause e-mail notifications",
        "enable": "Unpause e-mail notifications"
      },
      "short": {
        "disable": "Pause",
        "enable": "Unpause"
      }
    },
    "errorView": {
      "403": "You have insufficient rights to view this page.",
      "404": "We are sorry, but the page you are looking for does not exist.",
      "oops_title": "Lost in tasks?",
      "options": "Options",
      "step1": "Open projects list and open project from there",
      "step1_note": "If project exists and you are team member, you will see it in projects list.",
      "step2": "use search bar on top of this page",
      "step2_note": "Search looks through task names and descriptions. If task exists and you have access to it, you will find it.",
      "step3": "contact your project manager."
    },
    "forced_logout": {
      "explanation": "The recovery of access data (passwords) by your Project Administrator has probably been forced. Therefore, if you are unable to log in again, check your email to see if you have received instructions to change your password.",
      "reason": "Logged out for security reasons.",
      "relog": "Sign in again",
      "title": "You have been logged out"
    },
    "pnotify": {
      "close_title": "Close"
    },
    "tasksReminders": {
      "approaching_deadline": {
        "one": "Approaching deadline",
        "other": "Approaching deadlines"
      },
      "close": "Hide all",
      "hide": "Hide"
    }
  },
  "time": {
    "am": "am",
    "formats": {
      "at": "%B %-d, %Y at %I:%M%P",
      "default": "%B %-d, %Y %I:%M%P",
      "long": "%B %-d, %Y %I:%M%P",
      "short": "%d %b %H:%M",
      "time": "%I:%M%P",
      "time2": "%I:%M%P"
    },
    "moment_formats": {
      "default": "YYYY-MM-DD, h:mm A",
      "only_time": "h:mm A"
    },
    "pm": "pm"
  },
  "time_entries": {
    "hours_short": "h.",
    "last_month": "Last month",
    "modals": {
      "add": {
        "title": "Add spent time"
      },
      "edit": {
        "title": "Modify spent time"
      }
    },
    "next_month": "Next month",
    "next_week": "Next week",
    "reports": {
      "by_projects_members_tasks": "by projects, members and tasks",
      "by_projects_tasks": "by projects and tasks",
      "title": "Reports"
    },
    "this_month": "This month",
    "this_week": "This week",
    "today": "Today",
    "today_and_future": "Today and future",
    "yesterday": "Yesterday"
  },
  "timelog": {
    "compoundReport": {
      "groupByUsers": "Group by users",
      "hideArchivedProjects": "Archived projects",
      "hideNullHours": "Show empty rows",
      "sum": "Sum",
      "title": "Compound report",
      "users": "Users"
    },
    "daterange": {
      "all_time": "all time",
      "current_month": "this month",
      "current_week": "this week",
      "current_year": "this year",
      "custom": "Custom",
      "last_month": "last month",
      "last_week": "last week",
      "last_year": "last year",
      "title": "Date range",
      "today": "today",
      "yesterday": "yesterday"
    }
  },
  "users": {
    "add_alias": "add e-mail address",
    "change_password": {
      "button": "change password",
      "button_first_login": "nastavit heslo",
      "current_password": "Current password",
      "errors": {
        "invalid": "Current password is incorrect. Password was not changed.",
        "missing_current": "Enter current password",
        "often": "Its not possible to change password more than once in a day. Password was not changed."
      },
      "password": "New password",
      "requirements": {
        "char_types": {
          "capital": "capital letter",
          "lowercase": "small letter",
          "number": "number",
          "special": "special char, for example: !, $, #, %"
        },
        "complexity": {
          "all": "at least contains one of these char types",
          "other": "at least %{required} from 4 these char types"
        },
        "length": "minimal length %{length} chars",
        "recent": "cannot be used in past",
        "title": "Due to security requirements, new password has to fullfil following rules"
      },
      "set_password_and_begin": "Now you need to set your password and you can start.",
      "title": "Change password",
      "title_first_login": "Just a small step"
    },
    "me": "me",
    "profile": {
      "add_custom_image": "Upload profile image",
      "change_color": "Change color",
      "crop_image": "Crop image",
      "delete_image": "Delete profile image",
      "drag_to_upload": "Drag and drop image or click on Choose file",
      "profile_image": "Profile image",
      "upload_image": "Choose file"
    },
    "projects": {
      "bulkEditNote": "Change role for checked projects to",
      "change": "Change",
      "checkAll": "Check all",
      "no_memberships": "User is not a member of any project.",
      "only_if_member": "Only projects where the user is a member",
      "project": "Project",
      "role": "Role",
      "title": "Projects of user",
      "title_external": "Tasks of user"
    },
    "settings": {
      "active_users": "Active users",
      "add_note": "You can register new users in project team settings",
      "add_user": "Add user",
      "additional_info": "Additional information",
      "additional_info1": "Additional information 1",
      "additional_info2": "Additional information 2",
      "admin": "Admin",
      "archive": {
        "archived_header": "User was archived",
        "archived_info": "Within archivation is user removed from his assigned tasks. You have %{count} tasks without assignee. Here you can check it.",
        "archived_link": "Tasks without assignee",
        "label": "Archive user %{name}?",
        "note": "User name will be visible in task comments and in spent time entries. Archived user cannot login into app and will never receive any email from app.",
        "note2": "Archived user cannot be author or assignee of any task. Authorship will be changed to you, assigment to \"unassigned\"."
      },
      "archived_users": "Archived users",
      "available_month_hours": "Monthly work",
      "bar": {
        "all_label": "All users",
        "manage": "Manage account",
        "paid_label": "Paid users",
        "title": "Tariff usage"
      },
      "cant_access": "You dont have enough permissions.",
      "delete": {
        "alternative": "Beside delete you can also archive user.",
        "label": "Delete user %{name}?",
        "note": "User time entries will remain, but they will have unknown author. His tasks will be unassigned. In task history his comments will be signed as unknown user.",
        "note_archive_label": "Archive",
        "note_label": "Delete"
      },
      "edit_avatar": "Edit avatar",
      "edit_user": "Edit",
      "errors": {
        "firstname": "Please enter firstname.",
        "lastname": "Please enter lastname.",
        "mail": "Please enter e-mail.",
        "noCheckedProjects": "You need to selecte at least one project.",
        "noSelectedRole": "Choose one project role."
      },
      "firstname": "Name",
      "help_add": "Handy for large teams. As first info use employee role, as second info department.",
      "help_admin": "Admin can access all projects, edit users and company account.",
      "hour": "hrs",
      "hour_cost": "Hour rate",
      "issuesCheck": {
        "assignedTo": "is assignee of",
        "author": "is author of",
        "pass": "is not author either assignee of any open tasks.",
        "progress": "Checking tasks...",
        "tasks": "open tasks."
      },
      "language": "Language",
      "lastname": "Last name",
      "mail": "E-mail",
      "month_hours": "hrs / month",
      "no_external_users": "You have no external watchers",
      "notifications_opt_out": "Default email notifications for new users",
      "projects": "Projects Memberships",
      "register": "Register",
      "register_user": "Register user",
      "reset": {
        "password": "Do you really want to reset password for the user",
        "password_note": "System will send e-mail with link that can be used to create new password.",
        "reset_password_label": "Reset password"
      },
      "return_to_my_page": "Return to My page.",
      "settings": "Settings",
      "success": "Successfully registered, login info were sent to the user.",
      "tasks": "Tasks",
      "title": "User management",
      "title_external": "External users",
      "unwatch_tasks": {
        "confirm_question": "Remove external watcher <b>%{name}</b> from all tasks?"
      }
    }
  },
  "wizard": {
    "Examples": "Examples",
    "add_projects": "add projects",
    "add_template": "add templates",
    "blank_project": "You need to enter parent project.",
    "blank_template": "You need to enter parent template.",
    "create_templates": "create templates",
    "e-mail": "e-mail",
    "examples": "Příklady",
    "first_name": "Name",
    "go_to_projects": "go to projects",
    "invalid_mail": "User %{name} has an invalid email.",
    "invalid_role": "User %{name} does not have a role.",
    "last_name": "Surname",
    "non_unique_emails": "Users cannot use the same email.",
    "one_project": "You must create at least one project.",
    "one_template": "You must create at least one template.",
    "project": "Enter project name",
    "register_users": "register users",
    "role": "Select a role",
    "skip": "skip",
    "start": "start",
    "step1": "welcome",
    "step2": "add projects",
    "step3": "user registration",
    "subproject": "Enter subproject name",
    "subtemplate": "Enter subproject template name",
    "template": "Enter template name",
    "template_text1": "Templates created.",
    "template_text2": "You will see a cup of coffee icon near template name in project list. Now just click on it and start adding tasks.",
    "template_text3": "Tip: Try open your template and than in the left menu click to",
    "template_text4": "and create tasks through the mindmap.",
    "template_textHelp": "Templates are an excellent way to plan a project. In the template, you can assign tasks and set dates without notifiyng your team. In this way, you will prepare the base of the project and later you can easily create a real project from the template.",
    "template_title1": "Add project templates",
    "template_title2": "Done",
    "text1_step2": "Projects are the folders into which you enter tasks. Here you can create the structure of the first projects and sub-projects. For inspiration, we've put some examples on the right, just enter at least one project.",
    "text1_step3": "This step is voluntary, but with the team you will complete the project faster.",
    "text1_step3b": "You will be able to give tasks to your colleagues soon and Projektově will guard its deadlines.",
    "text1_step4": "Congratulations, Projektově is ready for you.",
    "text2_step3": "Manager - has unlimited access, can do everything.",
    "text2_step4": "Now just click on any project to add tasks.",
    "text3_step3": "Team member - works on tasks, can create tasks for himself and others.",
    "text3_step4": "Or do you want",
    "text4_step3": "Customer - Watcher role.",
    "text4_step4": "create a project template?",
    "text5_step3": "Externist - Just like Team member, but he see only his tasks. For example: when there are 8 tasks in project, he only sees his own 1.",
    "text6_step3": "More here:",
    "text7_step3": "view help for roles",
    "text_step1": "How to easily drive your projects? In the next 2 steps we will begin together.",
    "title_step2": "What projects do you manage?",
    "title_step3": "Add your colleagues",
    "title_step4": "Done",
    "welcome_text": "Welcome to Projektově"
  }
});
I18n.translations["hr"] = I18n.extend((I18n.translations["hr"] || {}), {
  "activities": {
    "action": {
      "close": "Zatvorio zadatak",
      "comment": "Komentirao",
      "create": "Stvorio zadatak",
      "edit": "Uredio zadatak"
    },
    "assigned_to": "Dodijeljeno",
    "author": "Autor",
    "change": {
      "all": "sve",
      "assigned_to": "dodijeljeno",
      "author": "autor",
      "close": "zatvoriti zadatak",
      "create": "stvaranje zadatka",
      "description": "opis",
      "done_ratio": "postotak završenosti",
      "due_date": "rok zadatka",
      "estimated_hours": "procijenjeno trajanje",
      "me": "ja",
      "note": "dodaj komentar",
      "others": "ostali",
      "priority": "prioritet",
      "project": "premjesti u drugi projekt",
      "start_date": "početak zadatka",
      "status": "status",
      "tracker": "red"
    },
    "changes": "Promjene",
    "complete_list": "Potpuni popis",
    "no_activity": "Trenutno nema aktivnosti.",
    "projects": "Projekti"
  },
  "admin": {
    "billing": {
      "cancel": "Otkaži promjene",
      "change_info": "Promijeni podatke",
      "change_save": "Pohrani promjene",
      "change_tariff": "Pohrani promjene i odaberi paket",
      "city": "Grad",
      "company": "Tvrtka",
      "contact_info": "Kontaktni podaci",
      "country": "Država",
      "country_cs": "Češka Republika",
      "country_sk": "Slovačka Republika",
      "dic_cs": "VAT ID porezni broj",
      "dic_sk": "VAT ID porezni broj",
      "email": "E-mail",
      "firstname": "Ime",
      "ic": "OIB (Osobni identifikacijski broj)",
      "invoice_email": "E-mail financije/račune",
      "invoice_info": "Informacije o računu",
      "lastname": "Prezime",
      "phone": "Telefon",
      "street": "Ulica",
      "zip": "Poštanski broj"
    },
    "client": {
      "billing": {
        "cancel": "zrušit změny",
        "change_info": "Změnit údaje",
        "change_save": "Uložit změny",
        "change_tariff": "Uložit a vybrat balíček",
        "city": "Město",
        "company": "Společnost",
        "contact_info": "Kontaktní údaje",
        "country": "Stát",
        "country_cs": "Česká republika",
        "country_sk": "Slovenská republika",
        "dic_cs": "DIČ",
        "dic_sk": "IČ DPH",
        "email": "E-mail",
        "firstname": "Jméno",
        "ic": "IČO",
        "invoice_email": "Fakturační email",
        "invoice_info": "Fakturační údaje",
        "lastname": "Příjmení",
        "phone": "Telefon",
        "street": "Ulice",
        "zip": "PSČ"
      },
      "messages": {
        "deleted": "Pretplata je otkazana. Projektová aplikacija će biti zaustavljena i nakon isteka obračunskog razdoblja bit će izbrisana.",
        "deleted_trial": "Probna verzija je završena. Uskoro će se račun sa svim podacima potpuno izbrisati.",
        "new": "Paket uspješno naručen. Projektová aplikacija je sada potpuno funkcionalna.",
        "updated": "Paket uspješno izmijenjen."
      },
      "tariff": {
        "cancel": "Otkazati pretplatu",
        "cancel_title": "Jeste li sigurni da želite otkazati pretplatu na Projektově?",
        "cancel_trial": "Završi probnu verziju",
        "cancel_trial_title": "Jeste li sigurni da želite završiti probnu verziju?",
        "change": "Promijeni paket",
        "current": "Trenutni paket",
        "custom": "Prilagođeno",
        "custom_tariff_notice": "Trebate promijeniti paket ili prilagoditi razdoblje fakturiranja? Kontaktirajte našu korisničku podršku.",
        "details": "Detalji paketa",
        "individual_20": "Individualni 20",
        "individual_30": "Individualni 30",
        "individual_40": "Individuanil 40",
        "individual_50": "Individualni 50",
        "onpremise": "On-premise",
        "order": "Naruči paket",
        "payment_info": "Sustav nakon narudžbe ili promjene paketa redovito šalje uplate i nakon plaćanja šalje porezni račun.",
        "payment_invoice": "Fakturiranje",
        "payment_method": "Metode plačanja",
        "payment_type": "Način plaćanja",
        "prodva": "Za dvoje",
        "promo": "Promo",
        "standard": "Standard",
        "start": "Početak",
        "trial": "Probna verzija",
        "users_count": "Broj korisnika u paketu",
        "users_current": "Trenutni broj korisnika",
        "users_manage": "Upravljanje korisnicima"
      },
      "tariff_dialog": {
        "affirmation_accept": "Souhlasím s",
        "affirmation_terms": "obchodními podmínkami",
        "annual": "roční (sleva 10 %)",
        "cancel": "zrušit změny",
        "choose": "Balíček k objednání",
        "discount": "Sleva",
        "monthly": "měsíční",
        "price": "Cena",
        "price_final": "Konečná cena",
        "price_monthly": " / měsíc",
        "quarterly": "čtvrtletní",
        "semi_annual": "půlroční",
        "tax_notice": "Uvedené ceny nezahrnují DPH, jsme plátci DPH.",
        "users": "Uživatelů",
        "users_total": "Celkový počet uživatelů"
      },
      "title": "Račun usluge Projektově"
    },
    "custom_fields": {
      "cancel_button": "Otkaži",
      "check_warning": "Kada aktivirate ovu opciju, nećete je moći isključiti.",
      "confirm_delete": "Opravdu si přejete odebrat tuto frontu?",
      "confirm_delete_alternative": "Jeste li sigurni da želite promijeniti red?",
      "confirm_delete_tracker": "Jeste li sigurni da želite izbrisati ovaj red?",
      "custom_field_header": "Upravljanje prilagođenim poljima",
      "delete_button": "Obriši",
      "delete_field_confirm": "Jeste li sigurni da želite izbrisati korisničko polje?",
      "delete_field_label": "Brisanjem će se izbrisati i sve unesene vrijednosti.",
      "delete_field_success": "Korisničko polje uspješno obrisano.",
      "delete_tracker": "Brisanjem reda, sve unesene vrijednosti u tom redu bit će nepovratno izbrisane.",
      "delete_tracker_alternative": "Odabirom reda, podaci iz drugih redova bit će nepovratno izbrisani. Polje je bilo vidljivo u svim redovima, a sada će biti vidljivo samo u odabranim redovima. Uneseni podaci za prilagođeno polje ostaju u odabranim redovima, dok će u drugim biti izbrisani.",
      "edit_button": "Uredi",
      "edit_field_success": "Korisničko polje uspješno izmijenjeno.",
      "enable_more_values": "Omogući odabir više vrijednosti",
      "field_add": "Dodaj dodatno polje",
      "label_field": "Polje",
      "label_field_visibility": "Vidljivost polja",
      "label_label": "Oznaka",
      "label_show_in_projectslist": "Popis projekata",
      "label_sorting": "Sortiranje",
      "label_tracker": "Red",
      "label_type": "Tip",
      "maximum_number": "Dostignut je maksimalni broj prilagođenih polja.",
      "move_begin": "Pomakni na početak",
      "move_end": "Pomakni na kraj",
      "move_higher": "Pomakni više",
      "move_lower": "Pomakni niže",
      "name_and_tracker_error": "Ime i red polja su obavezni.",
      "name_and_type_error": "Ime i tip polja su obavezni.",
      "name_error": "Ime polja je obavezno.",
      "name_type_tracker_error": "Ime polja, red i tip polja su obavezni.",
      "new_line": "Svaka vrijednost u novom retku.",
      "no_possible_values": "Odabrali ste tip popisa, morate navesti moguće vrijednosti.",
      "possible_values": "Moguće vrijednosti",
      "possible_values_changed": "Moguće vrijednosti su uspješno izmijenjene.",
      "possible_values_obligatory": "Moguće vrijednosti su obavezne.",
      "projects_label": "Projekti",
      "same_name_error": "Ime polja već postoji, odaberite drugo ime.",
      "save_button": "Pohrani",
      "tasks_label": "Zadatci",
      "tracker_and_type_error": "Red i tip polja su obavezni.",
      "tracker_error": "Red polja je obavezan.",
      "type_error": "Tip polja je obavezan.",
      "values_change": "Izmijeni vrijednosti popisa",
      "visible_for_all": "Vidljivo za sve"
    },
    "tariff_dialog": {
      "affirmation_accept": "Suglasan/na sam s",
      "affirmation_terms": "uvjetima poslovanja",
      "annual": "godišnje (popust 10 %)",
      "cancel": "poništiti promjene",
      "choose": "Odaberite paket",
      "discount": "Popust",
      "monthly": "mjesečno",
      "price": "Cijena",
      "price_final": "Konačna cijena",
      "price_monthly": " / mjesečno",
      "quarterly": "tromjesečno",
      "semi_annual": "polugodišnje",
      "support_contact": "Kontaktirajte podršku",
      "support_users": "Trebate više korisnika?",
      "tax_notice": "Navedene cijene ne uključuju PDV, mi smo PDV obveznici.",
      "users_total": "Ukupan broj korisnika"
    }
  },
  "budget_entries": {
    "expense_time_table": {
      "all": "svi",
      "filter": "Filter",
      "show": "Prikazati",
      "subtasks2": "sakriti podzadatke od 2. razine",
      "sum": "Ukupno",
      "task": "Zadatak",
      "title": "Vremenski raspored stvarnih troškova",
      "types": {
        "planned": "plánované náklady",
        "real": "skutečné náklady"
      },
      "warning": "Upozorenje, ovo je jednostan zbroj navedenih redaka. Ne uzima u obzir odnose između zadataka i podzadataka. Ako su u filtru i zadaci i podzadaci, zbroj neće biti ispravan.",
      "without_subtasks": "samo glavni zadaci"
    },
    "modal": {
      "category": "Kategorija",
      "comment": "Napomena",
      "date": "Datum",
      "not_set": "Nije postavljeno",
      "spent_hours": "Potrošeni sati",
      "summary": "Sažetak",
      "task": "Zadatak",
      "value": "Iznos",
      "value_vat": "s PDV-om",
      "value_without_vat": "bez PDV-a",
      "variable_costs": "Varijabilni troškovi",
      "vat": "Porez"
    },
    "project_bilances": {
      "cash_flow": "Cash flow",
      "cash_flow_label": "stvarni prihod - stvarni troškovi",
      "income_diff": "Zbývá vyfakturovat",
      "planned_expenses": "Planirani troškovi",
      "project": "Projekt",
      "real_diff": "Budući cash flow",
      "real_income": "Stvarni prihod",
      "title": "Bilance projekata"
    },
    "report": {
      "add": "Dodaj stavku (zadatak)\"",
      "addNew": "Enterom stvorite novu stavku",
      "add_expense": "Dodaj trošak",
      "add_income": "Dodaj prihod",
      "added_manually": "Planirani trošak je dodan ručno",
      "added_recalculated": "Planirani trošak je izračunat iz djelomičnih troškova ili je postavljen rekalculacijom iz procijenjenih vremena.'",
      "czech_currency": "Euro",
      "delete_budget_line": "Ukloni stavke iz proračuna",
      "diff": "Razlika",
      "expense": "Trošak",
      "expenseHours": "Trošak - varijabilni",
      "expenses": "Troškovi",
      "expenses_list": "Popis troškova:",
      "hidden_planned_expenses": "Sakriveni planirani troškovi",
      "hidden_real_expenses": "Sakriveni stvarni troškovi",
      "hide": "Sakrij ovu stavku u proračunu",
      "income": "Prihod",
      "incomes_list": "Popis prihoda:",
      "list_all_expenses": "Popis svih troškova",
      "notify_higher_income": "Unesite veći prihod",
      "notify_higher_income_text": "Ukupni planirani prihod mora premašiti planirane prihode u podprojektima koji su trenutno aktivni",
      "plan": "Plan",
      "planned_income": "Prihod (prema ugovoru)",
      "planned_profit": "Planirani dobitak",
      "project_budget": "Proračun projekta",
      "real_diff_label": "U projektima će biti fakturirani %{incomes}, preostali troškovi su %{expenses}. Budući novčani tok iznosi %{diff} kako bi se ispunio plan.",
      "real_expenses": "Stvarni troškovi",
      "reality": "Stvarnost",
      "spent_time": "Utrošeno vrijeme izvan zadatka",
      "sum": "Saldo",
      "sum_reserve": "Saldo / rezerva",
      "toggle": "Prekidač",
      "total": "Ukupno",
      "total_real_expenses": "Ukupni stvarni troškovi uključujući naknade",
      "withVat": "s PDV-om",
      "withoutVat": "bez PDV-a"
    },
    "submenu": {
      "prefill": "Ispuni planirane troškove unaprijed",
      "prefillText": "Želite li unaprijed ispuniti planirane troškove izračunom procijenjenog vremena pomnoženog s cijenom sata izvođača?",
      "prefillWarning": "Upozorenje, odabirom \"Da\" prepisat ćete plan.",
      "showAllIssues": "Prikazati samo glavne zadatke u proračunu",
      "showOnlyRootIssues": "Zobraziť v rozpočte len hlavné úlohy",
      "sorting": {
        "default": "zadano",
        "dueDate": "prema datumima završetka",
        "heading": "Sortiranje",
        "label": "Redoslijed stavki u proračunu",
        "startDate": "prema početnim datumima",
        "subject": "prema nazivu"
      }
    }
  },
  "dashboard": {
    "add_blocks": {
      "add": "Dodaj",
      "add_error": "Dodavanje bloka nije uspjelo. Pokušajte ponovno.",
      "choose": "Odaberite što dodati",
      "labels": {
        "activities": "Posljednja aktivnost",
        "allprojects": "Svi projekti",
        "estimatedhoursbyprojects": "Grafikon opterećenja prema projektima",
        "estimatedhoursbyusers": "Grafikon opterećenja tima",
        "estimatedhourstable": "Tablica opterećenja tima",
        "favouriteprojects": "Omiljeni projekti",
        "iframe": "Prilagođeni blok",
        "iframebottom": "Prilagođeni donji blok",
        "issuesassignedto": "Dodijeljeni zadaci",
        "issuesreportedbyme": "Zadaci koje sam delegirao",
        "issueswatched": "Praćeni zadaci",
        "polls": "Glasovanje",
        "shouldbeinprogress": "Zadaci koji trebaju biti u tijeku",
        "solvedclosedtasks": "Vyřešené a uzavřené úkoly",
        "timelog": "Moje potrošeno vrijeme"
      },
      "reset": "obriši sve",
      "reset_error": "Resetiranje blokova nije uspjelo. Pokušajte ponovno.",
      "title_here": "Prilagodi ovu stranicu"
    },
    "blockEstimatedHoursByProjects": {
      "defaultView": "Pokud není nic vybráno, použije se výchozí zobrazení - prvních 30 projektů.",
      "estimated_hours": "Opterećenje prema projektima",
      "graphTitle": "Pregled unatrag 4 mjeseca",
      "no_due_date": "Bez roka",
      "past": "U prošlosti",
      "tableTitle": "Sati izvan grafikona"
    },
    "blockEstimatedHoursByUsers": {
      "defaultView": "Ako ništa nije odabrano, koristi se zadani prikaz - prvih 30 projekata.",
      "estimated_hours": "Opterećenje korisnika.",
      "graphTitle": "Pregled unatrag 4 mjeseca",
      "no_due_date": "Bez roka",
      "past": "U prošlosti",
      "tableTitle": "Sati izvan grafikona"
    },
    "block_activities": {
      "title": "Zadnja aktivnost"
    },
    "block_all_projects": {
      "all_projects": "Svi projekti",
      "empty": "Ovdje nemate nijedan projekt."
    },
    "block_assigned_to": {
      "assigned_to": "Dodijeljeno %{name}",
      "complete_list": "Kompletan popis (%{size})\"",
      "empty": "Nemate nijedan zadatak ovdje.",
      "my_tasks": "Moji zadaci"
    },
    "block_author_me_tasks": {
      "author_me_tasks": "Zadaci koje sam delegirao",
      "empty": "Ovdje nemate nijedan zadatak.",
      "show": "Prikaz"
    },
    "block_favorite_projects": {
      "empty": "Nemate omiljenih projekata. Označite ih klikom na",
      "empty_end": "u popisu projekata.",
      "favorite_projects": "Omiljeni projekti"
    },
    "block_my_tasks_today": {
      "5days": "Zakašnjenje do 5 dana",
      "approaching": "Približava se",
      "approaching_dueDate": "Rok",
      "approaching_startDate": "Početak",
      "before_yesterday": "Prekjučer",
      "my_day": "Moj dan",
      "no_tasks": "Nemate nijedan zadatak ovdje.",
      "to_resolved": "U kašnjenju",
      "today": "Rok danas",
      "yesterday": "Jučer"
    },
    "block_my_time_entries": {
      "add": "Dodaj unos",
      "allTimes": "Svi unosi",
      "comment": "Komentar",
      "complete": "Kompletan popis",
      "empty": "U posljednjih 7 dana nemate nijedan zabilježeni sat.",
      "hours": "Sati",
      "project": "Projekt",
      "task": "Zadatak",
      "title": "Moj utrošeni vremenski unos"
    },
    "block_polls": {
      "choice": "Izbor",
      "due_date": "Rok",
      "question": "Pitanje",
      "task": "Zadatak"
    },
    "block_solved_closed_tasks": {
      "collapse": "Sažmi",
      "expand": "Proširi",
      "title": "Riješeni i zatvoreni zadaci"
    },
    "block_watched_tasks": {
      "allAssigned": "uključujući zadatke gdje sam rješitelj",
      "all_watched_tasks_with": "Svi praćeni zadaci (%{size})",
      "all_watched_tasks_without": "Svi praćeni zadaci bez zadataka gdje sam rješitelj (%{size})",
      "assignedWithoutMe": "bez zadataka gdje sam rješitelj",
      "empty": "Nemate nijedan zadatak ovdje.",
      "show": "Prikaz",
      "watched_tasks": "Praćeni zadaci"
    },
    "complete_list": "Potpuni popis",
    "planned_estimated_hours": "Planirani sati",
    "spent_hours": "Utrošeni sati"
  },
  "date": {
    "abbr_day_names": [
      "Ne",
      "Po",
      "Ut",
      "Sr",
      "Če",
      "Pe",
      "Su"
    ],
    "abbr_month_names": [
      null,
      "Sij",
      "Vel",
      "Ožu",
      "Tra",
      "Svib",
      "Lip",
      "Srp",
      "Kol",
      "Ruj",
      "Lis",
      "Stu",
      "Pro"
    ],
    "day_names": [
      "Nedjelja",
      "Ponedeljak",
      "Utorak",
      "Srijeda",
      "Četvrtak",
      "Petak",
      "Subota"
    ],
    "formats": {
      "default": "%-d. %-m. %Y",
      "long": "%B %d, %Y",
      "short": "%b %d"
    },
    "moment_formats": {
      "default": "D.M.YYYY",
      "from": "[od] D.M.YYYY",
      "short": "D.M.",
      "to": "[do] D.M.YYYY"
    },
    "month_names": [
      null,
      "Siječanj",
      "Veljača",
      "Ožujak",
      "Travanj",
      "Svibanj",
      "Lipanj",
      "Srpanj",
      "Kolovoz",
      "Rujan",
      "Listopad",
      "Studeni",
      "Prosinac"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_time": {
      "day_before_yesterday_at": "prekjučer u %{time}",
      "today_at": "danas u %{time}",
      "yesterday_at": "jučer u %{time}"
    },
    "distance_in_words": {
      "about_x_hours": {
        "few": "oko %{count} sata",
        "many": "oko %{count} sati",
        "one": "oko 1 sat",
        "other": "oko %{count} sati"
      },
      "about_x_months": {
        "few": "oko %{count} mjeseca",
        "many": "oko %{count} mjeseci",
        "one": "oko 1 mjesec",
        "other": "oko %{count} mjeseci"
      },
      "about_x_years": {
        "few": "oko %{count} godine",
        "many": "oko %{count} godina",
        "one": "oko 1 godine",
        "other": "oko %{count} godina"
      },
      "almost_x_years": {
        "few": "skoro %{count} godine",
        "many": "skoro %{count} godina",
        "one": "skoro 1 godina",
        "other": "skoro %{count} godina"
      },
      "half_a_minute": "pola minute",
      "less_than_x_minutes": {
        "few": "manje od %{count} minute",
        "many": "manje od %{count} minuta",
        "one": "manje od 1 minute",
        "other": "manje od %{count} minuta"
      },
      "less_than_x_seconds": {
        "few": "manje od %{count} sekunde",
        "many": "manje od %{count} sekundi",
        "one": "manje od 1 sekunde",
        "other": "manje od %{count} sekundi"
      },
      "over_x_years": {
        "few": "preko %{count} godine",
        "many": "preko %{count} godina",
        "one": "preko 1 godine",
        "other": "preko %{count} godina"
      },
      "x_days": {
        "few": "%{count} dana",
        "many": "%{count} dana",
        "one": "1 dan",
        "other": "%{count} dana"
      },
      "x_hours": {
        "few": "%{count} sata",
        "many": "%{count} sati",
        "one": "1 sat",
        "other": "%{count} sati"
      },
      "x_minutes": {
        "few": "%{count} minute",
        "many": "%{count} minuta",
        "one": "1 minuta",
        "other": "%{count} minuta"
      },
      "x_months": {
        "few": "%{count} mjeseca",
        "many": "%{count} mjeseci",
        "one": "1 mjesec",
        "other": "%{count} mjeseci"
      },
      "x_seconds": {
        "few": "%{count} sekunde",
        "many": "%{count} sekundi",
        "one": "1 sekunda",
        "other": "%{count} sekundi"
      },
      "x_weeks": {
        "few": "%{count} tjedana",
        "many": "${count} tjedana",
        "one": "1 tjedan",
        "other": "%{count} tjedana"
      }
    },
    "prompts": {
      "day": "Dan",
      "hour": "Sat",
      "minute": "Minuta",
      "month": "Mjesec",
      "second": "Sekunde",
      "year": "Godina"
    }
  },
  "default": {
    "admin_firstname": "Uzorak",
    "admin_lastname": "Projektově",
    "columns": {
      "assigned_to": "Rješitelj",
      "author": "Autor",
      "due_date": "Rok",
      "estimated_hours": "Procjena",
      "priority": "Prioritet",
      "project": "Projekt",
      "start_date": "Početak",
      "status": "Stav",
      "subject": "Zadatak",
      "total_spent_hours": "Potrošeno"
    },
    "user": {
      "change_color": "Primjeni boju",
      "email": "e-mail",
      "firstname": "ime",
      "language": "jezik",
      "lastname": "prezime"
    }
  },
  "estimated_hours_table": {
    "all_time": "Ukupno",
    "block_name": "Procijenjeni sati",
    "today": "Danas",
    "tomorrow": "Sutra"
  },
  "files": {
    "addFile": "přidat soubor",
    "addFileToTask": "Zvolte úkol",
    "author": "Autor",
    "createdAt": "Datum",
    "download": "Stáhnout",
    "filename": "Soubor",
    "issue": "Ukol",
    "project": "Projekt",
    "projectFiles": "Soubory v projektu",
    "tasksFiles": "Soubory u úkolů"
  },
  "gantt": {
    "add_name": "Unesite naziv novog zadatka",
    "add_new_task": "Dodaj zadatak",
    "add_next_task_header": "Dodaj sljedeći zadatak",
    "add_previous_task_header": "Dodaj prethodni zadatak",
    "add_task": "Dodaj zadatak",
    "adding_subtask_to_task": "Dodavanje podzadatka zadatku",
    "adding_task_to_project": "Dodavanje zadatka projektu",
    "cancel": "Otkaži",
    "cancel_changes": "Poništi promjene",
    "cant_export_grouped_by_assigned_to": "Grupirani prikaz prema primateljima zadatka ne može se izvesti.",
    "change_parent_task_error": "Promjena koju planirate bi dovela do promjene datuma omotničkog zadatka. Ova funkcija je dostupna samo za voditelja.",
    "color_by": {
      "default": "zadano",
      "label": "Boja",
      "projects": "po projektima",
      "templateTask": "prema zadatku iz predloška"
    },
    "confirm_link_deleting": "će biti uklonjena.",
    "conflicts_warning": {
      "note": "Klikom na zadatak možete otvoriti karticu i provjeriti promjene. Da biste postavili datume početka i završetka zadataka iz Ganttovog dijagrama, Pohranite dijagram.",
      "task": {
        "few": "Zadataka",
        "many": "Zadataka",
        "one": "Zadatak",
        "other": "Zadataka"
      },
      "was_changed": {
        "few": ", koje uređujete, također su promijenjeni na poslužitelju.",
        "many": ", koje uređujete, također su promijenjeni na poslužitelju.",
        "one": ", koji uređujete, također je promijenjen na poslužitelju.",
        "other": ", koje uređujete, također su promijenjeni na poslužitelju."
      }
    },
    "date_range": {
      "all_time": "Sve",
      "current_month": "Ovaj mjesec",
      "current_week": "Ovaj tjedan",
      "current_year": "Ova godina",
      "custom": "Prilagođeno",
      "next_month": "Sljedeći mjesec",
      "next_week": "Sljedeći tjedan",
      "next_year": "Sljedeća godina",
      "three_months": "3 mjeseca",
      "today": "Danas"
    },
    "filter_tasks": "Filtriraj zadatke",
    "filter_user": "Filtriraj prema izvršitelju",
    "fullscreen": "Cijeli ekran",
    "group_by_assignee": "Grupiraj prema izvršitelju",
    "group_by_projects": "Grupiraj po projektima",
    "heading": "Ganttov dijagram",
    "label_days": "dana",
    "label_delete_relation": "Jeste li sigurni da želite obrisati vezu usmjerenu iz",
    "label_done": "Završeno",
    "label_duration": "Trajanje",
    "label_end_after_start": "Rok mora biti veći od datuma početka.",
    "label_export": "Izvoz u",
    "label_from": "Od",
    "label_next": "Sljedeći",
    "label_no": "Ne",
    "label_previous": "Prethodni",
    "label_project": "Projekt",
    "label_start_before_end": "Datum početka mora biti manji od roka.",
    "label_to": "Do",
    "label_to_small": "prema",
    "label_yes": "Da",
    "link": "Veza",
    "new_task_added": "Novi zadatak uspješno dodan.",
    "new_task_not_added": "Novi zadatak nije mogao biti dodan.",
    "next_tasks_header": "Postojeći sljedeći zadaci",
    "no_next_tasks": "Nema postojećih sljedećih zadataka.",
    "no_previous_tasks": "Nema postojećih prethodnih zadataka.",
    "other_exports": "Ostali formati izvoza",
    "planned_dueDate": "Planirani kraj",
    "planned_startDate": "Planirani početak",
    "previous_tasks_header": "Postojeći prethodni zadaci",
    "readonly_note": "Verzija za čitanje. Da biste pristupili aktivnoj verziji, morate biti voditelj u svim prikazanim projektima.",
    "save_changes": "Pohrani promjene",
    "save_first": "Prvo Pohraniti ili odbaciti promjene.",
    "section_time": "Datum početka i trajanje zadatka",
    "show": {
      "all": "sve",
      "label": "Prikazati",
      "projects": "samo projekti",
      "subtasks2": "sakriti podzadatke od 2. razine",
      "tasks": "projekti i glavni zadaci"
    },
    "show_closed_tasks": "Prikaz zatvorenih",
    "sort_tasks": "Sortiraj zadatke",
    "task_duration": "Trajanje",
    "task_end": "Rok",
    "task_name": "Naziv zadatka",
    "task_order": "Automatsko sortiranje zadataka tijekom uređivanja",
    "task_start": "Početak",
    "time_disable_button": "Ukloni vremena",
    "time_enable_button": "Raspored",
    "type": {
      "envelope": "Omotnica",
      "label": "Tip",
      "task": "Zadatak"
    },
    "week": "Tjedan",
    "zoom_in": "Približi",
    "zoom_out": "Udalji"
  },
  "header": {
    "all_projects": "Svi projekti",
    "clear": "Izbriši popis. Nakon toga, samo posjetite druge dijelove Projektově, oni će se automatski dodati.",
    "menu": {
      "my_page": "Moja stranica",
      "my_tasks": "Moji zadaci",
      "new_member": "Novi kontakt",
      "new_task": "Novi zadatak",
      "open_tasks": "Otvoreni zadaci",
      "projects": "Projekti",
      "tasks_plural": "Zadaci"
    },
    "projects": {
      "current_project": "Popis zadataka u projektu"
    },
    "search": {
      "history": "Nedavne pretrage",
      "in_project": "samo u trenutačnom projektu",
      "placeholder": "Pretraživanje u zadacima"
    },
    "switch_to": "Promijeni na:",
    "title": "Pro přehledné řízení firmy, projektů a úkolů.",
    "user_menu": {
      "calendar": "Kalendar",
      "client_profil": "Korisnički račun usluge Projektově",
      "help": "Pomoć",
      "last_activity": "Posljednja aktivnost",
      "logout": "Odjava",
      "mobile_application": "Mobilna aplikacija",
      "my_settings": "Moje postavke",
      "test_v2": "Isprobaj Projektově 2.0",
      "this_week": "Ovaj tjedan",
      "timetracker": "Stoperica",
      "users_settings": "Upravljanje korisnicima"
    }
  },
  "issues": {
    "bulk_edit": {
      "all_issues": "sve otvorene zadatke",
      "all_issues_note": "iz projekta uključujući potprojekte",
      "assigned_to": "zadatci dodijeljeni:"
    },
    "delete_modal": {
      "button_no": "ne",
      "button_yes": "da, izbriši",
      "label": "Želite li zaista izbrisati zadatak?",
      "label_subtask": "Želite li zaista izbrisati podzadatak?",
      "note": "Ovim ćete izbrisati zadatak sa svim njegovim podzadacima."
    },
    "filters": {
      "assignedToId": "Rješavatelj",
      "authorId": "Autor",
      "buttons": {
        "apply": "Primijeni",
        "cancel": "Odustani"
      },
      "calendar": "Kalendar",
      "closedAt": "Zatvoreno",
      "createdAt": "Stvoren",
      "customFields": "Prilagođena polja",
      "customFields_icon_title": "Ukloniti",
      "customFields_title": "Prilagođeni filtri",
      "dueDate": "Rok",
      "fromFavoriteProjects": "Iz omiljenih projekata",
      "input_placeholder": "unesite tekst za pretraživanje",
      "lastActivityAt": "Zadnja aktivnost",
      "noActiveTag": "Tento filter v danom pohľade nie je možné použiť, preto nie je aktívny.",
      "no_assignedToId": "Nijedan korisnik nije pronađen",
      "no_authorId": "Nijedan korisnik nije pronađen",
      "no_one_assigned": "nedodijeljen",
      "no_projectId": "Nijedan projekt nije pronađen",
      "not_entered": "Nije uneseno",
      "others": "Ostali",
      "parentId": "Bez podzadataka",
      "priorityId": "Prioritet",
      "projectId": "Projekt",
      "repetitive_false": "Bez ponavljajućih zadataka",
      "repetitive_true": "Ponavljajuće",
      "startDate": "Početak",
      "statusId": "Status",
      "templates": "Uključujući zadatke iz predložaka",
      "this_2_weeks_and_older": "Ova 2 tjedna i starije",
      "this_4_weeks_and_older": "Ova 4 tjedna i starije",
      "this_month": "Ovaj mjesec",
      "this_week": "Ovaj tjedan",
      "this_week_and_older": "Ovog tjedna i starije",
      "today": "Danas",
      "today_and_older": "Danas i starije",
      "trackerId": "Fronta",
      "trakerId": "Red"
    },
    "index": {
      "add_first_task": "Unesite prvi zadatak.",
      "add_remove_tasks_order": "Dodaj / ukloni sljedeću razinu sortiranja.",
      "add_tasks_from_template": "Dodaj zadatke iz šablona",
      "bulk_edit": "Uređivanje na veliko.",
      "bulk_modal": {
        "assigned_to": "Zadaci dodijeljeni",
        "edit": "urediti",
        "edit_all": "prilagoditi sve otvorene zadatke."
      },
      "cant_be_closed": {
        "comment_wip": "Karticu zadatka trenutačno nije moguće zatvoriti jer imate nedovršen komentar uz zadatak.",
        "description_wip": "Karticu zadatka trenutačno nije moguće zatvoriti jer imate nedovršen opis zadatka.",
        "prompt": "Želite li prijeći na sljedeću stranicu?",
        "title": "Karticu zadatka nije moguće zatvoriti."
      },
      "choose_calendar_list": "Izaberite pregled koji želite povezati s kalendarom.<br> Zatim umetnite adresu odabranog pregleda u svoj kalendar.",
      "default_order": "Postavi zadanu vrstu sortiranja.",
      "delete": "Izbrisati zadatak %{subject}",
      "detail": "Detalj",
      "exports_modal": {
        "title": "Izvoz opisa zadataka",
        "with_descriptions": "uključujući opise zadataka",
        "without_descriptions": "bez opisa zadataka"
      },
      "grouped": {
        "group_assignee": "prema izvršitelju",
        "group_author": "po autoru",
        "group_by": "Sastaviti u grupe",
        "group_dueDate": "u skladu s rokom",
        "group_lastActivityAt": "po aktivnosti",
        "group_none": "poništi grupiranje",
        "group_priority": "po prioritetu",
        "group_project": "u skladu s projektom",
        "group_startDate": "prema početku",
        "group_status": "prema statusu",
        "no_dueDate": "Bez roka",
        "no_one_assigned": "Nije dodjeljeno",
        "no_startDate": "Bez početka",
        "no_value": "Bez vrijednosti",
        "sample": "Pretpregled P."
      },
      "ics_export": "Povezivanje s kalendarom",
      "ics_modal": {
        "all_tasks": "Svi zadaci",
        "all_tasks_duration": "Svi zadaci s početkom i rokovima",
        "all_tasks_only_with_times": "Svi zadaci, samo s vremenima.\"",
        "my_tasks": "Moji zadaci",
        "my_tasks_duration": "Moji zadaci s počecima i rokovima",
        "my_tasks_only_with_times": "Moje zadatke, samo s vremenima"
      },
      "in_one_column": "sve ispod sebe",
      "in_structure": "u granama",
      "link_with_calendar": "Povezivanje s kalendarom",
      "modals": {
        "new_issues": {
          "create": "Kreiraj",
          "parent_task": "Nadređeni zadatak",
          "plan": {
            "from_end": "od kraja",
            "from_start": "od početka",
            "help_left": "Planirani početak je poznat, od njega su postavljeni početci i rokovi zadataka.",
            "help_right": "Početci i rokovi zadataka postavljeni su tako da se posljednji zadatak završi u planiranom roku.",
            "title": "Planirati"
          },
          "planned_due_date": "Plan. rok",
          "planned_start_date": "Plan. početak",
          "success": "Uspješno dodani zadaci.",
          "template": "Šablona",
          "title": "Dodaj zadatke iz šablona"
        }
      },
      "need_update": "Prikazani zadaci više nisu ažurirani, kliknite za njihovu ažuriranje.",
      "no_closed_tasks": "U projektu trenutačno nisu zatvoreni zadaci.",
      "no_overdue_tasks": "Super, zadaci su bez kašnjenja.",
      "no_solved_tasks": "U projektu trenutačno nisu riješeni zadaci.",
      "not_found": "Nažalost, traženi zadatak nismo pronašli.",
      "only_with_times": "samo s vremenima",
      "order_tasks": "Razvrstati zadatke",
      "reminder": {
        "text": "Pripombe na novi ispis ovdje.",
        "title": "Pridružite se novoj Facebook grupi. Zahtjev za dodavanje ćemo odmah potvrditi."
      },
      "search_task": "Zadatak",
      "settings": {
        "add": "Dodati",
        "columns": "Postaviti stupce",
        "disable": "Isključiti",
        "enable": "Uključiti",
        "horizontalScroll": "listanje popisa kotačem",
        "reorder": "Izmijeniti redoslijed"
      },
      "show_more": {
        "few": "Prikazati idući %{count}",
        "many": "Prikazati sljedećih %{count}",
        "one": "Prikazati posljednje.",
        "other": "Prikazati sljedećih %{count}"
      },
      "show_structure_filter": "Prokaži",
      "task_quickadd": "Unesite naziv nove zadatke i pritisnite Enter.",
      "task_quickadd_hitenter": "pritisnite enter",
      "tasks_export": "Izvoz popisa zadataka"
    },
    "messages": {
      "created": "Zadatak je uspješno kreiran.",
      "errors": {
        "dueDate": {
          "greater_than_or_equal_to_start_date": "Rok zadatka ne može biti prije početka zadatka.",
          "locked_task_in_project": "U projektu postoji zadatak s zaključanim početkom i rokom. Zadatak se ne može mijenjati.",
          "lower_than_or_equal_to_parent_due_date": "Rok zadatka ne može biti kasniji od roka nadređenog zadatka.",
          "start_and_due_date_locked": "Početak i rok zadatka zaključao je upravitelj. Potrebno ga je prvo otključati."
        },
        "estimatedHours": {
          "greater_than": "Procijenjeno vrijeme mora biti veće od zbroja procjena podzadataka (%{count} sati)"
        },
        "startDate": {
          "greater_than_or_equal_to_parent_start_date": "Začátek úkolu nemůže být dříve, než začátek nadřazeného úkolu."
        },
        "start_date": {
          "greater_than_or_equal_to_parent_start_date": "Početak zadatka ne može biti prije početka nadređenog zadatka."
        }
      },
      "open_task": "Otvorite kreiran zadatak"
    },
    "modals": {
      "repetitive": {
        "cancel": "otkaži ponavljanje",
        "day_in_month": "dan u mjesecu",
        "days_in_week": "Dani u tjednu",
        "interval": "Interval",
        "last": "zadnji",
        "months": {
          "few": "svaka %{count} mjeseca",
          "many": "svakih %{count} mjeseci",
          "one": "svaki mjesec",
          "other": "svakih %{count} mjeseci"
        },
        "periodically_every": "svakih",
        "periodically_label": "dana od datuma zatvaranja",
        "repeat": "Ponoviti",
        "repeat_options": {
          "daily": "dnevno",
          "monthly": "mjesečno",
          "never": "nikad",
          "periodically": "redovito",
          "weekly": "tjedno",
          "yearly": "godišnje"
        },
        "start": "Početi",
        "submit": "postavi",
        "title": "Postavke ponavljanja\"",
        "weeks": {
          "few": "svaka %{count} tjedna",
          "many": "svakih %{count} tjedana",
          "one": "svaki tjedan",
          "other": "svakih %{count} tjedana"
        },
        "years": {
          "few": "svakih %{count} godina",
          "many": "svakih %{count} godina",
          "one": "svake godine",
          "other": "svakih %{count} godina"
        }
      },
      "tracker": "label:Upozorenje, promjenom reda izgubit ćete vrijednosti prilagođenih polja. Jedini izuzetak je ako ta prilagođena polja postoje i u novom redu. Želite li nastaviti?"
    },
    "new": {
      "add_contact": "dodaj kontakt",
      "assignee": "Rješavač",
      "contact": "Kontakt",
      "contact_name": "Naziv kontakta",
      "create_task": "kreiraj zadatak",
      "customer_tittle": "Nove zadatke koje su klijenti dodijelili su dodijeljene navedenoj osobi.",
      "due_date": "Rok",
      "errors": {
        "cannot_create": "Nije uspelo kreirati zadatak.",
        "date": "Rok za zadatak ne smije biti prije početka.",
        "name": "Nije unesen naziv zadatka.",
        "project": "Nije odabran projekt."
      },
      "new_contact": "Novi kontakt",
      "new_task": "Novi zadatak",
      "placeholders": {
        "assignee": "Nepovezane",
        "new_contact": "Nazivv kontakta",
        "new_contact_in_project": "Kontakt za projekt %{project}",
        "new_task": "Nazivv zadatka",
        "new_task_in_project": "Zadatak za projekt %{project}",
        "project": "Izaberite projekt",
        "starting_today": "Danas počinje"
      },
      "project": "Projekt",
      "set_start_date": "Unesite datum početka",
      "start_date": "Početak",
      "task": "Zadatak",
      "task_name": "Naziv zadataka",
      "tracker": "Red"
    },
    "no_due_date": "Bez roka",
    "no_one_assigned": "Nedodijeljen",
    "show": {
      "activity_created_at": "Zadatak je kreiran.",
      "add_comment": "Dodaj komentara.",
      "add_time": "Dodajte vrijeme zadatku.",
      "attachments": {
        "attach_delete": "Želite li zaista izbrisati datoteku?\"",
        "attach_deleted": "Datoteka je izbrisana.",
        "attach_file": "Priloži datoteke",
        "attach_not_deleted": "Brisanje datoteke nije uspjelo.",
        "button_no": "Ne",
        "button_yes": "Da, izbrisati.",
        "not_saved": "Spremanje datoteke nije uspjelo.",
        "saved": "Datoteke su bile spremljene",
        "saving": "Pohranjujem datoteke...",
        "title_delete": "Izbrisati datoteku."
      },
      "budget": {
        "add": "Dodaj",
        "amount": "Iznos",
        "bad_amount": "Iznos ne smije biti nula ili negativan.",
        "date": "Datum",
        "error": "Iznos nije dodan. Pokušajte ponovno kasnije.",
        "expenses": "Troškovi",
        "note": "Bilješka",
        "title": "Dodaj trošak",
        "vat": "Dorez"
      },
      "cannot_add_comment": "Zatvoreni zadaci se ne mogu komentirati. Molim vas, kreirajte novi zadatak putem gumba gore.",
      "close": "Zatvoriti?",
      "close_title": "Zatvoriti zadatak\"",
      "closed": "Zatvoreno",
      "done_ratio_locked": "Postotci završetka se računaju preko podzadataka. Molimo vas, uredite % završetka u njima.",
      "due_date_locked": "Rok je odredio autor zadatka, samo on ga može promijeniti.",
      "due_reminder": {
        "add": "Dodaj podsjetnik",
        "before": "prije datuma ispunjenja",
        "title": "Upozorenje",
        "tooltip": "Dodaj podsjetnik putem e-pošte rješavatelju."
      },
      "editor": {
        "cancel": "Odbaciti izmjene.",
        "placeholder": "Detalji za vas ili za kolege...",
        "save": "Pohrani"
      },
      "open_task": "Otvori zadaću.",
      "options": {
        "add_tasks": "Dodaj zadatke iz predloška",
        "copy": "Duplicirati zadatak",
        "delete": "Izbrisati zadatak",
        "download": "Preuzmi kao PDF bez komentara",
        "download_full": "Preuzmi kao PDF s komentarima",
        "lock_dates": "Zamknout začátek a termín",
        "title": "Postavke zadataka",
        "unlock_dates": "Odemknout začátek a termín"
      },
      "parent": {
        "loading": "Učitavam...",
        "more_items": {
          "few": "...i još %{count} zadatak. Precizirajte pretragu.",
          "many": "...i još %{count} zadataka. Precizirajte pretragu.",
          "one": "...i još 1 zadatak. Precizirajte pretragu.",
          "other": "...i još %{count} zadataka. Precizirajte pretragu."
        },
        "no_items": "Nije pronađen nijedan zadatak.",
        "no_parent": "Nijedan"
      },
      "project": {
        "no_items": "Nema pronađenih projekata.\""
      },
      "quill_editor": {
        "h1": "Titula",
        "h2": "Titl",
        "normal": "Normalan"
      },
      "relations": {
        "add_related": "Dodaj povezani",
        "close_title": "Zatvoriti",
        "closed_title": "Zatvoren",
        "hint_relates": "Počnite pisati naziv zadatka",
        "hint_subtask": "Unesite naziv podzadatka i pritisnite Enter",
        "label_relates": "Povezano: %{name}",
        "label_subtask": "Podzadatak",
        "link_relates": "Povezane",
        "open_title": "Otvoren",
        "relates": "Povezani",
        "remove_relation_to_relates": "Ukloniti povezanost s povezanim zadatkom",
        "reopen_title": "Zatvoreno, vratiti u status 'U tijeku'.",
        "solve_title": "Riješiti",
        "solved_title": "Riješeno",
        "unsolve_title": "Riješeno, vratiti u status 'U tijeku'."
      },
      "reopen_title": "Otvoriti zadatak.",
      "repetitive": {
        "add": "Postaviti ponavljanje",
        "disabled": "Nije moguće postaviti ponavljanje",
        "disabled_reason": {
          "already_repeated": "Ovaj zadatak je već ponovljen. Podesite postavke u zadnjem aktivnom zadatku koji možete pronaći među otvorenim zadacima u projektu.",
          "already_repetitive": "Neki od nadzadataka ili podzadataka već imaju postavljeno ponavljanje."
        },
        "edit": "Izmijeniti ponavljanje",
        "next_due": "Rok za sljedeći zadatak",
        "wrong_input": "odaberite barem jedan dan",
        "wrong_next_due": "Budući datum se ne slaže? Otkažite ponavljanje i postavite novi."
      },
      "solve": "Riješiti?",
      "solve_title": "Označite zadatak kao riješen kako bi ga autor mogao zatvoriti.",
      "solved": "Riješeno",
      "task_has_x_files": {
        "few": "Zadatak ima %{count} datoteka",
        "many": "Zadatak ima %{count} datoteka",
        "one": "Zadatak ima 1 datoteku",
        "other": "Zadatak ima %{count} datoteka"
      },
      "total_spent_hours": "%{hours} ukupno",
      "unsolve_title": "Označite zadatak kao nesvojstven (u procesu rješavanja).",
      "watchers": {
        "add": "Dodaj pratitelje.",
        "add_watchers": "Dodaj",
        "assigned": "Zadatak je dodijeljen %{name}",
        "check_all": "označi sve",
        "dialog_title": "Dodaj pratitelje.",
        "how_it_looks": "Ovdje je <a href=\"%{url}\" target=\"_blank\">pregled</a>, kako ga vidi pozvana osoba.",
        "label": "Pratitelj",
        "not_search": "Nijedan član s ovim imenom nije pronađen.",
        "notes": {
          "all_added": "Svi trenutni članovi tima već su dodani.",
          "externalusers_notifications": "Na e-mail dolaze samo komentari na koje također mogu odgovarati izravno s e-pošte (odgovaranjem na e-mail).",
          "externalusers_visibility": "U aplikaciji imate korisnike koji se mogu prijaviti sa svojom e-poštom i lozinkom i surađivati na zadacima. Korisnici mogu pratiti bilo koji zadatak. Kada pratim zadatak, dobit ću obavijesti na e-mail o promjenama zadatka i komentarima. Ako želim podijeliti komentare s drugima koji nemaju pristup sustavu, mogu koristiti Vanjskog pratitelja. Ti Vanjski pratitelji (bez registracije) nemaju pristup sustavu; preko veze u e-mail vide samo naslov zadatka, opis zadatka i komentare.",
          "how_to": "Ako želite podijeliti zadatak s kolegama, prvo postavite dijeljenje ovog projekta za njih ili zatražite od voditelja tima da projekt podijeli kolegama."
        },
        "remove": "Uklonite %{name} s praćenja zadatka",
        "removeExternal": "Uklonite vanjskog pratitelja %{name}. Uklanjanjem će izgubiti pristup ograničenom pregledu zadatka.",
        "tab_external": "Dijeli bez registracije",
        "tab_users": "Prijavljeni korisnici",
        "uncheck_all": "odznači sve"
      }
    },
    "show_static": {
      "no_comments_yet": "Zatím zde nejsou žádné komentáře."
    },
    "taskParentError": {
      "add": "Dodaj",
      "added": "Dodano.",
      "ask1": "Molim vas, zamolite voditelja projekta",
      "ask2": "da ih ovdje dodao.",
      "heading": "U ciljnom projektu nedostaju korisnici.",
      "missingTracker": "U ciljnom projektu nedostaje prednji dio. Premještanjem zadatka automatski će se promijeniti njegov prednji dio, što će rezultirati gubitkom podataka iz povezanih prilagođenih polja. Želite li zaista nastaviti?",
      "move": "Premjestiti",
      "note1": "Pomicanjem zadatka korisnici ispod zadatka bi izgubili pristup.",
      "role": "Uloga",
      "step1": "Prvo dodajte korisnika u projekt",
      "step2": "I zatim premjestite zadatak",
      "user": "Korisnik"
    },
    "time_tracker": {
      "comment": "komentar",
      "error_already_runing": "Štoperice već dugo trče",
      "stopped": "Uspješno spremljeno",
      "stopped_and_started": "Spremljeno i pokrenuto.",
      "stopped_and_started_text": "Na zadatku %{issue} je zabilježeno vrijeme provedeno, a istovremeno su ponovno pokrenuti sati za sljedeći zadatak."
    }
  },
  "journals": {
    "ShowAll": "Prikaži cjelokupnu povijest zadatka'",
    "add": "dodan",
    "allDayChange": "cijelodnevni događaj promijenjen iz",
    "attributes": {
      "assigned_to_id": "dodijeljeno korisniku",
      "attachment": "privitak",
      "author_id": "autor",
      "description": "opis zadatka je ažuriran",
      "done_ratio": "% završeno",
      "due_date": "rok",
      "estimated_hours": "procijenjeno trajanje",
      "parent_id": "nadzadatak",
      "priority_id": "prioritet",
      "project_id": "projekt",
      "start_date": "početak",
      "status_id": "status",
      "subject": "naziv zadatka",
      "tracker_id": "red"
    },
    "button_cancel": "odbaci promjene",
    "button_save": "Pohrani",
    "changedFromMan": "promijenjen iz",
    "changedFromThing": "promijenjeno iz",
    "changedFromWoman": "promijenjena iz",
    "comment_deleted": "Komentar je izbrisan.",
    "customField": "prilagođeno polje",
    "deleteMan": "izbrisan",
    "deleteThing": "izbrisano",
    "deleteWoman": "izbrisana",
    "edit_note": "Uređivanje komentara ne šalje e-mailove. Za slanje e-maila, dodajte novi komentar.",
    "events": {
      "repeated_from": {
        "link_label": "předchozí",
        "title": "Vytvořeno zopakováním úkolu."
      },
      "repeated_to": {
        "link_label": "následující",
        "title": "Vytvořen opakovaný úkol."
      },
      "user_archived": "Uživatel <b>%{user}</b> byl archivován."
    },
    "hours": "sati",
    "newDescriptionValue": "NovI opis",
    "no": "ne",
    "oldDescriptionValue": "Stari opis",
    "setToMan": "postavljeno na",
    "setToThing": "postavljeno na",
    "setToWoman": "postavljena na",
    "showAll": "Zobrazit celou historii úkolu",
    "to": "na",
    "userDeleted": "korisnik je već izbrisan",
    "yes": "da"
  },
  "labels": {
    "actions": {
      "add": "Dodaj",
      "archive": "Arhiviraj",
      "cancel": "Otkaži",
      "close": "Zatvori",
      "confirm_archive": "arhiviraj",
      "confirm_delete": "da, izbriši",
      "confirm_move": "da, premjesti",
      "confirm_reset_password": "da, resetiraj",
      "create": "Kreiraj",
      "delete": "Izbriši",
      "delete_from_all_tasks": "Ukloni iz svih zadataka",
      "edit": "Uredi",
      "print": "Ispis",
      "refresh": "Osvježi",
      "register": "Registriraj",
      "remove": "Ukloni",
      "reset": "Resetiraj",
      "save": "Pohrani",
      "zoomIn": "Povećaj",
      "zoomOut": "Smanji"
    },
    "choose": "Odaberi",
    "columns": {
      "assignedTo": "Izvršitelj",
      "author": "Autor",
      "budgetRealExpense": "Pravi troškovi",
      "close_toggle": "Zatvoreno",
      "createdOn": "Kreirano",
      "doneRatio": "Postotak završenosti",
      "dueDate": "Rok",
      "estimatedHours": "Procijenjeno trajanje",
      "id": "#",
      "lastActivityAt": "Zadnja aktivnost",
      "parent": "Nadzadatak",
      "parentProject": "Nadređeni projekt",
      "parentTask": "Roditeljski zadatak",
      "priority": "Prioritet",
      "project": "Projekt",
      "relations": "Povezano",
      "remainingHours": "Preostalo trajanje",
      "spentHours": "Utrošeno vremena",
      "startDate": "Početak",
      "status": "Status",
      "subject": "Zadatak",
      "timeTracker": "Stoperica",
      "totalEstimatedHours": "Ukupno procijenjeno trajanje",
      "totalEstimatedHoursTitle": "Ukupno procijenjeno trajanje, uključujući podzadatke",
      "totalExpenses": "Troškovi",
      "totalSpentHours": "Ukupno utrošeno vremena, uključujući podzadatke",
      "tracker": "Prioritet",
      "updatedAt": "Ažurirano"
    },
    "custom_field_types": {
      "bool": "Da/Ne",
      "date": "Datum",
      "file": "Datoteka",
      "int": "Cijeli broj",
      "list": "Popis",
      "string": "Tekst",
      "text": "Dugački tekst",
      "url": "URL"
    },
    "false": "ne",
    "help": "Pomoć",
    "languages": {
      "cs": "Češki",
      "de": "Njemački",
      "en": "Engleski",
      "hr": "Hrvatski",
      "sk": "Slovački"
    },
    "loading": "Učitavanje...",
    "modified": "izmijenjeno",
    "priority": {
      "high": "Visok",
      "low": "Nisko",
      "normal": "Normalno",
      "urgent": "Hitno"
    },
    "query": {
      "1": "Moji zadaci",
      "1-contacts": "Moji kontakti",
      "10": "Moji zatvoreni zadaci",
      "11": "Delegirani zadaci u zaostatku",
      "12": "Moji zadaci u zaostatku",
      "13": "Moji zadaci",
      "14": "Bez protokolova",
      "15": "Moji praćeni zadaci",
      "16": "Moji praćeni zadaci gdje nisam izvođač",
      "17": "Riješeni zadaci čiji sam ja autor",
      "18": "Delegirani zadaci u zaostatku",
      "19": "Zadaci koji se moraju rješavati",
      "2": "Zadaci u zaostatku",
      "2-contacts": "Kontakti u zaostatku",
      "3": "Otvoreni zadaci",
      "3-contacts": "Otvoreni kontakti",
      "4": "Zatvoreni zadaci",
      "4-contacts": "Zatvoreni kontakti",
      "5": "Riješeni zadaci",
      "5-contacts": "Riješeni kontakti",
      "6": "Zadaci koje sam delegirao",
      "7": "Moji dodijeljeni zadaci",
      "8": "Strukturirani pregled",
      "9": "Moji zadaci po datumu",
      "all": "Svi zadaci",
      "custom": "Prilagođeno razvrstavanje"
    },
    "time_entries": {
      "activity": "Aktivnost",
      "comment": "Komentar",
      "date": "Datum",
      "hours": "Utrošeno vremena",
      "user": "Korisnik"
    },
    "today": "danas",
    "true": "da",
    "user": {
      "deleted": "Korisnik izbrisan",
      "email": "E-mail",
      "firstname": "Ime",
      "lastname": "Prezime"
    }
  },
  "messages": {
    "announcements": {
      "deprecated_browser": {
        "info": "Projektově će uskoro obustaviti podršku za Internet Explorer 11.",
        "note1": "Prikazivanje aplikacije u IE 11 će i dalje biti moguće, ali aplikacija neće biti optimizirana za ovaj preglednik.",
        "note2": "Microsoft prestaje s podrškom za IE 11 u svojim proizvodima od 17. kolovoza 2021. Stoga preporučujemo korištenje suvremenijeg preglednika, poput Google Chromea ili Microsoft Edgea.\""
      },
      "link_email": "Vratiti ponovno slanje obaveštenja.",
      "nps": {
        "confirmation": "Zahvaljujemo ti se.",
        "note_10": "Hvala vam. Što Projektově radi radi čini dobro da zasluži najvišu ocjenu?",
        "note_9": "Hvala vam. Što Projektově radi radi čini dobro da zasluži visoku ocjenu?",
        "note_low": "Hvala. Što možemo učiniti da Projektově zasluži višu ocjenu?",
        "postpone": "odgovorit ću kasnije",
        "question": "Biste li preporučili Projektově drugim menadžerima ili tvrtkama?",
        "scale_high": "Definitivno da",
        "scale_low": "Definitivno be",
        "send": "Poslati"
      },
      "release": {
        "message": "Dostupna je nova verzija aplikacije.",
        "update": "izvršite ažuriranje"
      },
      "text_email": "Slanje e-mail obaveštenja o promenama u zadacima (od vas prema kolegama) je trenutno obustavljeno.",
      "trial": {
        "expires": {
          "few": "Sada koristite probnu verziju. Ona će isteći za %{count} dana.",
          "many": "Sada koristite probnu verziju. Ona će isteći za %{count} dana.",
          "one": "Vaša probna verzija završava sutra. Naručite Projektově danas kako biste mogli nastaviti bez prekida.",
          "other": "Sada koristite probnu verziju. Ona će isteći za %{count} dana.",
          "zero": "Vaša probna verzija ističe danas.Naručite Projektově danas kako biste mogli nastaviti bez prekida."
        },
        "note": "Nećete propustiti nijedan dan besplatno, plaćeni račun neprimjetno slijedi probnu verziju.",
        "order_now": "Naručiti"
      }
    },
    "by_code": {
      "1060": "E-mail je zauzet",
      "1061": "Adresa e-pošte već se koristi kao vaša primarna adresa e-pošte.",
      "2220": "Front '%{tracker_name}' koriste ga zadaci u projektu i ne može se izbrisati.",
      "2301": "Projekt nemohl být převeden na složku, protože obsahuje úkoly.",
      "2302": "Projekt nemohl být převeden na složku, protože má nadžazený projekt. Složka nemůže být vytvořena v projektu.",
      "2303": "Složka nemůže být prevedena na projekt, dokud obsahuje podsložky."
    },
    "errors": {
      "adding_time_entry_failed": "Prilikom unosa utrošenog vremena došlo je do greške.",
      "cannot_save": "Nije moguće Pohraniti.",
      "create_issue_failure": "Izrada zadatka nije uspjela.",
      "create_relation_failure": "Dodavanje veze nije uspjelo..",
      "error": "Greška",
      "exceeds_tariff": {
        "assign_role": "Kako bi dodali još članova u ovaj zadatak, potrebno je da administrator ažurira vaš paket usluge na Projektově."
      },
      "file_too_big": "Datoteka %{file} je pre prenos prevelika.",
      "file_upload": "Učitavanje datoteke nije uspelo.",
      "forbidden": "Nemate dozvolu za ovaj zahtjev.",
      "form_reading_failed": "Učitavanje obrasca nije uspjelo. Molimo pokušajte ponovno.",
      "general": "Ups, došlo je do greške. Molimo pokušajte kasnije.",
      "gql": {
        "error": "Pri pokušaju učitavanja došlo je do greške.",
        "retry": "Pokušaj ponovo"
      },
      "is_primary_mail": "Adresa %{mail} je već u upotrebi kao Vaš glavni e-mail.",
      "issue_assignee_change_failure": "Promjena izvršitelja nije uspjela..",
      "issue_closing_or_opening_failure": "Zatvaranje/Otvaranje zadatka nije uspjelo.",
      "issue_due_date_change_failure": "Promjena datuma nije uspjela.",
      "issue_status_change_failure": "Nije bilo moguće promijeniti status zadatka.",
      "mail_already_used": "Adresa %{mail} je već u upotrebi kod drugog korisnika.",
      "removing_relation_failure": "Nije moguće ukloniti vezu."
    },
    "feedback": {
      "error": "Greška pri čuvanju",
      "loading": "Pokušavaju se učitati projekti i zadaci...",
      "pending": "Čuvam...",
      "processing_request": "Molimo pričekajte, zahtjev se obrađuje...",
      "saved": "Sve promene su sačuvane."
    },
    "member_role_changed": "Uloga je promijenjena",
    "select": {
      "no_options": "Nema dostupnih izbora",
      "type_to_search": "Počnite tipkati za pretraživanje..."
    },
    "success": {
      "changed": "Uspješno promijenjeno.",
      "deleted": "Uspješno izbrisano",
      "password_changed": "Lozinka je uspešno promenjena.",
      "password_reset_sent": "Upute za resetiranje lozinke poslane su na korisnikovu e-poštu.",
      "saved": "Uspješno spremljeno."
    }
  },
  "mindmap": {
    "addSubtask": "Přidat podúkol",
    "addTask": "Dodaj podzadatak",
    "collapse": "Sakrij podzadatke",
    "expand": "Prikaži podzadatke",
    "heading": "Mind mapa",
    "hide_closed": "Sakrij zatvorene",
    "order": {
      "default": "zadano",
      "dueDate": "prema roku izvršenja",
      "label": "Redoslijed",
      "priority": "prema prioritetu",
      "subject": "prema naslovu",
      "updatedAt": "prema zadnjem ažuriranju"
    },
    "relations": {
      "howto1": "Ova mind mapa prikazuje zadatke onako kako su vremenski planirani jedan za drugim prema vezama u Ganttovom dijagramu.",
      "howto2": "Trenutno zadaci nemaju nikakvih veza. Otvorite Ganttov dijagram s lijeve strane i dodajte veze između zadataka tamo."
    },
    "view": {
      "basic": "osnovni",
      "extended": "prošireni",
      "relations": "prema vezama"
    }
  },
  "mobileApplication": {
    "benefit1": "S mobilnom aplikacijom imat ćete sve svoje projekte pri ruci.",
    "benefit2": "Lako ćete dodati novi zadatak i provjeriti status projekta.",
    "fastLogin": "Brza prijava:",
    "fastLogin2": "Pokrenite mobilnu aplikaciju, kliknite na prijavu QR koda i usmjerite kameru prema slici u nastavku.",
    "getItGoogle": "Sada dostupno na Google Playu",
    "getItiOS": "Preuzmite s App Storea",
    "installation": "Instalacija",
    "login": "Prijava",
    "step1": "Pokrenite mobilnu aplikaciju,",
    "step2": "kliknite na prijavu putem QR koda",
    "step3": "usmjerite na sliku u nastavku:",
    "step4": "završeno, automatski ste prijavljeni putem slike.",
    "step5": "Ako ne, druga opcija je ručno unijeti adresu (gore s trake za adresu), svoj e-mail i lozinku kojom se prijavljujete.",
    "title": "Kako instalirati mobilnu aplikaciju?"
  },
  "my": {
    "account": {
      "api": {
        "app_load": "Ponovno učitati cijelu aplikaciju",
        "app_reload": "Znovu načíst celou aplikaci",
        "created": "Ključ za pristup RSS-u je stvoren prije %{value}",
        "reset": "Ponovno postaviti ključ",
        "show": "Prikazati",
        "title": "Ključ za pristup API-ju"
      },
      "languages": {
        "croatian": "Hrvatski",
        "czech": "Češki",
        "english": "Engleski",
        "german": "Njemački",
        "slovak": "Slovački"
      },
      "mail": {
        "change_all_day": "Dodaj trajanje",
        "change_assigned_to": "Promjena zaduženog",
        "change_attachment": "Dodavanje priloge",
        "change_author": "Promjena autora",
        "change_description": "Promjena opisa zadatka",
        "change_done_ratio": "Promjena postotka izvršenja",
        "change_due_date": "Promjena roka izvršenja",
        "change_estimated_hours": "Promjena procijenjenog vremena",
        "change_notes": "Dodavanje komentara",
        "change_parent_id": "Premjesti zadatak",
        "change_priority_id": "Promjena prioriteta",
        "change_project_id": "Promjena projekta",
        "change_start_date": "Promjena datuma početka",
        "change_status": "Promjena statusa",
        "change_subject": "Promjena naziva zadatka",
        "estimated_hours_exhausted": "Upozorenje na potrošeno vrijeme",
        "estimated_hours_exhausted_note": "Pošaljite obavijest kada potrošeno vrijeme dosegne 80% procijenjenog vremena.",
        "mail_mark_off": "Označite preporučene postavke",
        "mail_note": "Primate previše e-mailova od nas?",
        "mail_notification": "E-mail obavijesti",
        "mail_settings": "Postavke e-pošte",
        "new_task": "Netko vam je izradio ili poslao zadatak",
        "newsletter": "Pretplata na novosti",
        "newsletter_note": "Šaljemo najviše jednom mjesečno savjete o upravljanju projektima i vijesti.",
        "oncoming_tasks_summary": "Prikaz bliskih rokova",
        "receive_notififactions_when": "Primi obavijesti kad se dogodi",
        "save_changes": "Pohrani promjene",
        "set_to_all_users": "Prebriši postavke e-pošte za sve korisnike",
        "solved_task": "Netko je riješio ili zatvorio zadatak čiji ste autor",
        "summaries": "Sažeci jutarnjih e-mailova",
        "title": "Postavke e-pošte"
      },
      "mail_notifications": {
        "default": "Šaljite obavijesti",
        "none": "Ne šaljite nikakve obavijesti"
      },
      "mail_summaries": {
        "everyday": "svaki dan",
        "never": "nikada ne šaljite",
        "no_weekends": "svaki dan osim vikendom"
      },
      "mobile": {
        "install": "Instalacija",
        "login": "Brza prijava",
        "text": "Pokrenite mobilnu aplikaciju, kliknite na QR prijavu i usmjerite kameru prema slici ispod.",
        "title": "Mobilna aplikacija"
      },
      "prefill_due_date": {
        "none": "ne",
        "today_plus": {
          "few": "danas +%{count} dana",
          "many": "danas +%{count} dana",
          "one": "danas +1 dan",
          "other": "danas +%{count} dana"
        }
      },
      "profile_image": {
        "cancel_changes": "Odustani od promjena",
        "choose_crop": "Odaberite dio slike koji želite izrezati.",
        "crop_tutorial": "Kliknite i povucite dolje-desno ili ako izrezujete s desna prema dolje, zatim povucite gore-lijevo, kako biste označili izrez slike koji želite zadržati.",
        "dropzone_tutorial": "Povucite sliku mišem ili kliknite za odabir datoteke.",
        "use_crop": "Koristi"
      },
      "project_overview": {
        "gantt": "Ganttov dijagram",
        "issues": "Popis zadataka - otvoreni zadaci",
        "issues_closed": "Popis zadataka - zatvoreni zadaci",
        "issues_my_tasks": "Popis zadataka - samo moji zadaci",
        "issues_overdue": "Popis zadataka - zakašnjeli zadaci",
        "issues_solved": "Popis zadataka - riješeni zadaci",
        "mind_map": "Mapa uma",
        "overview": "Pregled projekta",
        "tasks_board": "Ploča s zadacima"
      },
      "tasks_in_summary": {
        "1": "zadatci za sutra",
        "14": "zadatci s rokom do 14 dana",
        "3": "zadatci s rokom do 3 dana",
        "7": "zadatci s rokom do 7 dana",
        "none": "nijedan"
      },
      "user": {
        "due_date_preset": "Unaprijed postavljena dospjela datum",
        "errors": {
          "invalid": "Neispravan e-mail",
          "taken": "E-mail je već zauzet"
        },
        "firstname": "Ime",
        "language": "Jezik",
        "lastname": "Prezime",
        "login_mail": "E-mail / Prijava",
        "other_mail": "Moje druge e-mail adrese",
        "project_view": "Nakon klika na projekt prikaži",
        "time_zone": "Vremenska zona",
        "title": "Moj račun"
      }
    }
  },
  "number": {
    "currency": {
      "czk": {
        "delimiter": " ",
        "format": "%n %u",
        "precision": 2,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "Kč"
      },
      "eur": {
        "delimiter": ".",
        "format": "%n %u",
        "precision": 2,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "€"
      },
      "format": {
        "delimiter": ".",
        "format": "%n %u",
        "precision": 2,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "kn"
      }
    },
    "format": {
      "delimiter": " ",
      "precision": 2,
      "separator": ",",
      "significant": false,
      "strip_insignificant_zeros": true
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Milijarda",
          "million": "Milijun",
          "quadrillion": "Bilijarda",
          "thousand": "Tisuća",
          "trillion": "Bilijun",
          "unit": ""
        }
      },
      "format": {
        "delimiter": " ",
        "precision": 1,
        "separator": ",",
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "few": "Bajtova",
            "many": "Bajtova",
            "one": "Bajt",
            "other": "Bajtova"
          },
          "gb": "GB",
          "kb": "kB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": " "
      }
    }
  },
  "polls": {
    "add_answers": "Unesite odgovore",
    "add_choice": "+Dodaj opciju...",
    "add_ok": "Glasanje je uspješno dodano.",
    "add_poll": "Dodaj glasanje",
    "add_question": "Unesite pitanje",
    "block_name": "Glasovanje",
    "confirm_no": "ne",
    "confirm_yes": "da, izbriši",
    "delete_answer": "Izbriši odgovor",
    "delete_choice_confirm": "Pokušavate izbrisati odgovor za koji su već zabilježeni glasovi. Jeste li sigurni da ga želite izbrisati, uključujući zabilježene glasove?",
    "delete_ok": "Glasanje je uspješno izbrisano.",
    "delete_poll": "Izbriši glasanje",
    "delete_poll_confirm": "Jeste li sigurni da želite izbrisati glasanje? Svi zabilježeni korisnički glasovi bit će trajno izbrisani.",
    "edit_ok": "Glasanje je uspješno uređeno.",
    "edit_poll": "Uredi glasanje",
    "empty_question": "Unijeli ste prazno pitanje. Molimo vas da ga popunite.",
    "error": "Pogreška prilikom spremanja. Pokušajte ponovno kasnije.",
    "error_conflict": "Nije moguće dodati glasanje jer ova zadaća već sadrži glasanje.",
    "error_insufficient_choices": "Glasanje mora imati najmanje 2 opcije.",
    "no_polls": "Trenutno ne pratite nijedno glasanje.",
    "no_votes": "Niste glasovali",
    "successfully_voted": "Vaš glas je zabilježen."
  },
  "projectOverview": {
    "estimated_hours": "Odhadovaný čas",
    "heading": "Přehled projektu",
    "hide": "skrýt",
    "hours": "hod",
    "planned_due_date": "Plán. termín",
    "planned_start_date": "Plán. začátek",
    "remaining_hours": "Zbývající čas",
    "show": "zobrazit",
    "spent_hours": "Strávený čas"
  },
  "projects": {
    "archive": {
      "cant_unarchive": "Nije moguće ponovno aktivirati",
      "error_cant_unarchive": "Nije bilo moguće ponovno aktivirati projekt jer je neki od nadređenih projekata arhiviran.",
      "exceeds_tariff_modal": {
        "exceeded_tariff": "Imate više aktivnih korisnika koji se naplaćuju nego što je broj korisnika u odabranom Projektovo Tarifnom paketu.",
        "explanation": {
          "few": "Članovi projekta su %{count} korisnika koji bi prema ponovnom aktiviranju projekta premašili trenutačni Projekt Tarifni paket.",
          "many": "Projekt uključuje %{count} korisnika koji bi prema ponovnom aktiviranju projekta premašili trenutačni Projekt Tarifni paket.",
          "one": "Član projekta je korisnik <b>%{user}</b>, koji bi prema ponovnom aktiviranju projekta premašio trenutačni Projekt Tarifni paket..",
          "other": "Projekt uključuje %{count} korisnika koji bi prema ponovnom aktiviranju projekta premašili trenutačni Projekt Tarifni paket."
        },
        "overlimit_users": "Korisnici koji će biti dodatno naplaćeni nakon ponovnog aktiviranja projekta",
        "tip": "Povećajte Projekt Tarifni paket ili arhivirajte dovoljan broj korisnika kako biste mogli ponovno aktivirati projekt.",
        "title": "Projekt nije moguće ponovno aktivirati"
      },
      "from_project": "Iz aktivnog projekta",
      "remove": "Trajno izbriši projekt",
      "title": "Arhiva projekata",
      "unarchive": "Ponovno aktiviraj projekt",
      "unarchiveInfoModal": {
        "h2": "Najprije obnovite nadređeni projek",
        "h3": "Postup",
        "note1": "Projekt",
        "note2": "je također uključen u arhivirani projekt",
        "note3": "Za očuvanje strukture projekata potrebno je",
        "step1": "Ponovno aktivirajte nadređeni projekt %{name}",
        "step2": "Ponovno aktivirajte ovaj projekt - %{name}"
      }
    },
    "breadcrumbs": {
      "all_projects": "Svi projekti",
      "end_path": {
        "budget_entries": "Proračun projekta",
        "contacts": "Popis kontakata",
        "files": "Datoteke u projektu",
        "gantt": "Ganttov dijagram",
        "issues": "Popis zadataka",
        "mindmap": "Mind mapa",
        "overview": "Pregled projekata",
        "settings": "Postavke",
        "tasks_board": "Ploča s zadacima",
        "team_settings": "Postavke tima",
        "time_entries": "Výpis stráveného času"
      },
      "subprojects": "Podprojekti"
    },
    "filters": {
      "buttons": {
        "apply": "Primijeni",
        "cancel": "Otkaži"
      },
      "calendar": "Kalendar",
      "customFields": "Prilagođena polja",
      "customFields_icon_title": "Ukloni",
      "customFields_title": "Postavke filtra",
      "doneRatio": "Postotak završenosti",
      "doneRatio_g": "je veći od",
      "doneRatio_i": "je",
      "doneRatio_l": "e manji od",
      "favorites": "Favoriti",
      "input_placeholder": "Unesite traženi tekst",
      "ismanager": "Ja sam menadžer",
      "member": "Član tima",
      "not_entered": "Nije upisano",
      "only_favorites": "Samo favorita",
      "only_favorites_tree": "favoriti s potprojektima",
      "only_templates": "Samo predlošci",
      "plannedDueDate": "Planirani rok završetka",
      "plannedStartDate": "Planirani datum početka",
      "templates": "Predlošci",
      "this_month": "Ovaj mjesec",
      "this_week": "Ovaj tjedan",
      "today": "Danas",
      "without_favorites": "Bez favorita",
      "without_templates": "Bez predložaka"
    },
    "index": {
      "add_project": "Dodaj projekt",
      "add_sub_project": "Dodaj podprojekt",
      "archive_project": "Arhiviraj projekt",
      "columns": {
        "dates": "Vremenski okvir",
        "members": "Članovi tima",
        "name": "Naziv",
        "parentProject": "Nadređeni projekt",
        "plannedDueDate": "Planirani rok",
        "plannedStartDate": "Planirani početak",
        "progress": "Napredak"
      },
      "copy_project": "Kopiraj projekt\"",
      "details": {
        "collapse": "Sakrij redak",
        "expand": "Proširi redak",
        "incomes": "Proračun - prihodi"
      },
      "export": "Izvozi popis projekata",
      "favorite_default": "Projekti označeni zvjezdicom će biti prikazani na vrhu",
      "favorite_projects": "Omiljeni projekti",
      "move_project": "Premjesti projekt",
      "project_settings": "Postavke projekta",
      "remove_project": "Izbriši projekt",
      "rename_project": "Preimenuj projekt",
      "share_project": "Podijeli projekt",
      "show_all": "Svi projekti",
      "show_archive": "arhiva",
      "show_filter": "Prikaži filter",
      "show_my": "Moji projekti"
    },
    "modals": {
      "archive_project": {
        "text1": "Arhiviranjem projekta",
        "text2": "arhiviraju se i svi njegovi podprojekti",
        "title": "Zaista želite arhivirati projekt?"
      },
      "destroy_project": {
        "all_files": "sve datoteke projekta",
        "all_tasks": "sve zadatke u projektu",
        "members": "popis članova tima",
        "members_note": "korisnici će ostati u Projektovom, moguće ih je dodijeliti drugim projektima",
        "save": "izbriši projekt",
        "settings": "postavke projekta",
        "spent_times": "vrijeme utrošeno na zadatke",
        "subprojects_note": "Eventualni podprojekti su sastavni dio projekta i bit će također izbrisani.",
        "title": "Zaista želite izbrisati projekt?",
        "what_you_lost": "Izbrisom projekta trajno ćete izgubiti:"
      },
      "move_project": {
        "no_parent": "-- bez nadređenog projekta --",
        "save": "premjesti",
        "title": "Premještanje projekta"
      },
      "new_project": {
        "choose_template": "Potrebno je odabrati predložak",
        "create": "stvori",
        "empty": "Prazno",
        "folder": "Složka",
        "fromTemplate": "Iz predloška",
        "name": "Naziv",
        "parent": "Nadređeni projekt",
        "parentErrorHint": "Samo administrator može dodavati glavne projekte. Kao upravitelj, možete stvarati samo podprojekte svojih projekata. Molimo odaberite nadređeni projekt.",
        "parentFolder": "Nadřazená složka",
        "plan": {
          "from_end": "od kraja projekta\"",
          "from_start": "od početka projekta",
          "help_left": "Planirani početak projekta je poznat, a po njemu su postavljeni počeci i rokovi zadataka.",
          "help_right": "Počeci i rokovi zadataka su postavljeni kako bi projekt završio u planiranom roku.",
          "title": "Planirati"
        },
        "planned_due_date": "Planirani rok",
        "planned_start_date": "Planirani početak",
        "template": "Predložak",
        "title": "Novi projekt"
      },
      "rename_project": {
        "save": "preimenuj",
        "title": "Preimenovanje projekta"
      }
    },
    "navigation": {
      "labels": {
        "budget_entries": "Unosi u proračun projekta",
        "contacts": "Kontakti",
        "files": "Datoteke",
        "gantt": "Ganttov dijagram",
        "issues": "Zadaci",
        "last_activity": "Posljednja aktivnost",
        "mindmap": "Mind mapa",
        "mobile_application": "Mobilna aplikacija",
        "my_page": "Moja stranica",
        "new_issues": "novi",
        "overview": "Pregled projekta",
        "projects": "Projekti",
        "projektove_cz": "Projektově",
        "settings": "Postavke",
        "tasks_board": "Ploča zadataka",
        "team_settings": "Članovi tima",
        "time_entries": "Provedeno vrijeme",
        "users_settings": "Postavke korisnika"
      }
    },
    "settings": {
      "convert_to_folder": "Převést projekt na složku",
      "convert_to_project": "Převést složku na projekt",
      "description": "Opis",
      "folder": "Složka",
      "is_template": "Šablona projekta",
      "is_template_inherited": "Ovu osobinu nije moguće promijeniti jer je neki od nadređenih projekata šablona.",
      "name": "Naziv",
      "only_admin_can_change": "Samo administrator može promijeniti ovo postavku.",
      "parentId": "Nadređeni projekt",
      "parentIdFolder": "Nadřazená složka",
      "path": "Put",
      "plannedDueDate": "Planirani rok",
      "planned_due_date": "Planirani rok",
      "planned_income": "Planirani prihod",
      "planned_start_date": "Planirani početak",
      "real_incomes": "Stvarni prihod",
      "reset_trackers": "Vrati na izvorno",
      "shortcut": "Skratka",
      "shortcut_add": "Unesite skratku projekta. Time ćete dobiti e-mail adresu, tako da slanjem ili prosljeđivanjem e-maila na tu adresu stvarate zadatak iz e-maila.",
      "shortcut_alt_add": "Ispunite skraćenicu projekta. Zatim ćete primiti upute o tome kako stvoriti zadatak u ovom projektu slanjem ili proslijeđivanjem e-pošte.",
      "shortcut_alt_note1": "Slanjem ili proslijeđivanjem e-pošte na adresu",
      "shortcut_alt_note2": "stvorit ćete zadatak iz e-pošte. Predmet će biti naziv zadatka, a sadržaj će biti njegov opis.",
      "shortcut_alt_note3": "Na početak predmeta e-pošte dodajte skraćenicu projekta s dvotočkom, na primjer, za skraćenicu 'vrednovanje,' izgledat će ovako:",
      "shortcut_alt_subject": "predmet e-pošte ... ...",
      "shortcut_alt_subject_example": "vrednovanje: Molim vas za izračun cijene traženih izmjena",
      "shortcut_format": "Dopuštena su samo slova bez dijakritičkih znakova i brojevi. Možete koristiti točku, donju crtu i crticu, ali ne smiju biti na početku ili kraju.",
      "shortcut_note1": "Slanjem ili prosljeđivanjem e-maila na adresu",
      "shortcut_note2": "stvarate zadatak iz e-maila.",
      "shortcut_note3": "Iz predmeta će se uzeti naziv zadatka, a iz sadržaja će se uzeti opis.",
      "tracker": "Red",
      "unsaved_changes": {
        "prompt": "Želite li nastaviti na sljedeću stranicu?",
        "title": "Imate nespremljene promjene na projektu."
      },
      "unsortedCustomFields": "Polja projekta"
    },
    "team_settings": {
      "add_member": "Dodaj člana",
      "member": {
        "add_member": "Dodaj člana u podprojekte",
        "add_member_error": "Korisnik je već dodan u projekt %{project} zaradený",
        "add_member_info": "Korisnik je uspješno dodan u projekt %{project}",
        "add_task": "Dodijeli zadatak",
        "change_contact_person": "Novi zadaci koji su dodijeljeni od strane klijenta bit će prvo dodijeljeni osobi koja je navedena.",
        "change_hour_cost": "Postavi satnicu za praćenje varijabilnih troškova (potrošeni sati na zadatku * cijena) u projektu",
        "change_role": "Promijeni ulogu člana u ovom timu",
        "remove": "Ukloni člana iz projekta",
        "remove_from_subprojects": "Želite li ukloniti člana i iz podprojekata?",
        "show_info": "Prikaži informacije o članu"
      },
      "modals": {
        "add": {
          "add": "dodaj",
          "check_all": "Označi sve",
          "choose_member": "Izaberite člana tima",
          "choose_role": "Izaberite ulogu",
          "no_user_found": "Korisnika kojeg tražite nismo pronašli, potrebno ga je registrirati.",
          "search": "Pretraži po imenu ili dodatnim informacijama",
          "selected": "Označeno: %{count}",
          "uncheck_all": "Poništi sve označene"
        },
        "register": {
          "add": "Dodaj u projekt",
          "add_additional_info": "Dodaj dodatne informacije",
          "additional_info_1": "Dodatne informacije 1",
          "additional_info_2": "Dodatne informacije 2",
          "already_registered": "Korisnika s unesenim e-mailom već postoji u sustavu, nije ga potrebno registrirati.",
          "already_registered_external": "Pokušavate registrirati korisnika koji je već dodan kao vanjski promatrač.",
          "choose_role": "Izaberite ulogu",
          "delete_user": "Prvo uklonite kolegu iz vanjskih praćenja.",
          "help_1": "Na unesenu e-mail adresu poslat će se pozivnica, putem koje će pozvani prilikom prvog prijave postaviti vlastitu lozinku.",
          "help_2": "Nakon što pozivnica bude prihvaćena, aplikacija će poslati upute o tome kako se prijaviti.",
          "hint_email": "E-mail",
          "office365_only": "Dopusti samo prijavu putem Microsoft računa",
          "only_admin": "Dostupno samo administratorima",
          "register_user": "Registriraj korisnika",
          "set_name": "Molimo unesite ime i prezime prije registracije.",
          "title": "Registriraj novog korisnika",
          "user_registered": "Korisnik je registriran i na njegovu e-mail adresu poslana je pozivnica za online suradnju."
        }
      },
      "precheck": {
        "cannot_remove": "Nemoguće ukloniti iz projekta",
        "info": "%{user} ima zadatke u projektu koje rješava ili za koje je autor.",
        "list_assignee": "Popis zadataka za koje je zadužen.",
        "list_author": "Popis zadataka za koje je autor.",
        "list_check": "Provjerite i zatvorene zadatke.",
        "remove_anyway": "Unatoč tome, ukloni"
      },
      "register_member": "Registriraj novog korisnika",
      "register_member_external": "Registriraj korisnika izvan domene (LDAP)",
      "restricted_registrations": "Za registraciju novih korisnika obratite se svom administratoru.",
      "show_member_info": "Prikaži informacije o članu",
      "title": "Članovi tima"
    }
  },
  "quickadd": {
    "block_name": "Brzo stvaranje zadatka"
  },
  "search": {
    "button": "hledat",
    "header": "Hledání",
    "inComments": "hledat i v komentářích",
    "inProject": "hledat jen v jednom projektu",
    "notFound": "Nenalezeno."
  },
  "shared": {
    "button_back": "Natrag",
    "disableEmails": {
      "long": {
        "disable": "Onemogućite slanje e-pošte",
        "enable": "Omogućite slanje e-pošte"
      },
      "short": {
        "disable": "Onemogućite",
        "enable": "Omogućite"
      }
    },
    "errorView": {
      "403": "Nemate dovoljna prava za pregled ove stranice.",
      "404": "Stranica koju pokušavate pregledati ne postoji ili je izbrisana.",
      "oops_title": "Izgubljeni u zadacima?",
      "options": "Opcije",
      "step1": "Idite na popis projekata i pogledajte izravno u projekt,",
      "step1_note": "Ako projekt postoji i imate pristup, vidjet ćete ga ovdje.",
      "step2": "koristite pretraživanje u gornjem desnom kutu",
      "step2_note": "Pretraga pretražuje nazive i opise zadataka u cijeloj aplikaciji. Ako zadatak postoji i imate pristup, pronaći ćete ga.",
      "step3": "kontaktirajte voditelja projekta."
    },
    "forced_logout": {
      "explanation": "Vjerojatno je došlo do prisilne promjene lozinki od strane administratora Projektově. Ako ne možete ponovno prijaviti, provjerite svoju e-poštu za upute o promjeni lozinke.",
      "reason": "Odjava je iz sigurnosnih razloga.",
      "relog": "Ponovna prijava",
      "title": "Odjavljeni ste"
    },
    "pnotify": {
      "close_title": "Zatvori"
    },
    "tasksReminders": {
      "approaching_deadline": {
        "few": "Približavaju se rokovi zadataka",
        "many": "Približavaju se rokovi zadataka",
        "one": "Približava se rok zadatka",
        "other": "Približavaju se rokovi zadataka"
      },
      "close": "Sakrij sve",
      "hide": "Sakrij"
    }
  },
  "time": {
    "am": "am",
    "formats": {
      "at": "%-d.%-m.%Y v %-H:%M",
      "default": "%-d.%-m.%Y, %-H:%M",
      "long": "%B %d, %Y %-H:%M",
      "short": "%d %b %-H:%M",
      "time": "%-H:%M",
      "time2": "%H:%M"
    },
    "moment_formats": {
      "default": "D.M.YYYY, H:mm",
      "only_time": "H:mm"
    },
    "pm": "pm"
  },
  "time_entries": {
    "hours_short": "h.",
    "last_month": "Prošli mjesec",
    "modals": {
      "add": {
        "title": "Dodaj vremenski unos"
      },
      "edit": {
        "title": "Uredi vremenski unos"
      }
    },
    "next_month": "Sljedeći mjesec",
    "next_week": "Sljedeći tjedan",
    "reports": {
      "by_projects_members_tasks": "prema projektima, članovima tima i zadacima",
      "by_projects_tasks": "prema projektima i zadacima",
      "title": "Izvještaji"
    },
    "this_month": "Ovaj mjesec",
    "this_week": "Ovaj tjedan",
    "today": "Danas",
    "today_and_future": "Danas i budući",
    "yesterday": "Jučer"
  },
  "timelog": {
    "compoundReport": {
      "groupByUsers": "Seskupit dle uživatelů",
      "hideArchivedProjects": "Archivované projekty",
      "hideNullHours": "Zobrazit prázdné řádky",
      "sum": "Celkem",
      "title": "Souhrnný report",
      "users": "Uživatelé"
    },
    "daterange": {
      "all_time": "vše",
      "current_month": "tento měsíc",
      "current_week": "tento týden",
      "current_year": "tento rok",
      "custom": "vlastní",
      "last_month": "minulý měsíc",
      "last_week": "minulý týden",
      "last_year": "minulý rok",
      "title": "Časový rozsah",
      "today": "dnes",
      "yesterday": "včera"
    }
  },
  "users": {
    "add_alias": "dodaj e-mail adresu",
    "change_password": {
      "button": "promijeni lozinku",
      "button_first_login": "postavi lozinku",
      "current_password": "Trenutna lozinka",
      "errors": {
        "invalid": "Unesena trenutna lozinka nije ispravna. Promjena nije moguća.",
        "missing_current": "Unesite trenutnu lozinku",
        "often": "Lozinku je moguće promijeniti samo jednom dnevno. Promjena nije moguća."
      },
      "password": "Nova lozinka",
      "requirements": {
        "char_types": {
          "capital": "velika slova",
          "lowercase": "mala slova",
          "number": "brojevi",
          "special": "poseban znak, npr. !, $,"
        },
        "complexity": {
          "all": "sadržavati svaku od ovih vrsta znakova",
          "other": "barem %{required} od 4 vrste znakova"
        },
        "length": "duljina najmanje %{length} znakova",
        "recent": "ne smije biti korištena u nedavnoj povijesti",
        "title": "Radi povećanja sigurnosti, lozinka mora zadovoljavati sljedeće zahtjeve"
      },
      "set_password_and_begin": "Sada morate postaviti svoju lozinku i možete početi.",
      "title": "Promjena lozinke",
      "title_first_login": "Samo još jedan mali korak"
    },
    "me": "ja",
    "profile": {
      "add_custom_image": "Dodaj vlastitu sliku",
      "change_color": "Promijeni boju",
      "crop_image": "Izbriši sliku",
      "delete_image": "Obriši sliku",
      "drag_to_upload": "Povucite sliku mišem ili kliknite za odabir datoteke.",
      "profile_image": "Profilna slika",
      "upload_image": "Odaberi datoteku"
    },
    "projects": {
      "bulkEditNote": "Promijeni ulogu u označenim projektima na",
      "change": "Promijeni",
      "checkAll": "Označi sve",
      "no_memberships": "Korisnik nije član nijednog projekta.",
      "only_if_member": "Samo projekti na kojima je član",
      "project": "Projekt",
      "role": "Uloga",
      "title": "Korisnikovi projekti",
      "title_external": "Korisnikovi zadaci"
    },
    "settings": {
      "active_users": "Aktivni korisnici",
      "add_note": "Dodavanje dodatnih korisnika moguće je na razini projekta - dodajte člana u projekt.",
      "add_user": "Dodaj korisnika",
      "additional_info": "Dodatne informacije",
      "additional_info1": "Dodatne informacije 1",
      "additional_info2": "Dodatne informacije 2",
      "admin": "Administrator",
      "archive": {
        "archived_header": "Korisnik je arhiviran",
        "archived_info": "Arhiviranjem korisnika on se uklanja s izvršitelja zadataka. Trenutno imate %{count} otvorenih zadataka bez izvršitelja. Ovdje možete provjeriti:",
        "archived_link": "Zadaci bez izvršitelja",
        "label": "Arhiviraj korisnika %{name}?",
        "note": "Ime korisnika će biti vidljivo u komentarima i unesenim vremenima. Međutim, korisnik se neće moći prijaviti u aplikaciju i neće primati e-poštu.",
        "note2": "Arhivirani korisnik ne može biti autor ili izvršitelj bilo koje zadatka. Autorsvo zadataka bit će promijenjeno na Vas, a izvršitelj na \"Nepopunjen\"."
      },
      "archived_users": "Arhivirani korisnici",
      "available_month_hours": "Mjesečni dostupni sati",
      "bar": {
        "all_label": "Ukupan broj korisnika u trenutnom paketu.",
        "manage": "Upravljanje računom",
        "paid_label": "Broj plaćenih korisnika.",
        "title": "Korištenje paketa"
      },
      "cant_access": "Nemate dovoljna prava.",
      "delete": {
        "alternative": "Osim brisanja, također možete arhivirati korisnike.",
        "label": "Izbriši korisnika %{name}?",
        "note": "Unesena vremena korisnika će ostati, ali bit će označeni autorom Nepoznatim. Za zadatke za koje je bio izvršitelj, izvršitelj će biti postavljen na Nepopunjen. U povijesti zadataka, njegovih komentara bit će naveden nepoznat korisnik.",
        "note_archive_label": "Arhiviranje",
        "note_label": "Brisanje"
      },
      "edit_avatar": "Uredi avatar",
      "edit_user": "Uredi korisnika",
      "errors": {
        "firstname": "Polje za ime je obavezno.",
        "lastname": "Polje za prezime je obavezno.",
        "mail": "Unesite e-mail adresu.",
        "noCheckedProjects": "Morate označiti barem jedan projekt.",
        "noSelectedRole": "Odaberite jednu od uloga."
      },
      "firstname": "Ime",
      "help_add": "Korisno za veće timove. Prvo polje možete popuniti s radnim položajem, a drugo polje s odjelom, na primjer.",
      "help_admin": "Administrator može pregledavati projekte čiji nije član, upravljati korisnicima i računima u usluzi.",
      "hour": "sat",
      "hour_cost": "Satnica",
      "issuesCheck": {
        "assignedTo": "je izvršitelj",
        "author": "je autor",
        "pass": "nije autor ili izvršitelj nijednog otvorenog zadatka.",
        "progress": "Provjera zadataka...",
        "tasks": "otvorenih zadataka"
      },
      "language": "Jezik",
      "lastname": "Prezime",
      "mail": "E-mail",
      "month_hours": "sati/mjesec",
      "no_external_users": "Nemate vanjskih promatrača",
      "notifications_opt_out": "Novim korisnicima automatski onemogućiti e-mail obavijesti za",
      "projects": "Članstvo u projektima",
      "register": "Registracija",
      "register_user": "Registriraj korisnika",
      "reset": {
        "password": "Jeste li sigurni da želite resetirati lozinku korisnika",
        "password_note": "Na kolegu će biti poslan e-mail s vezom pomoću koje će moći postaviti novu lozinku.",
        "reset_password_label": "Resetiranje lozinke"
      },
      "return_to_my_page": "Povratak na Moju stranicu.",
      "settings": "Postavke",
      "success": "Uspješno registriran, korisnički podaci su poslani na e-mail.",
      "tasks": "Zadaci",
      "title": "Upravljanje korisnicima",
      "title_external": "Vanjski pratitelj",
      "unwatch_tasks": {
        "confirm_question": "Ukloniti vanjskog promatrača <b>%{name}</b> sa svih zadataka?"
      }
    }
  },
  "wizard": {
    "add_projects": "dodaj projekte",
    "add_template": "dodaj predloške",
    "blank_project": "Nemate unesen nadređeni projekt.",
    "blank_template": "Morate unijeti nadređeni predložak.",
    "create_templates": "stvori predloške",
    "e-mail": "e-mail",
    "examples": "Primjeri",
    "first_name": "Ime",
    "go_to_projects": "idi na projekte",
    "invalid_mail": "Korisnik %{name} ima nevažeću e-poštu.",
    "invalid_role": "Korisnik %{name} nije unio ulogu.",
    "last_name": "Prezime",
    "non_unique_emails": "Korisnici ne mogu koristiti istu e-poštu.",
    "one_project": "Morate stvoriti barem jedan projekt.",
    "one_template": "Morate stvoriti barem jedan predložak.",
    "project": "Unesite naziv projekta",
    "register_users": "registriraj korisnika",
    "role": "Vyberte roli",
    "skip": "preskoči",
    "start": "početi",
    "step1": "pozdrav",
    "step2": "dodavanje projekata",
    "step3": "registracija korisnika",
    "subproject": "Unesite naziv podprojekta",
    "subtemplate": "Unesite naziv podpredloška",
    "template": "Unesite naziv predloška",
    "template_text1": "Predlošci su dodani.",
    "template_text2": "U popisu projekata, predložak ćete vidjeti označen ikonom šalice za kavu. Sada samo trebate kliknuti na nju i početi dodavati zadatke.",
    "template_text3": "Savjet: u predlošku pokušajte u lijevom izborniku kliknuti na",
    "template_text4": "i planirajte zadatke putem mind mape.",
    "template_textHelp": "Predlošci su odličan način za planiranje projekta prije nego što ga stvarno pokrenete. U predlošku možete dodijeliti zadatke i postaviti rokove bez obavještavanja kolega o tome. Na taj način pripremate temelj projekta i kasnije lako stvarate stvarni projekt iz predloška.",
    "template_title1": "Dodaj predloške projekta",
    "template_title2": "Gotovo",
    "text1_step2": "Projekti čine mape u koje unosite zadatke. Ovdje možete stvoriti strukturu prvih projekata i podprojekata. Za inspiraciju, u desnom kutu priložili smo nekoliko primjera, dovoljno je unijeti barem jedan projekt.",
    "text1_step3": "Ovaj korak je dobrovoljan, ali raditi u timu ubrzava provedbu projekta.",
    "text1_step3b": "Uskoro ćete moći dodijeliti zadatke kolegama koje će Projektově pratiti.",
    "text1_step4": "Čestitke, Projektově je spremna za vas.",
    "text2_step3": "Voditelj - vidi sve u projektima, može napraviti sve.",
    "text2_step4": "Sada samo trebate kliknuti na bilo koji projekt i dodavati zadatke.",
    "text3_step3": "Član tima - radi na zadacima, može dodijeliti zadatke sebi i drugima.",
    "text3_step4": "Ili želite",
    "text4_step3": "Klijent - uloga za pregledavanje.",
    "text4_step4": "stvoriti predložak projekta?",
    "text5_step3": "Suradnik izvan tima - slično kao član tima, vidi samo svoje zadatke. Primjerice, ako u projektu postoji 8 zadataka, vidi samo 1, svoj.",
    "text6_step3": "Više informacija ovdje: ",
    "text7_step3": "prikaži više o ulogama",
    "text_step1": "Kako održavati red i pregled nad provedenim projektima? U iduća 2 koraka, krenimo zajedno.",
    "title_step2": "Koji projekti se realiziraju?",
    "title_step3": "Dodajte svoje kolege",
    "title_step4": "Gotovo",
    "welcome_text": "Dobrodošli u  Projektově"
  }
});
I18n.translations["sk"] = I18n.extend((I18n.translations["sk"] || {}), {
  "activities": {
    "action": {
      "close": "Zavrel úlohu",
      "comment": "Okomentoval",
      "create": "Vytvoril úlohu",
      "edit": "Upravil úlohu"
    },
    "assigned_to": "Riešiteľ",
    "author": "Autorom",
    "change": {
      "all": "všetko",
      "assigned_to": "riešiteľ",
      "author": "autora",
      "close": "ukončenie úlohy",
      "create": "vytvorenie úlohy",
      "description": "popisu",
      "done_ratio": "percento hotovo",
      "due_date": "termínu úlohy",
      "estimated_hours": "odhadu času",
      "me": "ja",
      "note": "pridať komentár",
      "others": "ostatné",
      "priority": "priority",
      "project": "presun do iného projektu",
      "start_date": "začiatok úlohy",
      "status": "stavu",
      "tracker": "fronty"
    },
    "changes": "Zmeny",
    "complete_list": "Kompletný výpis",
    "no_activity": "Zatiaľ bez aktivity.",
    "projects": "Projekty"
  },
  "admin": {
    "billing": {
      "cancel": "zrušiť zmeny",
      "change_info": "Zmeniť údaje",
      "change_save": "Uložiť zmeny",
      "change_tariff": "Uložiť zmeny a vybrať balíček",
      "city": "Mesto",
      "company": "Spoločnosť",
      "contact_info": "Kontaktné údaje",
      "country": "Štát",
      "country_cs": "Česká republika",
      "country_sk": "Slovenská republika",
      "dic_cs": "IČ DPH",
      "dic_sk": "IČ DPH",
      "email": "E-mail",
      "firstname": "Meno",
      "ic": "IČO",
      "invoice_email": "Fakturačný email",
      "invoice_info": "Fakturačné údaje",
      "lastname": "Priezvisko",
      "phone": "Telefón",
      "street": "Ulica",
      "zip": "PSČ"
    },
    "client": {
      "billing": {
        "cancel": "zrušit změny",
        "change_info": "Změnit údaje",
        "change_save": "Uložit změny",
        "change_tariff": "Uložit a vybrat balíček",
        "city": "Město",
        "company": "Společnost",
        "contact_info": "Kontaktní údaje",
        "country": "Stát",
        "country_cs": "Česká republika",
        "country_sk": "Slovenská republika",
        "dic_cs": "DIČ",
        "dic_sk": "IČ DPH",
        "email": "E-mail",
        "firstname": "Jméno",
        "ic": "IČO",
        "invoice_email": "Fakturační email",
        "invoice_info": "Fakturační údaje",
        "lastname": "Příjmení",
        "phone": "Telefon",
        "street": "Ulice",
        "zip": "PSČ"
      },
      "messages": {
        "deleted": "Predplatné zrušené. Aplikácia Projektově bude ku koncu fakturačného obdobia zastavená a následne zmazaná.",
        "deleted_trial": "Skúšobná verzia bola ukončená. Čoskoro bude účet so všetkými dátami kompletne zmazaný.",
        "new": "Balíček úspešne objednaný. Aplikácia Projektově je teraz plne funkčná.",
        "updated": "Balíček úspešne zmenený."
      },
      "tariff": {
        "cancel": "zrušiť predplatné",
        "cancel_title": "Naozaj si prajete zrušiť predplatné Projektově?",
        "cancel_trial": "ukončiť skúšobnú verziu",
        "cancel_trial_title": "Naozaj si prajete ukončiť skúšobnú verziu?",
        "change": "Zmeniť balíček",
        "current": "Aktuálny balíček",
        "custom": "Individuálne",
        "custom_tariff_notice": "Potrebujete zmeniť balíček alebo upraviť fakturačné obdobie? Kontaktujte našu zákaznícku podporu.",
        "details": "Podrobnosti balíčka",
        "individual_20": "Individual 20",
        "individual_30": "Individual 30",
        "individual_40": "Individual 40",
        "individual_50": "Individual 50",
        "onpremise": "On-premise",
        "order": "Objednať balíček",
        "payment_info": "Systém po objednaní alebo zmene balíčka pravidelne vystavuje výzvy na platbu a po úhrade zasiela daňový doklad.",
        "payment_invoice": "Fakturácia",
        "payment_method": "Platobné metódy",
        "payment_type": "Spôsob Platby",
        "prodva": "Pre dvoch",
        "promo": "Promo",
        "standard": "Štandard",
        "start": "Štart",
        "trial": "Zkušební verze",
        "users_count": "Počet používateľov v balíčku",
        "users_current": "Aktuálny počet používateľ",
        "users_manage": "Spravovať používateľov"
      },
      "tariff_dialog": {
        "affirmation_accept": "Souhlasím s",
        "affirmation_terms": "obchodními podmínkami",
        "annual": "roční (sleva 10 %)",
        "cancel": "zrušit změny",
        "choose": "Balíček k objednání",
        "discount": "Sleva",
        "monthly": "měsíční",
        "price": "Cena",
        "price_final": "Konečná cena",
        "price_monthly": " / měsíc",
        "quarterly": "čtvrtletní",
        "semi_annual": "půlroční",
        "tax_notice": "Uvedené ceny nezahrnují DPH, jsme plátci DPH.",
        "users": "Uživatelů",
        "users_total": "Celkový počet uživatelů"
      },
      "title": "Účet služby Projektově"
    },
    "custom_fields": {
      "cancel_button": "Zrušiť",
      "check_warning": "Akonáhle aktivujete túto voľbu, nebude ju už možné vypnúť.",
      "confirm_delete": "Opravdu si přejete odebrat tuto frontu?",
      "confirm_delete_alternative": "Naozaj chcete zmeniť front?",
      "confirm_delete_tracker": "Naozaj si prajete odobrať túto frontu?",
      "custom_field_header": "Správa vlastných polí",
      "delete_button": "Zmazať",
      "delete_field_confirm": "Naozaj si prajete zmazať používateľské pole?",
      "delete_field_label": "Zmazaním odstránite aj všetky k nemu vyplnené hodnoty.",
      "delete_field_success": "Používateľské pole úspešne zmazané.",
      "delete_tracker": "Odobratím fronty sa nenávratne zmažú aj všetky zadané hodnoty z odoberanej fronty.",
      "delete_tracker_alternative": "Výberom fronty budú dáta z ostatných front nenávratne vymazané. Pole bolo viditeľné pri všetkých frontov, teraz bude len pri vybraných. Zadané údaje k vlastnému poľu vybraných front zostanú, pri ostatných budú zmazané.",
      "edit_button": "Upraviť",
      "edit_field_success": "Používateľské pole úspešne zmenené.",
      "enable_more_values": "Povoliť výber viacerých hodnôt",
      "field_add": "Pridať ďalšie pole",
      "label_field": "Pole",
      "label_field_visibility": "Viditeľnosť polí",
      "label_label": "Popis",
      "label_show_in_projectslist": "Výpis projektov",
      "label_sorting": "Radenie",
      "label_tracker": "Fronta",
      "label_type": "Typ",
      "maximum_number": "Dosiahnutý maximálny počet vlastných polí.",
      "move_begin": "Presunúť na začiatok",
      "move_end": "Presunúť na koniec",
      "move_higher": "Presunúť hore",
      "move_lower": "Presunúť dole",
      "name_and_tracker_error": "Názov a fronta poľa sú povinné.",
      "name_and_type_error": "Názov a typ poľa sú povinné.",
      "name_error": "Názov poľa je povinný.",
      "name_type_tracker_error": "Názov poľa, fronta a typ poľa sú povinné.",
      "new_line": "Každá hodnota na novom riadku.",
      "no_possible_values": "Zvolili ste typ zoznam, musíte uviesť možné hodnoty.",
      "possible_values": "Možné hodnoty",
      "possible_values_changed": "Možné hodnoty boli úspešne zmenené.",
      "possible_values_obligatory": "Možné hodnoty sú povinné.",
      "projects_label": "Projekty",
      "same_name_error": "Názov poľa už existuje, zvoľte iný názov.",
      "save_button": "Uložiť",
      "tasks_label": "Úlohy",
      "tracker_and_type_error": "Fronta a typ poľa sú povinné.",
      "tracker_error": "Fronta poľa je povinná.",
      "type_error": "Typ poľa je povinný.",
      "values_change": "Upraviť hodnoty zoznamu",
      "visible_for_all": "Viditeľné všetkým"
    },
    "tariff_dialog": {
      "affirmation_accept": "Súhlasím s",
      "affirmation_terms": "obchodnými podmienkami",
      "annual": "ročná (zľava 10 %)",
      "cancel": "zrušiť zmeny",
      "choose": "Balíček na objednanie",
      "discount": "Zľava",
      "monthly": "mesačný",
      "price": "Cena",
      "price_final": "Konečná cena",
      "price_monthly": " / mesiac",
      "quarterly": "štvrťročná",
      "semi_annual": "polročná",
      "support_contact": "Kontaktovať podporu",
      "support_users": "Potrebujete viac užívateľov?",
      "tax_notice": "Uvedené ceny nezahŕňajú DPH, sme platcovia DPH.",
      "users_total": "Celkový počet uživatelů"
    }
  },
  "budget_entries": {
    "expense_time_table": {
      "all": "všetko",
      "filter": "Filter",
      "show": "Zobraziť",
      "subtasks2": "skryť pod-úlohy od 2 úrovne",
      "sum": "Celkom",
      "task": "Úloh",
      "title": "Časové rozvrhnutie skutočných nákladov",
      "types": {
        "planned": "plánované náklady",
        "real": "skutečné náklady"
      },
      "warning": "Pozor, ide o jednoduchý súčet vypísaných riadkov. Nerešpektuje vzťah úloh a pod-úloh. Ak je výška vyfiltrované mix úloh a pod-úloh, súčet nebude zodpovedať.",
      "without_subtasks": "len hlavné úlohy"
    },
    "modal": {
      "category": "Kategoria",
      "comment": "Poznámka",
      "date": "Dátum",
      "not_set": "Nezaradené",
      "spent_hours": "Strávené časy",
      "summary": "Súhrn",
      "task": "Úloha",
      "value": "Čiastka",
      "value_vat": "s DPH",
      "value_without_vat": "bez DPH",
      "variable_costs": "Variabilné náklady",
      "vat": "Daň"
    },
    "project_bilances": {
      "cash_flow": "Cash flow",
      "cash_flow_label": "skutočný príjem - skutočné náklady",
      "income_diff": "Zbývá vyfakturovat",
      "planned_expenses": "Plánované náklady",
      "project": "Projekt",
      "real_diff": "Budúce cash flow",
      "real_income": "Skutočný príjem",
      "title": "Bilancie projektov"
    },
    "report": {
      "add": "Pridať položku (úlohu)",
      "addNew": "Enterom vytvoríte novú položku",
      "add_expense": "Pridať náklad",
      "add_income": "Pridať príjem",
      "added_manually": "Plánovaný náklad bol pridaný ručne",
      "added_recalculated": "Plánovaný náklad bol vypočítaný z čiastkových nákladov alebo bol nastavený prepočítaním z odhadovaných časov.",
      "czech_currency": "Eur",
      "delete_budget_line": "Odobrať položky z rozpočtu",
      "diff": "Rozdiel",
      "expense": "Náklad",
      "expenseHours": "Náklad - variabilné",
      "expenses": "Náklady",
      "expenses_list": "Výpis nákladov:",
      "hidden_planned_expenses": "Skryté plánované náklady",
      "hidden_real_expenses": "Skryté skutočné náklady",
      "hide": "Nezobrazovať túto položku v rozpočte",
      "income": "Príjem",
      "incomes_list": "Výpis príjmov:",
      "list_all_expenses": "Výpis všetkých nákladov",
      "notify_higher_income": "Zadajte vyšší príjem",
      "notify_higher_income_text": "Celkový plánovaný príjem musí presahovať plánované príjmy v podprojektoch, ktoré sú teraz",
      "plan": "Plán",
      "planned_income": "Príjem (podľa zmluvy)",
      "planned_profit": "Plánovaný zisk",
      "project_budget": "Rozpočet projektu",
      "real_diff_label": "V projekte bude fakturované %{incomes}, zostávajúce náklady sú %{expenses}. Budúci peňažný tok je %{diff} aby bol splnený plán.",
      "real_expenses": "Skutočné náklady",
      "reality": "Skutočnosť",
      "spent_time": "Čas zapísaný mimo úlohy",
      "sum": "Bilancia",
      "sum_reserve": "Bilancia / rezerva",
      "toggle": "Prepnúť",
      "total": "Celkom",
      "total_real_expenses": "Súčet skutočných nákladov vrátane poúloh",
      "withVat": "s DPH",
      "withoutVat": "bez DPH"
    },
    "submenu": {
      "prefill": "Predvyplniť plánované náklady",
      "prefillText": "Prajete si predvyplniť plánované náklady výpočtom odhadovaného času krát nákladová hodinová sadzba riešiteľa?",
      "prefillWarning": "Pozor, stlačením áno prepíšete plán.",
      "showAllIssues": "Zobraziť v rozpočte všetky úlohy",
      "showOnlyRootIssues": "Zobraziť v rozpočte len hlavné úlohy",
      "sorting": {
        "default": "predvolený",
        "dueDate": "podľa termínov",
        "heading": "Radenie",
        "label": "Poradie položiek v rozpočte",
        "startDate": "podľa začiatkov",
        "subject": "podľa názvu"
      }
    }
  },
  "dashboard": {
    "add_blocks": {
      "add": "Pridať",
      "add_error": "Pridanie bloku zlyhalo. Skúste to, prosím, znova.",
      "choose": "Vyberte čo pridať",
      "labels": {
        "activities": "Posledná aktivita",
        "allprojects": "Všetky projekty",
        "estimatedhoursbyprojects": "Graf vyťaženia podľa projektov",
        "estimatedhoursbyusers": "Graf vyťaženia tímu",
        "estimatedhourstable": "Tabuľka vyťaženia tímu",
        "favouriteprojects": "Obľúbené projekty",
        "iframe": "Vlastný blok",
        "iframebottom": "Vlastní blok - spodný",
        "issuesassignedto": "Riešiteľom",
        "issuesreportedbyme": "Mnou delegované úlohy",
        "issueswatched": "Sledované úlohy",
        "polls": "Hlasovanie",
        "shouldbeinprogress": "Úlohy, ktoré majú byť v riešení",
        "solvedclosedtasks": "Vyřešené a uzavřené úkoly",
        "timelog": "Môj strávený čas"
      },
      "reset": "odobrať všetky",
      "reset_error": "Resetovanie blokov zlyhalo. Skúste to, prosím, znova.",
      "title_here": "Prispôsobiť túto stránku"
    },
    "blockEstimatedHoursByProjects": {
      "defaultView": "Pokiaľ nie je nič vybrané, použije sa predvolené zobrazenie - prvých 30 projektov.",
      "estimated_hours": "Vyťaženie podľa projektov",
      "graphTitle": "Výhľad na 4 mesiace",
      "no_due_date": "Bez termínu",
      "past": "V minulosti",
      "tableTitle": "Hodiny mimo graf"
    },
    "blockEstimatedHoursByUsers": {
      "estimated_hours": "Vyťaženie používateľov.",
      "graphTitle": "Výhľad na 4 mesiace",
      "no_due_date": "Bez termínu",
      "past": "V minulosti",
      "tableTitle": "Hodiny mimo graf"
    },
    "block_activities": {
      "title": "Posledná aktivita"
    },
    "block_all_projects": {
      "all_projects": "Všetky projekty",
      "empty": "Nemáte tu žiadne projekty."
    },
    "block_assigned_to": {
      "assigned_to": "Riešiteľom %{name}",
      "complete_list": "Kompletný výpis (%{size})\"",
      "empty": "Nemáte tu žiadne úlohy.",
      "my_tasks": "Moje úlohy"
    },
    "block_author_me_tasks": {
      "author_me_tasks": "Mnou delegované úlohy",
      "empty": "Nemáte žiadne úlohy.",
      "show": "Zobraziť"
    },
    "block_favorite_projects": {
      "empty": "Nemáte žiadne obľúbené projekty. Označte ich kliknutím na",
      "empty_end": "vo výpise projektov.",
      "favorite_projects": "Obľúbené projekty"
    },
    "block_my_tasks_today": {
      "5days": "Omeškané do 5 dní",
      "approaching": "Blíži sa",
      "approaching_dueDate": "Termín",
      "approaching_startDate": "Začiatok",
      "before_yesterday": "Predvčerom",
      "my_day": "Môj deň",
      "no_tasks": "Nemáte tu žiadne úlohy.",
      "to_resolved": "V oneskorení",
      "today": "Termín dnes",
      "yesterday": "Včera"
    },
    "block_my_time_entries": {
      "add": "Pridať čas",
      "allTimes": "Celkom",
      "comment": "Komentár",
      "complete": "Kompletný výpis",
      "empty": "V posledných 7 dňoch nemáte žiaden strávený čas.",
      "hours": "Hodiny",
      "project": "Projekt",
      "task": "Úloha",
      "title": "Môj strávený čas"
    },
    "block_polls": {
      "choice": "Voľba",
      "due_date": "Termín",
      "question": "Otázka",
      "task": "Úloha"
    },
    "block_solved_closed_tasks": {
      "collapse": "Sbalit",
      "expand": "Rozbaliť",
      "title": "Vyriešené a uzavreté úlohy"
    },
    "block_watched_tasks": {
      "allAssigned": "aj úlohy, kde som riešiteľom",
      "all_watched_tasks_with": "Všetky sledované úlohy (%{size})",
      "all_watched_tasks_without": "Všetky sledované úlohy bez úloh, kde som riešiteľom (% {size})",
      "assignedWithoutMe": "bez úloh, kde som riešiteľom",
      "empty": "Nemáte tu žiadne úlohy.",
      "show": "Zobraziť",
      "watched_tasks": "Sledované úlohy"
    },
    "complete_list": "Kompletný výpis",
    "planned_estimated_hours": "Naplánované",
    "spent_hours": "Zapísaný čas"
  },
  "date": {
    "abbr_day_names": [
      "Ne",
      "Po",
      "Ut",
      "St",
      "Št",
      "Pi",
      "So"
    ],
    "abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Máj",
      "Jún",
      "Júl",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dec"
    ],
    "day_names": [
      "Nedeľa",
      "Pondelok",
      "Utorok",
      "Streda",
      "Štvrtok",
      "Piatok",
      "Sobota"
    ],
    "formats": {
      "default": "%-d. %-m. %Y",
      "long": "%B %d, %Y",
      "short": "%b %d"
    },
    "moment_formats": {
      "default": "D.M.YYYY",
      "from": "[od] D.M.YYYY",
      "short": "D.M.",
      "to": "[do] D.M.YYYY"
    },
    "month_names": [
      null,
      "Január",
      "Február",
      "Marec",
      "Apríl",
      "Máj",
      "Jún",
      "Júl",
      "August",
      "September",
      "Október",
      "November",
      "December"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "datetime": {
    "distance_in_time": {
      "day_before_yesterday_at": "predvčerom v %{time}",
      "today_at": "dnes v %{time}",
      "yesterday_at": "včera v %{time}"
    },
    "distance_in_words": {
      "about_x_hours": {
        "few": "okolo %{count} hodín",
        "one": "okolo 1 hodiny",
        "other": "okolo %{count} hodín"
      },
      "about_x_months": {
        "few": "okolo %{count} mesiacov",
        "one": "okolo 1 mesiaca",
        "other": "okolo %{count} mesiace"
      },
      "about_x_years": {
        "few": "okolo %{count} rokov",
        "one": "okolo 1 roka",
        "other": "okolo %{count} roky"
      },
      "almost_x_years": {
        "few": "takmer %{count} rokov",
        "one": "takmer 1 rok",
        "other": "takmer %{count} roky"
      },
      "half_a_minute": "pol minúty",
      "less_than_x_minutes": {
        "few": "menej ako %{count} minútami",
        "one": "menej ako minúta",
        "other": "menej ako %{count} minút"
      },
      "less_than_x_seconds": {
        "few": "menej ako %{count} sekundami",
        "one": "menej ako 1 sekunda",
        "other": "menej ako %{count} sekúnd"
      },
      "over_x_years": {
        "few": "viac ako %{count} rokov",
        "one": "viac ako 1 rok",
        "other": "viac ako %{count} roky"
      },
      "x_days": {
        "few": "%{count} dni",
        "one": "1 deň",
        "other": "%{count} dní"
      },
      "x_hours": {
        "few": "%{count} hodín",
        "one": "1 hodina",
        "other": "%{count} hodín"
      },
      "x_minutes": {
        "few": "%{count} minúty",
        "one": "1 minúta",
        "other": "%{count} minút"
      },
      "x_months": {
        "few": "%{count} mesiacov",
        "one": "1 mesiac",
        "other": "%{count} mesiace"
      },
      "x_seconds": {
        "few": "%{count} sekundami",
        "one": "1 sekunda",
        "other": "%{count} sekúnd"
      },
      "x_weeks": {
        "few": "%{count} týždne",
        "one": "1 týždeň",
        "other": "%{count} týždňov"
      }
    },
    "prompts": {
      "day": "Deň",
      "hour": "Hodina",
      "minute": "Minúta",
      "month": "Mesiac",
      "second": "Sekunda",
      "year": "Rok"
    }
  },
  "default": {
    "admin_firstname": "Ukážka",
    "admin_lastname": "Projektově",
    "columns": {
      "assigned_to": "Riešiteľ",
      "author": "Autor",
      "due_date": "Termín",
      "estimated_hours": "Odhad",
      "priority": "Priorita",
      "project": "Projekt",
      "start_date": "Začiatok",
      "status": "Stav",
      "subject": "Úloha",
      "total_spent_hours": "Strávené"
    },
    "user": {
      "change_color": "Zmeniť farbu",
      "email": "e-mail",
      "firstname": "meno",
      "language": "jazyk",
      "lastname": "priezvisko"
    }
  },
  "estimated_hours_table": {
    "all_time": "Celkom",
    "block_name": "Naplánované hodiny",
    "today": "Dnes",
    "tomorrow": "Zajtra"
  },
  "files": {
    "addFile": "pridať súbor",
    "addFileToTask": "Zvoľte úlohu",
    "author": "Autor",
    "createdAt": "Dátum",
    "download": "Stiahnuť",
    "filename": "Súbor",
    "issue": "Uloha",
    "project": "Projekt",
    "projectFiles": "Súbory v projekte",
    "tasksFiles": "Súbory pri úlohách"
  },
  "gantt": {
    "add_name": "Zadajte názov novej úlohy",
    "add_new_task": "Pridať úlohu",
    "add_next_task_header": "Pridať nasledujúcu úlohu",
    "add_previous_task_header": "Pridať predchádzajúcu úlohu",
    "add_task": "Pridať úlohu",
    "adding_subtask_to_task": "Pridanie podúlohy k úlohe",
    "adding_task_to_project": "Pridanie úlohy k projektu",
    "cancel": "Zrušiť",
    "cancel_changes": "Zrušiť zmeny",
    "cant_export_grouped_by_assigned_to": "Zoskupený pohľad podľa riešiteľa nie je možné exportovať.",
    "change_parent_task_error": "Zmena, ktorú sa chystáte vykonať by viedla k zmene dátumu obálkovej úlohy. Táto funkcia je dostupná iba pre manažéra.",
    "color_by": {
      "default": "predvolená",
      "label": "Farba",
      "projects": "podľa projektu",
      "templateTask": "podľa úlohy zo šablóny"
    },
    "confirm_link_deleting": "bude odobratá.",
    "conflicts_warning": {
      "note": "Kliknutím na úlohu zobrazíte jej kartu, na ktorej môžete skontrolovať zmeny. Uložte diagram, aby ste nastavili dátumy začiatku a konca úloh z Ganttovho diagramu.",
      "task": {
        "few": "Úlohy",
        "one": "Úloha",
        "other": "Úlohy"
      },
      "was_changed": {
        "few": ", ktoré upravujete, boli zmenené aj na serveri.",
        "one": ", ktorú upravujete, bola zmenená aj na serveri.",
        "other": ", ktoré upravujete, boli zmenené aj na serveri."
      }
    },
    "date_range": {
      "all_time": "Všetko",
      "current_month": "Tento mesiac",
      "current_week": "Tento týždeň",
      "current_year": "Tento rok",
      "custom": "Vlastné",
      "next_month": "budúci mesiac",
      "next_week": "Budúci týždeň",
      "next_year": "Budúci rok",
      "three_months": "3 mesiace",
      "today": "Dnes"
    },
    "filter_tasks": "Filter na úlohy",
    "filter_user": "Filter na riešiteľa",
    "fullscreen": "Celá obrazovka",
    "group_by_assignee": "Zoskupiť podľa riešiteľa",
    "group_by_projects": "Zoskupiť podľa projektov",
    "heading": "Ganttov diagram",
    "label_days": "dní",
    "label_delete_relation": "Naozaj si prajete zmazať väzbu smerujúcu z",
    "label_done": "Hotovo",
    "label_duration": "Trvanie",
    "label_end_after_start": "Termín musí byť vyšší ako dátum začiatku.",
    "label_export": "Exportovať do",
    "label_from": "Z",
    "label_next": "Ďalší",
    "label_no": "Nie",
    "label_previous": "Predchádzajúci",
    "label_project": "Projekt",
    "label_start_before_end": "Dátum začiatku musí byť nižší ako termín.",
    "label_to": "Do",
    "label_to_small": "do",
    "label_yes": "Áno",
    "link": "Väzba",
    "new_task_added": "Nová úloha úspešne pridaná.",
    "new_task_not_added": "Novú úlohu sa nepodarilo pridať.",
    "next_tasks_header": "Existujúce nasledujúce úlohy",
    "no_next_tasks": "Žiadne existujúce následujúce úlohy.",
    "no_previous_tasks": "Žiadne existujúce predchádzajúce úlohy.",
    "other_exports": "Ďalšie varianty",
    "planned_dueDate": "Plánovaný koniec",
    "planned_startDate": "Plánovaný začiatok",
    "previous_tasks_header": "Existujúce predchádzajúce úlohy",
    "readonly_note": "Verzia pre čítanie. Pre aktívnu verziu musíte byť manažérom vo všetkých zobrazených projektoch.",
    "save_changes": "Uložiť zmeny",
    "save_first": "Najprv uložte alebo zahoďte zmeny.",
    "section_time": "Dátum začiatku a trvania úlohy",
    "show": {
      "all": "všetko",
      "label": "Zobraziť",
      "projects": "len projekty",
      "subtasks2": "skryť Podúlohy od 2 úrovne",
      "tasks": "projekty a hlavné úlohy"
    },
    "show_closed_tasks": "Zobraziť uzavreté",
    "sort_tasks": "Zoradiť úlohy",
    "task_duration": "Trvanie",
    "task_end": "Termín",
    "task_name": "Názov úlohy",
    "task_order": "Automaticky zoradiť úlohy pri úprave",
    "task_start": "Začiatok",
    "time_disable_button": "Odobrať časy",
    "time_enable_button": "Naplánovať",
    "type": {
      "envelope": "Obálka",
      "label": "Typ",
      "task": "Úloha"
    },
    "week": "Týždeň",
    "zoom_in": "Priblížiť",
    "zoom_out": "Oddialiť"
  },
  "header": {
    "all_projects": "Všetky projekty",
    "clear": "Zmazať zoznam. Potom stačí ostatné Projektové navštíviť, pridajú sa samé.",
    "menu": {
      "my_page": "Moja stránka",
      "my_tasks": "Moje úlohy",
      "new_member": "Nový kontakt",
      "new_task": "Nová úloha",
      "open_tasks": "Otvorené úlohy",
      "projects": "Projekty",
      "tasks_plural": "Úlohy"
    },
    "projects": {
      "current_project": "Výpis úloh v projekte"
    },
    "search": {
      "history": "Posledné hľadania",
      "in_project": "len v aktuálnom projekte",
      "placeholder": "Hľadať v úlohách"
    },
    "switch_to": "Prepnúť na:",
    "title": "Pro přehledné řízení firmy, projektů a úkolů.",
    "user_menu": {
      "calendar": "Kalendár",
      "client_profil": "Účet služby Projektově",
      "help": "Nápoveda",
      "last_activity": "Posledná aktivita",
      "logout": "Odhlásiť",
      "mobile_application": "Mobilná aplikácia",
      "my_settings": "Moje nastavenie",
      "test_v2": "Vyzkoušet Projektově 2",
      "this_week": "Tento týždeň",
      "timetracker": "Stopky",
      "users_settings": "Správa používateľov"
    }
  },
  "issues": {
    "bulk_edit": {
      "all_issues": "všetky otvorené úlohy",
      "all_issues_note": "z projektu vrátane podprojektov",
      "assigned_to": "úlohy priradené:"
    },
    "delete_modal": {
      "button_no": "nie",
      "button_yes": "áno, zmazať",
      "label": "Naozaj chcete úlohu zmazať?",
      "label_subtask": "Naozaj chcete pod-úlohu zmazať?",
      "note": "Týmto sa odstráni úloha so všetkými jej podúlohami."
    },
    "filters": {
      "assignedToId": "Riešiteľ",
      "authorId": "Autor",
      "buttons": {
        "apply": "Použiť",
        "cancel": "Zrušiť"
      },
      "calendar": "Kalendár",
      "closedAt": "Uzavreté",
      "createdAt": "Vytvorené",
      "customFields": "Vlastné pole",
      "customFields_icon_title": "Odobrať",
      "customFields_title": "Nastavené filtre",
      "dueDate": "Termín",
      "fromFavoriteProjects": "Z obľúbených projektov",
      "input_placeholder": "zadajte hľadaný text",
      "lastActivityAt": "Zmenené",
      "noActiveTag": "Tento filter v danom pohľade nie je možné použiť, preto nie je aktívny.",
      "no_assignedToId": "žiadny používateľ nebol nájdený",
      "no_authorId": "žiadny používateľ nebol nájdený",
      "no_one_assigned": "nepriradené",
      "no_projectId": "žiadny projekt nebol nájdený",
      "not_entered": "Nezadané",
      "others": "Ostatní",
      "parentId": "Bez podúloh",
      "priorityId": "Priorita",
      "projectId": "Projekt",
      "repetitive_false": "Bez opakov. úloh",
      "repetitive_true": "Opakované",
      "startDate": "Začiatok",
      "statusId": "Stav",
      "templates": "Vrátane úloh z šablón",
      "this_2_weeks_and_older": "Tieto 2 týždne a staršie",
      "this_4_weeks_and_older": "Tieto 4 týždne a staršie",
      "this_month": "Tento mesiac",
      "this_week": "Tento týždeň",
      "this_week_and_older": "Tento týždeň a starší",
      "today": "Dnes",
      "today_and_older": "Dnešné a staršie",
      "trackerId": "Fronta",
      "trakerId": "Fronta"
    },
    "index": {
      "add_first_task": "Zadajte prvú úlohu.",
      "add_remove_tasks_order": "Pridať / odobrať ďalšiu úroveň radenia",
      "add_tasks_from_template": "pridať úlohy zo šablóny",
      "bulk_edit": "Hromadne upraviť",
      "bulk_modal": {
        "assigned_to": "Úlohy priradené",
        "edit": "upraviť",
        "edit_all": "upraviť všetky otvorené úlohy"
      },
      "cant_be_closed": {
        "comment_wip": "Kartu úlohy nie je možné teraz zatvoriť, lebo máte rozpracovaný komentár k úlohe.",
        "description_wip": "Kartu úlohy nie je možné teraz zatvoriť, lebo máte rozpracovaný popis k úlohe.",
        "prompt": "Chcete prejsť na ďalšiu stránku?",
        "title": "Kartu úlohy nie je možne zavrieť"
      },
      "choose_calendar_list": "Vyberte si výpis, ktorý si prajete prepojiť s kalendárom.<br> Adresu vybraného výpisu následne vložte do Vášho kalendára.",
      "default_order": "Nastaviť predvolené radenie",
      "delete": "Zmazať úlohu %{subject}",
      "detail": "Detail",
      "exports_modal": {
        "title": "Exportovať výpisy úloh",
        "with_descriptions": "vrátane popisu úloh",
        "without_descriptions": "bez popisov úloh"
      },
      "grouped": {
        "group_assignee": "podľa riešiteľa",
        "group_author": "podľa autora",
        "group_by": "Zoskupiť",
        "group_dueDate": "podľa termínu",
        "group_lastActivityAt": "podľa aktivity",
        "group_none": "zrušiť zoskupenie",
        "group_priority": "podľa priority",
        "group_project": "podľa projektu",
        "group_startDate": "podľa začiatku",
        "group_status": "podľa stavu",
        "no_dueDate": "Bez termínu",
        "no_one_assigned": "Nepriradené",
        "no_startDate": "Bez začiatku",
        "no_value": "Bez hodnoty",
        "sample": "Ukážka P."
      },
      "ics_export": "Prepojiť s kalendárom",
      "ics_modal": {
        "all_tasks": "Všetky úlohy",
        "all_tasks_duration": "Všetky úlohy so začiatkami i termínmi",
        "all_tasks_only_with_times": "Všetky úlohy, len s časom",
        "my_tasks": "Moje úlohy",
        "my_tasks_duration": "Moje úlohy so začiatkami i termínmi",
        "my_tasks_only_with_times": "Moje úlohy, len s časmi"
      },
      "in_one_column": "všetko pod sebou",
      "in_structure": "vo vetvách",
      "link_with_calendar": "Prepojenie s kalendárom",
      "modals": {
        "new_issues": {
          "create": "Vytvoriť",
          "parent_task": "Nadradená úloha",
          "plan": {
            "from_end": "od konca",
            "from_start": "od začiatku",
            "help_left": "Plánovaný začiatok je známy, od neho sú nastavené začiatky a termíny úloh.",
            "help_right": "Začiatky a termíny úloh sú nastavené tak, aby posledná úloha v plánovanom termíně skončila.",
            "title": "Plánovať"
          },
          "planned_due_date": "Plán. termín",
          "planned_start_date": "Plán. začiatok",
          "success": "Úlohy úspešne pridané.",
          "template": "Šablóna",
          "title": "Pridať úlohy zo šablóny"
        }
      },
      "need_update": "Zobrazené úlohy už nie sú aktuálne, kliknite pre ich aktualizáciu.",
      "no_closed_tasks": "V projekte nie sú zatiaľ uzatvorené žiadne úlohy.",
      "no_overdue_tasks": "Super, úlohy sú bez omeškania.",
      "no_solved_tasks": "V projekte nie sú zatiaľ vyriešené žiadne úlohy.",
      "not_found": "Žial hľadanú úlohu sme nenašli.",
      "only_with_times": "iba s časmi",
      "order_tasks": "Zoradiť úlohy",
      "reminder": {
        "text": "Pripomienky k novému výpisu tu.",
        "title": "Pripojte sa do novej facebookovej skupiny. Žiadosť o pridanie obratom potvrdíme."
      },
      "search_task": "Úloha",
      "settings": {
        "add": "Pridať",
        "columns": "Nastaviť stĺpce",
        "disable": "Vypnúť",
        "enable": "Zapnúť",
        "horizontalScroll": " skrolovanie výpisu kolieskom",
        "reorder": "Zmeniť poradie"
      },
      "show_more": {
        "few": "Zobraziť ďalší %{count}",
        "one": "Zobraziť posledné",
        "other": "Zobraziť ďalších %{count}"
      },
      "show_structure_filter": "Zobraziť",
      "task_quickadd": "Zadajte názov novej úlohy a stlačte Enter",
      "task_quickadd_hitenter": "stlačte enter",
      "tasks_export": "Exportovať výpis úloh"
    },
    "messages": {
      "created": "Úloha bola úspešne vytvorená.",
      "errors": {
        "dueDate": {
          "greater_than_or_equal_to_start_date": "Termín úlohy nemôže byť skôr, ako začiatok úlohy.",
          "locked_task_in_project": "V projekte je úloha s uzamknutým začiatok a termínom. Úlohu nie je možné zmeniť.",
          "lower_than_or_equal_to_parent_due_date": "Termín úlohy nemôže byť neskôr, než termín nadradenej úlohy.",
          "start_and_due_date_locked": "Začiatok a termín úlohy bol manažérom uzamknutý. Je nutné ho prvý odomknúť."
        },
        "estimatedHours": {
          "greater_than": "Odhadovaný čas musí byť väčší ako súčet odhadov v podúlohách (%{count}h)"
        },
        "startDate": {
          "greater_than_or_equal_to_parent_start_date": "Začátek úkolu nemůže být dříve, než začátek nadřazeného úkolu."
        },
        "start_date": {
          "greater_than_or_equal_to_parent_start_date": "Začiatok úlohy nemôže byť skôr, než začiatok nadradenej úlohy."
        }
      },
      "open_task": "otvoriť vytvorenú úlohu"
    },
    "modals": {
      "repetitive": {
        "cancel": "zrušiť opakovanie",
        "day_in_month": "deň v mesiaci",
        "days_in_week": "Dni v týždni",
        "interval": "Interval",
        "last": "posledný",
        "months": {
          "few": "každé %{count} mesiace",
          "one": "každý mesiac",
          "other": "každých %{count} mesiacov"
        },
        "periodically_every": "čo",
        "periodically_label": "dní odo dňa uzavretia",
        "repeat": "Opakovať",
        "repeat_options": {
          "daily": "denne",
          "monthly": "mesačne",
          "never": "nikdy",
          "periodically": "pravidelne",
          "weekly": "týždenne",
          "yearly": "ročne"
        },
        "start": "Začať",
        "submit": "nastaviť",
        "title": "Nastavenie opakovania",
        "weeks": {
          "few": "každé %{count} týždne",
          "one": "každý týždeň",
          "other": "každých %{count} týždňov"
        },
        "years": {
          "few": "každé %{count} roky",
          "one": "každý rok",
          "other": "každých %{count} rokov"
        }
      },
      "tracker": {
        "label": "Pozor, so zmenou fronty stratíte hodnoty vlastných polí. Jedinou výnimkou je mať tieto vlastné polia aj v nové fronte. Chcete pokračovať?"
      }
    },
    "new": {
      "add_contact": "pridať kontakt",
      "assignee": "Riešiteľ",
      "contact": "Kontakt",
      "contact_name": "Názov kontaktu",
      "create_task": "vytvoriť úlohu",
      "customer_tittle": "Nové úlohy zadané zákazníkom sú priradené uvedenej osobe.",
      "due_date": "Termín",
      "errors": {
        "cannot_create": "Nepodarilo sa vytvoriť úlohu.",
        "date": "Termín úlohy nesmie byť skôr ako začiatok.",
        "name": "Nie je zadaný názov úlohy.",
        "project": "Nie je vybratý projekt."
      },
      "new_contact": "Nový kontakt",
      "new_task": "Nová úloha",
      "placeholders": {
        "assignee": "Nepriradené",
        "new_contact": "Názov kontaktu",
        "new_contact_in_project": "Kontakt do projektu %{project}",
        "new_task": "Názov úlohy",
        "new_task_in_project": "Úloha do projektu %{project}",
        "project": "Vyberte projekt",
        "starting_today": "Začína dnes"
      },
      "project": "Projekt",
      "set_start_date": "zadať dátum začiatku",
      "start_date": "Začiatok",
      "task": "Úloha",
      "task_name": "Názov úlohy",
      "tracker": "Fronta"
    },
    "no_due_date": "Bez termínu",
    "no_one_assigned": "Nepriradené",
    "show": {
      "activity_created_at": "Úloha bola vytvorená.",
      "add_comment": "Pridať komentár",
      "add_time": "Pridať čas k úlohe",
      "attachments": {
        "attach_delete": "Naozaj chcete súbor zmazať?",
        "attach_deleted": "Súbor bol zmazaný.",
        "attach_file": "Priložiť súbory",
        "attach_not_deleted": "Súbor sa nepodarilo zmazať.",
        "button_no": "Nie",
        "button_yes": "Ano, zmazať",
        "not_saved": "Ukladanie súboru sa nepodarilo.",
        "saved": "Súbory boli uložené",
        "saving": "Ukladám súbory...",
        "title_delete": "Odstrániť súbor"
      },
      "budget": {
        "add": "Pridať",
        "amount": "Suma",
        "bad_amount": "Suma nesmie byť nulová alebo záporná.",
        "date": "Dátum",
        "error": "Suma nebola pridaná. Prosím, skúste to znovu neskôr.",
        "expenses": "Výdavky",
        "note": "Poznámka",
        "title": "Pridať náklad",
        "vat": "Daň"
      },
      "cannot_add_comment": "Uzavreté úlohy nie je možné komentovať. Prosím založte novú úlohu cez tlačidlo hore.",
      "close": "Uzavrieť?",
      "close_title": "Uzavrieť úlohu",
      "closed": "Uzavreté",
      "done_ratio_locked": "Hotové percenta sa počítajú cez podúlohy. Upravte prosím % hotovo v nich.",
      "due_date_locked": "Termín určil autor úlohy, len on ho môže zmeniť.",
      "due_reminder": {
        "add": "Pridať pripomenutie",
        "before": "pred termínom splnenia",
        "title": "Pripomenutie",
        "tooltip": "pridať pripomenutie termínu e-mailom pre riešiteľa."
      },
      "editor": {
        "cancel": "Zahodiť zmeny",
        "placeholder": "Podrobnosti pre Vás alebo pre kolegov...",
        "save": "Uložiť"
      },
      "open_task": "Otvoriť úlohu",
      "options": {
        "add_tasks": "Pridať úlohy zo šablóny",
        "copy": "Duplikovat úlohu",
        "delete": "Zmazať úlohu",
        "download": "Stiahnuť ako PDF bez komentárov",
        "download_full": "Stiahnuť ako PDF s komentármi",
        "lock_dates": "Zamknout začátek a termín",
        "title": "Nastavenie úlohy",
        "unlock_dates": "Odemknout začátek a termín"
      },
      "parent": {
        "loading": "Načítavanie...",
        "more_items": {
          "few": "...a ďalšia %{count} úloha. Upresnite hľadanie.",
          "one": "...a 1 ďalšia úloha. Upresnite hľadanie.",
          "other": "...a ďalšie %{count} úlohy. Upresnite hľadanie."
        },
        "no_items": "Nebola nájdená žiada úloha.",
        "no_parent": "Žiadny"
      },
      "project": {
        "no_items": "Nebol nájdený žiadny projekt."
      },
      "quill_editor": {
        "h1": "Nadpis",
        "h2": "Podnadpis",
        "normal": "Normálne"
      },
      "relations": {
        "add_related": "Pridať súvisiaci",
        "close_title": "Uzavrieť",
        "closed_title": "Uzavreté",
        "hint_relates": "Začnite písať názov úlohy",
        "hint_subtask": "Zadajte názov podúlohy a stlačte Enter",
        "label_relates": "Súvisiace: %{name}",
        "label_subtask": "Podúloha",
        "link_relates": "Súvisiace",
        "open_title": "Otvorený",
        "relates": "Súvisiaci",
        "remove_relation_to_relates": "Odobrať väzbu na súvisiacu úlohu",
        "reopen_title": "Uzavreté, vrátiť do stavu \"Rieši sa\"",
        "solve_title": "Vyriešiť",
        "solved_title": "Vyriešené",
        "unsolve_title": "Vyriešené, vrátiť do stavu \"Rieši sa\""
      },
      "reopen_title": "Otvoriť úlohu",
      "repetitive": {
        "add": "nastaviť opakovanie",
        "disabled": "nie je možné nastaviť opakovanie",
        "disabled_reason": {
          "already_repeated": "Táto úloha už bola zopakovaná. Upravte nastavenia v poslednej aktívnej úlohe, ktorú nájdete medzi otvorenými úlohami v projekte.",
          "already_repetitive": "Niektorá z nadúloh alebo podúloh už má nastavené opakovanie."
        },
        "edit": "zmeniť opakovanie",
        "next_due": "Termín ďalšej úlohy",
        "wrong_input": "vyberte aspoň jeden deň",
        "wrong_next_due": "Nesúhlasí budúci termín? Zrušte opakovanie a nastavte nové."
      },
      "solve": "Vyriešiť?",
      "solve_title": "Označiť úlohu ako vyriešenú, aby ju autor mohol uzavrieť.",
      "solved": "Vyriešené",
      "task_has_x_files": {
        "few": "Úloha má %{count} súborov",
        "one": "Úloha má %{count} súbor",
        "other": "Úloha má %{count} súborov"
      },
      "total_spent_hours": "%{hours} celkom",
      "unsolve_title": "Označiť úlohu ako nevyriešenú (rieši sa)",
      "watchers": {
        "add": "Pridať sledujúcich",
        "add_watchers": "Pridať",
        "assigned": "Úloha je priradená %{name}",
        "check_all": "zaškrtnúť všetko",
        "dialog_title": "Pridať sledujúcich",
        "how_it_looks": "Tu je <a href=\"%{url}\" target=\"_blank\">náhľad</a>, ako to vidí prizvaná osoba.",
        "label": "Sledujúci",
        "not_search": "Žiaden člen s týmto menom nebol nájdený.",
        "notes": {
          "all_added": "Všetci súčasní členovia tímu už boli pridaní.",
          "externalusers_notifications": "Na e-mail im prichádzajú len komentáre, na ktoré môžu tiež rovno z e-mailu (odpovedí na e-mail) reagovať.",
          "externalusers_visibility": "V aplikácii máte užívateľov, ktorí sa so svojim e-mailom a heslom môžu prihlásiť a spolupracovať na úlohách. Užívatelia môžu sledovať ľubovoľné úlohy. Keď budem v sledovaní úlohy, budú mi na e-mail chodiť notifikácia so zmenami úlohy a komentármi. Ak komentáre chcem nasdílet ostatným, ktorí nemajú prístup do systému, môžem použiť Externý sledujúci. Títo Externý sledujúci (bez registrácie) nemajú prístup do systému, cez odkaz v e-maile vidí len názov úlohy, popis úlohy a komentáre.",
          "how_to": "Pokiaľ chcete úlohu zdieľať s ďalšími kolegami, najskôr im nastavte zdieľanie tohto projektu alebo požiadajte manažéra tímu, aby projekt kolegom nazdieľal."
        },
        "remove": "Odobrať %{name} zo sledovania úlohy",
        "removeExternal": "Odstrániť externého sledujúceho% %{name}. Odstránením stratí prístup k obmedzenému náhľadu úlohy.",
        "tab_external": "Zdieľať bez registrácie",
        "tab_users": "Registrovaní používatelia",
        "uncheck_all": "odkškrtnúť všetko"
      }
    },
    "show_static": {
      "no_comments_yet": "Zatím zde nejsou žádné komentáře."
    },
    "taskParentError": {
      "add": "Pridať",
      "added": "Pridané.",
      "ask1": "Prosím, požiadajte manažéra projektu",
      "ask2": "aby ich sem pridal.",
      "heading": "V cieľovom projekte chýbajú používatelia",
      "missingTracker": "V cieľovom projekte chýba front. Presunutím úlohy bude jeho front automaticky zmenený a tým zahodené dáta z pripojených vlastných polí. Naozaj chcete pokračovať?",
      "move": "Presunúť",
      "note1": "Presunutím úlohy by používatelia nižšie k úlohe stratili prístup",
      "role": "Rola",
      "step1": "Najskôr pridajte používateľa do projektu",
      "step2": "A následne presuňte úlohu",
      "user": "Používateľ"
    },
    "time_tracker": {
      "comment": "komentár",
      "error_already_runing": "Stopky už dávno bežia.",
      "stopped": "Úspešne uložené",
      "stopped_and_started": "Uložené a spustené.",
      "stopped_and_started_text": "K úlohe% {issue} bol pripísaný strávený čas a zároveň boli stopky pre ďalšiu úlohu znova spustené."
    }
  },
  "journals": {
    "ShowAll": "Zobraziť celú históriu úlohy",
    "add": "pridaný",
    "allDayChange": "celodennú udalosť zmenená z",
    "attributes": {
      "assigned_to_id": "riešiteľ",
      "attachment": "súbor",
      "author_id": "autor",
      "description": "bol aktualizovaný opis úlohy",
      "done_ratio": "% hotovo",
      "due_date": "termín",
      "estimated_hours": "odhadovaný čas",
      "parent_id": "nadúloha",
      "priority_id": "priorita",
      "project_id": "projekt",
      "start_date": "začiatok",
      "status_id": "stav",
      "subject": "meno úlohy",
      "tracker_id": "fronta"
    },
    "button_cancel": "zahodiť zmeny",
    "button_save": "uložiť",
    "changedFromMan": "zmenený z",
    "changedFromThing": "zmenené z",
    "changedFromWoman": "zmenená z",
    "comment_deleted": "Komentár bol zmazaný.",
    "customField": "voliteľné pole",
    "deleteMan": "zmazaný",
    "deleteThing": "vymazané",
    "deleteWoman": "zmazaná",
    "edit_note": "Úprava komentára nezasiela e-maily. Ak chcete zaslať e-mail, vložte nový komentár.",
    "events": {
      "repeated_from": {
        "link_label": "predchádzajúca",
        "title": "Vytvorené zopakovaním úlohy."
      },
      "repeated_to": {
        "link_label": "následujúca",
        "title": "Vytvorená opakovaná úloha."
      },
      "user_archived": "Používateľ <b>%{user}</b> bol archivovaný."
    },
    "hours": "hod",
    "newDescriptionValue": "Nový popis",
    "no": "nie",
    "oldDescriptionValue": "Pôvodný popis",
    "setToMan": "nastavený na",
    "setToThing": "nastavené na",
    "setToWoman": "nastavená na",
    "showAll": "Zobrazit celou historii úkolu",
    "to": "na",
    "userDeleted": "používateľ už bol zmazaný",
    "yes": "áno"
  },
  "labels": {
    "actions": {
      "add": "Pridať",
      "archive": "Archivovať",
      "cancel": "Zrušiť",
      "close": "Zavrieť",
      "confirm_archive": "archivovať",
      "confirm_delete": "áno, zmazať",
      "confirm_move": "ano, presunúť",
      "confirm_reset_password": "áno, resetovať",
      "create": "Vytvoriť",
      "delete": "Zmazať",
      "delete_from_all_tasks": "Odstrániť zo všetkých úloh",
      "edit": "Upraviť",
      "print": "Tlač",
      "refresh": "Obnoviť",
      "register": "Registrovať",
      "remove": "Odobrať",
      "reset": "Resetovať",
      "save": "Uložiť",
      "zoomIn": "Priblížiť",
      "zoomOut": "Oddialiť"
    },
    "choose": "Vyberte",
    "columns": {
      "assignedTo": "Riešiteľ",
      "author": "Autor",
      "budgetRealExpense": "Náklady",
      "close_toggle": "Uzatvorené",
      "createdOn": "Vytvorené",
      "doneRatio": "Hotovo",
      "dueDate": "Termín",
      "estimatedHours": "Odhadovaný čas",
      "id": "#",
      "lastActivityAt": "Aktualizované",
      "parent": "Nadúloha",
      "parentProject": "Nadradený projekt",
      "parentTask": "Nadradená úloha",
      "priority": "Priorita",
      "project": "Projekt",
      "relations": "Súvisiace",
      "remainingHours": "Zostávajúci čas",
      "spentHours": "Strávený čas",
      "startDate": "Začiatok",
      "status": "Stav",
      "subject": "Úloha",
      "timeTracker": "Stopky",
      "totalEstimatedHours": "vr. podúloh",
      "totalEstimatedHoursTitle": "Odhadovaný čas vrátane podúloh",
      "totalExpenses": "Náklady",
      "totalSpentHours": "Strávený čas vrátane podúloh",
      "tracker": "Fronta",
      "updatedAt": "Aktualizované"
    },
    "custom_field_types": {
      "bool": "Ano/Ne",
      "date": "Dátum",
      "file": "Súbor",
      "int": "Celé číslo",
      "list": "Zoznam",
      "string": "Text",
      "text": "Dlhý text",
      "url": "Url"
    },
    "false": "nie",
    "help": "Nápoveda",
    "languages": {
      "cs": "český",
      "de": "nemecký",
      "en": "anglický",
      "hr": "chorvátčina",
      "sk": "slovenský"
    },
    "loading": "Nahrávam ...",
    "modified": "upravené",
    "priority": {
      "high": "Vysoká",
      "low": "Nízka",
      "normal": "Normálny",
      "urgent": "Urgentné"
    },
    "query": {
      "1": "Moje úlohy",
      "1-contacts": "Moje kontakty",
      "10": "Moje uzatvorené úlohy",
      "11": "Delegované úlohy v omeškaní",
      "12": "Moje omeškané úlohy",
      "13": "Moje úlohy",
      "14": "Bez protokolov",
      "15": "Moje sledované úlohy",
      "16": "Moje sledované úlohy, kde nie som riešiteľom",
      "17": "Vyriešené úlohy, ktorých som autorom",
      "18": "Delegovanej úlohy vo meškanie",
      "19": "Úlohy, ktoré majú byť v riešení",
      "2": "Omeškané úlohy",
      "2-contacts": "Omeškané kontakty",
      "3": "Otvorené úlohy",
      "3-contacts": "Otvorené kontakty",
      "4": "Ukončené úlohy",
      "4-contacts": "Uzatvorené kontakty",
      "5": "Vyriešené úlohy",
      "5-contacts": "Vyriešené kontakty",
      "6": "Mnou delegované úlohy",
      "7": "Moje zadané úlohy",
      "8": "Štrukturovaný výpis",
      "9": "Moje úlohy podľa dátumu",
      "all": "Všetky úlohy",
      "custom": "Vlastné radenie"
    },
    "time_entries": {
      "activity": "Aktivita",
      "comment": "Komentár",
      "date": "Dátum",
      "hours": "Strávený čas",
      "user": "Používateľ"
    },
    "today": "dnes",
    "true": "áno",
    "user": {
      "deleted": "Užívateľ zmazaný",
      "email": "E-mail",
      "firstname": "Meno",
      "lastname": "Priezvisko"
    }
  },
  "messages": {
    "announcements": {
      "deprecated_browser": {
        "info": "Projektově v blízkej budúcnosti ukončí podporu pre Internet Explorer 11.",
        "note1": "Stále bude možné aplikáciu v IE 11 zobraziť, avšak aplikácia nebude pre tento prehliadač optimalizovaná.",
        "note2": "Spoločnosť Microsoft k 17. 8. 2021 tiež končí podporu IE 11 vo svojich produktoch. Odporúčame preto použiť modernejší prehliadač, napríklad Google Chrome alebo Microsoft Edge."
      },
      "link_email": "Znovu zapnúť zasielanie notifikácií.",
      "nps": {
        "confirmation": "Ďakujeme.",
        "note_10": "Ďakujeme. Čo Projektovo robí dobre, že si vyslúžilo najvyššie hodnotenie?",
        "note_9": "Ďakujeme. Čo Projektovo robí dobre, že si vyslúžilo vysoké hodnotenie?",
        "note_low": "Ďakujeme. Čo môžeme urobiť, aby si Projektově zaslúžilo vyššie skóre?",
        "postpone": "odpoviem neskôr",
        "question": "Odporučili by ste Projektovo iným manažérom alebo firmám?",
        "scale_high": "Určite áno",
        "scale_low": "Určite nie",
        "send": "Odoslať"
      },
      "release": {
        "message": "Je k dispozícii nová verzia aplikácie.",
        "update": "vykonať aktualizáciu"
      },
      "text_email": "Odosielanie e-mailových notifikácií o zmenách úloh (od Vás kolegom) je teraz pozastavené.",
      "trial": {
        "expires": {
          "few": "Teraz používate skúšobnú verziu. Tá končí za %{count} dni.",
          "one": "Zajtra končí Vaša skúšobná verzia. Objednajte Projektovo ešte dnes, nech môžete bez prerušenia pokračovať.",
          "other": "Teraz používate skúšobnú verziu. Tá končí za %{count} dní.",
          "zero": "Dnes končí Vaša skúšobná verzia. Objednajte Projektovo ešte dnes, nech môžete bez prerušenia pokračovať."
        },
        "note": "O dni zadarmo neprídete, platený účet plynule nadväzuje na skúšobnú verziu.",
        "order_now": "Objednať"
      }
    },
    "by_code": {
      "1060": "E-mail je obsadený",
      "1061": "E-mailová adresa je už použitá ako vaša primárna E-mailová adresa.",
      "2220": "Front '%{tracker_name}' je používaný úlohami v projekte a nie je možné ho odstrániť.",
      "2301": "Projekt nemohl být převeden na složku, protože obsahuje úkoly.",
      "2302": "Projekt nemohl být převeden na složku, protože má nadžazený projekt. Složka nemůže být vytvořena v projektu.",
      "2303": "Složka nemůže být prevedena na projekt, dokud obsahuje podsložky."
    },
    "errors": {
      "adding_time_entry_failed": "Pri pridávaní stráveného času došlo k chybe.",
      "cannot_save": "Nepodarilo sa uložiť",
      "create_issue_failure": "Vytvorenie úlohy sa nepodarilo.",
      "create_relation_failure": "Pridanie súvislosti sa nepodarilo.",
      "error": "Chyba",
      "exceeds_tariff": {
        "assign_role": "Používateľa nebolo možné pridať. Pred pridaním ďalšieho člena v tejto úlohe je nutné, aby administrátor navýšil balík služby Projektově."
      },
      "file_too_big": "Súbor %{file} je pre nahranie príliš veľký.",
      "file_upload": "Nepodarilo sa nahrať súbor.",
      "forbidden": "Pre danú požiadavku nemáte oprávnenie.",
      "form_reading_failed": "Načítanie formulára sa nepodarilo. Skúste to prosím znova.",
      "general": "Ejha, stala sa chyba. Skúste to prosím neskôr.",
      "gql": {
        "error": "Pri načítaní sa stala chyba.",
        "retry": "Skúsiť znova"
      },
      "is_primary_mail": "Adresa %{mail} je už použitá ako Váš hlavný e-mail.",
      "issue_assignee_change_failure": "Zmena riešiteľa sa nepodarila.",
      "issue_closing_or_opening_failure": "Nepodarilo sa uzavrieť/otvoriť úlohu.",
      "issue_due_date_change_failure": "Zmena termínu sa nepodarila.",
      "issue_status_change_failure": "Nepodarilo sa zmeniť stav úlohy.",
      "mail_already_used": "Adresu %{mail} už používa iný používateľ.",
      "removing_relation_failure": "Väzbu sa nepodarilo odstrániť."
    },
    "feedback": {
      "error": "Chyba pri ukladaní",
      "loading": "Nahrávajú sa projekty a úlohy ...",
      "pending": "Ukladám...",
      "processing_request": "Chvíľku strpenia, požiadavka sa spracováva...",
      "saved": "Všetky zmeny uložené."
    },
    "member_role_changed": "Rola bola zmenená",
    "select": {
      "no_options": "Žiadne dostupné voľby",
      "type_to_search": "Začnite písať pre vyhľadanie..."
    },
    "success": {
      "changed": "Úspešne zmenené.",
      "deleted": "Úspešne zmazané",
      "password_changed": "Heslo bolo úspešne zmeněné.",
      "password_reset_sent": "Inštrukcie k obnoveniu hesla boli odoslané na e-mail používateľa.",
      "saved": "Úspešne uložené."
    }
  },
  "mindmap": {
    "addSubtask": "Přidat podúkol",
    "addTask": "Pridať podúlohu",
    "collapse": "Skryť podúlohy",
    "expand": "Zobraziť podúlohy",
    "heading": "Myšlienková mapa",
    "hide_closed": "Skryť uzatvorené",
    "order": {
      "default": "predvolené",
      "dueDate": "podľa termínu",
      "label": "Radenie",
      "priority": "podľa priority",
      "subject": "podľa názvu",
      "updatedAt": "podľa poslednej zmeny"
    },
    "relations": {
      "howto1": "Táto myšlienková mapa zobrazí úlohy tak ako sú naplánované v čase za sebou podľa väzieb v Ganttovom diagrame.",
      "howto2": "Zatiaľ úlohy žiadne väzby nemajú. Otvorte v ľavom menu Ganttov diagram a pridajte väzby medzi úlohami v ňom."
    },
    "view": {
      "basic": "základná",
      "extended": "rozšírená",
      "relations": "podľa väzieb"
    }
  },
  "mobileApplication": {
    "benefit1": "S mobilnou aplikáciou budete mať všetky svoje projekty po ruke.",
    "benefit2": "Ľahko zadáte novú úlohu a skontrolujete stav projektu.",
    "fastLogin": "Rýchle prihlásenie:",
    "fastLogin2": "Spustite mobilnú aplikáciu, kliknite na prihlásenie QR a namierte fotoaparátom na obrázok nižšie.",
    "getItGoogle": "Teraz na Google Play",
    "getItiOS": "Stiahnuť na App Store",
    "installation": "Inštalácia",
    "login": "Prihlásenie",
    "step1": "Spustite mobilnú aplikáciu,",
    "step2": "kliknite na prihlásenie cez QR kód",
    "step3": "namierte na obrázok nižšie:",
    "step4": "hotovo, cez obrázok budete automaticky prihlásený.",
    "step5": "Pokiaľ nie, druhou možnosťou je ručne vyplniť adresu (hore z adresného riadku), Váš e-mail a heslo, s ktorým sa prihlasujete.",
    "title": "Ako nainštalovať mobilnú aplikáciu?"
  },
  "my": {
    "account": {
      "api": {
        "app_load": "Znovu načítať celú aplikáciu",
        "app_reload": "Znovu načíst celou aplikaci",
        "created": "Prístupový klúč pre RSS bol vytvorený pred %{value}",
        "reset": "Resetovať kľúč",
        "show": "Zobraziť",
        "title": "API prístupový kľúč"
      },
      "languages": {
        "croatian": "Hrvatski",
        "czech": "Čeština",
        "english": "English",
        "german": "Deutsch",
        "slovak": "Slovenčina"
      },
      "mail": {
        "change_all_day": "Pridať čas splnenia",
        "change_assigned_to": "Zmena riešiteľa",
        "change_attachment": "Pridanie prílohy",
        "change_author": "Zmena autora",
        "change_description": "Zmena popisu úlohy",
        "change_done_ratio": "Zmena hotových percent",
        "change_due_date": "Zmena termínu splnenia",
        "change_estimated_hours": "Zmena odhadovaného času",
        "change_notes": "Pridanie komentára",
        "change_parent_id": "Presun úlohy",
        "change_priority_id": "Zmena priority",
        "change_project_id": "Zmena projektu",
        "change_start_date": "Zmena začiatku",
        "change_status": "Zmena stavu",
        "change_subject": "Zmena názvu úlohy",
        "estimated_hours_exhausted": "Varování odhadovaných časov",
        "estimated_hours_exhausted_note": "Odesílat notifikáciu pri naplnení odhadovaného času z 80 %.",
        "mail_mark_off": "Začiarknuť odporúčané nastavenia",
        "mail_note": "Máte od nás priveľa e-mailov?",
        "mail_notification": "E-mailové oznámenia",
        "mail_settings": "E-mailové nastavenia",
        "new_task": "Niekto vám vytvoril alebo postúpil úlohu",
        "newsletter": "Odoberať newsletter",
        "newsletter_note": "Odosielame max. 1x mesačne tipy na riadenie projektov a novinky.",
        "oncoming_tasks_summary": "Zobrazovať Blížiace sa termíny",
        "receive_notififactions_when": "Prijímať upozornenia pri",
        "save_changes": "Uložiť zmeny",
        "set_to_all_users": "Prepísať nastavenia e-mailov všetkým užívateľom",
        "solved_task": "Niekto vyriešil alebo uzavrel úlohu, ktorej ste autorom",
        "summaries": "Súhrnné ranné e-maily",
        "title": "E-mailové nastavenia"
      },
      "mail_notifications": {
        "default": "Zasielať upozornenia",
        "none": "Nezasielať žiadne upozornenia"
      },
      "mail_summaries": {
        "everyday": "každý deň",
        "never": "nikdy nezasielať",
        "no_weekends": "každý deň mimo víkendov"
      },
      "mobile": {
        "install": "Inštalácia",
        "login": "Rýchle prihlásenie",
        "text": "Spustite mobilnú aplikáciu, kliknite na prihlásenie QR a namierte fotoaparátom na obrázok nižšie.",
        "title": "Mobilná aplikácie"
      },
      "prefill_due_date": {
        "none": "nie",
        "today_plus": {
          "few": "dnes +%{count} dní",
          "one": "dnes +1 deň",
          "other": "dnes +%{count} dní"
        }
      },
      "profile_image": {
        "cancel_changes": "Zrušiť zmeny",
        "choose_crop": "Vyberte časť obrázka, ktorú chcete orezať.",
        "crop_tutorial": "Kliknite a ťahom vpravo dole alebo ak orezávate sprava zospodu, tak potom ťahom vľavo hore, označíte výrez obrázku, ktorý chcete získať.",
        "dropzone_tutorial": "Pretiahnite obrázok myšou alebo kliknite na vybrať súbor.",
        "use_crop": "Použiť"
      },
      "project_overview": {
        "gantt": "Ganttov diagram",
        "issues": "Výpis úloh - otvorené úlohy",
        "issues_closed": "Výpis úloh - uzavreté",
        "issues_my_tasks": "Výpis úloh - len moje úlohy",
        "issues_overdue": "Výpis úloh - oneskorené",
        "issues_solved": "Výpis úloh - vyriešené",
        "mind_map": "Myšlienková mapa",
        "overview": "Prehľad projektu",
        "tasks_board": "Tabule s úkoly"
      },
      "tasks_in_summary": {
        "1": "úlohy na nasledujúci deň",
        "14": "úlohy s termínom do 14 dní",
        "3": "úlohy s termínom do 3 dní",
        "7": "úlohy s termínom do 7 dní",
        "none": "nie"
      },
      "user": {
        "due_date_preset": "Prednastavovať termín splnenia",
        "errors": {
          "invalid": "Neplatný e-mail",
          "taken": "E-mail je obsadený"
        },
        "firstname": "Meno",
        "language": "Jazyk",
        "lastname": "Priezvisko",
        "login_mail": "E-mail / Prihlásenie",
        "other_mail": "Moje ďalšie e-mailové adresy",
        "project_view": "Po kliknutí na projekt zobraziť",
        "time_zone": "Časové pásmo",
        "title": "Môj účet"
      }
    }
  },
  "number": {
    "currency": {
      "czk": {
        "delimiter": " ",
        "format": "%n %u",
        "precision": 2,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "Kč"
      },
      "eur": {
        "delimiter": ".",
        "format": "%n %u",
        "precision": 2,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "€"
      },
      "format": {
        "delimiter": " ",
        "format": "%n %u",
        "precision": 2,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "€"
      }
    },
    "format": {
      "delimiter": " ",
      "precision": 2,
      "separator": ",",
      "significant": false,
      "strip_insignificant_zeros": true
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Miliarda",
          "million": "Milión",
          "quadrillion": "Biliarda",
          "thousand": "Tisíc",
          "trillion": "Bilión",
          "unit": ""
        }
      },
      "format": {
        "delimiter": " ",
        "precision": 1,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "few": "Bajty",
            "one": "Bajt",
            "other": "Bajtov"
          },
          "gb": "GB",
          "kb": "kB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": " ",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": " "
      }
    }
  },
  "polls": {
    "add_answers": "Zadajte odpoveď",
    "add_choice": "+Pridať možnosť...",
    "add_ok": "Hlasovanie bolo pridané.",
    "add_poll": "Pridať hlasovanie",
    "add_question": "Zadajte otázku",
    "block_name": "Hlasovanie",
    "confirm_no": "nie",
    "confirm_yes": "áno, zmazať",
    "delete_answer": "Zmazať odpoveď",
    "delete_choice_confirm": "Snažíte sa zmazať odpoveď, pri ktorej sa už hlasovalo. Skutočne ju chcete odstrániť vrátane zaznamenaných hlasov?",
    "delete_ok": "Hlasovanie bolo zmazané.",
    "delete_poll": "Zmazať hlasovanie",
    "delete_poll_confirm": "Naozaj chcete hlasovanie zmazať? Všetky zaznamenané hlasy používateľov budú tiež nenávratne zmazané.",
    "edit_ok": "Hlasovanie bolo upravené.",
    "edit_poll": "Upraviť hlasovanie",
    "empty_question": "Bola zadaná prázdna otázka. Prosím, vyplňte ju.",
    "error": "Chyba pri ukladaní. Skúste to znovu neskôr.",
    "error_conflict": "Hlasovanie nie je možné pridať, pretože táto úloha už hlasovanie obsahuje.",
    "error_insufficient_choices": "Hlasovanie musí mať najmenej 2 možnosti.",
    "no_polls": "Teraz nesledujete žiadne hlasovania.",
    "no_votes": "Nehlasované",
    "successfully_voted": "Váš hlas bol zaznamenaný."
  },
  "projectOverview": {
    "estimated_hours": "odhadovaný čas",
    "heading": "Prehľad projektu",
    "hide": "Zobraziť",
    "hours": "hod",
    "planned_due_date": "Plánovaný koniec",
    "planned_start_date": "Plánovaný začiatok",
    "remaining_hours": "Zostávajúci čas",
    "show": "Skryť",
    "spent_hours": "Zapísaný čas"
  },
  "projects": {
    "archive": {
      "cant_unarchive": "Nemožno obnoviť",
      "error_cant_unarchive": "Nebolo možné obnoviť projekt, pretože niektorý z nadprojektů bol archivovaný.",
      "exceeds_tariff_modal": {
        "exceeded_tariff": "Máte viac aktívnych účtovaných užívateľov než je počet užívateľov objednaného balíčka Projektovo.",
        "explanation": {
          "few": "Členmi projektu sú %{count} užívatelia, ktorí by po obnovení projektu prekračovali aktuálny balíček Projektovo.",
          "one": "Členom projektu je používateľ <b>%{user}</ b>, ktorý by po obnovení projektu prekračoval aktuálny balíček Projektovo.",
          "other": "Členmi projektu zapojené je %{count} používateľov, ktorí by po obnovení projektu prekračovali aktuálny balíček Projektovo."
        },
        "overlimit_users": "Používatelia, ktorí budú novo spoplatňovaní po obnovení projektu",
        "tip": "Navýšte balíček Projektovo alebo archivujte potrebný počet užívateľov, aby ste mohli projekt obnoviť.",
        "title": "Projekt nebolo možné obnoviť"
      },
      "from_project": "Z aktívneho projektu",
      "remove": "Trvalo zmazať projekt",
      "title": "Archív projektov",
      "unarchive": "Obnoviť projekt",
      "unarchiveInfoModal": {
        "h2": "Najskôr obnovte nadradený projekt",
        "h3": "Postup",
        "note1": "Projekt",
        "note2": "je zaradený v takisto archivovanom projekte",
        "note3": "Pre zachovanie štruktúry projektov je nutné",
        "step1": "Obnovte z archívu nadradený projekt %{name}",
        "step2": "Obnovte tento projekt - %{name}"
      }
    },
    "breadcrumbs": {
      "all_projects": "Všetky projekty",
      "end_path": {
        "budget_entries": "Rozpočet projektu",
        "contacts": "Výpis kontaktov",
        "files": "Súbory v projekte",
        "gantt": "Ganttov diagram",
        "issues": "Výpis úloh",
        "mindmap": "Myšlienková mapa",
        "overview": "Prehľad projektov",
        "settings": "Nastavenie",
        "tasks_board": "Tabuľa s úlohami",
        "team_settings": "Členovia projektu",
        "time_entries": "Výpis stráveného času"
      },
      "subprojects": "Podprojekty"
    },
    "filters": {
      "buttons": {
        "apply": "Použiť",
        "cancel": "Zrušiť"
      },
      "calendar": "Kalendár",
      "customFields": "Vlastné pole",
      "customFields_icon_title": "Odobrať",
      "customFields_title": "Nastavené filtre",
      "doneRatio": "Priebeh",
      "doneRatio_g": "je väčšia než",
      "doneRatio_i": "je",
      "doneRatio_l": "je menšia než",
      "favorites": "Obľúbené",
      "input_placeholder": "zadajte hľadaný text",
      "ismanager": "Som manažérom",
      "member": "Člen tímu",
      "not_entered": "Nezadané",
      "only_favorites": "iba obľúbené",
      "only_favorites_tree": "obľúbené s podprojektmi",
      "only_templates": "iba šablóny",
      "plannedDueDate": "Plánovaný termín",
      "plannedStartDate": "Plánovaný začiatok",
      "templates": "Šablóny",
      "this_month": "Tento mesiac",
      "this_week": "Tento týždeň",
      "today": "Dnes",
      "without_favorites": "bez obľúbených",
      "without_templates": "bez šablón"
    },
    "index": {
      "add_project": "pridať projekt",
      "add_sub_project": "Pridať podprojekt",
      "archive_project": "Archivovať projekt",
      "columns": {
        "dates": "Obdobie",
        "members": "Členovia tímu",
        "name": "Názov",
        "parentProject": "Nadradený projekt",
        "plannedDueDate": "Plán. termín",
        "plannedStartDate": "Plán. začiatok",
        "progress": "Priebeh"
      },
      "copy_project": "Duplikovat",
      "details": {
        "collapse": "Zbaliť riadok",
        "expand": "Rozbaliť riadok",
        "incomes": "Rozpočet - príjmy"
      },
      "export": "Exportovať výpis projektov",
      "favorite_default": "Hviezdičkou označené projekty budete mať poruke",
      "favorite_projects": "Obľúbené projekty",
      "move_project": "Presunúť projekt",
      "project_settings": "Nastavenie projektu",
      "remove_project": "Zmazať projekt",
      "rename_project": "Premenovať projekt",
      "share_project": "Zdieľať projekt",
      "show_all": "všetky projekty",
      "show_archive": "archív",
      "show_filter": "Zobraziť",
      "show_my": "moje projekty"
    },
    "modals": {
      "archive_project": {
        "text1": "Archiváciou projektu",
        "text2": "sa archivujú aj všetky jeho podprojekty",
        "title": "Naozaj chcete projekt archivovať?"
      },
      "destroy_project": {
        "all_files": "všetky súbory projektu",
        "all_tasks": "všetky úlohy v projekte",
        "members": "zoznam členov tímu",
        "members_note": "samotní používatelia zostávajú v Projektově, je možné ich priradiť do iných projektov",
        "save": "zmazať projekt",
        "settings": "nastavenie projektu",
        "spent_times": "strávené časy na úlohách",
        "subprojects_note": "Prípadné podprojekty sú súčasťou projektu a budú tiež odstránené.",
        "title": "Naozaj si želáte zmazať projekt?",
        "what_you_lost": "Zmazaním projektu nenávratne odstránite:"
      },
      "move_project": {
        "no_parent": "-- bez nadriadeného projektu --",
        "save": "presunúť",
        "title": "Presunutie projektu"
      },
      "new_project": {
        "choose_template": "Je nutné vybrať šablónu.",
        "create": "vytvoriť",
        "empty": "Prázdny",
        "folder": "Složka",
        "fromTemplate": "Zo šablóny",
        "name": "Názov",
        "parent": "Nadradený projekt",
        "parentErrorHint": "Len administrátor môže zadávať hlavné projekty. Ako manažér môžete zakladať len pod-projekty svojich projektov. Prosím vyberte nadradený projekt.",
        "parentFolder": "Nadřazená složka",
        "plan": {
          "from_end": "od konca projektu",
          "from_start": "od začiatku projektu",
          "help_left": "Plánovaný začiatok projektu je známy, od neho sú nastavené začiatky a termíny úloh.",
          "help_right": "Začiatky a termíny úloh sú nastavené tak, aby projekt v plánovanom termíne skončil.",
          "title": "Plánovať"
        },
        "planned_due_date": "Plán. termín",
        "planned_start_date": "Plán. začiatok",
        "template": "Šablóna",
        "title": "Nový projekt"
      },
      "rename_project": {
        "save": "premenovať",
        "title": "Premenovanie projektu"
      }
    },
    "navigation": {
      "labels": {
        "budget_entries": "Rozpočet projektu",
        "contacts": "Kontakty",
        "files": "Súbory",
        "gantt": "Ganttov diagram",
        "issues": "Úlohy",
        "last_activity": "Posledná aktivita",
        "mindmap": "Myšlienková mapa",
        "mobile_application": "Mobilná aplikácie",
        "my_page": "Moja stránka",
        "new_issues": "nový",
        "overview": "Prehľad projektu",
        "projects": "Projekty",
        "projektove_cz": "Projektově",
        "settings": "Nastavenie",
        "tasks_board": "Tabuľa s úlohami",
        "team_settings": "Členovia tímu",
        "time_entries": "Strávený čas",
        "users_settings": "Správa používateľov"
      }
    },
    "settings": {
      "convert_to_folder": "Převést projekt na složku",
      "convert_to_project": "Převést složku na projekt",
      "description": "Popis",
      "folder": "Složka",
      "is_template": "Projektová šablóna",
      "is_template_inherited": "Túto vlastnosť nemôžete zmeniť, pretože niektorý z nad-projektov je šablónou.",
      "name": "Názov",
      "only_admin_can_change": "Toto nastavenie môže zmeniť len administrátor.",
      "parentId": "Nadradený projekt",
      "parentIdFolder": "Nadřazená složka",
      "path": "Cesta",
      "plannedDueDate": "Plánovaný termín",
      "planned_due_date": "Plánovaný termín",
      "planned_income": "Plánovaný príjem",
      "planned_start_date": "Plánovaný začiatok",
      "real_incomes": "Skutočný príjem",
      "reset_trackers": "Obnoviť pôvodné",
      "shortcut": "Skratka",
      "shortcut_add": "Vyplňte skratku projektu. Potom získate e-mailovú adresu, kedy zaslaním či preposlaním e-mailu na túto adresu vytvoríte z e-mailu úlohu.",
      "shortcut_alt_add": "Vyplňte skratku projektu. Následne získate inštrukcie, ako odoslaním či preposlaním e-mailu vytvoríte úlohu v tomto projekte.",
      "shortcut_alt_note1": "Odoslaním či preposlaním e-mailu na adresu",
      "shortcut_alt_note2": "vytvorte z e-mailu úlohu. Z predmetu bude názov úlohy, z obsahu jeho popis.",
      "shortcut_alt_note3": "Na začiatok predmetu správy pripíšte skratku projektu a dvojbodku, napr. pre skratku 'oceňovanie' to bude vyzerať nasledovne:",
      "shortcut_alt_subject": "predmet správy ...",
      "shortcut_alt_subject_example": "oceňovanie: Prosím o cenovú kalkuláciu úprav podľa zadania",
      "shortcut_format": "Povolené sú iba písmená bez diakritiky a číslice. Je možné použiť bodku, podčiarkovník a pomlčku, avšak tieto nesmú byť použité na začiatku a ani na konci.",
      "shortcut_note1": "Zaslaním či preposlaním e-mailu na adresu",
      "shortcut_note2": "vytvoríte z e-mailu úlohu.",
      "shortcut_note3": "Z predmetu bude názov úlohy, z obsahu potom jej popis.",
      "tracker": "Fronta",
      "unsaved_changes": {
        "prompt": "Chcete prejsť na ďalšiu stránku?",
        "title": "Máte nezmenené zmeny projektu."
      },
      "unsortedCustomFields": "Projektová pole"
    },
    "team_settings": {
      "add_member": "pridať člena",
      "member": {
        "add_member": "Pridať člena do podprojektov",
        "add_member_error": "Používateľ je už v projekte %{project} zaradený",
        "add_member_info": "Používateľ bol úspešne priradený do projektu %{project}",
        "add_task": "Zadať úlohu",
        "change_contact_person": "Nové úlohy zadané zákazníkom sú najprv priradené uvedenej osobe.",
        "change_hour_cost": "Nastaviť hodinovú sadzbu pre sledovanie variabilných nákladov (strávené hodiny na úlohu * sadzba) v projekte",
        "change_role": "Zmeniť rolu člena v tomto tíme",
        "remove": "Odobrať člena z projektu",
        "remove_from_subprojects": "Prajete si člena odobrať aj z podprojektov?",
        "show_info": "Zobraziť podrobnosti o členovi"
      },
      "modals": {
        "add": {
          "add": "pridať",
          "check_all": "zaškrtnúť všetko",
          "choose_member": "Vyberte člena tímu",
          "choose_role": "Vyberte rolu",
          "no_user_found": "Hľadaného používateľa sme nenašli, je potrebné ho registrovať.",
          "search": "vyhľadať podľa mena alebo doplňujúcich informácií",
          "selected": "Označených: %{count}",
          "uncheck_all": "odškrtnúť všetko"
        },
        "register": {
          "add": "pridať do projektu",
          "add_additional_info": "pridať doplňujúce informácie",
          "additional_info_1": "doplňujúce informácie 1",
          "additional_info_2": "doplňujúce informácie 2",
          "already_registered": "Zadaného používateľa sme našli v systéme, nie je nutné ho registrovať.",
          "already_registered_external": "Pokúšate sa registrovať používateľa, ktorý je už pridaný ako externý sledujúci.",
          "choose_role": "Vyberte rolu",
          "delete_user": "Odoberte najskôr kolegu z externých sledujúcich.",
          "help_1": "Na zadaný e-mail bude zaslaná pozvánka, cez ktorú si zvaný s prvom prihlásení nastaví svoje vlastné heslo.",
          "help_2": "Po prijatí pozvánky potom aplikácia zašle rekapituláciu - kde a ako sa prihlásiť.",
          "hint_email": "E-mail",
          "office365_only": "Povoliť iba prihlásenie účtom Microsoft",
          "only_admin": "dostupné iba administrátorom",
          "register_user": "Registrovať používateľa",
          "set_name": "Pred registráciou prosím zadajte meno a priezvisko.",
          "title": "Registrovať nového používateľa",
          "user_registered": "Používateľ bol zaregistrovaný a na jeho e-mail bola zaslaná pozvánka k online spolupráci."
        }
      },
      "precheck": {
        "cannot_remove": "Nemožno odstrániť z projektu",
        "info": "%{user} má v projekte úlohy, ktoré riešia alebo ktorých je autorom.",
        "list_assignee": "Výpis úloh, ktorých je riešiteľom.",
        "list_author": "Výpis úloh, ktorých je autorom.",
        "list_check": "Skontrolujte tiež uzavreté úlohy.",
        "remove_anyway": "Napriek tomu odobrať"
      },
      "register_member": "registrovať nového používateľa",
      "register_member_external": "registrovať používateľa mimo doménu (LDAP)",
      "restricted_registrations": "O registrácii nových používateľov požiadajte vášho správcu.",
      "show_member_info": "Zobraziť podrobnosti o členovi",
      "title": "Členovia tímu"
    }
  },
  "quickadd": {
    "block_name": "Rýchle založenie úlohy"
  },
  "search": {
    "button": "hľadať",
    "header": "Hľadanie",
    "inComments": "hľadať aj v komentároch",
    "inProject": "hľadať len v jednom projekte",
    "notFound": "Nenalezeno.",
    "notfound": "Nenájdené."
  },
  "shared": {
    "button_back": "Späť",
    "disableEmails": {
      "long": {
        "disable": "pozastaviť posielanie e-mailov",
        "enable": "Spustiť posielanie e-mailov"
      },
      "short": {
        "disable": "Pozastaviť",
        "enable": "Spustiť"
      }
    },
    "errorView": {
      "403": "Nemáte dostatočné práva pre zobrazenie tejto stránky.",
      "404": "Stránka, ktorú sa snažíte zobraziť, neexistuje alebo bola vymazaná.",
      "oops_title": "Stratení v úlohách?",
      "options": "Možnosti",
      "step1": "Prejdite na výpis projektu a pozrite sa priamo do projektu,",
      "step1_note": "Ak projekt existuje a máte k nemu prístup, tu ho uvidíte .",
      "step2": "použite hľadanie vpravo hore",
      "step2_note": "Hľadanie prehľadáva názvy a popisy úloh v celej aplikácii. Ak úloha existuje a máte k nej prístup, nájdete ju.",
      "step3": "kontaktujte projektového manažéra."
    },
    "forced_logout": {
      "explanation": "Pravdepodobne bola vynútená obnova prístupových údajov (hesiel) zo strany správcu Vášho Projektově. Ak by sa Vám teda nedarilo znovu sa prihlásiť, skontrolujte svoj email, či ste nedostali inštrukcie pre zmenu hesla.",
      "reason": "Došlo k odhlásenie z bezpečnostných dôvodov.",
      "relog": "Znovu prihlásiť",
      "title": "Boli ste odhlásený"
    },
    "pnotify": {
      "close_title": "Zatvoriť"
    },
    "tasksReminders": {
      "approaching_deadline": {
        "few": "Bližia sa termíny úloh",
        "one": "Bliži sa termín úlohy",
        "other": "Bližia sa termíny úloh"
      },
      "close": "Skryť všetko",
      "hide": "Skryť"
    }
  },
  "time": {
    "am": "am",
    "formats": {
      "at": "%-d.%-m.%Y v %-H:%M",
      "default": "%-d.%-m.%Y, %-H:%M",
      "long": "%B %d, %Y %-H:%M",
      "short": "%d %b %-H:%M",
      "time": "%-H:%M",
      "time2": "%H:%M"
    },
    "moment_formats": {
      "default": "D.M.YYYY, H:mm",
      "only_time": "H:mm"
    },
    "pm": "pm"
  },
  "time_entries": {
    "hours_short": "hod.",
    "last_month": "Minulý mesiac",
    "modals": {
      "add": {
        "title": "Pridať strávený čas"
      },
      "edit": {
        "title": "Upraviť strávený čas"
      }
    },
    "next_month": "Budúci mesiac",
    "next_week": "Nasledujúci týždeň",
    "reports": {
      "by_projects_members_tasks": "podľa projektov, členov tímu a úloh",
      "by_projects_tasks": "podľa projektov a úloh",
      "title": "Reporty"
    },
    "this_month": "Tento mesiac",
    "this_week": "Tento týždeň",
    "today": "Dnes",
    "today_and_future": "Dnes a budúci",
    "yesterday": "Včera"
  },
  "timelog": {
    "compoundReport": {
      "groupByUsers": "Zoskupiť podľa užívateľov",
      "hideArchivedProjects": "Archivované projekty",
      "hideNullHours": "Zobraziť prázdne riadky",
      "sum": "Celkom",
      "title": "Súhrnný report",
      "users": "Používateľov"
    },
    "daterange": {
      "all_time": "všetko",
      "current_month": "tento mesiac",
      "current_week": "tento týždeň",
      "current_year": "tento rok",
      "custom": "vlastný",
      "last_month": "minulý mesiac",
      "last_week": "minulý týždeň",
      "last_year": "minulý rok",
      "title": "Časový rozsah",
      "today": "dnes",
      "yesterday": "včera"
    }
  },
  "users": {
    "add_alias": "pridať e-mail adresu",
    "change_password": {
      "button": "zmeniť heslo",
      "button_first_login": "nastaviť heslo",
      "current_password": "Súčasné heslo",
      "errors": {
        "invalid": "Zadané súčasné heslo nie je správne. Zmenu nebolo možné vykonať.",
        "missing_current": "Zadajte súčasné heslo",
        "often": "Heslo je možné zmeniť len 1x denne. Zmenu nebolo možné vykonať."
      },
      "password": "Nové heslo",
      "requirements": {
        "char_types": {
          "capital": "veľké písmená",
          "lowercase": "malé písmená",
          "number": "číslica",
          "special": "špeciálny znak, napr. !, $,"
        },
        "complexity": {
          "all": "obsahovať každý z týchto typov znakov",
          "other": "aspoň %{required} zo 4 typov znakov"
        },
        "length": "dĺžka najmenej %{length} znakov",
        "recent": "nesmie byť použité v minulosti",
        "title": "Z dôvodu zvýšenia bezpečnosti, heslo musí spĺňať nasledujúce požiadavky"
      },
      "set_password_and_begin": "Teraz je potrebné nastaviť vaše heslo a môžete začať.",
      "title": "Zmena hesla",
      "title_first_login": "Už len malý krôčik"
    },
    "me": "ja",
    "profile": {
      "add_custom_image": "Nahrať vlastný obrázok",
      "change_color": "Zmeniť farbu",
      "crop_image": "Orezať obrázok",
      "delete_image": "Odstrániť obrázok",
      "drag_to_upload": "Presuňte obrázok myšou alebo kliknite na vybrať súbor.",
      "profile_image": "Profilový obrázok",
      "upload_image": "Vybrať súbor"
    },
    "projects": {
      "bulkEditNote": "Pri zaškrtnutých projektov zmeniť rolu na",
      "change": "Zmeniť",
      "checkAll": "Zaškrtnúť všetko",
      "no_memberships": "Používateľ nie je členom žiadneho projektu.",
      "only_if_member": "Iba projekty, kde je členom",
      "project": "Projekt",
      "role": "Role",
      "title": "Projekty používateľa",
      "title_external": "Úlohy používateľa"
    },
    "settings": {
      "active_users": "Aktívni používatelia",
      "add_note": "Registrovať ďalších používateľov je možné na úrovni projektu - pridať člena do projektu.",
      "add_user": "Pridať užívateľa",
      "additional_info": "Doplnenie",
      "additional_info1": "Doplnenie 1",
      "additional_info2": "Doplnenie 2",
      "admin": "Admin",
      "archive": {
        "archived_header": "Užívateľ bol archivovaný",
        "archived_info": "Pri archiváciu užívateľa dochádza k jeho odobratie z řešitelství úloh. Aktuálne máte %{count} úloh bez riešiteľa. Tu je môžete skontrolovať:",
        "archived_link": "Úlohy bez riešiteľa",
        "label": "Archivovať používateľa %{name}?",
        "note": "U komentárov a strávených časov bude vidieť meno používateľa. Nebude sa však môcť prihlásiť do aplikácie a nebudú mu chodiť žiadne e-maily.",
        "note2": "Archivovaný užívateľ nemôže byť autorom ani riešiteľom žiadneho úlohy. Autorstvo úloh bude zmenené na Vás, řešitelství na \"nepriradené\"."
      },
      "archived_users": "Archivovaní používatelia",
      "available_month_hours": "Mesačný úväzok",
      "bar": {
        "all_label": "Počet užívateľov v aktuálnom balíčku.",
        "manage": "Spravovať účet",
        "paid_label": "Počet účtovaných užívateľov.",
        "title": "Využitie  balíčku"
      },
      "cant_access": "Nemáte dostatočné práva.",
      "delete": {
        "alternative": "Okrem zmazanie môžete užívateľov tiež archivovať.",
        "label": "Zmazat používateľa %{name}?",
        "note": "Zapísané strávené časy používateľa zostanú, ale bude u nich uvedený autor Neznámy. Pri úlohách, ktorých bol riešiteľom bude nastavené riešiteľ Nepriradený. V histórii úloh bude u jeho komentárov napísané neznámy používateľ.",
        "note_archive_label": "Archivácia",
        "note_label": "Zmazanie"
      },
      "edit_avatar": "Upraviť avatar",
      "edit_user": "Upraviť užívateľa",
      "errors": {
        "firstname": "Je nutné vyplniť krstné meno.",
        "lastname": "Je nutné vyplniť priezvisko.",
        "mail": "Zadejte e-mail.",
        "noCheckedProjects": "Je nutné zaškrtnúť aspoň jeden projekt.",
        "noSelectedRole": "Vyberte jednu z rolí."
      },
      "firstname": "Meno",
      "help_add": "Vhodné vyplniť pri väčších tímoch. Do prvého poľa uveďte napríklad pracovnú pozíciu, do druhého poľa oddelenia.",
      "help_admin": "Administrátor si môže zobraziť aj projekty, ktorých nie je členom, spravovať používateľov a účet v službe.",
      "hour": "hod",
      "hour_cost": "Hodinová sadzba",
      "issuesCheck": {
        "assignedTo": "je riešiteľom",
        "author": "je autorom",
        "pass": "nie je autorom ani riešiteľom žiadnych otvorených úloh.",
        "progress": "Kontrola úloh...",
        "tasks": "otovrených úloh"
      },
      "language": "Jazyk",
      "lastname": "Priezvisko",
      "mail": "E-mail",
      "month_hours": "hod / mesiac",
      "no_external_users": "Nemáte žiadne externé sledujúci",
      "notifications_opt_out": "Novým používateľom prednastaviť e-mailové upozornenia na",
      "projects": "Členstvo v projektoch",
      "register": "Registrovať",
      "register_user": "Registrovať užívateľa",
      "reset": {
        "password": "Naozaj si prajete resetovať heslo používateľa",
        "password_note": "Na kolegov e-mail bude odoslaný odkaz, cez ktorý si bude môcť nastaviť svoje nové heslo.",
        "reset_password_label": "Resetovať heslo"
      },
      "return_to_my_page": "Návrat na Moju stránku.",
      "settings": "Nastavenie",
      "success": "Úspešne registrované, používateľské prístupy zaslané na e-mail.",
      "tasks": "Úlohy",
      "title": "Správa používateľov",
      "title_external": "Externí sledujúci",
      "unwatch_tasks": {
        "confirm_question": "Odstrániť externého sledujúceho <b>%{name}</b> zo všetkých úloh?"
      }
    }
  },
  "wizard": {
    "add_projects": "pridať projekty",
    "add_template": "pridať šablóny",
    "blank_project": "Máte nevyplnený nadradený projekt.",
    "blank_template": "Musíte zadať nadradenú šablónu.",
    "create_templates": "vytvoriť šablóny",
    "e-mail": "e-mail",
    "examples": "Príklady",
    "first_name": "Meno",
    "go_to_projects": "prejsť na projekty",
    "invalid_mail": "Používateľ %{name} má neplatný e-mail.",
    "invalid_role": "Používateľ %{name} nemá vyplnenú rolu.",
    "last_name": "Priezvisko",
    "non_unique_emails": "Používatelia nemôžu používať rovnaký email.",
    "one_project": "Musíte založiť aspoň jeden projekt.",
    "one_template": "Musíte založiť aspoň jednu šablónu.",
    "project": "Zadajte názov projektu",
    "register_users": "registrovať používateľa",
    "role": "Vyberte rolu",
    "skip": "preskočiť",
    "start": "začať",
    "step1": "privítanie",
    "step2": "pridanie  projektov",
    "step3": "registrácia  používateľov",
    "subproject": "Zadajte názov podprojektu",
    "subtemplate": "Zadajte názov podprojektu šablóny",
    "template": "Zadajte názov šablóny",
    "template_text1": "Šablóny založené.",
    "template_text2": "Vo výpise projektov šablónu uvidíte označenú ikonou šálky s kávou. Teraz na ňu stačí kliknúť a začať pridávať úlohy.",
    "template_text3": "Tip: vyskúšajte v šablóne v ľavom menu kliknúť na",
    "template_text4": "a naplánovať úlohy cez myšlienkovú mapu.",
    "template_textHelp": "Šablóny sú výbornou cestou ako nanečisto naplánovať projekt. V šablóne môžete rozdať úlohy a nastaviť termíny bez toho, aby o tom chodili notifikácie kolegom. Takto pripravíte základ projektu a následne zo šablóny ľahko založíte ostrý projekt.",
    "template_title1": "Pridať šablóny projektu",
    "template_title2": "Hotovo",
    "text1_step2": "Projekty tvoria zložky, do ktorých zadávate úlohy. Tu si môžete vytvoriť štruktúru prvých projektov a pod-projektov. Pre inšpiráciu sme vpravo priložili niekoľko príkladov, stačí zadať aspoň jeden projekt.",
    "text1_step3": "Tento krok je dobrovoľný, avšak v tíme sa projekt realizuje rýchlejšie.",
    "text1_step3b": "Kolegom budete môcť čoskoro rozdať úlohy, ktoré Projektově postráži.",
    "text1_step4": "Gratulujeme, Projektově je pre Vás pripravené.",
    "text2_step3": "Manažér - vidí v projektoch všetko, môže všetko.",
    "text2_step4": "Teraz stačí rozkliknúť akýkoľvek projekt a pridávať úlohy.",
    "text3_step3": "Člen tímu - pracuje na úlohách, môže zakladať úlohy sebe i ostatným.",
    "text3_step4": "Alebo si prajete ešte",
    "text4_step3": "Zákazník - rola k nahliadaniu.",
    "text4_step4": "vytvoriť šablónu projektu?",
    "text5_step3": "Externista - rovnako ako člen tímu, vidí ale iba svoje úlohy. Napríklad keď je v projekte úloh 8, on vidí iba 1, svoju.",
    "text6_step3": "Viac tu: ",
    "text7_step3": "zobraziť viac k rolám",
    "text_step1": "Ako mať poriadok a prehľad nad realizovanými projektami? V nasledujúcich 2 krokoch spoločne začneme.",
    "title_step2": "Aké projekty realizujete?",
    "title_step3": "Pridajte svojich kolegov",
    "title_step4": "Hotovo",
    "welcome_text": "Vitajte v Projektově"
  }
});
